import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useReactiveVar } from '@apollo/client'

import { FormField, FormLabel, FormRow } from './form'
import Input from './input'
import Link from './link'
import Tooltip from './tooltip'
import { UrlValidationMessage } from './url-validation-message'
import { currentUserDetails } from '../api/apollo/variables'
import { isAdminUser, isValidUrl } from '../helpers'
import { makeLinkSecure } from '../helpers/track-create'
import { defaultValidationChecksValues } from '../helpers/track-module'
import useLogAction from '../hooks/useLogAction'
import { UpdateFormOptions } from '../hooks/useTrackCreateSavedValues'
import useUrlValidation from '../hooks/useUrlValidation'
import styles from '../styles/track-create-landing-page-field.module.scss'
import { GetCampaignCodeGeneratorQuery } from '../__gql-types__/graphql'

interface ErrorItem {
  isError: boolean
  isTracked: boolean
}

interface LandingPageFieldProps {
  showField?: boolean
  bottomBorder?: boolean
  isApp?: boolean
  validationChecks:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['validationChecks']
    | null
  savedValue: string[]
  onChange: (newVal: string, options?: UpdateFormOptions) => void
  submitError?: boolean
  /** If the rest of the form is ready to submit, pressing enter should submit the whole form */
  submitOnEnterKey?: boolean
}

const LandingPageField = ({
  showField = true,
  bottomBorder = true,
  isApp,
  validationChecks,
  savedValue,
  onChange,
  submitError,
  submitOnEnterKey,
}: LandingPageFieldProps) => {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const { validateUrls, validationResults } = useUrlValidation()

  const isAdmin = isAdminUser(userPermission)

  const formRowRef = useRef<HTMLDivElement>(null)

  const [currentValue, setCurrentValue] = useState(savedValue[0] || '')
  const [noSpacesError, setNoSpacesError] = useState<ErrorItem>({
    isError: false,
    isTracked: false,
  })
  const [noSpecialCharactersError, setNoSpecialCharactersError] = useState<
    ErrorItem
  >({
    isError: false,
    isTracked: false,
  })

  // Update URL value on e.g. clearing the form
  useEffect(() => {
    if (savedValue[0] !== currentValue) {
      setNoSpacesError({ isTracked: false, isError: false })
      setNoSpecialCharactersError({ isTracked: false, isError: false })
      setCurrentValue(savedValue[0])
    }
  }, [savedValue])

  const noSpecialCharacters = useMemo(() => {
    return (
      validationChecks?.find(
        (check) => check.name === 'NO_SPECIAL_CHARS_LANDING_PAGE',
      )?.enabled || false
    )
  }, [validationChecks])

  /** Replaces spaces and prevents special characters when rule is set */
  const formatInput = useCallback(
    (input: string) => {
      setNoSpacesError((curr) => ({ ...curr, isError: false }))
      setNoSpecialCharactersError((curr) => ({ ...curr, isError: false }))

      let preparedVal = input

      // Spaces are not allowed in URLs
      if (/\s/.test(preparedVal)) {
        preparedVal = preparedVal.replaceAll(/\s/g, '')

        if (preparedVal.length > 0) {
          let errorTracked = noSpacesError.isTracked

          if (!errorTracked) {
            logAction({
              variables: {
                action: 'track-error-landing-page-spaces',
                extra: '',
                websiteSection: 'track-create',
                functionName: 'beforeChange',
                pagePath: '/track/create-links',
              },
            })

            errorTracked = true
          }

          setNoSpacesError({ isError: true, isTracked: errorTracked })
        }
      }

      const hasSpecialCharacters =
        noSpecialCharacters &&
        preparedVal.match(
          new RegExp(
            defaultValidationChecksValues.NO_SPECIAL_CHARS_LANDING_PAGE,
          ),
        ) !== null

      if (hasSpecialCharacters) {
        let errorTracked = noSpecialCharactersError.isTracked

        if (!errorTracked) {
          logAction({
            variables: {
              action: 'track-error-landing-page-spaces',
              extra: '',
              websiteSection: 'track-create',
              functionName: 'beforeChange',
              pagePath: '/track/create-links',
            },
          })

          errorTracked = true
        }

        setNoSpecialCharactersError({
          isError: true,
          isTracked: errorTracked,
        })
      }

      return preparedVal
    },
    [noSpecialCharacters, noSpacesError, noSpecialCharactersError],
  )

  const onValueChange = useCallback(
    (newVal: string) => {
      setCurrentValue(newVal)

      let disableForm = !newVal

      /** Disable form if URL contains special characters with rule enabled */
      if (!disableForm) {
        const hasSpecialCharacters =
          noSpecialCharacters &&
          newVal.match(
            new RegExp(
              defaultValidationChecksValues.NO_SPECIAL_CHARS_LANDING_PAGE,
            ),
          ) !== null

        if (hasSpecialCharacters) {
          disableForm = true
        }
      }

      onChange(
        newVal,
        disableForm
          ? { errorsToAdd: ['landingPage'] }
          : {
              errorsToRemove: ['landingPage'],
            },
      )
    },
    [noSpecialCharacters, onChange],
  )

  // Check the state of valid URLs
  useEffect(() => {
    if (
      isValidUrl(currentValue) &&
      !Object.prototype.hasOwnProperty.call(validationResults, currentValue)
    ) {
      validateUrls([currentValue])
    }
  }, [currentValue])

  if (!showField) {
    return null
  }

  return (
    <FormRow ref={formRowRef} bottomBorder={bottomBorder} includePaddingBottom>
      <FormLabel>
        <Tooltip
          id="landing-page-tooltip"
          useIcon
          tooltipPosition="right"
          tooltipMessage={
            isApp
              ? 'The URL users will go to if they click on the app link on a desktop device.'
              : 'This is the page your user will land on once they click on your campaign link. Make sure your link does not already contain a campaign code.'
          }
        >
          {isApp ? 'Fallback URL' : 'Landing page URL'}
        </Tooltip>
      </FormLabel>
      <FormField>
        <Input
          id="landing-page-url"
          name="Landing page URL"
          className={styles.inputField}
          placeholder="Type landing page URL"
          showClear
          delay={800}
          error={
            submitError ||
            noSpacesError.isError ||
            noSpecialCharactersError.isError
          }
          value={currentValue}
          beforeChange={(inputValue) => formatInput(inputValue)}
          onValueChange={onValueChange}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            // Prevent the form from submitting on Enter if not ready
            if (e.key === 'Enter' && !submitOnEnterKey) {
              e.preventDefault()
            }
          }}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
            // If form is not ready to submit, tab to the next field instead
            // Triggered on keyup to prevent keyup event occurring after focus on new element
            if (e.key === 'Enter' && !submitOnEnterKey) {
              if (formRowRef.current) {
                let nextRow = formRowRef.current.nextElementSibling

                while (nextRow && !nextRow.classList.contains('formRow')) {
                  nextRow = nextRow.nextElementSibling

                  if (nextRow) {
                    const nextRowInput = nextRow.querySelector('input')

                    if (nextRowInput) {
                      nextRowInput.focus()
                      break
                    }
                  }
                }
              }
            }
          }}
          onPaste={(e) => {
            // Specifically to block error message being shown about spaces being removed from pasted data
            const newPastedText = formatInput(e.clipboardData.getData('Text'))

            onValueChange(newPastedText)
          }}
          onBlur={(e) => {
            setNoSpacesError((curr) => ({ ...curr, isTracked: false }))
            setNoSpecialCharactersError((curr) => ({
              ...curr,
              isTracked: false,
            }))

            // Accomodate a delay in setting currentValue
            // This may happen if blur occurs too quickly
            const valueOnBlur = e.target.value

            // Automatically add protocol to link if not present
            const validUrl = makeLinkSecure(valueOnBlur)

            if (validUrl !== valueOnBlur) {
              // Link did not have protocol
              logAction({
                variables: {
                  action: 'track-error-landing-page-missing-protocol',
                  extra: '',
                  websiteSection: 'track-create',
                  functionName: 'onBlur',
                  pagePath: '/track/create-links',
                },
              })

              onValueChange(validUrl)
            }
          }}
        />
        {noSpacesError.isError && (
          <p className={styles.inputError}>
            Spaces are not allowed in the landing page URL.
          </p>
        )}
        {noSpecialCharactersError.isError && (
          <p className={styles.inputError}>
            {isAdmin ? (
              <>
                Special characters (?=&) are not allowed to avoid users copying
                landing pages with existing parameters.{' '}
                <Link
                  type="arrowForward"
                  href="/track/edit-parameters-and-rules"
                >
                  Disable this rule for deep linking
                </Link>
              </>
            ) : (
              'Special characters (?=&) are disabled by your admin.'
            )}
          </p>
        )}
        {submitError && (
          <p className={styles.inputError}>
            You must enter a valid landing page URL.
          </p>
        )}
        {isValidUrl(currentValue) && (
          <UrlValidationMessage
            url={currentValue}
            validationDetails={validationResults[currentValue]}
          />
        )}
      </FormField>
    </FormRow>
  )
}

export default LandingPageField
