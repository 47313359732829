import React from 'react'
import classNames from 'classnames'

import { Label } from './input'
import styles from '../styles/input-v2.module.scss'

interface RadioButtonProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label: React.ReactNode
  labelClassName?: string
  subLabel?: string
}

export const RadioButton = ({
  label,
  labelClassName,
  subLabel,
  ...props
}: RadioButtonProps) => {
  const { id, name, className } = props

  return (
    <Label
      id={id || name}
      className={classNames(labelClassName, styles.radioLabel)}
      optional={subLabel}
    >
      <input
        type="radio"
        id={id}
        name={name}
        className={classNames(className, styles.radioInput)}
        {...props}
      />
      <span>{label}</span>
    </Label>
  )
}

interface RadioGroupProps {
  className?: string
  horizontal?: boolean
  options: Omit<RadioButtonProps, 'checked' | 'onChange' | 'labelClassName'>[]
  optionsClassName?: string
  selectedValue?: string
  onChange: (value: string) => void
}

export const RadioGroup = ({
  className,
  horizontal,
  options,
  optionsClassName,
  selectedValue,
  onChange,
}: RadioGroupProps) => {
  return (
    <div
      className={classNames(className, styles.radioPanel, {
        [styles.horizontal]: horizontal,
      })}
    >
      {options.map(({ id, value, ...optionProps }) => (
        <RadioButton
          key={value as string}
          id={id || (value as string)}
          labelClassName={optionsClassName}
          checked={typeof selectedValue === 'string' && selectedValue === value}
          onChange={(e) => onChange(e.target.id)}
          {...optionProps}
        />
      ))}
    </div>
  )
}
