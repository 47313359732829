import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import FileSaver from 'file-saver'
import moment from 'moment'
import numeral from 'numeraljs'

import { ButtonRow } from './button-row'
import Button, { NavigateButton } from './button'
import AddNewSelectField from './campaign-codes-add-new-select-field'
import DeleteButtonWithConfirmation from './delete-button-with-confirmation'
import FileDragAndDrop from './file-drag-and-drop'
import { ClickEditInput, SearchInput } from './input'
import Link from './link'
import Modal from './modal'
import OrderArrow from './order-arrow'
import Pagination from './pagination'
import { Body, BodyItem, Cell, Head, HeaderCell, Table } from './row'
import SelectBox, { SelectBoxSimple } from './select-box'
import Tooltip from './tooltip'
import { currentUserDetails } from '../api/apollo/variables'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import {
  deleteGeneratorParameterSelectOption,
  updateGeneratorParameterSelectOption,
  updateGeneratorParameterSelectResetParent,
} from '../api/graphql/track-edit-client'
import { getUserAccounts } from '../api/graphql/user-client'
import { bulkUploadDropdowns } from '../api/REST/track-client'
import {
  GeneratorFields,
  GeneratorSelectFields,
  ValidationChecks,
} from '../api/types'
import hide from '../assets/icon-eye-open.svg'
import hidden from '../assets/icon-eye-crossed-inactive.svg'
import deleteImg from '../assets/bin.svg'
import { brandName, supportEmail } from '../core/constants'
import {
  getCsvString,
  getItemByKeyValue,
  getUrlQuery,
  isAdminUser,
  prepareInput,
} from '../helpers'
import useLogAction from '../hooks/useLogAction'
import useMobile from '../hooks/useMobile'
import useOnboarding from '../hooks/useOnboarding'
import useTableSortFilter from '../hooks/useTableSortFilter'
import styles from '../styles/track-dropdowns-table.module.scss'
import { BoxedText } from './typography'

interface DropdownsTableProps {
  param: GeneratorFields
  parentsList: GeneratorFields[]
  validation: ValidationChecks[]
}

export const DropdownsTable = ({
  param,
  parentsList,
  validation,
}: DropdownsTableProps) => {
  const { workspaceID, companyID } = useReactiveVar(currentUserDetails)

  const query = getUrlQuery()

  const fieldID = query?.get('fieldID')

  // TODO: Get all workspaces for a company
  const { data: userAccountData } = useQuery(getUserAccounts)

  const [refetchGenerator] = useLazyQuery(getCampaignCodeGenerator, {
    fetchPolicy: 'network-only',
  })

  const [updateSelectOption] = useMutation(updateGeneratorParameterSelectOption)
  const [deleteSelectOption] = useMutation(deleteGeneratorParameterSelectOption)
  const [resetParentOptions, { loading: resetOptionsLoading }] = useMutation(
    updateGeneratorParameterSelectResetParent,
  )

  /** Get the workspaces for which the current user is an admin
   * The workspace-specific restriction rule only applies to companies using shared generators
   * This code doesn't check if a generator is shared
   * So workspace-restricted dropdown values just won't matter if the current workspace's generator isn't shared
   * Shared generators are currently only configurable manually via the backend
   * TODO: Accommodate shared generators via UI
   */
  const userAdminWorkspaces = useMemo(() => {
    if (!companyID || !userAccountData) return []

    return userAccountData.currentUser.userAccountProfiles
      .filter(
        (account) =>
          account.companyID === companyID &&
          isAdminUser(account.userPermission),
      )
      .map((account) => ({
        optionID: account.accountID,
        optionName: account.accountName,
      }))
  }, [userAccountData, companyID])

  const logAction = useLogAction()

  const isMobile = useMobile(769)

  // Reverse by order received from API on load
  const [fieldsCorrectOrder, setFieldsCorrectOrder] = useState<
    GeneratorSelectFields[] | null | undefined
  >(null)
  const [restrictDropdowns, setRestrictDropdowns] = useState(false)
  const [hasChildren, setHasChildren] = useState(false)
  const [parentFieldID, setParentFieldID] = useState('')
  const [confirmRemoveParentActive, setConfirmRemoveParentActive] = useState(
    false,
  )
  const [showAdd, setShowAdd] = useState(false)
  const [currOrderAsc, setCurrOrderAsc] = useState(true)
  const [bulkUploadModal, setBulkUploadModal] = useState(false)
  const [bulkUploadStatus, setBulkUploadStatus] = useState<{
    error: string | React.ReactElement
    success: string
    loading: boolean
  }>({
    success: '',
    error: '',
    loading: false,
  })

  const { fullOnboardingSections, updateOnboardingSection } = useOnboarding()

  const onBulkUpload = useCallback(
    async (acceptedFiles: File[]) => {
      setBulkUploadStatus({ success: '', error: '', loading: true })

      if (acceptedFiles.length > 0) {
        const res = await bulkUploadDropdowns({
          file: acceptedFiles.pop() as File,
          parameterID: param.fieldID,
        })

        if (res === true) {
          setBulkUploadStatus({
            error: '',
            success: 'Your dropdowns have been added.',
            loading: false,
          })

          // Refetch to update dropdowns
          refetchGenerator()

          logAction({
            variables: {
              action: 'bulk-upload-parameter-options-success',
              functionName: 'bulkImportParameterOptions',
              pagePath: '/track/edit-dropdowns',
              websiteSection: 'track',
            },
          })

          setTimeout(() => {
            setBulkUploadModal(false)
          }, 3000)

          return
        }

        if (
          res?.status === 400 &&
          res?.error?.response?.data &&
          res?.error?.response?.headers['content-type'] ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          // Save the file as a blob and add a button to download it
          const fileBlob = new Blob([new Uint8Array(res.error.response.data)], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })

          setBulkUploadStatus({
            error: (
              <>
                <span>
                  There were validation issues with your form.{' '}
                  <NavigateButton
                    variant="text"
                    onPress={async () => {
                      const now = new Date(Date.now())

                      await FileSaver.saveAs(
                        fileBlob,
                        `${moment(now).format(
                          'YYYY-MM-DD',
                        )} - ${brandName} Bulk Upload Errors.xlsx`,
                      )
                    }}
                  >
                    Download error sheet
                  </NavigateButton>
                </span>
              </>
            ),
            success: '',
            loading: false,
          })

          return
        }

        const resJSON =
          res?.status === 500
            ? res?.error?.response?.data
            : JSON.parse(
                String.fromCharCode.apply(
                  null,
                  // @ts-ignore
                  new Uint8Array(res?.error?.response?.data),
                ),
              )

        const type = resJSON?.detail || ''

        if (
          type === 'BAD_TEMPLATE' ||
          type === 'Template file does not match expected format'
        ) {
          setBulkUploadStatus({
            error:
              'Incorrect import template. Please download and use the sample template.',
            success: '',
            loading: false,
          })
        } else if (type === 'BAD_FILE_FORMAT') {
          setBulkUploadStatus({
            error: 'Incorrect file type.',
            success: '',
            loading: false,
          })
        } else if (type === 'FAILED_UPLOAD') {
          setBulkUploadStatus({
            error: 'File upload has failed, please try again.',
            success: '',
            loading: false,
          })
        } else {
          setBulkUploadStatus({
            error: (
              <span>
                File upload has failed. Please email{' '}
                <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> for
                help.
              </span>
            ),
            success: '',
            loading: false,
          })
        }

        logAction({
          variables: {
            action: 'bulk-upload-parameter-options-failed',
            functionName: 'bulkImportParameterOptions',
            pagePath: '/track/edit-dropdowns',
            websiteSection: 'track',
          },
        })
      }
    },
    [param.fieldID],
  )

  useEffect(() => {
    if (!fieldID || param.fieldID !== fieldID) return

    setShowAdd(true)
  }, [fieldID])

  // Search optionValue and optionName together
  // Custom search fn ensures search is not fuzzy (default)
  const customSearch = useCallback(
    (data: GeneratorSelectFields[], searchTerm?: string) => {
      if (!searchTerm) return data

      const searchPattern = searchTerm
        .replace(/[^a-zA-Z0-9]/g, '')
        .split('')
        .join('.*')

      return data.filter(
        (item) =>
          item.optionValue
            .toLowerCase()
            .match(new RegExp(searchPattern, 'i')) !== null ||
          item.optionName
            .toLowerCase()
            .match(new RegExp(searchPattern, 'i')) !== null,
      )
    },
    [],
  )

  const {
    initialSort,
    setInitialSort,
    orderAsc,
    sortKey,
    setSortOrder,
    pages,
    activePage,
    setActivePage,
    setSearchTerm,
    count,
    orderedList: orderedFields,
    total,
  } = useTableSortFilter({
    inputList: fieldsCorrectOrder,
    startingRowsPerPage: 10,
    initialSortAsc: currOrderAsc,
    customSearches: {
      all: customSearch,
    },
  })

  const completeOnboardingSection = useCallback(() => {
    const editTaxonomy = fullOnboardingSections.account.find(
      (section) => section.onboardingSectionID === 'editTaxonomy',
    )

    if (editTaxonomy && !editTaxonomy.sectionCompleted) {
      updateOnboardingSection('editTaxonomy', 'account')
    }
  }, [fullOnboardingSections])

  // Check if the parameter has restrictions against it
  useEffect(() => {
    if (param.selectFields) {
      const selectFieldFilter = param.selectFields.find(
        (field) => field.optionFilter && field.optionFilter.length > 0,
      )

      if (selectFieldFilter && selectFieldFilter.optionFilter) {
        setRestrictDropdowns(true)
        setParentFieldID(selectFieldFilter.optionFilter[0].parentFieldID)

        let _hasChildren = false

        param.selectFields.forEach((selectField) => {
          if (_hasChildren || !selectField.optionFilter) return

          if (
            selectField.optionFilter.find(
              (optionFilter) => optionFilter.parentOptionIDs.length > 0,
            )
          ) {
            _hasChildren = true
          }
        })

        setHasChildren(_hasChildren)
      }
    }
  }, [param])

  const selectedParent: null | GeneratorFields = useMemo(() => {
    // Restrict by workspace
    if (parentFieldID === '' || parentFieldID === 'account') return null

    return getItemByKeyValue(parentsList, 'fieldID', parentFieldID)
  }, [parentFieldID])

  useEffect(() => {
    if (initialSort) {
      setFieldsCorrectOrder(
        JSON.parse(JSON.stringify(param.selectFields)).reverse(),
      )
    } else {
      setFieldsCorrectOrder(param.selectFields)
    }
  }, [initialSort, parentsList, param])

  const SelectParent = (
    <>
      <span>Only show if</span>
      <SelectBoxSimple
        className={styles.selectWrapper}
        name={`select-${param.fieldName}`}
        value={parentFieldID}
        onChange={(val) => {
          if (hasChildren) {
            setConfirmRemoveParentActive(true)
          } else {
            setParentFieldID(val)
          }
        }}
      >
        <>
          <option value="">None</option>
          {/* Dropdown values can be restricted by workspace */}
          <option value="account">Workspace</option>
          {parentsList.map((item: GeneratorFields) => {
            return (
              <option key={item.fieldID} value={item.fieldID}>
                {item.fieldName}
              </option>
            )
          })}
        </>
      </SelectBoxSimple>
      <span>=</span>
    </>
  )

  return (
    <>
      <ButtonRow className={styles.dropdownHeader}>
        <div className={styles.tableSearch}>
          <SearchInput
            onChange={(value) => {
              setSearchTerm(value || '')
              setActivePage(1)
            }}
            delay={50}
          >
            <>
              {count !== total ? `${numeral(count).format('0,0')}/` : ''}
              {numeral(total).format('0,0')} option
              {total > 1 ? 's' : ''}
            </>
          </SearchInput>
        </div>
        <div className={styles.flexBtns}>
          {!isMobile && (
            <Button onPress={() => setShowAdd(!showAdd)}>Add dropdown</Button>
          )}
          {!isMobile && (
            <Button
              variant="secondary"
              onPress={() => setBulkUploadModal(true)}
            >
              Bulk import
            </Button>
          )}
          <Button
            variant="secondary"
            isDisabled={!!parentFieldID && hasChildren}
            onPress={() => setRestrictDropdowns(!restrictDropdowns)}
          >
            {restrictDropdowns
              ? "Don't restrict dropdowns"
              : 'Restrict dropdowns'}
          </Button>
        </div>
      </ButtonRow>

      <Table className={styles.table} inline>
        {isMobile && (
          <>
            {restrictDropdowns && <HeaderCell>{SelectParent}</HeaderCell>}
            <HeaderCell>
              <Button variant="secondary" onPress={() => setShowAdd(!showAdd)}>
                Add dropdown
              </Button>
            </HeaderCell>
          </>
        )}
        {!isMobile && (
          <Head className={styles.tableHeader}>
            <HeaderCell
              className={styles.headerCellWithButton}
              width={restrictDropdowns ? 340 : 503}
            >
              <Button
                variant="text"
                color="grey"
                className={styles.headerButton}
                onPress={() => {
                  if (initialSort) {
                    setInitialSort(false)
                  }
                  setCurrOrderAsc(!orderAsc)

                  setSortOrder('optionName')
                }}
              >
                <span>Dropdown option name</span>
                <OrderArrow
                  className={styles.orderArrow}
                  currentKey="optionName"
                  sortKey={sortKey}
                  orderAsc={orderAsc}
                />
                <Tooltip
                  id="dropdown-option-name-tooltip"
                  useIcon
                  tooltipIconClassName={styles.headerTooltip}
                  tooltipPosition="bottom"
                  tooltipMessage={
                    <p>
                      The name users can select on{' '}
                      <BoxedText>Track &gt; Create links</BoxedText> page
                      dropdown.
                    </p>
                  }
                />
              </Button>
            </HeaderCell>
            <HeaderCell width={restrictDropdowns ? 340 : 503}>
              <Button
                variant="text"
                color="grey"
                className={styles.headerButton}
                onPress={() => {
                  if (initialSort) {
                    setInitialSort(false)
                  }

                  setCurrOrderAsc(!orderAsc)

                  setSortOrder('optionValue')
                }}
              >
                Code
                <OrderArrow
                  className={styles.orderArrow}
                  currentKey="optionValue"
                  sortKey={sortKey}
                  orderAsc={orderAsc}
                />
                <Tooltip
                  id="dropdown-option-value-tooltip"
                  useIcon
                  tooltipIconClassName={styles.headerTooltip}
                  tooltipPosition="bottom"
                  tooltipMessage="The value used in the URL when the option is selected."
                />
              </Button>
            </HeaderCell>
            {restrictDropdowns && (
              <HeaderCell width={340}>{SelectParent}</HeaderCell>
            )}
            <HeaderCell width={120}>
              Actions
              <Tooltip
                id="dropdown-actions-tooltip"
                useIcon
                maxWidth={370}
                tooltipClassName={styles.actionsTooltip}
                tooltipIconClassName={styles.headerTooltip}
                tooltipPosition="left"
                tooltipMessage={
                  <>
                    <div>
                      <img src={hide} alt="hide" />
                      <p>Temporarily hide this name and value.</p>
                    </div>
                    <div>
                      <img src={hidden} alt="hidden" />
                      <p>Unhide name and value.</p>
                    </div>
                    <div>
                      <img src={deleteImg} alt="delete" />
                      <p>Remove this name and value permanently.</p>
                    </div>
                  </>
                }
              />
            </HeaderCell>
          </Head>
        )}
        <Body>
          {showAdd && (
            <>
              <AddNewSelectField
                fieldID={param.fieldID}
                restrictDropdowns={restrictDropdowns}
                fields={param.selectFields as GeneratorSelectFields[]}
                fieldName={param.fieldName}
                validation={validation}
                setSearchTerm={setSearchTerm}
                shownValues={orderedFields[activePage - 1]}
              />
            </>
          )}
          {orderedFields &&
            orderedFields[activePage - 1] &&
            [...orderedFields[activePage - 1]].map((item, index) => {
              const { optionName, optionValue, optionID, optionFilter } = item

              let restrictDropdownsOptionsList:
                | GeneratorSelectFields[]
                | { optionName: string; optionID: string }[] = []

              if (parentFieldID === 'account') {
                restrictDropdownsOptionsList = userAdminWorkspaces
              } else if (selectedParent && selectedParent.selectFields) {
                restrictDropdownsOptionsList = selectedParent.selectFields
              }

              const restrictDropdownsValue =
                optionFilter && optionFilter.length > 0
                  ? optionFilter[0].parentOptionIDs
                  : []

              return (
                <BodyItem
                  key={`${param.fieldName}${optionName}${optionValue}${optionID}`}
                  className={styles.bodyItem}
                >
                  <Cell width={restrictDropdowns ? 340 : 503}>
                    <ClickEditInput
                      hide={item.hide}
                      id={`${param.fieldName} ${optionName} name`}
                      name={`${param.fieldName} ${optionName} name`}
                      value={optionName}
                      onChange={(value: string) => {
                        updateSelectOption({
                          variables: {
                            fieldID: param.fieldID,
                            optionID: item.optionID,
                            optionName: value,
                          },
                        })
                      }}
                    />
                  </Cell>
                  <Cell width={restrictDropdowns ? 340 : 503}>
                    <ClickEditInput
                      hide={item.hide}
                      id={`${param.fieldName} ${optionValue} value`}
                      name={`${param.fieldName} ${optionValue} value`}
                      value={optionValue}
                      beforeChange={(value: string) => {
                        return prepareInput(value, validation)
                      }}
                      onChange={(value: string) => {
                        updateSelectOption({
                          variables: {
                            fieldID: param.fieldID,
                            optionID: item.optionID,
                            optionValue: value,
                          },
                        })
                      }}
                    />
                  </Cell>
                  {restrictDropdowns && (
                    <Cell width={340}>
                      {parentFieldID !== '' && (
                        <SelectBox
                          key={`${param.fieldName} ${optionName} ${optionValue}`}
                          id={`${param.fieldName} ${optionName} select`}
                          className={styles.multiSelect}
                          placeholder="Always show"
                          isMulti
                          labelKey="optionName"
                          valueKey="optionID"
                          value={restrictDropdownsOptionsList.filter(
                            (option) =>
                              restrictDropdownsValue.indexOf(option.optionID) >
                              -1,
                          )}
                          options={restrictDropdownsOptionsList}
                          onChange={(newValue) => {
                            const newOptionIDs = newValue.map(
                              (option) => option.optionID,
                            )

                            // Preserve workspaces that the current user isn't admin on
                            if (parentFieldID === 'account') {
                              restrictDropdownsValue.forEach((id) => {
                                if (
                                  !restrictDropdownsOptionsList.find(
                                    (option) => option.optionID === id,
                                  )
                                )
                                  newOptionIDs.push(id)
                              })
                            }

                            updateSelectOption({
                              variables: {
                                fieldID: param.fieldID,
                                optionID: item.optionID,
                                optionFilter: [
                                  {
                                    parentFieldID,
                                    parentOptionIDs: newOptionIDs,
                                  },
                                ],
                              },
                            })
                          }}
                        />
                      )}
                    </Cell>
                  )}
                  <Cell width={120}>
                    <Button
                      variant="iconOnly"
                      icon={{
                        src: item.hide ? hidden : hide,
                        alt: item.hide ? 'Unhide' : 'Hide',
                      }}
                      onPress={() => {
                        updateSelectOption({
                          variables: {
                            fieldID: param.fieldID,
                            optionID: item.optionID,
                            hide: !item.hide,
                          },
                        })
                      }}
                    />
                    <DeleteButtonWithConfirmation
                      odd={!showAdd ? !(index % 2) : !!(index % 2)}
                      onClick={() => {
                        deleteSelectOption({
                          variables: {
                            fieldID: param.fieldID,
                            optionID: item.optionID,
                          },
                        })

                        logAction({
                          variables: {
                            action:
                              'update-generator-parameter-delete-select-option',
                            extra: JSON.stringify({
                              fieldID: param.fieldID,
                              optionID: item.optionID,
                              accountID: workspaceID,
                            }),
                            websiteSection: 'track',
                            functionName:
                              'updateGeneratorParameterSelectDeleteOption',
                            pagePath: '/track/edit-dropdowns',
                          },
                        })

                        completeOnboardingSection()
                      }}
                    >
                      <p>Are you sure you want to remove this row?</p>
                    </DeleteButtonWithConfirmation>
                  </Cell>
                </BodyItem>
              )
            })}
        </Body>
      </Table>
      {pages > 1 && (
        <ButtonRow className={styles.paginationRow}>
          <Pagination
            pages={pages}
            activePage={activePage}
            onChange={(index) => setActivePage(index)}
          />
        </ButtonRow>
      )}
      {confirmRemoveParentActive && (
        <Modal
          setIsOpen={setConfirmRemoveParentActive}
          headerColor="pink"
          isWarning
          modalHeader="Remove dropdown restrictions"
          noText="Cancel"
          yesText="Yes"
          yesButtonLoading={resetOptionsLoading}
          onYes={async () => {
            await resetParentOptions({
              variables: {
                fieldID: param.fieldID,
              },
            })

            setConfirmRemoveParentActive(false)
            setParentFieldID('')
          }}
        >
          <p>Are you sure you want to remove all the dropdown restrictions?</p>
        </Modal>
      )}
      {bulkUploadModal && (
        <Modal
          setIsOpen={setBulkUploadModal}
          width="wide"
          modalHeader="Bulk import from CSV"
        >
          <p>Save time when adding long lists of dropdown names and values.</p>
          <ol>
            <li>
              <NavigateButton
                onPress={async () => {
                  const data = getCsvString([
                    { 'Option name': '', 'Option value': '' },
                  ])

                  const blob = new Blob([data], {
                    type: 'data:text/csv;charset=utf-8',
                  })
                  await FileSaver.saveAs(
                    blob,
                    `Bulk import parameters - ${param.fieldName}.csv`,
                  )
                }}
              >
                Download CSV template
              </NavigateButton>
            </li>
            <li>
              Add the parameter names (how the dropdown appears in Uplifter) and
              the parameter values (how it appears in the link), into the two
              columns for each parameter
            </li>
            <li>
              Save the CSV template in CSV UTF-8 (Comma delimited) (*CSV) format
            </li>
            <li>Upload your CSV below</li>
          </ol>
          <FileDragAndDrop
            onDrop={onBulkUpload}
            inProgress={bulkUploadStatus.loading}
            success={bulkUploadStatus.success}
            error={bulkUploadStatus.error}
          />
        </Modal>
      )}
    </>
  )
}
