type Result = { [key: string]: any } | null | string

export const getLocalItem = (storageKey = '') => {
  let res: any = null

  try {
    res = window.localStorage.getItem(storageKey)

    if (res) {
      return JSON.parse(res)
    }
  } catch (e) {
    console.error(e)
  }

  return res
}

export const setLocalItem = (storageKey: string, storageObject: Result) => {
  let res = true

  try {
    window.localStorage.setItem(storageKey, JSON.stringify(storageObject))
  } catch (e) {
    res = false
    console.error(e)
  }

  return res
}

export const removeLocalItem = (storageKey: string | string[]) => {
  try {
    const iterate = typeof storageKey === 'string' ? [storageKey] : storageKey

    iterate.forEach((key) => {
      window.localStorage.removeItem(key)
    })
  } catch (e) {
    console.error(e)
    return false
  }

  return true
}

// TODO: Move this elsewhere
export const returnUnique = <T>(arr: T[]) => {
  const seen = {}

  return arr.filter((item) => {
    const hasSeen = Object.prototype.hasOwnProperty.call(
      seen,
      JSON.stringify(item),
    )
    if (!hasSeen) {
      seen[JSON.stringify(item)] = true
    }
    return !hasSeen
  })
}

export const saveUserData = (
  data: object,
  currentAccount = 'user',
  doMerge = true,
) => {
  const savedData = getLocalItem('user-session-data') || {}
  const useData = Object.prototype.hasOwnProperty.call(
    savedData,
    currentAccount,
  )
    ? savedData
    : {
        [currentAccount]: Array.isArray(data) ? [] : {},
      }

  if (Array.isArray(data) && Array.isArray(useData[currentAccount])) {
    useData[currentAccount] = returnUnique([
      ...(doMerge ? useData[currentAccount] : []),
      ...data,
    ])
  } else {
    useData[currentAccount] = {
      ...(doMerge ? useData[currentAccount] : []),
      ...data,
    }
  }

  setLocalItem('user-session-data', { ...savedData, ...useData })
}

export const getUserData = (currentAccount = 'user') => {
  const savedData = getLocalItem('user-session-data') || {}
  if (Object.prototype.hasOwnProperty.call(savedData, currentAccount)) {
    return savedData[currentAccount]
  }
  return null
}
