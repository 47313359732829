import React, { useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'

import Accordion, { AccordionItemElement } from './accordion'
import CompanyBillingAndDetails from './company-billing-and-details'
import EnterpriseOrgSettings from './enterprise-org-settings'
import ManageWorkspaces from './manage-workspaces'
import PublicAPI from './public-api'
import TeamMatesSettings from './teammates-settings'
import { currentUserDetails } from '../api/apollo/variables'
import { getUrlQuery, isAdminUser, isSupportUser } from '../helpers'
import styles from '../styles/company-settings.module.scss'

export default function CompanySettings() {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const { accordionItems, initialOpenState } = useMemo(() => {
    const allItems: AccordionItemElement[] = [
      {
        key: 'billing',
        header: {
          icon: '📝',
          title: 'Your plan and billing',
          subtitle: "Manage your company's settings.",
        },
        expandedContent: <CompanyBillingAndDetails />,
      },
      {
        key: 'users',
        header: {
          icon: '👥',
          title: 'Users',
          subtitle: 'Add, remove and reassign users.',
        },
        expandedContent: <TeamMatesSettings />,
      },
      {
        key: 'workspaces',
        header: {
          icon: '🗂️',
          title: 'Manage workspaces',
          subtitle: 'Create new workspaces and connect website analytics.',
        },
        expandedContent: <ManageWorkspaces />,
      },
      {
        key: 'publicAPI',
        header: {
          icon: '🔑',
          title: 'API token',
          subtitle: 'Get your API key to export data from Uplifter.',
        },
        expandedContent: <PublicAPI />,
      },
    ]

    const query = getUrlQuery()

    const openItem = query?.get('show')

    const openState = allItems.map(({ key }) => {
      return openItem === key
    })

    if (isSupportUser(userPermission)) {
      allItems.push({
        key: 'accountSettings',
        header: {
          icon: '⚙️',
          title: 'Enterprise client details (Support only)',
          subtitle: 'Review contract details.',
        },
        expandedContent: <EnterpriseOrgSettings />,
      })

      openState.push(openItem === 'accountSettings')
    }

    return { accordionItems: allItems, initialOpenState: openState }
  }, [userPermission])

  if (!userPermission || !isAdminUser(userPermission)) return null

  return (
    <Accordion
      id="companySettings"
      className={styles.accordionContainer}
      initialOpenState={initialOpenState}
      accordionItems={accordionItems}
    />
  )
}
