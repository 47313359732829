import moment from 'moment'
import _ from 'lodash'

import { getItemByKeyValue } from '.'
import { getUserData, saveUserData } from './local-client'

export interface SavedCustomLink {
  availableUntil: string
  isAvailable: boolean
  /**
   * This applies to appLinks too now
   * But haven't changed prop name to 'customLinkID'
   * To avoid breaking existing localStorage items
   */
  shortLinkID: string
}

export const addGeneratedCustomLinkToStorage = (
  shortLinkID: string,
  domainID: string,
) => {
  const savedData = getUserData(domainID)

  const newDataToSave: SavedCustomLink[] = []

  if (savedData && Array.isArray(savedData)) {
    // Add pre-existing values that aren't the current ID
    savedData.forEach((savedShortLink) => {
      if (savedShortLink.shortLinkID === shortLinkID) return

      newDataToSave.push({ ...savedShortLink })
    })
  }

  const now = new Date(Date.now())

  newDataToSave.push({
    availableUntil: `${moment(now)
      .add(1, 'day')
      .format('YYYY-MM-DD')} 00:00:00`,
    isAvailable: true,
    shortLinkID,
  })

  saveUserData(newDataToSave, domainID, false)
}

/**
 * This applies to appLinks too now
 * But haven't changed prop name to 'customLinkID'
 * To avoid breaking existing localStorage items
 */
export const removeCustomLinkFromStorage = (
  shortLinkID: string,
  domainID: string,
) => {
  const savedData = getUserData(domainID)

  if (!savedData || !Array.isArray(savedData)) return

  const foundIndex: number = getItemByKeyValue(
    savedData,
    'shortLinkID',
    shortLinkID,
    true,
  )

  if (foundIndex === -1) return

  const newSavedData: SavedCustomLink[] = _.cloneDeep(savedData)

  newSavedData.splice(foundIndex, 1)

  saveUserData(newSavedData, domainID, false)
}
