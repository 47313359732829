import React, { useCallback, useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import classNames from 'classnames'
import _ from 'lodash'

import BurgerIcon from './burger-icon'
import Button from './button'
import { InnerBox } from './two-columns'
import { Heading } from './typography'
import { ParamColors } from '../api/types'
import Arrow from '../assets/svgs/arrow'
import CheckedWhite from '../assets/checked-white.svg'
import CheckedGreen from '../assets/checked-green.svg'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/accordion.module.scss'

interface AccordionItemHeaderProps {
  /** Adds a tickbox before title */
  checked?: boolean
  /** Image/emoji to use before title */
  icon?: string | React.ReactNode
  /** Should use h2 font size & 500 font weight if not a string */
  title: React.ReactNode
  /** Displayed immediately below the title */
  subtitle?: string
  /** Displayed to the right of the title (or under on mobile) */
  rightContent?: React.ReactNode
}

interface AccordionItemProps {
  id: string
  index: number
  isDraggable?: boolean
  dragHandleColor?: string
  isOpen: boolean
  fwdRef?: (self: HTMLDivElement | null) => void
  onToggle: () => void
  onlyExpandOnArrowClick?: boolean
  header: AccordionItemHeaderProps
  headerClassName?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  children: React.ReactNode
}

export const AccordionItem = ({
  id,
  index,
  isDraggable,
  dragHandleColor,
  isOpen,
  fwdRef,
  onToggle,
  onlyExpandOnArrowClick,
  header,
  headerClassName,
  onMouseEnter,
  onMouseLeave,
  children,
}: AccordionItemProps) => {
  const { checked, icon, title, subtitle, rightContent } = header

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={!isDraggable}>
      {(draggableProvided) => {
        return (
          <div
            ref={draggableProvided.innerRef}
            className={classNames(styles.accordionItemContainer, {
              [styles.expanded]: isOpen,
            })}
            {...draggableProvided.draggableProps}
          >
            <div
              ref={(self) => {
                if (fwdRef) fwdRef(self)
              }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <div
                className={classNames(
                  headerClassName,
                  styles.accordionItemHeader,
                  {
                    [styles.clickable]: !onlyExpandOnArrowClick,
                  },
                )}
                role={onlyExpandOnArrowClick ? undefined : 'button'}
                tabIndex={onlyExpandOnArrowClick ? undefined : -1}
                onClick={onlyExpandOnArrowClick ? undefined : onToggle}
              >
                {isDraggable && (
                  <BurgerIcon
                    color={dragHandleColor}
                    {...draggableProvided.dragHandleProps}
                  />
                )}
                {typeof checked === 'boolean' && (
                  <div className={styles.accordionCheckbox}>
                    <img
                      alt={`${checked ? 'complete' : 'incomplete'}-icon`}
                      src={checked ? CheckedGreen : CheckedWhite}
                    />
                  </div>
                )}
                {icon && (
                  <>
                    {typeof icon === 'string' ? (
                      <div className={styles.emoji}>
                        <span>{icon}</span>
                      </div>
                    ) : (
                      icon
                    )}
                  </>
                )}
                <div className={styles.accordionItemTitle}>
                  {typeof title === 'string' ? (
                    <Heading type={2} align="left">
                      {title}
                    </Heading>
                  ) : (
                    title
                  )}
                  {subtitle && (
                    <span className={styles.subtitle}>{subtitle}</span>
                  )}
                </div>
                {rightContent && (
                  <div className={styles.rightContent}>{rightContent}</div>
                )}
                <Button
                  variant="iconOnly"
                  className={classNames(styles.arrow, {
                    [styles.arrowClosed]: !isOpen,
                  })}
                  onPress={onToggle}
                >
                  <Arrow />
                </Button>
              </div>
              {isOpen && (
                <InnerBox className={styles.accordionItemInner}>
                  {children}
                </InnerBox>
              )}
            </div>
          </div>
        )
      }}
    </Draggable>
  )
}

export interface AccordionItemElement {
  key: string
  header: AccordionItemHeaderProps
  expandedContent: React.ReactNode
}

interface AccordionProps {
  id: string
  className?: string
  headerClassName?: string
  expandedContentClassName?: string
  /** Used to set accoridaons to open on mount. Should be same length as accordionItems */
  initialOpenState?: boolean[]
  /** When expanding an accordion item, all others will close by default. Set this to true to override. */
  allowMultipleOpen?: boolean
  /** Stops the whole header of the accordion item being clickable */
  onlyExpandOnArrowClick?: boolean
  /** Adds 'Next' button to all accordion items except the last */
  progressionButtonText?: string
  accordionItems: AccordionItemElement[]
  /** Set to false if no need to track accordion interactions in interactionLog/GA */
  trackToggle?: boolean
  onToggle?: (itemIndex: number, state: 'close' | 'open') => void
  /** Triggers custom actions on hover over accordion item elements */
  onMouseEnter?: (itemKey: string, itemIndex: number) => void
  /** Triggers custom actions on mouseout over accordion item elements */
  onMouseLeave?: (itemKey: string, itemIndex: number) => void
  /** Should use optimistic rendering if possible in Apollo mutations */
  onDragEnd?: (result: DropResult, provided: ResponderProvided) => Promise<void>
  dragHandleColors?: ParamColors | null
}

const Accordion = ({
  id,
  className,
  headerClassName,
  expandedContentClassName,
  initialOpenState,
  allowMultipleOpen,
  onlyExpandOnArrowClick,
  progressionButtonText,
  accordionItems,
  trackToggle = true,
  onToggle,
  onMouseEnter,
  onMouseLeave,
  dragHandleColors,
  onDragEnd,
}: AccordionProps) => {
  const logAction = useLogAction()

  const [currentInitialOpenState, setCurrentInitialOpenState] = useState(
    initialOpenState,
  )
  const [accordionItemOpenStates, setAccordionItemOpenStates] = useState(
    initialOpenState ||
      Array.from(new Array(accordionItems.length), () => false),
  )
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    if (
      initialOpenState &&
      !_.isEqual(initialOpenState, currentInitialOpenState)
    ) {
      setCurrentInitialOpenState(initialOpenState)
      setAccordionItemOpenStates(initialOpenState)
    }
  }, [initialOpenState])

  // Reset accordion open states if number of accordion items change
  useEffect(() => {
    if (accordionItemOpenStates.length !== accordionItems.length) {
      setAccordionItemOpenStates(
        Array.from(new Array(accordionItems.length), () => false),
      )
    }
  }, [accordionItems.length])

  /** Updates open states of accordion items based on current item toggled */
  const toggleOpenStates = useCallback(
    (
      itemIndex: number,
      state?: 'open' | 'close',
      key?: string,
      closeOthers = !allowMultipleOpen,
    ) => {
      if (trackToggle) {
        logAction({
          variables: {
            action: 'toggle-accordion',
            extra: JSON.stringify({
              state,
              sectionName: key,
            }),
            websiteSection: window.location.pathname,
            pagePath: window.location.pathname,
            functionName: 'toggleAccordion',
          },
        })
      }

      setAccordionItemOpenStates((curr) => {
        // If only one item can be open at once
        if (closeOthers) {
          return Array.from(new Array(accordionItems.length), (a, index) =>
            index === itemIndex ? !curr[itemIndex] : false,
          )
        }

        const newOpenStates = [...curr]

        const currentItemState = newOpenStates[itemIndex]

        newOpenStates.splice(itemIndex, 1, !currentItemState)

        return newOpenStates
      })
    },
    [allowMultipleOpen, trackToggle],
  )

  return (
    <DragDropContext
      onDragEnd={async (result, provided) => {
        const { destination, source } = result

        if (!destination) return

        if (
          destination.droppableId === source.droppableId &&
          destination.index === source.index
        ) {
          return
        }

        // If moved item was expanded, keep it expanded
        setAccordionItemOpenStates((curr) => {
          return curr.map(
            (a, index) => curr[source.index] && index === destination.index,
          )
        })

        if (onDragEnd) {
          await onDragEnd(result, provided)
        }
      }}
    >
      <Droppable droppableId={id}>
        {(droppableProvided) => {
          return (
            <div
              ref={droppableProvided.innerRef}
              className={classNames(className, styles.accordionContainer)}
              {...droppableProvided.droppableProps}
            >
              {accordionItems.map(
                ({ key, header, expandedContent }, itemIndex) => {
                  return (
                    <AccordionItem
                      key={key}
                      id={key}
                      index={itemIndex}
                      isDraggable={!!onDragEnd}
                      dragHandleColor={
                        dragHandleColors ? dragHandleColors[key] : undefined
                      }
                      fwdRef={(self) => {
                        if (self) {
                          // Scroll into view if only one open
                          if (
                            !scrolled &&
                            accordionItemOpenStates[itemIndex] &&
                            accordionItemOpenStates.filter((item) => item)
                              .length === 1
                          ) {
                            self.scrollIntoView({
                              block: 'start',
                              behavior: 'smooth',
                            })
                            setScrolled(true)
                          }
                        }
                      }}
                      header={header}
                      headerClassName={headerClassName}
                      isOpen={accordionItemOpenStates[itemIndex]}
                      onlyExpandOnArrowClick={onlyExpandOnArrowClick}
                      onMouseEnter={
                        onMouseEnter
                          ? () => onMouseEnter(key, itemIndex)
                          : undefined
                      }
                      onMouseLeave={
                        onMouseLeave
                          ? () => onMouseLeave(key, itemIndex)
                          : undefined
                      }
                      onToggle={() => {
                        toggleOpenStates(
                          itemIndex,
                          accordionItemOpenStates[itemIndex] ? 'close' : 'open',
                          key,
                        )

                        setScrolled(false)

                        if (onToggle) {
                          onToggle(
                            itemIndex,
                            accordionItemOpenStates[itemIndex]
                              ? 'close'
                              : 'open',
                          )
                        }
                      }}
                    >
                      <div
                        className={classNames(
                          styles.expandedContentContainer,
                          expandedContentClassName,
                        )}
                      >
                        {expandedContent}
                        {progressionButtonText &&
                          itemIndex < accordionItems.length - 1 && (
                            <Button
                              className={styles.progressionButton}
                              onPress={() => {
                                toggleOpenStates(
                                  itemIndex + 1,
                                  accordionItemOpenStates[itemIndex]
                                    ? 'close'
                                    : 'open',
                                  key,
                                  true,
                                )

                                if (onToggle) {
                                  onToggle(
                                    itemIndex,
                                    accordionItemOpenStates[itemIndex]
                                      ? 'close'
                                      : 'open',
                                  )
                                }
                              }}
                            >
                              {itemIndex === accordionItems.length - 2
                                ? 'Done'
                                : progressionButtonText}
                            </Button>
                          )}
                      </div>
                    </AccordionItem>
                  )
                },
              )}
              {droppableProvided.placeholder}
            </div>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}

export default Accordion
