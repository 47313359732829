import { InputTypes } from '../api/types'

export interface TemplateParam {
  fieldName: string
  fieldType: InputTypes
  required: boolean
  prefix: string
  helpText: string
  metaParameter?: boolean
  forceLowerCase?: boolean
  optionList?: {
    hide: boolean
    optionName: string
    optionValue: string
    optionFilter: null
  }[]
  /**
   * Tells the code which existing parameter to place this before, but only based on its prefix
   * This ensures that param order is only updated for the default (initial) generator or any that still has the given parameter
   */
  precedingParamPrefix?: string
}

// https://www.andiamo.co.uk/resources/iso-language-codes/
const languages = [
  {
    hide: false,
    optionFilter: null,
    optionName: 'Afrikaans',
    optionValue: 'af',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Albanian',
    optionValue: 'sq',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Algeria)',
    optionValue: 'ar-dz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Bahrain)',
    optionValue: 'ar-bh',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Egypt)',
    optionValue: 'ar-eg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Iraq)',
    optionValue: 'ar-iq',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Jordan)',
    optionValue: 'ar-jo',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Kuwait)',
    optionValue: 'ar-kw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Lebanon)',
    optionValue: 'ar-lb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Libya)',
    optionValue: 'ar-ly',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Morocco)',
    optionValue: 'ar-ma',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Oman)',
    optionValue: 'ar-om',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Qatar)',
    optionValue: 'ar-qa',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Saudi Arabia)',
    optionValue: 'ar-sa',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Syria)',
    optionValue: 'ar-sy',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Tunisia)',
    optionValue: 'ar-tn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (U.A.E.)',
    optionValue: 'ar-ae',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Arabic (Yemen)',
    optionValue: 'ar-ye',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Basque',
    optionValue: 'eu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Belarusian',
    optionValue: 'be',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bulgarian',
    optionValue: 'bg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Catalan',
    optionValue: 'ca',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Chinese (Hong Kong)',
    optionValue: 'zh-hk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Chinese (PRC)',
    optionValue: 'zh-cn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Chinese (Singapore)',
    optionValue: 'zh-sg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Chinese (Taiwan)',
    optionValue: 'zh-tw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Croatian',
    optionValue: 'hr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Czech',
    optionValue: 'cs',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Danish',
    optionValue: 'da',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Dutch (Belgium)',
    optionValue: 'nl-be',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Dutch (Standard)',
    optionValue: 'nl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'English',
    optionValue: 'en',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'English (Australia)',
    optionValue: 'en-au',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'English (Belize)',
    optionValue: 'en-bz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'English (Canada)',
    optionValue: 'en-ca',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'English (Ireland)',
    optionValue: 'en-ie',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'English (Jamaica)',
    optionValue: 'en-jm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'English (New Zealand)',
    optionValue: 'en-nz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'English (South Africa)',
    optionValue: 'en-za',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'English (Trinidad)',
    optionValue: 'en-tt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'English (United Kingdom)',
    optionValue: 'en-gb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'English (United States)',
    optionValue: 'en-us',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Estonian',
    optionValue: 'et',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Faeroese',
    optionValue: 'fo',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Farsi',
    optionValue: 'fa',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Finnish',
    optionValue: 'fi',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'French (Belgium)',
    optionValue: 'fr-be',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'French (Canada)',
    optionValue: 'fr-ca',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'French (Luxembourg)',
    optionValue: 'fr-lu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'French (Standard)',
    optionValue: 'fr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'French (Switzerland)',
    optionValue: 'fr-ch',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Gaelic (Scotland)',
    optionValue: 'gd',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'German (Austria)',
    optionValue: 'de-at',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'German (Liechtenstein)',
    optionValue: 'de-li',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'German (Luxembourg)',
    optionValue: 'de-lu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'German (Standard)',
    optionValue: 'de',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'German (Switzerland)',
    optionValue: 'de-ch',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Greek',
    optionValue: 'el',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Hebrew',
    optionValue: 'he',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Hindi',
    optionValue: 'hi',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Hungarian',
    optionValue: 'hu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Icelandic',
    optionValue: 'is',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Indonesian',
    optionValue: 'id',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Irish',
    optionValue: 'ga',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Italian (Standard)',
    optionValue: 'it',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Italian (Switzerland)',
    optionValue: 'it-ch',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Japanese',
    optionValue: 'ja',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Korean',
    optionValue: 'ko',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Korean (Johab)',
    optionValue: 'ko',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Kurdish',
    optionValue: 'ku',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Latvian',
    optionValue: 'lv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Lithuanian',
    optionValue: 'lt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Macedonian (FYROM)',
    optionValue: 'mk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Malayalam',
    optionValue: 'ml',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Malaysian',
    optionValue: 'ms',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Maltese',
    optionValue: 'mt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Norwegian',
    optionValue: 'no',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Norwegian (Bokmål)',
    optionValue: 'nb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Norwegian (Nynorsk)',
    optionValue: 'nn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Polish',
    optionValue: 'pl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Portuguese (Brazil)',
    optionValue: 'pt-br',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Portuguese (Portugal)',
    optionValue: 'pt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Punjabi',
    optionValue: 'pa',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Rhaeto-Romanic',
    optionValue: 'rm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Romanian',
    optionValue: 'ro',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Romanian (Republic of Moldova)',
    optionValue: 'ro-md',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Russian',
    optionValue: 'ru',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Russian (Republic of Moldova)',
    optionValue: 'ru-md',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Serbian',
    optionValue: 'sr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Slovak',
    optionValue: 'sk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Slovenian',
    optionValue: 'sl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Sorbian',
    optionValue: 'sb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Argentina)',
    optionValue: 'es-ar',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Bolivia)',
    optionValue: 'es-bo',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Chile)',
    optionValue: 'es-cl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Colombia)',
    optionValue: 'es-co',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Costa Rica)',
    optionValue: 'es-cr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Dominican Republic)',
    optionValue: 'es-do',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Ecuador)',
    optionValue: 'es-ec',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (El Salvador)',
    optionValue: 'es-sv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Guatemala)',
    optionValue: 'es-gt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Honduras)',
    optionValue: 'es-hn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Mexico)',
    optionValue: 'es-mx',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Nicaragua)',
    optionValue: 'es-ni',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Panama)',
    optionValue: 'es-pa',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Paraguay)',
    optionValue: 'es-py',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Peru)',
    optionValue: 'es-pe',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Puerto Rico)',
    optionValue: 'es-pr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Spain)',
    optionValue: 'es',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Uruguay)',
    optionValue: 'es-uy',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spanish (Venezuela)',
    optionValue: 'es-ve',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Swedish',
    optionValue: 'sv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Swedish (Finland)',
    optionValue: 'sv-fi',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Thai',
    optionValue: 'th',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tsonga',
    optionValue: 'ts',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tswana',
    optionValue: 'tn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Turkish',
    optionValue: 'tr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Ukrainian',
    optionValue: 'ua',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Urdu',
    optionValue: 'ur',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Venda',
    optionValue: 've',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Vietnamese',
    optionValue: 'vi',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Welsh',
    optionValue: 'cy',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Xhosa',
    optionValue: 'xh',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Yiddish',
    optionValue: 'ji',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Zulu',
    optionValue: 'zu',
  },
]

// https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
const twoLetterCountries = [
  {
    hide: false,
    optionFilter: null,
    optionName: 'Afghanistan',
    optionValue: 'af',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Åland Islands',
    optionValue: 'ax',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Albania',
    optionValue: 'al',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Algeria',
    optionValue: 'dz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'American Samoa',
    optionValue: 'as',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Andorra',
    optionValue: 'ad',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Angola',
    optionValue: 'ao',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Anguilla',
    optionValue: 'ai',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Antarctica',
    optionValue: 'aq',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Antigua and Barbuda',
    optionValue: 'ag',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Argentina',
    optionValue: 'ar',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Armenia',
    optionValue: 'am',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Aruba',
    optionValue: 'aw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Australia',
    optionValue: 'au',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Austria',
    optionValue: 'at',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Azerbaijan',
    optionValue: 'az',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bahamas (the)',
    optionValue: 'bs',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bahrain',
    optionValue: 'bh',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bangladesh',
    optionValue: 'bd',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Barbados',
    optionValue: 'bb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Belarus',
    optionValue: 'by',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Belgium',
    optionValue: 'be',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Belize',
    optionValue: 'bz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Benin',
    optionValue: 'bj',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bermuda',
    optionValue: 'bm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bhutan',
    optionValue: 'bt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bolivia (Plurinational State of)',
    optionValue: 'bo',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bonaire',
    optionValue: 'bq',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bosnia and Herzegovina',
    optionValue: 'ba',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Botswana',
    optionValue: 'bw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bouvet Island',
    optionValue: 'bv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Brazil',
    optionValue: 'br',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'British Indian Ocean Territory (the)',
    optionValue: 'io',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Brunei Darussalam',
    optionValue: 'bn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bulgaria',
    optionValue: 'bg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Burkina Faso',
    optionValue: 'bf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Burundi',
    optionValue: 'bi',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cabo Verde',
    optionValue: 'cv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cambodia',
    optionValue: 'kh',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cameroon',
    optionValue: 'cm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Canada',
    optionValue: 'ca',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cayman Islands (the)',
    optionValue: 'ky',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Central African Republic (the)',
    optionValue: 'cf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Chad',
    optionValue: 'td',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Chile',
    optionValue: 'cl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'China',
    optionValue: 'cn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Christmas Island',
    optionValue: 'cx',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cocos (Keeling) Islands (the)',
    optionValue: 'cc',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Colombia',
    optionValue: 'co',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Comoros (the)',
    optionValue: 'km',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Congo (the Democratic Republic of the)',
    optionValue: 'cd',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Congo (the)',
    optionValue: 'cg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cook Islands (the)',
    optionValue: 'ck',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Costa Rica',
    optionValue: 'cr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: "Côte d'Ivoire",
    optionValue: 'ci',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Croatia',
    optionValue: 'hr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cuba',
    optionValue: 'cu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Curaçao',
    optionValue: 'cw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cyprus',
    optionValue: 'cy',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Czechia',
    optionValue: 'cz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Denmark',
    optionValue: 'dk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Djibouti',
    optionValue: 'dj',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Dominica',
    optionValue: 'dm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Dominican Republic (the)',
    optionValue: 'do',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Ecuador',
    optionValue: 'ec',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Egypt',
    optionValue: 'eg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'El Salvador',
    optionValue: 'sv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Equatorial Guinea',
    optionValue: 'gq',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Eritrea',
    optionValue: 'er',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Estonia',
    optionValue: 'ee',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Eswatini',
    optionValue: 'sz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Ethiopia',
    optionValue: 'et',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Falkland Islands (the) [Malvinas]',
    optionValue: 'fk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Faroe Islands (the)',
    optionValue: 'fo',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Fiji',
    optionValue: 'fj',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Finland',
    optionValue: 'fi',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'France',
    optionValue: 'fr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'French Guiana',
    optionValue: 'gf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'French Polynesia',
    optionValue: 'pf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'French Southern Territories (the)',
    optionValue: 'tf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Gabon',
    optionValue: 'ga',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Gambia (the)',
    optionValue: 'gm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Georgia',
    optionValue: 'ge',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Germany',
    optionValue: 'de',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Ghana',
    optionValue: 'gh',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Gibraltar',
    optionValue: 'gi',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Greece',
    optionValue: 'gr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Greenland',
    optionValue: 'gl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Grenada',
    optionValue: 'gd',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guadeloupe',
    optionValue: 'gp',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guam',
    optionValue: 'gu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guatemala',
    optionValue: 'gt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guernsey',
    optionValue: 'gg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guinea',
    optionValue: 'gn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guinea-Bissau',
    optionValue: 'gw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guyana',
    optionValue: 'gy',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Haiti',
    optionValue: 'ht',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Heard Island and McDonald Islands',
    optionValue: 'hm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Holy See (the)',
    optionValue: 'va',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Honduras',
    optionValue: 'hn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Hong Kong',
    optionValue: 'hk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Hungary',
    optionValue: 'hu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Iceland',
    optionValue: 'is',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'India',
    optionValue: 'in',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Indonesia',
    optionValue: 'id',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Iran (Islamic Republic of)',
    optionValue: 'ir',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Iraq',
    optionValue: 'iq',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Ireland',
    optionValue: 'ie',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Isle of Man',
    optionValue: 'im',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Israel',
    optionValue: 'il',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Italy',
    optionValue: 'it',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Jamaica',
    optionValue: 'jm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Japan',
    optionValue: 'jp',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Jersey',
    optionValue: 'je',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Jordan',
    optionValue: 'jo',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Kazakhstan',
    optionValue: 'kz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Kenya',
    optionValue: 'ke',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Kiribati',
    optionValue: 'ki',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: "Korea (the Democratic People's Republic of)",
    optionValue: 'kp',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Korea (the Republic of)',
    optionValue: 'kr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Kuwait',
    optionValue: 'kw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Kyrgyzstan',
    optionValue: 'kg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: "Lao People's Democratic Republic (the)",
    optionValue: 'la',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Latvia',
    optionValue: 'lv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Lebanon',
    optionValue: 'lb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Lesotho',
    optionValue: 'ls',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Liberia',
    optionValue: 'lr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Libya',
    optionValue: 'ly',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Liechtenstein',
    optionValue: 'li',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Lithuania',
    optionValue: 'lt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Luxembourg',
    optionValue: 'lu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Macao',
    optionValue: 'mo',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'North Macedonia',
    optionValue: 'mk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Madagascar',
    optionValue: 'mg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Malawi',
    optionValue: 'mw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Malaysia',
    optionValue: 'my',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Maldives',
    optionValue: 'mv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mali',
    optionValue: 'ml',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Malta',
    optionValue: 'mt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Marshall Islands (the)',
    optionValue: 'mh',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Martinique',
    optionValue: 'mq',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mauritania',
    optionValue: 'mr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mauritius',
    optionValue: 'mu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mayotte',
    optionValue: 'yt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mexico',
    optionValue: 'mx',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Micronesia (Federated States of)',
    optionValue: 'fm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Moldova (the Republic of)',
    optionValue: 'md',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Monaco',
    optionValue: 'mc',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mongolia',
    optionValue: 'mn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Montenegro',
    optionValue: 'me',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Montserrat',
    optionValue: 'ms',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Morocco',
    optionValue: 'ma',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mozambique',
    optionValue: 'mz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Myanmar',
    optionValue: 'mm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Namibia',
    optionValue: 'na',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Nauru',
    optionValue: 'nr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Nepal',
    optionValue: 'np',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Netherlands, Kingdom of the',
    optionValue: 'nl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'New Caledonia',
    optionValue: 'nc',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'New Zealand',
    optionValue: 'nz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Nicaragua',
    optionValue: 'ni',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Niger (the)',
    optionValue: 'ne',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Nigeria',
    optionValue: 'ng',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Niue',
    optionValue: 'nu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Norfolk Island',
    optionValue: 'nf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Northern Mariana Islands (the)',
    optionValue: 'mp',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Norway',
    optionValue: 'no',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Oman',
    optionValue: 'om',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Pakistan',
    optionValue: 'pk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Palau',
    optionValue: 'pw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Palestine, State of',
    optionValue: 'ps',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Panama',
    optionValue: 'pa',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Papua New Guinea',
    optionValue: 'pg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Paraguay',
    optionValue: 'py',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Peru',
    optionValue: 'pe',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Philippines (the)',
    optionValue: 'ph',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Pitcairn',
    optionValue: 'pn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Poland',
    optionValue: 'pl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Portugal',
    optionValue: 'pt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Puerto Rico',
    optionValue: 'pr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Qatar',
    optionValue: 'qa',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Réunion',
    optionValue: 're',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Romania',
    optionValue: 'ro',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Russian Federation (the)',
    optionValue: 'ru',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Rwanda',
    optionValue: 'rw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Barthélemy',
    optionValue: 'bl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Helena',
    optionValue: 'sh',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Kitts and Nevis',
    optionValue: 'kn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Lucia',
    optionValue: 'lc',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Martin (French part)',
    optionValue: 'mf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Pierre and Miquelon',
    optionValue: 'pm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Vincent and the Grenadines',
    optionValue: 'vc',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Samoa',
    optionValue: 'ws',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'San Marino',
    optionValue: 'sm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Sao Tome and Principe',
    optionValue: 'st',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saudi Arabia',
    optionValue: 'sa',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Senegal',
    optionValue: 'sn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Serbia',
    optionValue: 'rs',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Seychelles',
    optionValue: 'sc',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Sierra Leone',
    optionValue: 'sl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Singapore',
    optionValue: 'sg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Sint Maarten (Dutch part)',
    optionValue: 'sx',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Slovakia',
    optionValue: 'sk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Slovenia',
    optionValue: 'si',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Solomon Islands',
    optionValue: 'sb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Somalia',
    optionValue: 'so',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'South Africa',
    optionValue: 'za',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'South Georgia and the South Sandwich Islands',
    optionValue: 'gs',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'South Sudan',
    optionValue: 'ss',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spain',
    optionValue: 'es',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Sri Lanka',
    optionValue: 'lk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Sudan (the)',
    optionValue: 'sd',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Suriname',
    optionValue: 'sr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Svalbard',
    optionValue: 'sj',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Sweden',
    optionValue: 'se',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Switzerland',
    optionValue: 'ch',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Syrian Arab Republic (the)',
    optionValue: 'sy',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Taiwan',
    optionValue: 'tw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tajikistan',
    optionValue: 'tj',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tanzania, the United Republic of',
    optionValue: 'tz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Thailand',
    optionValue: 'th',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Timor-Leste',
    optionValue: 'tl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Togo',
    optionValue: 'tg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tokelau',
    optionValue: 'tk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tonga',
    optionValue: 'to',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Trinidad and Tobago',
    optionValue: 'tt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tunisia',
    optionValue: 'tn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Türkiye',
    optionValue: 'tr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Turkmenistan',
    optionValue: 'tm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Turks and Caicos Islands (the)',
    optionValue: 'tc',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tuvalu',
    optionValue: 'tv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Uganda',
    optionValue: 'ug',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Ukraine',
    optionValue: 'ua',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'United Arab Emirates (the)',
    optionValue: 'ae',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'United Kingdom of Great Britain and Northern Ireland (the)',
    optionValue: 'gb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'United States Minor Outlying Islands (the)',
    optionValue: 'um',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'United States of America (the)',
    optionValue: 'us',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Uruguay',
    optionValue: 'uy',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Uzbekistan',
    optionValue: 'uz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Vanuatu',
    optionValue: 'vu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Venezuela (Bolivarian Republic of)',
    optionValue: 've',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Viet Nam',
    optionValue: 'vn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Virgin Islands (British)',
    optionValue: 'vg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Virgin Islands (U.S.)',
    optionValue: 'vi',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Wallis and Futuna',
    optionValue: 'wf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Western Sahara',
    optionValue: 'eh',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Yemen',
    optionValue: 'ye',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Zambia',
    optionValue: 'zm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Zimbabwe',
    optionValue: 'zw',
  },
]

// https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
const threeLetterCountries = [
  {
    hide: false,
    optionFilter: null,
    optionName: 'Afghanistan',
    optionValue: 'afg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Åland Islands',
    optionValue: 'ala',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Albania',
    optionValue: 'alb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Algeria',
    optionValue: 'dza',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'American Samoa',
    optionValue: 'asm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Andorra',
    optionValue: 'and',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Angola',
    optionValue: 'ago',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Anguilla',
    optionValue: 'aia',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Antarctica',
    optionValue: 'ata',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Antigua and Barbuda',
    optionValue: 'atg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Argentina',
    optionValue: 'arg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Armenia',
    optionValue: 'arm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Aruba',
    optionValue: 'abw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Australia',
    optionValue: 'aus',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Austria',
    optionValue: 'aut',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Azerbaijan',
    optionValue: 'aze',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bahamas (the)',
    optionValue: 'bhs',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bahrain',
    optionValue: 'bhr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bangladesh',
    optionValue: 'bgd',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Barbados',
    optionValue: 'brb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Belarus',
    optionValue: 'blr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Belgium',
    optionValue: 'bel',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Belize',
    optionValue: 'blz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Benin',
    optionValue: 'ben',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bermuda',
    optionValue: 'bmu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bhutan',
    optionValue: 'btn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bolivia (Plurinational State of)',
    optionValue: 'bol',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bonaire',
    optionValue: 'bes',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bosnia and Herzegovina',
    optionValue: 'bih',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Botswana',
    optionValue: 'bwa',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bouvet Island',
    optionValue: 'bvt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Brazil',
    optionValue: 'bra',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'British Indian Ocean Territory (the)',
    optionValue: 'iot',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Brunei Darussalam',
    optionValue: 'brn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Bulgaria',
    optionValue: 'bgr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Burkina Faso',
    optionValue: 'bfa',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Burundi',
    optionValue: 'bdi',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cabo Verde',
    optionValue: 'cpv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cambodia',
    optionValue: 'khm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cameroon',
    optionValue: 'cmr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Canada',
    optionValue: 'can',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cayman Islands (the)',
    optionValue: 'cym',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Central African Republic (the)',
    optionValue: 'caf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Chad',
    optionValue: 'tcd',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Chile',
    optionValue: 'chl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'China',
    optionValue: 'chn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Christmas Island',
    optionValue: 'cxr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cocos (Keeling) Islands (the)',
    optionValue: 'cck',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Colombia',
    optionValue: 'col',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Comoros (the)',
    optionValue: 'com',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Congo (the Democratic Republic of the)',
    optionValue: 'cod',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Congo (the)',
    optionValue: 'cog',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cook Islands (the)',
    optionValue: 'cok',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Costa Rica',
    optionValue: 'cri',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: "Côte d'Ivoire",
    optionValue: 'civ',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Croatia',
    optionValue: 'hrv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cuba',
    optionValue: 'cub',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Curaçao',
    optionValue: 'cuw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Cyprus',
    optionValue: 'cyp',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Czechia',
    optionValue: 'cze',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Denmark',
    optionValue: 'dnk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Djibouti',
    optionValue: 'dji',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Dominica',
    optionValue: 'dma',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Dominican Republic (the)',
    optionValue: 'dom',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Ecuador',
    optionValue: 'ecu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Egypt',
    optionValue: 'egy',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'El Salvador',
    optionValue: 'slv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Equatorial Guinea',
    optionValue: 'gnq',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Eritrea',
    optionValue: 'eri',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Estonia',
    optionValue: 'est',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Eswatini',
    optionValue: 'swz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Ethiopia',
    optionValue: 'eth',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Falkland Islands (the) [Malvinas]',
    optionValue: 'flk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Faroe Islands (the)',
    optionValue: 'fro',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Fiji',
    optionValue: 'fji',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Finland',
    optionValue: 'fin',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'France',
    optionValue: 'fra',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'French Guiana',
    optionValue: 'guf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'French Polynesia',
    optionValue: 'pyf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'French Southern Territories (the)',
    optionValue: 'atf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Gabon',
    optionValue: 'gab',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Gambia (the)',
    optionValue: 'gmb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Georgia',
    optionValue: 'geo',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Germany',
    optionValue: 'deu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Ghana',
    optionValue: 'gha',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Gibraltar',
    optionValue: 'gib',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Greece',
    optionValue: 'grc',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Greenland',
    optionValue: 'grl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Grenada',
    optionValue: 'grd',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guadeloupe',
    optionValue: 'glp',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guam',
    optionValue: 'gum',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guatemala',
    optionValue: 'gtm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guernsey',
    optionValue: 'ggy',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guinea',
    optionValue: 'gin',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guinea-Bissau',
    optionValue: 'gnb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Guyana',
    optionValue: 'guy',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Haiti',
    optionValue: 'hti',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Heard Island and McDonald Islands',
    optionValue: 'hmd',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Holy See (the)',
    optionValue: 'vat',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Honduras',
    optionValue: 'hnd',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Hong Kong',
    optionValue: 'hkg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Hungary',
    optionValue: 'hun',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Iceland',
    optionValue: 'isl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'India',
    optionValue: 'ind',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Indonesia',
    optionValue: 'idn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Iran (Islamic Republic of)',
    optionValue: 'irn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Iraq',
    optionValue: 'irq',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Ireland',
    optionValue: 'irl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Isle of Man',
    optionValue: 'imn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Israel',
    optionValue: 'isr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Italy',
    optionValue: 'ita',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Jamaica',
    optionValue: 'jam',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Japan',
    optionValue: 'jpn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Jersey',
    optionValue: 'jey',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Jordan',
    optionValue: 'jor',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Kazakhstan',
    optionValue: 'kaz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Kenya',
    optionValue: 'ken',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Kiribati',
    optionValue: 'kir',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: "Korea (the Democratic People's Republic of)",
    optionValue: 'prk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Korea (the Republic of)',
    optionValue: 'kor',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Kuwait',
    optionValue: 'kwt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Kyrgyzstan',
    optionValue: 'kgz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: "Lao People's Democratic Republic (the)",
    optionValue: 'lao',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Latvia',
    optionValue: 'lva',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Lebanon',
    optionValue: 'lbn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Lesotho',
    optionValue: 'lso',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Liberia',
    optionValue: 'lbr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Libya',
    optionValue: 'lby',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Liechtenstein',
    optionValue: 'lie',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Lithuania',
    optionValue: 'ltu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Luxembourg',
    optionValue: 'lux',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Macao',
    optionValue: 'mac',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'North Macedonia',
    optionValue: 'mkd',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Madagascar',
    optionValue: 'mdg',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Malawi',
    optionValue: 'mwi',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Malaysia',
    optionValue: 'mys',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Maldives',
    optionValue: 'mdv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mali',
    optionValue: 'mli',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Malta',
    optionValue: 'mlt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Marshall Islands (the)',
    optionValue: 'mhl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Martinique',
    optionValue: 'mtq',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mauritania',
    optionValue: 'mrt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mauritius',
    optionValue: 'mus',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mayotte',
    optionValue: 'myt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mexico',
    optionValue: 'mex',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Micronesia (Federated States of)',
    optionValue: 'fsm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Moldova (the Republic of)',
    optionValue: 'mda',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Monaco',
    optionValue: 'mco',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mongolia',
    optionValue: 'mng',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Montenegro',
    optionValue: 'mne',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Montserrat',
    optionValue: 'msr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Morocco',
    optionValue: 'mar',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Mozambique',
    optionValue: 'moz',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Myanmar',
    optionValue: 'mmr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Namibia',
    optionValue: 'nam',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Nauru',
    optionValue: 'nru',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Nepal',
    optionValue: 'npl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Netherlands, Kingdom of the',
    optionValue: 'nld',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'New Caledonia',
    optionValue: 'ncl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'New Zealand',
    optionValue: 'nzl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Nicaragua',
    optionValue: 'nic',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Niger (the)',
    optionValue: 'ner',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Nigeria',
    optionValue: 'nga',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Niue',
    optionValue: 'niu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Norfolk Island',
    optionValue: 'nfk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Northern Mariana Islands (the)',
    optionValue: 'mnp',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Norway',
    optionValue: 'nor',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Oman',
    optionValue: 'omn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Pakistan',
    optionValue: 'pak',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Palau',
    optionValue: 'plw',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Palestine, State of',
    optionValue: 'pse',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Panama',
    optionValue: 'pan',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Papua New Guinea',
    optionValue: 'png',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Paraguay',
    optionValue: 'pry',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Peru',
    optionValue: 'per',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Philippines (the)',
    optionValue: 'phl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Pitcairn',
    optionValue: 'pcn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Poland',
    optionValue: 'pol',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Portugal',
    optionValue: 'prt',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Puerto Rico',
    optionValue: 'pri',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Qatar',
    optionValue: 'qat',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Réunion',
    optionValue: 'reu',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Romania',
    optionValue: 'rou',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Russian Federation (the)',
    optionValue: 'rus',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Rwanda',
    optionValue: 'rwa',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Barthélemy',
    optionValue: 'blm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Helena',
    optionValue: 'shn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Kitts and Nevis',
    optionValue: 'kna',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Lucia',
    optionValue: 'lca',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Martin (French part)',
    optionValue: 'maf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Pierre and Miquelon',
    optionValue: 'spm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saint Vincent and the Grenadines',
    optionValue: 'vct',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Samoa',
    optionValue: 'wsm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'San Marino',
    optionValue: 'smr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Sao Tome and Principe',
    optionValue: 'stp',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Saudi Arabia',
    optionValue: 'sau',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Senegal',
    optionValue: 'sen',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Serbia',
    optionValue: 'srb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Seychelles',
    optionValue: 'syc',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Sierra Leone',
    optionValue: 'sle',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Singapore',
    optionValue: 'sgp',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Sint Maarten (Dutch part)',
    optionValue: 'sxm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Slovakia',
    optionValue: 'svk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Slovenia',
    optionValue: 'svn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Solomon Islands',
    optionValue: 'slb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Somalia',
    optionValue: 'som',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'South Africa',
    optionValue: 'zaf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'South Georgia and the South Sandwich Islands',
    optionValue: 'sgs',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'South Sudan',
    optionValue: 'ssd',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Spain',
    optionValue: 'esp',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Sri Lanka',
    optionValue: 'lka',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Sudan (the)',
    optionValue: 'sdn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Suriname',
    optionValue: 'sur',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Svalbard',
    optionValue: 'sjm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Sweden',
    optionValue: 'swe',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Switzerland',
    optionValue: 'che',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Syrian Arab Republic (the)',
    optionValue: 'syr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Taiwan',
    optionValue: 'twn',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tajikistan',
    optionValue: 'tjk',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tanzania, the United Republic of',
    optionValue: 'tza',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Thailand',
    optionValue: 'tha',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Timor-Leste',
    optionValue: 'tls',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Togo',
    optionValue: 'tgo',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tokelau',
    optionValue: 'tkl',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tonga',
    optionValue: 'ton',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Trinidad and Tobago',
    optionValue: 'tto',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tunisia',
    optionValue: 'tun',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Türkiye',
    optionValue: 'tur',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Turkmenistan',
    optionValue: 'tkm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Turks and Caicos Islands (the)',
    optionValue: 'tca',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Tuvalu',
    optionValue: 'tuv',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Uganda',
    optionValue: 'uga',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Ukraine',
    optionValue: 'ukr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'United Arab Emirates (the)',
    optionValue: 'are',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'United Kingdom of Great Britain and Northern Ireland (the)',
    optionValue: 'gbr',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'United States Minor Outlying Islands (the)',
    optionValue: 'umi',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'United States of America (the)',
    optionValue: 'usa',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Uruguay',
    optionValue: 'ury',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Uzbekistan',
    optionValue: 'uzb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Vanuatu',
    optionValue: 'vut',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Venezuela (Bolivarian Republic of)',
    optionValue: 'ven',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Viet Nam',
    optionValue: 'vnm',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Virgin Islands (British)',
    optionValue: 'vgb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Virgin Islands (U.S.)',
    optionValue: 'vir',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Wallis and Futuna',
    optionValue: 'wlf',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Western Sahara',
    optionValue: 'esh',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Yemen',
    optionValue: 'yem',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Zambia',
    optionValue: 'zmb',
  },
  {
    hide: false,
    optionFilter: null,
    optionName: 'Zimbabwe',
    optionValue: 'zwe',
  },
]

export const popularParameters: { [paramName: string]: TemplateParam } = {
  Audience: {
    fieldName: 'Audience',
    fieldType: 'select',
    required: false,
    prefix: '-',
    helpText:
      'What are you selling or promoting in this link? Use [All] if this is brand marketing covering multiple audiences.',
    precedingParamPrefix: 'utm_campaign=',
    forceLowerCase: true,
    optionList: [
      {
        hide: false,
        optionName: '(All)',
        optionValue: 'all',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Audience A',
        optionValue: 'audience_a',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Audience B',
        optionValue: 'audience_b',
        optionFilter: null,
      },
    ],
  },
  'Business unit': {
    fieldName: 'Business unit',
    fieldType: 'select',
    required: false,
    prefix: '-',
    helpText:
      'What area of the business are you marketing? Use [All] if this is brand marketing covering multiple business units.',
    precedingParamPrefix: 'utm_campaign=',
    forceLowerCase: true,
    optionList: [
      {
        hide: false,
        optionName: '(All)',
        optionValue: 'all',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Business unit A',
        optionValue: 'business_unit_a',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Business unit B',
        optionValue: 'business_unit_b',
        optionFilter: null,
      },
    ],
  },
  'Country (ISO 2 letters)': {
    fieldName: 'Country (ISO 2 letters)',
    fieldType: 'select',
    required: false,
    prefix: '-',
    helpText:
      'Where are you marketing to? Use [All] if you are marketing to all countries.',
    metaParameter: false,
    precedingParamPrefix: 'utm_campaign=',
    forceLowerCase: true,
    optionList: [
      ...twoLetterCountries,
      {
        hide: false,
        optionFilter: null,
        optionName: '(All)',
        optionValue: 'all',
      },
    ],
  },
  'Country (ISO 3 letters)': {
    fieldName: 'Country (ISO 3 letters)',
    fieldType: 'select',
    required: false,
    prefix: '-',
    helpText:
      'Where are you marketing to? Use [All] if you are marketing to all countries.',
    metaParameter: false,
    precedingParamPrefix: 'utm_campaign=',
    forceLowerCase: true,
    optionList: [
      ...threeLetterCountries,
      {
        hide: false,
        optionFilter: null,
        optionName: '(All)',
        optionValue: 'all',
      },
    ],
  },
  Language: {
    fieldName: 'Language',
    fieldType: 'select',
    required: false,
    prefix: '-',
    helpText: 'What language are you marketing in?',
    metaParameter: false,
    precedingParamPrefix: 'utm_campaign=',
    forceLowerCase: true,
    optionList: [
      ...languages,
      {
        hide: false,
        optionFilter: null,
        optionName: '(All)',
        optionValue: 'all',
      },
    ],
  },
  'Notes (meta)': {
    fieldName: 'Notes',
    fieldType: 'input',
    required: false,
    prefix: '',
    metaParameter: true,
    helpText:
      'Use this field to describe, categorise or give context to this campaign link. As a meta data parameter this will be only visible in this platform.',
    forceLowerCase: true,
  },
  Product: {
    fieldName: 'Product',
    fieldType: 'select',
    required: false,
    prefix: '-',
    helpText:
      'What are you selling or promoting in this link? Use [All] if this is brand marketing covering multiple products.',
    precedingParamPrefix: 'utm_campaign=',
    forceLowerCase: true,
    optionList: [
      {
        hide: false,
        optionName: '(All)',
        optionValue: 'all',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Product A',
        optionValue: 'product_a',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Product B',
        optionValue: 'product_b',
        optionFilter: null,
      },
    ],
  },
}

export const ga4Parameters: { [paramName: string]: TemplateParam } = {
  'Creative format': {
    fieldName: 'Creative format',
    fieldType: 'select',
    required: false,
    prefix: 'utm_creative_format=',
    helpText:
      'Identify the creative used (e.g. skyscraper). It is currently not available in either the reports or in Explore',
    forceLowerCase: true,
    optionList: [
      {
        hide: false,
        optionName: 'Text',
        optionValue: 'text',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Photo',
        optionValue: 'photo',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Button',
        optionValue: 'button',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Video',
        optionValue: 'video',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Banner',
        optionValue: 'banner',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'MPU',
        optionValue: 'mpu',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Skyscraper',
        optionValue: 'skyscraper',
        optionFilter: null,
      },
    ],
  },
  'Marketing tactic': {
    fieldName: 'Marketing tactic',
    fieldType: 'select',
    required: false,
    prefix: 'utm_marketing_tactic=',
    helpText: 'What is the aim of this marketing activity?',
    forceLowerCase: true,
    optionList: [
      {
        hide: false,
        optionName: 'Awareness',
        optionValue: 'awareness',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Engagement',
        optionValue: 'engagement',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Conversions',
        optionValue: 'conversions',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Retargeting',
        optionValue: 'retargeting',
        optionFilter: null,
      },
      {
        hide: false,
        optionName: 'Retention',
        optionValue: 'retention',
        optionFilter: null,
      },
    ],
  },
  'Source platform': {
    fieldName: 'Source platform',
    fieldType: 'input',
    required: false,
    prefix: 'utm_source_platform=',
    helpText: 'What platform are you marketing / advertising on?',
    forceLowerCase: true,
  },
}
