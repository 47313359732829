// eslint-disable-next-line import/no-unresolved
import { RelationType } from 'react-archer/lib/types'

import { NewOptions } from '../api/types'
import { GetMarketingFunnelReportQuery } from '../__gql-types__/graphql'

export type AvailableTo = 'company' | 'account' | 'user'

export type Granularity = 'quarterly' | 'monthly' | 'weekly' | 'daily'

export interface GranularitySelectOptions {
  name: string
  value: string
}

export interface ReportDate {
  displayName: string
  metricValue: string
  label: string
  granularity: Granularity
}

export interface ReportFilterItem {
  dimensionName: string
  dimensionParameterID: string
  dimensionOptions: string[]
}

export interface ReportFilterSummaryItem {
  dimensionName: string
  dimensionParameterID: string
  dimensionOptions: NewOptions[]
}

export interface DashboardGraphData {
  dimensionName: string
  dimensionValues: number[]
}

export interface ReportSearchFilter {
  searchField: string
  searchValue: string
}

/** User-set variables common to all reports */
export interface ReportDataConfigCore {
  startDate: string
  endDate: string
}

export interface ReportDataConfigFull extends ReportDataConfigCore {
  selectedMetric?: string
  granularity?: Granularity
  matchType?: string | null
  stackDimension?: string | null
  applyFilters?: ReportFilterItem[] | null
  searchFilter?: ReportSearchFilter
  totalRows?: number
  tempTable?: string
  offset?: number
  limit?: number
  sortDirection?: 'ASC' | 'DESC'
  groupByLandingPage?: boolean
}

/* ********************************************* */
/* Main Performance Report
/* ********************************************* */

export interface PerformanceReportDataConfig extends ReportDataConfigCore {
  selectedMetric: string
  granularity: Granularity
  matchType: string
  stackDimension?: string | null
  applyFilters?: ReportFilterItem[] | null
}

export interface PerformanceReportRefetchOptions {
  getClickData?: boolean
  refetchMetrics?: boolean
  refetchGraph?: boolean
  refetchTable?: boolean
}

export interface SavedPerformanceReportVars {
  availableTo: AvailableTo
  reportName: string
  reportTitle: string
}

export interface SavedPerformanceReportDetails
  extends SavedPerformanceReportVars {
  savedReportID: string
  dateCreated: string
  createdByID: string
  createdBy: string
  lastEditedDate: string
  lastEditedByID: string
  lastEditedBy: string
  updatedTableColumnIndexOrderList?: number[]
  hideColumnIndexList?: number[]
}

export type SavedPerformanceReportDataConfig = PerformanceReportDataConfig &
  SavedPerformanceReportDetails

/* ********************************************* */
/* Lost Links Report
/* ********************************************* */

export interface LostLinksReportDataConfig extends ReportDataConfigCore {
  selectedMetric: string
  granularity: Granularity
  stackDimension?: string | null
  applyFilters?: ReportFilterItem[] | null
  searchFilter?: ReportSearchFilter
  totalRows?: number
  tempTable?: string
  offset?: number
  limit?: number
  sortDirection?: 'ASC' | 'DESC'
  groupByLandingPage?: boolean
}

export interface LostLinksReportRefetchOptions {
  tempTable?: string
  pagination?: PaginationConfig
  refetchTable?: boolean
  refetchGraph?: boolean
  refetchFilters?: boolean
}

export interface SavedLostLinksReportVars {
  availableTo: AvailableTo
  reportName: string
  reportTitle: string
}

export interface SavedLostLinksReportDetails extends SavedLostLinksReportVars {
  savedReportID: string
  dateCreated: string
  createdByID: string
  createdBy: string
  lastEditedDate: string
  lastEditedByID: string
  lastEditedBy: string
  updatedTableColumnIndexOrderList?: number[]
  hideColumnIndexList?: number[]
}

export type SavedLostLinksReportDataConfig = LostLinksReportDataConfig &
  SavedLostLinksReportDetails

/* ********************************************* */
/* Marketing Journeys Report
/* ********************************************* */

/** Shape returned when first requesting saved MarketingJourney reports */
export interface SavedMarketingJourneyResponse {
  boardID: string
  boardTitle: string
  boardURL?: string | null
  boardDescription: string
  boardHidden?: boolean
  availableTo: AvailableTo
}

export interface MarketingJourneyDataConfig extends ReportDataConfigCore {
  awarenessFilter?: string
  mainFilter?: ReportFilterItem[] | null
  filterList?: ReportFilterItem[] | null
}

export type MarketingJourneyReportLanes =
  | 'awareness'
  | 'consideration'
  | 'conversions'

export interface MarketingJourneyReportNode {
  id: string
  laneId: MarketingJourneyReportLanes
  title: string
  content: React.ReactNode
  showLinks?: boolean
  relations?: RelationType[]
  parentNodeId?: string
  childNodes?: MarketingJourneyReportNode[]
}

export interface MarketingJourneyReportLane {
  title: string
  summaryData?: string
  nodes: MarketingJourneyReportNode[]
}

export type CardConnection = NonNullable<
  GetMarketingFunnelReportQuery['report']['marketingFunnel']['getMarketingFunnelReport']['laneList'][0]['cardList'][0]['cardConnections']
>[0]

export interface CardConnectionsRefItem {
  laneIndex: number
  connections: (CardConnection & { secondaryConnectionIDs?: string[] })[]
  additionalConnections: CardConnection[]
}

export interface CardConnectionsRefObject {
  [cardID: string]: CardConnectionsRefItem
}

/* ********************************************* */
/* Usage Report
/* ********************************************* */

export const successMetrics = [
  'badCodesBlocked',
  'invalidCodesBreakdown',
  'codesCreated',
  'logins',
  'activeUsers',
] as const
type UsageSuccessMetricsTuple = typeof successMetrics
export type UsageSuccessMetrics = UsageSuccessMetricsTuple[number]

export interface UsageReportDataConfig extends ReportDataConfigCore {
  granularity: Granularity
  successMetric: UsageSuccessMetrics
  stackDimension?: string | null
  accountFilter?: NewOptions[]
  userFilter?: NewOptions[]
}
