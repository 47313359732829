import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'

import { currentUserDetails, dataSourceReactive } from '../api/apollo/variables'
import {
  deleteMarketingFunnelBoard,
  getCampaignLinkDashboardMeta,
  getMarketingFunnelReport,
  listSavedMarketingFunnelBoards,
  saveMarketingFunnelBoardInitial,
  updateMarketingFunnelBoard,
  updateMarketingFunnelBoardOrder,
} from '../api/graphql/report-client'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import ConnectAnalyticsBlocker from '../components/connect-analytics-blocker'
import Intro from '../components/intro'
import Layout from '../components/layout'
import ReportControls from '../components/report-controls'
import ReportMarketingJourneysFlow from '../components/report-marketing-journeys-flow'
import ReportSavedTabs, {
  ManageSavedReportsModal,
  SaveEditReportModal,
} from '../components/report-save'
import { SelectBoxChecklist } from '../components/select-box'
import SiteWrapper from '../components/site-wrapper'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/report-marketing-journeys.module.scss'
import {
  AvailableTo,
  MarketingJourneyDataConfig,
  ReportDataConfigCore,
  SavedMarketingJourneyResponse,
} from '../types/report-module'

export const initialDataConfig: ReportDataConfigCore = {
  startDate: 'thisYear',
  endDate: '',
}

const ReportMarketingJourneysPage = () => {
  const logAction = useLogAction()

  const dataSource = useReactiveVar(dataSourceReactive)
  const { companySubscriptionLevel, workspaceID } = useReactiveVar(
    currentUserDetails,
  )

  const [
    getGenerator,
    { data: generatorData, loading: loadingGenerator },
  ] = useLazyQuery(getCampaignCodeGenerator)
  const [
    getParamDimensionValues,
    {
      data: campaignLinkDashboardMetaData,
      loading: loadingCampaignLinkDashboardMeta,
      error: campaignLinkDashboardMetaError,
    },
  ] = useLazyQuery(getCampaignLinkDashboardMeta)
  const [
    getReportData,
    {
      data: currentReportData,
      loading: loadingReport,
      error: errorFetchingReportData,
    },
  ] = useLazyQuery(getMarketingFunnelReport, {
    notifyOnNetworkStatusChange: true,
  })
  const [
    listSavedBoards,
    { data: savedBoards, loading: loadingSavedBoards },
  ] = useLazyQuery(listSavedMarketingFunnelBoards, {
    fetchPolicy: 'cache-first',
  })

  const [saveNewBoard, { loading: savingNewReport }] = useMutation(
    saveMarketingFunnelBoardInitial,
    {
      refetchQueries: [listSavedMarketingFunnelBoards],
    },
  )
  const [updateSavedReport, { loading: updatingReport }] = useMutation(
    updateMarketingFunnelBoard,
    {
      refetchQueries: [listSavedMarketingFunnelBoards],
    },
  )
  const [updateBoardOrder] = useMutation(updateMarketingFunnelBoardOrder, {
    refetchQueries: [listSavedMarketingFunnelBoards],
  })
  const [deleteSavedBoard] = useMutation(deleteMarketingFunnelBoard, {
    refetchQueries: [listSavedMarketingFunnelBoards],
  })

  const [currentDataConfig, setCurrentDataConfig] = useState<
    MarketingJourneyDataConfig
  >(initialDataConfig)
  const [fetchedCampaignParameter, setFetchedCampaignParameter] = useState(
    false,
  )
  const [
    currentSavedReport,
    setCurrentSavedReport,
  ] = useState<SavedMarketingJourneyResponse | null>(null)
  const [
    reportToEdit,
    setReportToEdit,
  ] = useState<SavedMarketingJourneyResponse | null>(null)
  const [
    includeConfigInUpdateReport,
    setIncludeConfigInUpdateReport,
  ] = useState(true)
  const [mainFilterUpdated, setMainFilterUpdated] = useState(false)
  const [showSaveReportModal, setShowSaveReportModal] = useState(false)
  const [showManageReportsModal, setShowManageReportsModal] = useState(false)

  /** Used as initial breakdown parameter in controls */
  const {
    sourceParam,
    campaignParameter,
    availableDimensions,
  } = useMemo(() => {
    if (!generatorData || !campaignLinkDashboardMetaData) {
      return {
        sourceParam: null,
        campaignParameter: null,
        availableDimensions: [],
      }
    }

    const newDataConfig: MarketingJourneyDataConfig = { ...initialDataConfig }

    let _campaignParameter: {
      paramID: string
      paramName: string
      paramOptions: { name: string; value: string }[]
    } | null = null
    let _sourceParam: string | null = null

    const {
      campaignCodeGenerator: { paramDefs },
    } = generatorData

    const utmSourceParam = paramDefs.find(
      (field) => field.prefix.indexOf('utm_source=') > -1,
    )

    // Add to current data config
    if (utmSourceParam) {
      _sourceParam = utmSourceParam.fieldID
      newDataConfig.awarenessFilter = utmSourceParam.fieldID
    }

    const utmCampaignParam =
      // Check for a saved field first
      // TODO: This should allow for multiple fields to be concatenated in the future
      paramDefs.find((field) => field.isCampaignField) ||
      paramDefs.find((field) => field.prefix.indexOf('utm_campaign=') > -1)

    if (utmCampaignParam) {
      const {
        campaignLinkDashboardMeta: { availableDimensions: metaDimensions },
      } = campaignLinkDashboardMetaData

      if (!metaDimensions) return { campaignParameter: null }

      const utmParamID = utmCampaignParam.fieldID

      const fullDimension = metaDimensions.find(
        ({ dimensionParameterID }) => dimensionParameterID === utmParamID,
      )

      if (
        fullDimension &&
        fullDimension.dimensionOptions &&
        fullDimension.dimensionOptions.length > 0
      ) {
        _campaignParameter = {
          paramID: fullDimension.dimensionParameterID,
          paramName: fullDimension.dimensionName,
          paramOptions: fullDimension.dimensionOptions.map((option) => ({
            name: option,
            value: option,
          })),
        }

        // Add to current data config
        setCurrentDataConfig({
          ...newDataConfig,
          mainFilter: [
            {
              dimensionParameterID: _campaignParameter.paramID,
              dimensionName: _campaignParameter.paramName,
              dimensionOptions: [_campaignParameter.paramOptions[0].value],
            },
          ],
        })
      }
    }

    const {
      campaignLinkDashboardMeta: {
        availableDimensions: fullAvailableDimensions,
      },
    } = campaignLinkDashboardMetaData

    const _availableDimensions = fullAvailableDimensions
      ? fullAvailableDimensions.filter(
          (dim) =>
            _campaignParameter &&
            _campaignParameter.paramID !== dim.dimensionParameterID,
        )
      : []

    setFetchedCampaignParameter(true)

    return {
      sourceParam: _sourceParam,
      campaignParameter: _campaignParameter,
      availableDimensions: _availableDimensions,
    }
  }, [generatorData, campaignLinkDashboardMetaData])

  // Initial data fetch
  useEffect(() => {
    if (!workspaceID) return

    if (!fetchedCampaignParameter) {
      getGenerator()
      getParamDimensionValues()
      listSavedBoards()
    } else {
      // Only get report data once campaign name parameter has been checked
      getReportData({
        variables: {
          startDate: 'thisYear',
          endDate: '',
          awarenessFilter: sourceParam || undefined,
          // Default is to fetch first campaign name parameter only
          mainFilter: campaignParameter
            ? [
                {
                  dimensionParameterID: campaignParameter.paramID,
                  dimensionName: campaignParameter.paramName,
                  dimensionOptions: [campaignParameter.paramOptions[0].value],
                },
              ]
            : undefined,
        },
      })
    }
  }, [workspaceID, fetchedCampaignParameter])

  const savedReports: SavedMarketingJourneyResponse[] = useMemo(() => {
    if (!savedBoards) {
      return []
    }

    return savedBoards.report.marketingFunnel.getMarketingFunnelBoardList.map(
      (board) => ({
        ...board,
        boardID: board.boardID || '',
        availableTo: board.availableTo as AvailableTo,
      }),
    )
  }, [savedBoards])

  /** List of parameters that can be split by for the report */
  const awarenessFilterOptions = useMemo(() => {
    if (!generatorData) return []

    return generatorData.campaignCodeGenerator.paramDefs.map(
      ({ fieldName, fieldID, helpText }) => ({
        name: fieldName,
        shortName: fieldName,
        value: fieldID,
        tooltip: helpText,
      }),
    )
  }, [generatorData])

  const refetchData = useCallback(
    async (
      data: MarketingJourneyDataConfig | { boardID: string },
      options?: {
        switchToDefault?: boolean
        updateDataConfig?: boolean
        refetchFromNetwork?: boolean
      },
    ) => {
      const { data: refetchedData } = await getReportData({
        variables: data,
        fetchPolicy: options?.refetchFromNetwork ? 'network-only' : undefined,
      })

      if (options?.updateDataConfig && refetchedData) {
        const {
          report: {
            marketingFunnel: {
              getMarketingFunnelReport: {
                boardDefinition: {
                  startDate,
                  endDate,
                  awarenessFilter,
                  mainFilter,
                  filterList,
                },
              },
            },
          },
        } = refetchedData

        // Update report controls to match current config
        setCurrentDataConfig(
          options?.switchToDefault
            ? {
                ...initialDataConfig,
                awarenessFilter: awarenessFilter || undefined,
                mainFilter: campaignParameter
                  ? [
                      {
                        dimensionParameterID: campaignParameter.paramID,
                        dimensionName: campaignParameter.paramName,
                        dimensionOptions: [
                          campaignParameter.paramOptions[0].value,
                        ],
                      },
                    ]
                  : undefined,
                filterList: undefined,
              }
            : {
                startDate,
                endDate: endDate || '',
                awarenessFilter: awarenessFilter || undefined,
                mainFilter: mainFilter || undefined,
                filterList: filterList
                  ? filterList.map(
                      ({
                        dimensionParameterID,
                        dimensionName,
                        dimensionOptions,
                      }) => ({
                        dimensionParameterID,
                        dimensionName,
                        dimensionOptions,
                      }),
                    )
                  : undefined,
              },
        )
      }
    },
    [workspaceID, currentSavedReport, campaignParameter],
  )

  const switchCurrentReport = useCallback(
    (resetToReport: SavedMarketingJourneyResponse | null) => {
      setCurrentSavedReport(resetToReport)

      if (resetToReport && resetToReport.boardID) {
        const { boardID } = resetToReport

        refetchData({ boardID }, { updateDataConfig: true })
      } else {
        // Reset to default
        refetchData(
          {
            boardID: undefined,
            startDate: 'thisYear',
            endDate: '',
            mainFilter: campaignParameter
              ? [
                  {
                    dimensionParameterID: campaignParameter.paramID,
                    dimensionName: campaignParameter.paramName,
                    dimensionOptions: [campaignParameter.paramOptions[0].value],
                  },
                ]
              : undefined,
            awarenessFilter: sourceParam || undefined,
            filterList: undefined,
          },
          {
            switchToDefault: true,
            updateDataConfig: true,
            refetchFromNetwork: true,
          },
        )
      }
    },
    [currentDataConfig, campaignParameter],
  )

  // Page should not be visible for workspaces with Adobe data source
  if (dataSource?.connectionSource === 'adobe') {
    return (
      <Redirect
        to={{
          pathname: '/report/performance',
        }}
      />
    )
  }

  if (
    companySubscriptionLevel === 'preSetup' ||
    (dataSource &&
      (dataSource.connectionSource === 'not-connected' ||
        !!dataSource.requiresReconnect))
  ) {
    return (
      <SiteWrapper>
        <Layout width={1600}>
          <Intro />
          <ConnectAnalyticsBlocker
            includeAdobe={false}
            reconnect={!!dataSource?.requiresReconnect}
          />
        </Layout>
      </SiteWrapper>
    )
  }

  return (
    <>
      <SiteWrapper>
        <Layout width={2560}>
          <Intro title="Marketing journeys" className={styles.intro} isBeta>
            <p>
              Create diagrams which show how campaign traffic flows through key
              marketing journeys.
            </p>
          </Intro>
          <ReportControls
            interactionLogReportName="marketing-journeys"
            loadingData={loadingReport}
            controlsPrefix={
              campaignParameter ? (
                <>
                  <SelectBoxChecklist
                    variant="grey"
                    id={campaignParameter.paramID}
                    className={styles.controlsSelector}
                    isLoading={
                      loadingGenerator || loadingCampaignLinkDashboardMeta
                    }
                    excludeAny
                    isDisabled={loadingReport}
                    noneLabel="All campaigns"
                    isSearchable
                    labelKey="name"
                    placeholder="All campaigns"
                    defaultValue={[]}
                    value={
                      currentDataConfig.mainFilter &&
                      currentDataConfig.mainFilter.length > 0
                        ? campaignParameter.paramOptions.filter((param) =>
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            currentDataConfig.mainFilter![0].dimensionOptions.includes(
                              param.value,
                            ),
                          )
                        : []
                    }
                    options={campaignParameter.paramOptions}
                    onChange={async (newValue) => {
                      if (!newValue) return

                      const newDataConfig = {
                        ...currentDataConfig,
                        mainFilter:
                          newValue.length === 0 ||
                          newValue.length ===
                            campaignParameter.paramOptions.length
                            ? undefined
                            : [
                                {
                                  dimensionParameterID:
                                    campaignParameter.paramID,
                                  dimensionName: campaignParameter.paramName,
                                  dimensionOptions: newValue.map(
                                    (option) => option.value,
                                  ),
                                },
                              ],
                      }

                      setCurrentDataConfig(newDataConfig)

                      if (newValue.length === 0) {
                        // Immediately refetch
                        await refetchData({
                          ...newDataConfig,
                          boardID: undefined,
                        })

                        logAction({
                          variables: {
                            action: `update-mainFilter-marketing-journeys`,
                            extra: JSON.stringify(newDataConfig),
                            websiteSection: 'report',
                            functionName: `update_mainFilter`,
                            pagePath: window.location.pathname,
                          },
                        })

                        setMainFilterUpdated(false)
                      } else {
                        // Wait for blur
                        setMainFilterUpdated(true)
                      }
                    }}
                    onBlur={async () => {
                      if (mainFilterUpdated) {
                        await refetchData({
                          ...currentDataConfig,
                          boardID: undefined,
                        })

                        logAction({
                          variables: {
                            action: `update-mainFilter-marketing-journeys`,
                            extra: JSON.stringify(currentDataConfig),
                            websiteSection: 'report',
                            functionName: `update_mainFilter`,
                            pagePath: window.location.pathname,
                          },
                        })

                        setMainFilterUpdated(false)
                      }
                    }}
                  />
                  <span className={styles.reportControlCopy}> split by </span>
                </>
              ) : (
                'Show'
              )
            }
            primaryBreakdownID="awarenessFilter"
            includePrimaryBreakdown
            fullPrimaryBreakdownList={awarenessFilterOptions}
            loadingPrimaryBreakdowns={loadingGenerator}
            includeGranularity={false}
            includeFilter
            filterPropertyName="filterList"
            loadingFilters={loadingCampaignLinkDashboardMeta}
            filterError={!!campaignLinkDashboardMetaError}
            filterData={availableDimensions}
            currentDataConfig={currentDataConfig}
            setCurrentDataConfig={setCurrentDataConfig}
            onChange={async (newDataConfig) => {
              await refetchData({
                ...newDataConfig,
                boardID: undefined,
              })
            }}
          />
          <ReportSavedTabs
            reportType="marketingJourney"
            initialReportName="Default journey"
            currentDataConfig={currentDataConfig}
            currentSavedReport={currentSavedReport}
            setShowSaveReportModal={setShowSaveReportModal}
            loading={loadingSavedBoards || updatingReport || savingNewReport}
            reportIDKey="boardID"
            reportNameKey="boardTitle"
            reportDescriptionKey="boardDescription"
            savedReports={savedReports.filter((board) => !board.boardHidden)}
            onResetReport={switchCurrentReport}
            onSaveReport={async (newReport) => {
              await updateSavedReport({ variables: newReport })
            }}
            actionButtons={[
              {
                key: 'manageReports',
                isDisabled: savedReports.length === 0,
                onPress: () => setShowManageReportsModal(true),
                buttonText: 'Manage reports',
              },
              {
                key: 'saveReport',
                isDisabled: loadingSavedBoards,
                loading: updatingReport,
                onPress: async () => {
                  if (currentSavedReport === null) {
                    setShowSaveReportModal(true)
                  } else {
                    await updateSavedReport({
                      variables: {
                        ...currentDataConfig,
                        boardID: currentSavedReport.boardID as string,
                        boardTitle: currentSavedReport.boardTitle,
                      },
                    })
                  }
                },
                buttonText:
                  currentSavedReport === null ? 'Save report' : 'Save changes',
                logAction: {
                  action: 'saved-marketingJourney-reports-update-existing',
                  extra: JSON.stringify({
                    ...currentSavedReport,
                    ...currentDataConfig,
                  }),
                  functionName: 'updateSavedReport',
                },
              },
            ]}
          />
          <div className={styles.reportPaper}>
            <ReportMarketingJourneysFlow
              reportData={currentReportData}
              loading={
                loadingReport || (!currentSavedReport && savingNewReport)
              }
              error={!!errorFetchingReportData}
              stackDimensionName={
                awarenessFilterOptions.find(
                  (option) =>
                    option.value === currentDataConfig.awarenessFilter,
                )?.name ||
                awarenessFilterOptions[0]?.name ||
                undefined
              }
              // Unsaved boards should be implicitly saved when edited
              saveNewBoard={
                currentSavedReport
                  ? undefined
                  : async (boardID: string, boardTitle: string) => {
                      await saveNewBoard({
                        variables: {
                          ...currentDataConfig,
                          boardID,
                          boardTitle,
                          availableTo: 'user',
                        },
                      })

                      setCurrentSavedReport({
                        boardID,
                        boardTitle,
                        boardDescription: '',
                        availableTo: 'user',
                      })
                    }
              }
            />
          </div>
        </Layout>
      </SiteWrapper>
      {showSaveReportModal && currentReportData && (
        <SaveEditReportModal
          toggleActive={setShowSaveReportModal}
          reportType="marketingJourney"
          reportIDKey="boardID"
          reportNameKey="boardTitle"
          reportToEdit={reportToEdit}
          loading={savingNewReport || updatingReport}
          reportDescriptionKey="boardDescription"
          onSuccess={async (newReport) => {
            if (reportToEdit === null) {
              await saveNewBoard({
                variables: {
                  ...newReport,
                  ...currentDataConfig,
                  boardID: currentReportData.report.marketingFunnel
                    .getMarketingFunnelReport.boardID as string,
                },
              })
            } else {
              await updateSavedReport({
                variables: includeConfigInUpdateReport
                  ? {
                      ...newReport,
                      ...currentDataConfig,
                    }
                  : { ...newReport },
              })
            }

            setCurrentSavedReport({
              ...newReport,
              boardID: currentReportData.report.marketingFunnel
                .getMarketingFunnelReport.boardID as string,
            })

            setShowSaveReportModal(false)
          }}
        />
      )}
      {showManageReportsModal && (
        <ManageSavedReportsModal
          toggleActive={() => {
            setShowManageReportsModal(false)
            setIncludeConfigInUpdateReport(true)
          }}
          reportType="marketingJourney"
          reportIDKey="boardID"
          reportNameKey="boardTitle"
          reportHiddenKey="boardHidden"
          loading={loadingSavedBoards || savingNewReport || updatingReport}
          savedReports={savedReports}
          setReportToEdit={setReportToEdit}
          setShowSaveReportModal={(bool) => {
            setShowSaveReportModal(bool)
            setIncludeConfigInUpdateReport(false)
          }}
          onUpdateOrder={async (newOrder) => {
            await updateBoardOrder({
              variables: { newOrder },
            })
          }}
          onHideReport={async (reportsOrder, hiddenReports) => {
            await updateBoardOrder({
              variables: {
                newOrder: reportsOrder,
                hideReports: hiddenReports,
              },
            })

            // If current report is deleted, switch back to default report
            if (
              currentSavedReport?.boardID &&
              hiddenReports.indexOf(currentSavedReport?.boardID) > -1
            ) {
              switchCurrentReport(null)
            }
          }}
          onDeleteReport={async (reportIdToDelete) => {
            await deleteSavedBoard({
              variables: {
                boardID: reportIdToDelete,
              },
            })

            // If current report is deleted, switch back to default report
            if (reportIdToDelete === currentSavedReport?.boardID) {
              switchCurrentReport(null)
            }
          }}
        />
      )}
    </>
  )
}

export default ReportMarketingJourneysPage
