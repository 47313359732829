import React from 'react'
import classNames from 'classnames'

import ImgOne from '../assets/generator-help-img-1.jpg'
import GoodCampaignNames from '../assets/goodcampaignnames.png'
import BadCampaignNames from '../assets/badcampaignnames.jpg'
import BrandedLink from '../assets/branded-link.png'
import QRCode from '../assets/qrcode.jpg'
import Button from '../components/button'
import ButtonDropdown from '../components/button-dropdown'
import Link from '../components/link'
import Row, { Slot } from '../components/row'
import Table from '../components/table'
import Tooltip from '../components/tooltip'
import {
  BoxedText,
  NoteText,
  OrderedList,
  TickListItem,
} from '../components/typography'
import { brandName, supportEmail } from '../core/constants'
import styles from '../styles/track-learn-accordion.module.scss'

const trackLearnContent = {
  adobe: [
    {
      heading: '1. Why we track marketing and campaigns',
      content: (
        <>
          <p>
            Marketing is big business, with 5-10% of a typical company's budgets
            spent on marketing, representing a total of $4.7 trillion dollars a
            year. Tracking marketing activity and campaigns help us compare
            campaigns, understand performance, and learn what marketing
            strategies work and what doesn't.
          </p>
          <p>
            By continuously learning from campaign data, we can optimise our
            marketing spend and get a larger Return on Investment (ROI) from
            marketing.
          </p>
          <p>
            Campaigns used to be tracked with unreliable surveys. Since the
            growth of digital advertising, we can see much deeper into users
            journeys after they click on an ad and come to our digital
            properties (website and apps).
          </p>
        </>
      ),
    },
    {
      heading: '2. Why should you use campaign links?',
      content: (
        <>
          <p>
            To understand which marketing channels, campaigns, ad creatives and
            formats perform the best after they click on your ad and visit your
            website / app.
          </p>
          <p>
            This allows you to test different campaign approaches and then
            optimise your marketing budget on the channels and campaigns which
            achieve your goals.
          </p>
          <h4>Campaign links help answer:</h4>
          <ul className={styles.unorderedList}>
            <TickListItem>
              Which campaign should we spend more / less money on?
            </TickListItem>
            <TickListItem>
              Which is the best channel to get additional engaged traffic to our
              website?
            </TickListItem>
            <TickListItem>
              Which ad formats give us the most engagement and onsite sales?
            </TickListItem>
            <TickListItem>
              Which email offer generated the most profit on our website?
            </TickListItem>
            <TickListItem>
              Which search engine keyword groups should we spend more money on?
            </TickListItem>
            <TickListItem>
              What's the demographics of our campaign visitors?
            </TickListItem>
            <TickListItem>
              What content or products are visitors from campaigns interested
              in?
            </TickListItem>
            <TickListItem>
              How did our campaign visitors behave and navigate on-site?
            </TickListItem>
            <TickListItem>
              How much website activity did our offline media campaigns
              generate?
            </TickListItem>
          </ul>
          <NoteText>
            Google Analytics will try and categorise your website traffic into
            'Default Channel Grouping' categories including Direct, Organic
            Search, Paid Search, Referral &amp; Social, using the referring URL.
            This results in too much traffic going into the 'Direct' category,
            including App and Email traffic. The default categories are often
            categorised traffic incorrectly and lacks the detail UTMs can
            provide.
          </NoteText>
        </>
      ),
    },
    {
      heading: '3. What is a campaign link and what does it look like?',
      content: (
        <>
          <p>
            A link to a website or app, which starts with the website landing
            page and ends with a snippet of tracking code.
          </p>
          <p>
            This code tells marketing analytics platforms (like Google and Adobe
            Analytics) how and where the user came from. Helping you understand
            what marketing works and what doesn't.{' '}
          </p>
          <p>
            This code is also known as a query string parameter, marketing code,
            tracking code or in Google Analytics a UTM code.
          </p>
          <p>
            It is best practice to create a unique query string parameter for
            every marketing URL which lands on your website.
          </p>
          <p>
            You can identify the query string parameter as the part after the{' '}
            <BoxedText>?</BoxedText> in the URL. Hover over the link below to
            learn campaign link anatomy:
          </p>
          <div className={styles.example}>
            <Tooltip
              id="landing-page-url-tooltip"
              className={classNames(styles.urlExample, styles.auditColor)}
              tooltipMessage="The landing page URL of your campaign"
            >
              http://www.website.com/
            </Tooltip>
            <Tooltip
              id="query-param-tooltip"
              className={styles.urlExample}
              tooltipMessage="Indicates all characters after the question mark are query string parameters and not part of the URL"
            >
              ?
            </Tooltip>
            <Tooltip
              id="cid-tooltip"
              className={classNames(styles.urlExample, styles.trackColor)}
              tooltipMessage="The parameter which will be collected and sent to Adobe"
            >
              cid
            </Tooltip>
            <Tooltip
              id="separator-tooltip"
              className={styles.urlExample}
              tooltipMessage="Separates the first parameter name from the parameter value"
            >
              =
            </Tooltip>
            <Tooltip
              id="first-parameter-value-tooltip"
              className={classNames(styles.urlExample, styles.explainColor)}
              tooltipMessage="The first parameter value, which can be viewed in Adobe Analytics under campaign ID"
            >
              sum_sale|social|twitter
            </Tooltip>
          </div>
          <p>
            In this example, there are two query string parameters. Custom
            parameters can be added by including extra{' '}
            <BoxedText>&amp;</BoxedText>at the end of any parameter value. For
            example:
            <BoxedText>&amp;product=value</BoxedText>
          </p>
          <NoteText label="Important:">
            All visitors go to the same webpage if the landing page URL is the
            same.
          </NoteText>
          <img src={ImgOne} alt="campaign diagram" />
        </>
      ),
    },
    {
      heading: '4. What campaign parameters make up a CID?',
      content: (
        <>
          <p>
            You can view all your current parameters in{' '}
            <BoxedText>
              <Link href="/track/create-links">Track &gt; Create links</Link>
            </BoxedText>
            , with customisable tool tips which explain what they are.
          </p>
          <p>
            In Adobe Analytics your company creates the campaign parameters
            which are valuable to them. Your unique CID is often translated in
            Adobe Analytics to multiple parameters values.
          </p>
          <NoteText label="Example:">
            <>
              <p>
                <BoxedText>cid=sum_sale|social|twitter</BoxedText> can appear in
                Adobe Analytics and {brandName} as:
              </p>
              <ul className={styles.unorderedList}>
                <TickListItem>
                  Campaign name: <BoxedText>Summer Sale</BoxedText>
                </TickListItem>
                <TickListItem>
                  Channel: <BoxedText>Social Paid</BoxedText>
                </TickListItem>
                <TickListItem>
                  Source: <BoxedText>Twitter</BoxedText>
                </TickListItem>
              </ul>
            </>
          </NoteText>
          <p>
            Admins set a readable name <BoxedText>Summer Sale</BoxedText> and
            code name <BoxedText>sum_sale</BoxedText> in{' '}
            <BoxedText>
              <Link href="/track/edit-dropdowns">
                Track &gt; Edit dropdowns
              </Link>
            </BoxedText>{' '}
            for each dropdown value.
          </p>
          <p>
            {brandName} automatically uploads an Adobe translation file at
            00:30(GMT) every night which tells Adobe Analytics what each short
            code means and what parameter it should be mapped to.
          </p>
          <p>
            As part of the onboarding process in {brandName}, we assess your
            current parameters and connect to Adobe Analytics.
          </p>
          <p>
            By default every link contains the parameter
            <BoxedText>&up_id=(unique_id)</BoxedText>. We use up_id to stitch
            data together more accurately and let users bulk edit existing links
            more easily.{' '}
            <Link
              type="arrowForward"
              href="https://support.uplifter.ai/hc/en-us/articles/16258919414685-What-are-smart-links-and-up-id"
            >
              Learn more about Smart Links
            </Link>
          </p>
          <p>
            Most of our clients choose to make the CID value from a combination
            of parameters and separate them by a special character like a pipe
            <BoxedText>|</BoxedText>.
          </p>
          <h4>Our most common campaign parameters include:</h4>
          <ul className={styles.unorderedList}>
            <TickListItem>Campaign name</TickListItem>
            <TickListItem>Business unit or Department</TickListItem>
            <TickListItem>Target audience or Persona</TickListItem>
            <TickListItem>Source or Publisher</TickListItem>
            <TickListItem>Medium or Channel</TickListItem>
            <TickListItem>Country or Language</TickListItem>
            <TickListItem>Ad creative</TickListItem>
            <TickListItem>Ad format</TickListItem>
            <TickListItem>Keywords</TickListItem>
          </ul>
          <NoteText label="Top tip:">
            Use campaign parameters sparingly to avoid creating a burden on your
            users each time they need to create new campaign links.
          </NoteText>
        </>
      ),
    },
    {
      heading: '5. How to create a campaign link',
      content: (
        <>
          <p>
            Once your campaign link creator has been setup and connected to
            Adobe Analytics, you can create codes by:
          </p>
          <OrderedList>
            <li>
              In the left-hand navigation bar, click{' '}
              <BoxedText>Track&gt;Create</BoxedText>
            </li>
            <li>
              In the landing page box, type/paste the URL where you want users
              to land.{' '}
            </li>
            <li>
              Select an option for each of the dropdown parameters. (Source,
              Medium). If your dropdown choice is not visible, admins can add it
              in <BoxedText>Track&gt;Edit</BoxedText>.
            </li>
            <li>
              In the free text boxes, type readable text into (Campaign,
              Content, Term). We recommend lowercase only, replacing spaces with
              <BoxedText>_</BoxedText>
            </li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Create campaign link
              </Button>{' '}
              button to create your campaign link.
            </li>
          </OrderedList>
          <p>
            If all required fields are entered correctly - a shake animation
            will show your new campaign link created in the 'Recently created
            campaign links' box. You should never need to create the same
            landing page with campaign link twice - we will prevent this from
            happening.
          </p>
          <p>With your codes created you can:</p>
          <ul className={styles.unorderedList}>
            <TickListItem>
              Copy the URL with campaign link directly into marketing platforms.
            </TickListItem>
            <TickListItem>
              Click{' '}
              <Button
                variant="secondary"
                className={styles.mockButton}
                demoOnly
              >
                Download csv
              </Button>{' '}
              to download all recently created codes.
            </TickListItem>
            <TickListItem>
              Let other users view, download, and share codes from{' '}
              <BoxedText>Track&gt;View</BoxedText> page.
            </TickListItem>
          </ul>
          <p>
            You can review, share, delete and edit your own campaign links from
            the <BoxedText>Track&gt;View</BoxedText> page. Only admins can
            delete and edit other users' codes.
          </p>
        </>
      ),
    },
    {
      heading: '6. Campaign link best practice',
      content: (
        <>
          <p>
            Without following best practice, tracking could be lost or worse the
            landing page might break, wasting your marketing budget. Best
            practice will ensure:
          </p>
          <ul className={styles.unorderedList}>
            <TickListItem>
              <strong>Good governance.</strong> Everyone knows who owns the
              process, who task for help and it's enforced.
            </TickListItem>
            <TickListItem>
              <strong>Complete and accurate data.</strong> With no channels or
              campaigns missing or different campaign data blended.
            </TickListItem>
            <TickListItem>
              <strong>Easy to understand data.</strong> To stop users
              misinterpreting the data, making false insights or sub-optimal
              decisions.
            </TickListItem>
          </ul>
          <h4>Best practice:</h4>
          <OrderedList>
            <li>
              <strong>Create an internal designated owner(s).</strong> Designate
              someone to be accountable for making sure all campaigns are
              tracked correctly. Users and external media agencies often forget
              to apply codes, affecting all your analytics data and
              decision-making ability.
            </li>
            <li>
              <strong>Don't use a spreadsheet.</strong> Spreadsheets are easily
              corrupted and can be shared without good governance. It can also
              be hard to enforce campaign link naming conventions and rules
              around uniformity, hierarchy, spelling, and capitalisation. They
              do not provide a record of all campaigns or a user history for
              accountability.
            </li>
            <li>
              <strong>Never retype a URL with a campaign link.</strong> Always
              copy and paste the campaign link from {brandName} or the CSV
              download directly. This ensures the URL is correct and stops human
              error.
            </li>
            <li>
              <strong>Name campaigns clearly.</strong> Your campaign names
              should be simple and use recognisable terms. This enables users to
              understand what data they are looking at without having to use a
              lookup table to decipher meaning.
              <div>
                <Row>
                  <Slot>
                    <img src={GoodCampaignNames} alt="Good campaign names" />
                  </Slot>
                  <Slot>
                    <img src={BadCampaignNames} alt="Bad campaign names" />
                  </Slot>
                </Row>
              </div>
            </li>
            <li>
              <strong>No long parameter names.</strong> Keep any names under 40
              characters, total query string parameters under 255 and your whole
              URL under 1024 characters.
            </li>
            <li>
              <strong>
                Use special characters <BoxedText>?</BoxedText>
                <BoxedText>&amp;</BoxedText>
                <BoxedText>=</BoxedText>
                appropriately.
              </strong>{' '}
              Otherwise, the link will break, follow these rules:
              <ul className={styles.unorderedList}>
                <TickListItem>
                  Only one question mark <BoxedText>?</BoxedText> at the start
                  of the query string parameter.
                </TickListItem>
                <TickListItem>
                  Ampersands <BoxedText>&amp;</BoxedText> should only be used as
                  connectors between parameters.
                </TickListItem>
                <TickListItem>
                  Equal <BoxedText>=</BoxedText> symbols should only be used
                  between a parameter name and value.
                </TickListItem>
              </ul>
            </li>
            <li>
              <strong>
                Spaces should be replaced by
                <BoxedText>_</BoxedText> or <BoxedText>-</BoxedText> or{' '}
                <BoxedText>|</BoxedText>.
              </strong>{' '}
              Otherwise, spaces can break the link or be replaced by a messy{' '}
              <BoxedText>%20</BoxedText>.
            </li>
            <li>
              <strong>Always use lower case letters in the link. </strong> This
              will make your reports easier to read.
            </li>
            <li>
              <strong>
                Not every campaign needs to use all available parameters.
              </strong>{' '}
              Only the necessary fields need to be completed. There is no need
              to label the term or content fields as 'none' if they are not
              actively being used.
            </li>
            <li>
              <strong>
                Don't use inappropriate or discriminatory language.
              </strong>{' '}
              It can be possible for your end users to see campaign links and be
              offended.
            </li>
            <li>
              <strong>Don't use the same code for different campaigns. </strong>{' '}
              Using the same code will combine all the traffic, conversions and
              behaviour into one pot you cannot separate.
            </li>
            <li>
              <strong>Be careful if your landing page has redirects</strong> If
              your landing page has a redirect, you need to implement campaign
              links on both the original landing page and the redirect page.
              Otherwise, the original landing page could be picked up as the
              referrer of traffic.
            </li>
            <li>
              <strong>
                Don't use campaign links on internal website links.
              </strong>{' '}
              If you do, your campaign data will be overwritten, and you will
              lose the original tracking source. You can use internal promotion
              code parameters for tracking internal links.
            </li>
          </OrderedList>
        </>
      ),
    },
    {
      heading: '7. How to setup your unique campaign link creator',
      content: (
        <>
          <p>
            Our campaign link generator is fully flexible and should work for
            any organisation regardless of parameters, code structure or
            analytics platform. We acknowledge setting up unique code structures
            can be tricky, so contact{' '}
            <Link href={`mailo:${supportEmail}`}>{supportEmail}</Link> for
            additional help.
          </p>
          <NoteText>
            Only admins have permissions to edit the campaign link structure.
          </NoteText>
          <p>
            Admin users will need to add new parameter values (Sources, Medium
            and Campaigns) to the parameter dropdowns campaign link generator
            over time.
          </p>

          <h4>To add new parameter value to a dropdown list</h4>
          <OrderedList>
            <li>
              Click on{' '}
              <BoxedText>
                <Link href="/track/edit-dropdowns">
                  Track &gt; Edit dropdowns
                </Link>
              </BoxedText>{' '}
              in the left-hand navigation bar.
            </li>
            <li>
              Click on a parameter to see the current dropdown names and codes.
              The name is what your users will see in the dropdown box, the
              'code' is what will be present in the URL. This allows you to use
              shorter codes.
            </li>
            <li>
              Click on{' '}
              <Button className={styles.mockButton} demoOnly>
                Add new dropdown
              </Button>{' '}
              button next to dropdown name.
            </li>
            <li>In the blank boxes, type your name and code.</li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Confirm
              </Button>{' '}
              button to the right, underneath actions.
            </li>
          </OrderedList>

          <h4>To remove a parameter value from a dropdown list</h4>
          <OrderedList>
            <li>
              Click on{' '}
              <BoxedText>
                <Link href="/track/edit-dropdowns">
                  Track &gt; Edit dropdowns
                </Link>
              </BoxedText>{' '}
              in the left-hand navigation bar.
            </li>
            <li>
              Click on a parameter to see the current dropdown names and codes.
            </li>
            <li>
              Click on the trashcan to the right of the dropdown name and code.
            </li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Yes
              </Button>{' '}
              to confirm.
            </li>
          </OrderedList>
          <h4>Edit parameters and rules</h4>
          <p>
            In the{' '}
            <BoxedText>
              <Link href="/track/edit-parameters-and-rules">
                Track &gt; Edit
              </Link>
            </BoxedText>{' '}
            parameters page, admins can edit options which effect every single
            link created.
          </p>
          <p>
            Prefix is the first code that will be shown after the URL and in
            most cases is a <BoxedText>?</BoxedText>.
          </p>
          <p>
            Separator is a common value separates each parameter, this is
            usually <BoxedText>&amp;</BoxedText> but may be blank if you are
            adding together multiple dropdowns into one parameter.
          </p>
          <p>
            <BoxedText>
              <Link href="/track/edit-parameters-and-rules">
                Track &gt; Edit
              </Link>
            </BoxedText>{' '}
            parameters page is where you add, edit, reorder, or delete your
            query string parameters.
          </p>
          <p>
            Your organisation may have additional or different parameters (other
            than source, medium, campaign, term and content) you wish to add.
          </p>

          <h4>To add a new parameter</h4>
          <OrderedList>
            <li>
              Click on{' '}
              <BoxedText>
                <Link href="/track/edit-parameters-and-rules">
                  Track &gt; Edit parameters
                </Link>
              </BoxedText>{' '}
              in the left-hand navigation bar.
            </li>
            <li>
              Next to parameter, click on the{' '}
              <Button
                variant="secondary"
                className={styles.mockButton}
                demoOnly
              >
                Add parameter
              </Button>{' '}
              button.
            </li>
            <li>Type the parameter name as it will appear in {brandName}.</li>
            <li>
              Enter a description which will explain what this parameter is to
              your users.
            </li>
            <li>
              Type prefix which is the parameter name as you want it to appear
              in your campaign link URL.
            </li>
            <li>
              Choose if you want users to have to select a dropdown input
              managed by admin (good for governance) or allow them to write free
              text (good for self-serve).
            </li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Add parameter
              </Button>{' '}
              to add it to your campaign link generator. It will always appear
              at the bottom in <BoxedText>Edit campaign links</BoxedText> but
              can be dragged into the position you wish it to be shown in your
              URL.
            </li>
          </OrderedList>

          <p>
            Your organisation may need to delete parameters which are not
            useful. This will remove all your codes for this parameter
            permanently, contact us if you remove a parameter by mistake.
          </p>
          <h4>To delete a query parameter</h4>
          <OrderedList>
            <li>
              Click on{' '}
              <BoxedText>
                <Link href="/track/edit-parameters-and-rules">
                  Track &gt; Edit
                </Link>
              </BoxedText>{' '}
              in the left-hand navigation bar.
            </li>
            <li>
              Next to parameter options, click on the More expansion arrow.
            </li>
            <li>
              Click on the trashcan button next to the parameter you wish to
              delete.
            </li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Yes
              </Button>{' '}
              to confirm.
            </li>
          </OrderedList>
          <h4>Advanced rules</h4>
          <p>
            These rules to make sure your campaign links are readable,
            consistent, and functional. By default, best practice data
            validation is enforced. To turn off a rule, just uncheck the tick
            box next to it.
          </p>
        </>
      ),
    },
    {
      heading: '8. How to import historical links from a CSV',
      content: (
        <>
          <p>
            You may want to import campaign links from other platforms or old
            spreadsheets into {brandName}{' '}
            <BoxedText>
              <Link href="/track/view-links">Track &gt; View links</Link>
            </BoxedText>{' '}
            table. This allows you to review all campaign links and their
            performance in one place.
          </p>
          <NoteText label="Important:">
            <>
              You need to setup your campaign link creator to have the latest
              parameters before uploading your old campaign codes. We recommend
              making your old parameters fit into your latest {brandName}{' '}
              parameters rather than vice versa.
            </>
          </NoteText>
          <p>
            To import codes into{' '}
            <BoxedText>
              <Link href="/track/view-links">Track &gt; View links</Link>
            </BoxedText>{' '}
            table:
          </p>

          <OrderedList>
            <li>
              In the left-hand navigation bar click on{' '}
              <BoxedText>
                <Link href="/track/view-links">Track &gt; View links</Link>
              </BoxedText>
            </li>
            <li>
              In the{' '}
              <Button className={styles.mockButton} demoOnly>
                Actions
              </Button>{' '}
              dropdown select 'Import historical links'.
            </li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Download CSV template
              </Button>
            </li>
            <li>
              Copy and paste your landing pages with campaign links and
              parameters into each column in the CSV and save it.
            </li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Upload file
              </Button>{' '}
              to upload the CSV.
            </li>
          </OrderedList>
        </>
      ),
    },
    {
      heading: '9. Using campaign links for offline media channels',
      content: (
        <>
          <p>
            Offline marketing campaigns do not have to be invisible to your
            analytics platform. They can be tracked using QR codess and short
            links. This allows you to compare between offline marketing efforts,
            to see which ones bring the most engaged traffic to your website.
          </p>

          <h4>QR codes</h4>
          <p>
            QR codes scanned by mobile phones on physical printouts, ads or
            digital screens redirect to a unique landing page with your campaign
            parameters.
          </p>
          <NoteText label="Example:">
            <>
              A printed flyer handed out in New York could contain a QR code
              which once scanned takes the user to:
              <strong>
                https://centralperk.com/coffee?utm_source=new_york&amp;utm_medium=flyer
              </strong>
            </>
          </NoteText>
          <p>
            This way you can compare the two locations to see which had the best
            performance. You could also hand out flyers with different
            promotions and different QR codes to test which ones have the
            greatest impact.
          </p>
          <h4>Short links/Vanity URLs</h4>
          <p>
            A short link or vanity URL is a user-friendly, memorable version of
            a webpage address or link, which redirects the user to a longer web
            address, complete with campaign links to track their activity. This
            means it can be used on a billboard or on a flyer.
          </p>
          <NoteText label="Example:">
            <>
              Instead of sending someone to
              <strong>
                https://centralperk/coffee?utm_source=flyer&amp;utm_medium=offline&amp;utm_campaign=free_coffee
              </strong>
              to receive a coffee voucher, you can send them to
              <BoxedText>https://cperk.store/free</BoxedText> which will then
              redirect them to
              <strong>
                https://centralperk/coffee?utm_source=flyer&amp;utm_medium=offline&amp;utm_campaign=free_coffee
              </strong>
            </>
          </NoteText>
          <p>
            Short links can be put on marketing materials as they take less
            space. It's worth noticing that some users may still just search for
            your product using a search engine and therefore you should only
            compare between offline activities, not verses online.
          </p>
        </>
      ),
    },
    {
      heading: '10. What are short links?',
      content: (
        <>
          <p>
            Research shows users can be put off clicking on long URLs with
            tracking IDs. Short links allow you to create beautiful and
            memorable links which builds trust with your users and increases
            clickthrough rates.
          </p>
          <p>They are made up of three parts:</p>
          <OrderedList>
            <li>
              Domain: <strong>upl.</strong>inc/offer
            </li>
            <li>
              TLD: upl.<strong>inc</strong>/offer
            </li>
            <li>
              URL slug: upl.inc<strong>/offer</strong>
            </li>
          </OrderedList>
          <p>
            When users click on the short link, they are redirected to a
            specific landing page, often with campaign codes attached.{' '}
            {brandName} can count how many clicks were redirected, giving us an
            additional metric to compare.
          </p>

          <h4>When should you use short links?</h4>
          <p>
            We recommend always using short links for any digital drive-to-web
            clickthrough activity. By creating a short link for every link in{' '}
            {brandName}, you can compare short link clickthrough's in{' '}
            <BoxedText>
              <Link href="/track/view-links">Track &gt; View links</Link>
            </BoxedText>
            and{' '}
            <BoxedText>
              <Link href="/report/performance">Report &gt; Performance</Link>
            </BoxedText>
            .
          </p>

          <h4>How to use short links</h4>
          <p>
            Whenever you create a new link on the{' '}
            <BoxedText>
              <Link href="/track/create-links">Track &gt; Create links</Link>
            </BoxedText>{' '}
            page, your link will be given an associated short link. You can
            change the URL slug to be more memorable and fit with our campaign.
          </p>
          <p>
            When someone clicks through on your short link, they'll be
            redirected to your landing page with all of the parameters you set
            applied.
          </p>
          <h4>Branded short links</h4>
          <p>
            Companies can buy their own branded domains to make even more
            trustworthy links:
          </p>
          <NoteText label="Examples:">
            <>
              <span style={{ display: 'block' }}>
                Main website:{' '}
                <strong style={{ display: 'inline-block' }}>
                  americanexpress.com
                </strong>{' '}
                short domain:{' '}
                <strong style={{ display: 'inline-block' }}>amex.co</strong>
              </span>
              <span style={{ display: 'block' }}>
                Main website:{' '}
                <strong style={{ display: 'inline-block' }}>virgin.com</strong>{' '}
                short domain:{' '}
                <strong style={{ display: 'inline-block' }}>virg.in</strong>
              </span>
              <span style={{ display: 'block' }}>
                Main website:{' '}
                <strong style={{ display: 'inline-block' }}>spotify.com</strong>{' '}
                short domain:{' '}
                <strong style={{ display: 'inline-block' }}>spoti.fi</strong>
              </span>
            </>
          </NoteText>
          <p>
            You can create short links using our upl.inc domain or purchase an
            Add-on to have branded custom domains. To do so, select 'Branded
            short link' on the{' '}
            <BoxedText>
              <Link href="/track/create-links">Track &gt; Create links</Link>
            </BoxedText>{' '}
            page and tell us your preferred domain in the modal.
          </p>
          <img src={BrandedLink} alt="branded short link" />
        </>
      ),
    },
    {
      heading: '11. What are QR codes and when should I use them?',
      content: (
        <>
          <p>
            QR codes provide a useful way of connecting on and offline marketing
            efforts, giving your audience instant access to more info about your
            products and services. They help increase user engagement and make
            call-to-actions more effective.
          </p>

          <h4>What are QR codes?</h4>
          <p>
            A QR code (quick response code) is a type of matrix barcode invented
            in 1994 by the Japanese automotive company Denso Wave. A barcode is
            a machine-readable optical label that can contain information about
            the item to which it is attached. In marketing, QR codes often
            contain data that points to a website or application. A customer can
            scan the QR code using a smart phone camera to open a specific
            webpage or app.
          </p>
          <p>
            When used in conjunction with Short links and UTMs, you can track
            how many people scanned each QR code to compare offline marketing
            activity and track follow up actions online.
          </p>

          <h4>When to use a QR code</h4>
          <p>
            They can be used in any setting where users have the ability (and
            incentive) to scan the QR image to access a webpage. They are best
            used to bridge offline activity with online action.
          </p>
          <p>They are best used on:</p>
          <ul className={styles.unorderedList}>
            <TickListItem>
              Print: To give users an offer or further information.
            </TickListItem>
            <TickListItem>
              Billboards adverts (print or digital): To give a passing users the
              ability to discover more when you have limited space.
            </TickListItem>
            <TickListItem>
              Events: Displayed on entry they can give participants access to
              further information without the environmental cost of printing.
            </TickListItem>
          </ul>

          <h4>How to create QR codes</h4>
          <p>
            You can customise your own QR code with your brand logo, colour and
            tagged UTM codes. You can also choose the file type and image size.
          </p>
          <img src={QRCode} alt="qr code" style={{ maxWidth: 400 }} />
          <br />
          <p>
            You can create a QR code in{' '}
            <BoxedText>
              <Link href="/track/create-links">Track &gt; Create links</Link>
            </BoxedText>{' '}
            by:
          </p>
          <OrderedList>
            <li>Enter the required campaign link parameters</li>
            <li>Click ‘Create campaign link'</li>
          </OrderedList>
          <p>
            A QR code will appear below, hover over it and click on customise to
            make changes or download.
          </p>
          <p>
            You can also recreate any QR code in 'Your recently created links'
            table by hovering over any link and clicking on the QR code button.
          </p>
          <p>
            Or in{' '}
            <BoxedText>
              <Link href="/track/view-links">Track &gt; View links</Link>
            </BoxedText>
            :
          </p>
          <OrderedList>
            <li>Tick the checkbox of a previously created link</li>
            <li>
              Click on the{' '}
              <Button className={styles.mockButton} demoOnly>
                Actions
              </Button>{' '}
              button and then click 'Download QR code'
            </li>
          </OrderedList>
          <NoteText label="Important:">
            Always test your QR code before it goes to print. The technology is
            99.9% accurate but long names or mistakes can prove costly. So
            always check it works.
          </NoteText>
        </>
      ),
    },
    {
      heading: 'Get a Campaign Tracking Expert LinkedIn certificate 🏅',
    },
  ],
  google: [
    {
      heading: '1. Why we track marketing and campaigns',
      content: (
        <>
          <p>
            Marketing is big business, with 5-10% of a typical company's budgets
            spent on marketing, representing a total of $4.7 trillion dollars a
            year. Tracking marketing activity and campaigns help us compare
            campaigns, understand performance, and learn what marketing
            strategies work and what doesn't.
          </p>
          <p>
            By continuously learning from campaign data, we can optimise our
            marketing spend and get a larger Return on Investment (ROI) from
            marketing.
          </p>
          <p>
            Campaigns used to be tracked with unreliable surveys. Since the
            growth of digital advertising, we can see much deeper into users
            journeys after they click on an ad and come to our digital
            properties (website and apps).
          </p>
        </>
      ),
    },
    {
      heading: '2. Why should you use campaign links?',
      content: (
        <>
          <p>
            To understand which marketing channels, campaigns, ad creatives and
            formats perform the best after they click on your ad and visit your
            website / app.
          </p>
          <p>
            This allows you to test different campaign approaches and then
            optimise your marketing budget on the channels and campaigns which
            achieve your goals.
          </p>
          <h4>Campaign links help answer:</h4>
          <ul className={styles.unorderedList}>
            <TickListItem>
              Which campaign should we spend more / less money on?
            </TickListItem>
            <TickListItem>
              Which is the best channel to get additional engaged traffic to our
              website?
            </TickListItem>
            <TickListItem>
              Which ad formats give us the most engagement and onsite sales?
            </TickListItem>
            <TickListItem>
              Which email offer generated the most profit on our website?
            </TickListItem>
            <TickListItem>
              Which search engine keyword groups should we spend more money on?
            </TickListItem>
            <TickListItem>
              What's the demographics of our campaign visitors?
            </TickListItem>
            <TickListItem>
              What content or products are visitors from campaigns interested
              in?
            </TickListItem>
            <TickListItem>
              How did our campaign visitors behave and navigate on-site?
            </TickListItem>
            <TickListItem>
              How much website activity did our offline media campaigns
              generate?
            </TickListItem>
          </ul>
        </>
      ),
    },
    {
      heading: '3. What is a campaign link and what does it look like?',
      content: (
        <>
          <p>
            A link to a website or app, which starts with the website landing
            page and ends with a snippet of tracking code.
          </p>
          <p>
            This code tells marketing analytics platforms (like Google and Adobe
            Analytics) how and where the user came from. Helping you understand
            what marketing works and what doesn't.{' '}
          </p>
          <p>
            This code is also known as a query string parameter, marketing code,
            tracking code or in Google Analytics a UTM code.
          </p>
          <p>
            It is best practice to create a unique query string parameter for
            every marketing URL which lands on your website.
          </p>
          <p>
            You can identify the query string parameter as the part after the{' '}
            <BoxedText>?</BoxedText> in the URL. Hover over the link below to
            learn campaign link anatomy:
          </p>
          <div className={styles.example}>
            <Tooltip
              id="landing-page-url-tooltip"
              className={classNames(styles.urlExample, styles.auditColor)}
              tooltipMessage="The landing page URL of your campaign"
            >
              http://www.website.com/
            </Tooltip>
            <Tooltip
              id="prefix-tooltip"
              className={styles.urlExample}
              tooltipMessage="Indicates all characters after the question mark are query string parameters and not part of the URL"
            >
              ?
            </Tooltip>
            <Tooltip
              id="first-parameter-name-tooltip"
              className={classNames(styles.urlExample, styles.trackColor)}
              tooltipMessage="The first parameter name"
            >
              utm_source
            </Tooltip>
            <Tooltip
              id="value-separator-tooltip"
              className={styles.urlExample}
              tooltipMessage="Separates the first parameter name from the first parameter value"
            >
              =
            </Tooltip>
            <Tooltip
              id="value-tooltip"
              className={classNames(styles.urlExample, styles.explainColor)}
              tooltipMessage="The first parameter value, which can be read as this within your analytics platform"
            >
              facebook
            </Tooltip>
            <Tooltip
              id="separator-tooltip"
              className={styles.urlExample}
              tooltipMessage="Indicates an additional query string parameter
                 "
            >
              &amp;
            </Tooltip>
            <Tooltip
              id="second-parameter-name-tooltip"
              className={classNames(styles.urlExample, styles.trackColor)}
              tooltipMessage="The second parameter name"
            >
              utm_medium
            </Tooltip>
            <Tooltip
              id="second-value-separator-tooltip"
              className={styles.urlExample}
              tooltipMessage="Separates the second parameter name from the second parameter value"
            >
              =
            </Tooltip>
            <Tooltip
              id="second-value-tooltip"
              className={classNames(styles.urlExample, styles.explainColor)}
              tooltipMessage="The second parameter value, which can be read as this within your analytics platform"
            >
              social
            </Tooltip>
            <Tooltip
              id="second-separator-tooltip"
              className={styles.urlExample}
              tooltipMessage="Indicates an additional query string parameter
                 "
            >
              &amp;
            </Tooltip>
            <Tooltip
              id="third-parameter-name-tooltip"
              className={classNames(styles.urlExample, styles.trackColor)}
              tooltipMessage="The third parameter name"
            >
              utm_campaign
            </Tooltip>
            <Tooltip
              id="third-value-separator-tooltip"
              className={styles.urlExample}
              tooltipMessage="Separates the third parameter name from the third parameter value"
            >
              =
            </Tooltip>
            <Tooltip
              id="third-value-tooltip"
              className={classNames(styles.urlExample, styles.explainColor)}
              tooltipMessage="The third parameter value, which can be read as this within your analytics platform"
            >
              new
            </Tooltip>
          </div>
          <p>
            In this example, there are two query string parameters. Custom
            parameters can be added by including extra{' '}
            <BoxedText>&amp;</BoxedText> at the end of any parameter value. For
            example:
            <BoxedText>&amp;product=boots</BoxedText>
          </p>
          <p>
            The diagram below shows how you would have different links for each
            source / medium going to the same landing page.
          </p>
          <img
            style={{ margin: 'auto', maxWidth: '65%' }}
            src={ImgOne}
            alt="campaign diagram"
          />
        </>
      ),
    },
    {
      heading: '4. What are the different campaign parameters?',
      content: (
        <>
          <p>
            Campaign links often send multiple parameters, so you can filter and
            compare your campaign activity by multiple dimensions in your
            analytics tool. Common parameters that Google Analytics recommends
            using:
          </p>
          <Table>
            <thead>
              <tr>
                <th>UTM parameter</th>
                <th>Definition</th>
                <th>Example</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>utm_source</td>
                <td>Where the user came from</td>
                <td>google, facebook, newsletter, tradeshow</td>
              </tr>
              <tr>
                <td>utm_medium</td>
                <td>How the user got to your website</td>
                <td>paid search, display, email, cpc, poster</td>
              </tr>
              <tr>
                <td>utm_campaign</td>
                <td>
                  Identifies a specific product promotion or strategic campaign
                </td>
                <td>spring_sale, blackfriday, summersandals</td>
              </tr>
              <tr>
                <td>utm_term (optional)</td>
                <td>
                  Used for paid search. Use utm_term to note the keywords for
                  this ad
                </td>
                <td>running+shoes, brandterms</td>
              </tr>
              <tr>
                <td>utm_content (optional)</td>
                <td>
                  Used for A/B testing and content-targeted ads. Use utm_content
                  to differentiate ads or links that point to the same URL
                </td>
                <td>logolink, textlink, imagelink</td>
              </tr>
            </tbody>
          </Table>
          <p>
            By default every link contains the parameter
            <BoxedText>&up_id=(unique_id)</BoxedText>. We use up_id to stitch
            data together more accurately and let users bulk edit existing links
            more easily.{' '}
            <Link
              type="arrowForward"
              href="https://support.uplifter.ai/hc/en-us/articles/16258919414685-What-are-smart-links-and-up-id"
            >
              Learn more about Smart Links
            </Link>
          </p>
          <p>
            Admins can add custom parameters and edit parameters in{' '}
            <BoxedText>
              <Link href="/track/edit-parameters-and-rules">
                Track &gt; Edit
              </Link>
            </BoxedText>
            . You can learn how to customise your{' '}
            <BoxedText>
              <Link href="/track/create-links">Track &gt; Create links</Link>
            </BoxedText>
            page in <Link href="?lesson=7">lesson 7</Link>.
          </p>
          <NoteText label="Good to know:">
            Google Analytics will automaticly categorise your website traffic
            into a parameter called 'Default Channel Grouping' (Direct, Organic
            Search, Paid Search, Referral & Social). This is guessed by looking
            at the referring URL to your website. It often categorises
            incorrectly, allocating email to direct, and lacks the detail UTMs
            can provide. You can change the default channel grouping settings in
            Google Analytics to be more aligned to your organisation or use
            source/medium dimension instead for more accurate reporting.
          </NoteText>
        </>
      ),
    },
    {
      heading: '5. How to create a campaign link',
      content: (
        <>
          <p>
            Our{' '}
            <BoxedText>
              <Link href="/track/create-links">Track &gt; Create links</Link>
            </BoxedText>{' '}
            page enables you to create campaigns codes quickly and reliably for
            most Google Analytics users.
          </p>
          <p>
            Admin users can learn how to customise it by adding more parameters
            and changing input types in <Link href="?lesson=7">lesson 7</Link>.
          </p>
          <h4>How to create campaign links:</h4>

          <OrderedList>
            <li>
              In the left-hand navigation bar, click{' '}
              <BoxedText>
                <Link href="/track/create-links">Track &gt; Create links</Link>
              </BoxedText>
            </li>
            <li>
              In the landing page box, type/paste the URL where you want users
              to land.
            </li>
            <li>
              Select an option for each of the dropdown parameters. (Source,
              Medium). If your dropdown choice is not visible, you can request a
              new one and admins can approve and add it in{' '}
              <BoxedText>
                <Link href="/track/edit-dropdowns">
                  Track &gt; Edit dropdowns
                </Link>
              </BoxedText>
              .
            </li>
            <li>
              In the free text boxes, type readable text into (Campaign,
              Content, Term). We recommend lowercase only, replacing spaces with{' '}
              <BoxedText>_</BoxedText>
            </li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Create campaign link
              </Button>{' '}
              button to create your campaign link.
            </li>
          </OrderedList>
          <p>
            If all required fields are entered correctly - a shake animation
            will show your new campaign link created in the 'Recently created
            campaign links' box.
          </p>
          <p>
            We recommend always using short links for campaign links, we explain
            why in <Link href="?lesson=9">lesson 9</Link>.
          </p>
          <p>
            A QR code will automatically be created for print and offline ads.
            This can be customised and downloaded.
          </p>
          <h4>With your created links you can:</h4>
          <ul className={styles.unorderedList}>
            <TickListItem>
              Copy the campaign link directly into marketing platforms.
            </TickListItem>
            <TickListItem>
              Click{' '}
              <Button
                variant="secondary"
                className={styles.mockButton}
                demoOnly
              >
                Download
              </Button>{' '}
              to download all recently created links to csv.
            </TickListItem>
            <TickListItem>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Share
              </Button>{' '}
              to email recently created links to a colleague or agency.
            </TickListItem>
            <TickListItem>
              From{' '}
              <BoxedText>
                <Link href="/track/view-links">Track &gt; View links</Link>
              </BoxedText>{' '}
              page, let other users view, download, and share links.
            </TickListItem>
          </ul>
          <p>
            You can review, share, delete and edit your own campaign links from
            the{' '}
            <BoxedText>
              <Link href="/track/view-links">Track &gt; View links</Link>
            </BoxedText>{' '}
            page. Only admins can delete and edit other users' codes.
          </p>
        </>
      ),
    },
    {
      heading: '6. Campaign link best practice',
      content: (
        <>
          <p>
            Without following best practice, tracking could be lost or worse the
            landing page might break, wasting your marketing budget. Best
            practice will ensure:
          </p>
          <ul className={styles.unorderedList}>
            <TickListItem>
              <strong>Good governance.</strong> Everyone knows who owns the
              process, who to ask for help and how it's enforced.
            </TickListItem>
            <TickListItem>
              <strong>Complete and accurate data.</strong> With no channels or
              campaigns missing or different campaign data blended.
            </TickListItem>
            <TickListItem>
              <strong>Easy to understand data.</strong> To stop users
              misinterpreting the data, making false insights or sub-optimal
              decisions.
            </TickListItem>
          </ul>
          <h4>Best practice:</h4>
          <OrderedList>
            <li>
              <strong>Create an internal designated owner(s).</strong> Designate
              someone to be accountable for making sure all campaigns are
              tracked correctly. Users and external media agencies often forget
              to apply codes, affecting all your analytics data and
              decision-making ability.
            </li>
            <li>
              <strong>Don't use a spreadsheet.</strong> Spreadsheets are easily
              corrupted and can be shared without good governance. It can also
              be hard to enforce campaign link naming conventions and rules
              around uniformity, hierarchy, spelling, and capitalisation. They
              do not provide a record of all campaigns or a user history for
              accountability.
            </li>
            <li>
              <strong>Never retype a URL with a campaign link.</strong> Always
              copy and paste the campaign link from {brandName} or the CSV
              download directly. This ensures the URL is correct and stops human
              error.
            </li>
            <li>
              <strong>Name campaigns clearly.</strong> Your campaign names
              should be simple and use recognisable terms. This enables users to
              understand what data they are looking at without having to use a
              lookup table to decipher meaning.
              <div>
                <Row>
                  <Slot>
                    <img src={GoodCampaignNames} alt="Good campaign names" />
                  </Slot>
                  <Slot>
                    <img src={BadCampaignNames} alt="Bad campaign names" />
                  </Slot>
                </Row>
              </div>
            </li>
            <li>
              <strong>No long parameter names.</strong> Keep any names under 40
              characters, total query string parameters under 255 and your whole
              URL under 1024 characters.
            </li>
            <li>
              <strong>
                Use special characters <BoxedText>?</BoxedText>
                <BoxedText>&amp;</BoxedText>
                <BoxedText>=</BoxedText>
                appropriately.
              </strong>{' '}
              Otherwise, the link will break, follow these rules:
              <ul className={styles.unorderedList}>
                <TickListItem>
                  Only one question mark <BoxedText>?</BoxedText> at the start
                  of the query string parameter.
                </TickListItem>
                <TickListItem>
                  Ampersands <BoxedText>&amp;</BoxedText> should only be used as
                  connectors between parameters.
                </TickListItem>
                <TickListItem>
                  Equal <BoxedText>=</BoxedText> symbols should only be used
                  between a parameter name and value.
                </TickListItem>
              </ul>
            </li>
            <li>
              <strong>
                Spaces should be replaced by
                <BoxedText>_</BoxedText> or <BoxedText>-</BoxedText> or{' '}
                <BoxedText>|</BoxedText>.
              </strong>{' '}
              Otherwise, spaces can break the link or be replaced by a messy{' '}
              <BoxedText>%20</BoxedText>.
            </li>
            <li>
              <strong>Always use lower case letters in the link. </strong> This
              will make your reports easier to read.
            </li>
            <li>
              <strong>
                Not every campaign needs to use all available parameters.
              </strong>{' '}
              Only the necessary fields need to be completed. There is no need
              to label the term or content fields as 'none' if they are not
              actively being used.
            </li>
            <li>
              <strong>
                Don't use inappropriate or discriminatory language.
              </strong>{' '}
              It can be possible for your end users to see campaign links and be
              offended.
            </li>
            <li>
              <strong>Don't use the same code for different campaigns. </strong>{' '}
              Using the same code will combine all the traffic, conversions and
              behaviour into one pot you cannot separate.
            </li>
            <li>
              <strong>Be careful if your landing page has redirects</strong> If
              your landing page has a redirect, you need to implement campaign
              links on both the original landing page and the redirect page.
              Otherwise, the original landing page could be picked up as the
              referrer of traffic.
            </li>
            <li>
              <strong>
                Don't use campaign links on internal website links.
              </strong>{' '}
              If you do, your campaign data will be overwritten, and you will
              lose the original tracking source. You can use internal promotion
              code parameters for tracking internal links.
            </li>
          </OrderedList>
        </>
      ),
    },
    {
      heading: '7. How to set up your unique campaign link creator',
      content: (
        <>
          <p>
            Our campaign link generator is fully flexible and should work for
            any organisation regardless of parameters, code structure or
            analytics platform. We acknowledge setting up unique code structures
            can be tricky, so contact{' '}
            <Link href={`mailo:${supportEmail}`}>{supportEmail}</Link> for
            additional help.
          </p>
          <NoteText>
            Only admins have permissions to edit the campaign link structure.
          </NoteText>
          <p>
            Admin users will need to add new parameter values (Sources, Medium
            and Campaigns) to the parameter dropdowns campaign link generator
            over time.
          </p>

          <h4>To add new parameter value to a dropdown list</h4>
          <OrderedList>
            <li>
              Click on{' '}
              <BoxedText>
                <Link href="/track/edit-dropdowns">
                  Track &gt; Edit dropdowns
                </Link>
              </BoxedText>{' '}
              in the left-hand navigation bar.
            </li>
            <li>
              Click on a parameter to see the current dropdown names and codes.
              The name is what your users will see in the dropdown box, the
              'code' is what will be present in the URL. This allows you to use
              shorter codes.
            </li>
            <li>
              Click on{' '}
              <Button className={styles.mockButton} demoOnly>
                Add new dropdown
              </Button>{' '}
              button next to dropdown name.
            </li>
            <li>In the blank boxes, type your name and code.</li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Confirm
              </Button>{' '}
              button to the right, underneath actions.
            </li>
          </OrderedList>

          <h4>To remove a parameter value from a dropdown list</h4>
          <OrderedList>
            <li>
              Click on{' '}
              <BoxedText>
                <Link href="/track/edit-dropdowns">
                  Track &gt; Edit dropdowns
                </Link>
              </BoxedText>{' '}
              in the left-hand navigation bar.
            </li>
            <li>
              Click on a parameter to see the current dropdown names and codes.
            </li>
            <li>
              Click on the trashcan to the right of the dropdown name and code.
            </li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Yes
              </Button>{' '}
              to confirm.
            </li>
          </OrderedList>
          <h4>Edit parameters and rules</h4>
          <p>
            In the{' '}
            <BoxedText>
              <Link href="/track/edit-parameters-and-rules">
                Track &gt; Edit
              </Link>
            </BoxedText>{' '}
            parameters page, admins can edit options which effect every single
            link created.
          </p>
          <p>
            Prefix is the first code that will be shown after the URL and in
            most cases is a <BoxedText>?</BoxedText>.
          </p>
          <p>
            Separator is a common value separates each parameter, this is
            usually <BoxedText>&amp;</BoxedText> but may be blank if you are
            adding together multiple dropdowns into one parameter.
          </p>
          <p>
            <BoxedText>
              <Link href="/track/edit-parameters-and-rules">
                Track &gt; Edit
              </Link>
            </BoxedText>{' '}
            parameters page is where you add, edit, reorder, or delete your
            query string parameters.
          </p>
          <p>
            Your organisation may have additional or different parameters (other
            than source, medium, campaign, term and content) you wish to add.
          </p>

          <h4>To add a new parameter</h4>
          <OrderedList>
            <li>
              Click on{' '}
              <BoxedText>
                <Link href="/track/edit-parameters-and-rules">
                  Track &gt; Edit parameters
                </Link>
              </BoxedText>{' '}
              in the left-hand navigation bar.
            </li>
            <li>
              Next to parameter, click on the{' '}
              <Button
                variant="secondary"
                className={styles.mockButton}
                demoOnly
              >
                Add parameter
              </Button>{' '}
              button.
            </li>
            <li>Type the parameter name as it will appear in this platform.</li>
            <li>
              Enter a description which will explain what this parameter is to
              your users.
            </li>
            <li>
              Type prefix which is the parameter name as you want it to appear
              in your campaign link URL.
            </li>
            <li>
              Choose if you want users to have to select a dropdown input
              managed by admin (good for governance) or allow them to write free
              text (good for self-serve).
            </li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Add parameter
              </Button>{' '}
              to add it to your campaign link generator. It will always appear
              at the bottom in <BoxedText>Edit campaign links</BoxedText> but
              can be dragged into the position you wish it to be shown in your
              URL.
            </li>
          </OrderedList>

          <p>
            Your organisation may need to delete parameters which are not
            useful. This will remove all your codes for this parameter
            permanently, contact us if you remove a parameter by mistake.
          </p>
          <h4>To delete a query parameter</h4>
          <OrderedList>
            <li>
              Click on{' '}
              <BoxedText>
                <Link href="/track/edit-parameters-and-rules">
                  Track &gt; Edit
                </Link>
              </BoxedText>{' '}
              in the left-hand navigation bar.
            </li>
            <li>
              Next to parameter options, click on the More expansion arrow.
            </li>
            <li>
              Click on the trashcan button next to the parameter you wish to
              delete.
            </li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Yes
              </Button>{' '}
              to confirm.
            </li>
          </OrderedList>
          <h4>Advanced rules</h4>
          <p>
            These rules to make sure your campaign links are readable,
            consistent, and functional. By default, best practice data
            validation is enforced. To turn off a rule, just uncheck the tick
            box next to it.
          </p>
        </>
      ),
    },
    {
      heading: '8. How to import historical links from a CSV',
      content: (
        <>
          <p>
            You may want to import campaign links from other platforms or old
            spreadsheets into {brandName}{' '}
            <BoxedText>
              <Link href="/track/view-links">Track &gt; View links</Link>
            </BoxedText>{' '}
            table. This allows you to review all campaign links and their
            performance in one place.
          </p>
          <NoteText label="Important:">
            <>
              You need to setup your campaign link creator to have the latest
              parameters before uploading your old campaign codes. We recommend
              making your old parameters fit into your latest {brandName}{' '}
              parameters rather than vice versa.
            </>
          </NoteText>
          <p>
            To import codes into{' '}
            <BoxedText>
              <Link href="/track/view-links">Track &gt; View links</Link>
            </BoxedText>{' '}
            table:
          </p>

          <OrderedList>
            <li>
              In the left-hand navigation bar click on{' '}
              <BoxedText>
                <Link href="/track/view-links">Track &gt; View links</Link>
              </BoxedText>
            </li>
            <li>
              In the{' '}
              <ButtonDropdown
                containerClassName={styles.mockButtonDropdown}
                buttonText="Actions"
                demoOnly
              >
                <></>
              </ButtonDropdown>{' '}
              dropdown select 'Import historical links'.
            </li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Download CSV template
              </Button>
            </li>
            <li>
              Copy and paste your landing pages with campaign links and
              parameters into each column in the CSV and save it.
            </li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Upload file
              </Button>{' '}
              to upload the CSV.
            </li>
          </OrderedList>
        </>
      ),
    },
    {
      heading: '9. Using campaign links for offline media channels',
      content: (
        <>
          <p>
            Offline marketing campaigns do not have to be invisible to your
            analytics platform. They can be tracked using QR codess and short
            links. This allows you to compare between offline marketing efforts,
            to see which ones bring the most engaged traffic to your website.
          </p>

          <h4>QR codes</h4>
          <p>
            QR codes scanned by mobile phones on physical printouts, ads or
            digital screens redirect to a unique landing page with your campaign
            parameters.
          </p>
          <NoteText label="Example:">
            <>
              <p>
                A printed flyer handed out in New York could contain a QR code
                which once scanned takes the user to:
                <strong>
                  https://centralperk.com/coffee?utm_source=new_york&amp;utm_medium=flyer
                </strong>
              </p>
              <p>
                Another printed flyer handed out in London could have a
                different QR code which once scanned takes the user to:
                <strong>
                  https://centralperk.com/coffee?utm_source=london&amp;utm_medium=flyer
                </strong>
              </p>
            </>
          </NoteText>
          <p>
            This way you can compare the two locations to see which had the best
            performance. You could also hand out flyers with different
            promotions and different QR codes to test which ones have the
            greatest impact.
          </p>
          <h4>Short links/Vanity URLs</h4>
          <p>
            A short link or vanity URL is a user-friendly, memorable version of
            a webpage address or link, which redirects the user to a longer web
            address, complete with campaign links to track their activity. This
            means it can be used on a billboard or on a flyer.
          </p>
          <NoteText label="Example:">
            <>
              Instead of sending someone to
              <strong>
                https://centralperk/coffee?utm_source=flyer&amp;utm_medium=offline&amp;utm_campaign=free_coffee
              </strong>
              to receive a coffee voucher, you can send them to
              <strong>https://cperk.store/free</strong> which will then redirect
              them to
              <strong>
                https://centralperk/coffee?utm_source=flyer&amp;utm_medium=offline&amp;utm_campaign=free_coffee
              </strong>
            </>
          </NoteText>
          <p>
            Short links can be put on marketing materials as they take less
            space. It's worth noticing that some users may still just search for
            your product using a search engine and therefore you should only
            compare between offline activities, not verses online.
          </p>
        </>
      ),
    },
    {
      heading: '10. What are short links?',
      content: (
        <>
          <p>
            Research shows users can be put off clicking on long URLs with
            tracking IDs. Short links allow you to create beautiful and
            memorable links which builds trust with your users and increases
            clickthrough rates.
          </p>
          <p>They are made up of three parts:</p>
          <OrderedList>
            <li>
              Domain: <strong>upl.</strong>inc/offer
            </li>
            <li>
              TLD: upl.<strong>inc</strong>/offer
            </li>
            <li>
              URL slug: upl.inc<strong>/offer</strong>
            </li>
          </OrderedList>
          <p>
            When users click on the short link, they are redirected to a
            specific landing page, often with campaign codes attached.{' '}
            {brandName} can count how many clicks were redirected, giving us an
            additional metric to compare.
          </p>

          <h4>When should you use short links?</h4>
          <p>
            We recommend always using short links for any digital drive-to-web
            clickthrough activity. By creating a short link for every link in
            {brandName}, you can compare short link clickthrough's in{' '}
            <BoxedText>
              <Link href="/track/view-links">Track &gt; View links</Link>
            </BoxedText>{' '}
            and{' '}
            <BoxedText>
              <Link href="/report/performance">Report &gt; Performance</Link>
            </BoxedText>
            .
          </p>

          <h4>How to use short links</h4>
          <p>
            Whenever you create a new link on the{' '}
            <BoxedText>
              <Link href="/track/create-links">Track &gt; Create links</Link>
            </BoxedText>{' '}
            page, your link will be given an associated short link. You can
            change the URL slug to be more memorable and fit with our campaign.
          </p>
          <p>
            When someone clicks through on your short link, they'll be
            redirected to your landing page with all of the parameters you set
            applied.
          </p>
          <h4>Branded short links</h4>
          <p>
            Companies can buy their own branded domains to make even more
            trustworthy links:
          </p>
          <NoteText label="Examples:">
            <>
              <span style={{ display: 'block' }}>
                Main website:{' '}
                <strong style={{ display: 'inline-block' }}>
                  americanexpress.com
                </strong>{' '}
                Short domain:{' '}
                <strong style={{ display: 'inline-block' }}>amex.co</strong>
              </span>
              <span style={{ display: 'block' }}>
                Main website:{' '}
                <strong style={{ display: 'inline-block' }}>virgin.com</strong>{' '}
                Short domain:{' '}
                <strong style={{ display: 'inline-block' }}>virg.in</strong>
              </span>
              <span style={{ display: 'block' }}>
                Main website:{' '}
                <strong style={{ display: 'inline-block' }}>spotify.com</strong>{' '}
                Short domain:{' '}
                <strong style={{ display: 'inline-block' }}>spoti.fi</strong>
              </span>
            </>
          </NoteText>
          <p>
            You can create short links using our upl.inc domain or purchase an
            Add-on to have branded custom domains. To do so, select 'Branded
            short link' on the{' '}
            <BoxedText>
              <Link href="/track/create-links">Track &gt; Create links</Link>
            </BoxedText>{' '}
            page and tell us your preferred domain in the modal.
          </p>
          <img src={BrandedLink} alt="branded short link" />
        </>
      ),
    },
    {
      heading: '11. What are QR codes and when should I use them?',
      content: (
        <>
          <p>
            QR codes provide a useful way of connecting on and offline marketing
            efforts, giving your audience instant access to more info about your
            products and services. They help increase user engagement and make
            call-to-actions more effective.
          </p>

          <h4>What are QR codes?</h4>
          <p>
            A QR code (quick response code) is a type of matrix barcode invented
            in 1994 by the Japanese automotive company Denso Wave. A barcode is
            a machine-readable optical label that can contain information about
            the item to which it is attached. In marketing, QR codes often
            contain data that points to a website or application. A customer can
            scan the QR code using a smart phone camera to open a specific
            webpage or app.
          </p>
          <p>
            When used in conjunction with Short links and UTMs, you can track
            how many people scanned each QR code to compare offline marketing
            activity and track follow up actions online.
          </p>

          <h4>When to use a QR code</h4>
          <p>
            They can be used in any setting where users have the ability (and
            incentive) to scan the QR image to access a webpage. They are best
            used to bridge offline activity with online action.
          </p>
          <p>They are best used on:</p>
          <ul className={styles.unorderedList}>
            <TickListItem>
              Print: To give users an offer or further information.
            </TickListItem>
            <TickListItem>
              Billboards adverts (print or digital): To give a passing users the
              ability to discover more when you have limited space.
            </TickListItem>
            <TickListItem>
              Events: Displayed on entry they can give participants access to
              further information without the environmental cost of printing.
            </TickListItem>
          </ul>

          <h4>How to create QR codes</h4>
          <p>
            You can customise your own QR code with your brand logo, colour and
            tagged UTM codes. You can also choose the file type and image size.
          </p>
          <img src={QRCode} alt="qr code" style={{ maxWidth: 400 }} />
          <br />
          <p>
            You can create a QR code in{' '}
            <BoxedText>
              <Link href="/track/create-links">Track &gt; Create links</Link>
            </BoxedText>{' '}
            by:
          </p>
          <OrderedList>
            <li>Enter the required campaign link parameters</li>
            <li>
              Click{' '}
              <Button className={styles.mockButton} demoOnly>
                Create campaign link
              </Button>
            </li>
          </OrderedList>
          <p>
            A QR code will appear below, hover over it and click on customise to
            make changes or download.
          </p>
          <p>
            You can also recreate any QR code in 'Your recently created links'
            table by hovering over any link and clicking on the QR code button.
          </p>
          <p>
            Or in{' '}
            <BoxedText>
              <Link href="/track/view-links">Track &gt; View links</Link>
            </BoxedText>
            :
          </p>
          <OrderedList>
            <li>Tick the checkbox of a previously created link</li>
            <li>
              Click on the{' '}
              <Button className={styles.mockButton} demoOnly>
                Actions
              </Button>{' '}
              button and then click 'Download QR code'
            </li>
          </OrderedList>
          <NoteText label="Important:">
            Always test your QR code before it goes to print. The technology is
            99.9% accurate but long names or mistakes can prove costly. So
            always check it works.
          </NoteText>
        </>
      ),
    },
    {
      heading: 'Get a Campaign Tracking Expert LinkedIn certificate 🏅',
    },
  ],
}

export default trackLearnContent
