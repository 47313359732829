import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'

import { currentUserDetails } from './variables'
import { GRAPHQL_API_BASE_DOMAIN, TOKEN } from '../constants'
import { getLocalItem } from '../../helpers/local-client'

export const httpLink = createHttpLink({
  uri: `${GRAPHQL_API_BASE_DOMAIN}graphql`,
})

export const authLink = setContext((unused, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getLocalItem(TOKEN)

  const context = {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }

  // Apply staging override
  if (
    process.env.REACT_APP_STAGING_OVERRIDE === 'true' &&
    process.env.REACT_APP_NODE_ENV !== 'production'
  ) {
    context.headers['override-staging-database'] = 'true'
  }

  // return the headers to the context so httpLink can read them
  return context
})

// List of GQL operations that should be applied under the below retry policy
const operationsToRetry = ['GetUplifterIDCurrentTotal']

export const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      const { operationName } = _operation

      return !!error && operationsToRetry.indexOf(operationName) > -1
    },
  },
})

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      const { message, locations, path } = error

      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
    })

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

export const cache = new InMemoryCache({
  typePolicies: {
    CurrentUser: {
      keyFields: ['userID'],
    },
    Account: {
      fields: {
        userAccountProfiles: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
      keyFields: ['accountID'],
    },
    Company: {
      keyFields: ['companyID'],
    },
    TrackQueries: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
      },
      keyFields: ['accountID'],
    },
    DeepLinkQueries: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
      },
      keyFields: ['accountID'],
    },
    DeepLinkAppGroup: {
      keyFields: ['appGroupID'],
    },
    CampaignCodeGenerator: {
      fields: {
        accountID: {
          read(x, { variables }) {
            // If query is requesting by accountID
            // Instead of current account
            if (
              variables &&
              Object.prototype.hasOwnProperty.call(variables, 'accountID')
            ) {
              return variables.accountID
            }

            return currentUserDetails().workspaceID
          },
        },
        paramDefs: {
          merge(existing: any[] = [], incoming: any[], { variables }) {
            return incoming
          },
        },
      },
      keyFields: ['accountID'],
    },
    // TODO: Update caching so this doesn't have to be nested in CampaignCodeGenerator
    // ParamDef: {
    //   keyFields: ['fieldID'],
    // },
    CampaignLinkDashboard: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
      },
      keyFields: ['accountID'],
    },
    MinimalCodeList: {
      // Default policy for fields here is to override them
      // This is to ensure data is fetched from network every time
      // Exception is on the addCodes mutation, where we want to add to the cache
      // All fields below are configured to do this.
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
        linkID: {
          read(x, { variables }) {
            // If query is requesting by accountID
            // Instead of current account
            if (
              variables &&
              Object.prototype.hasOwnProperty.call(variables, 'codeID')
            ) {
              return variables.codeID
            }

            return ''
          },
        },
        author: {
          merge(existing: any[] = [], incoming: any[], { variables }) {
            // If variables contains codeList, the codes are being added (addCodes)
            if (
              variables &&
              Object.prototype.hasOwnProperty.call(variables, 'codeList')
            ) {
              return [...incoming, ...existing]
            }

            return incoming
          },
        },
        codeDef: {
          merge(existing: any[] = [], incoming: any[], { variables }) {
            // If variables contains codeList, the codes are being added (addCodes)
            if (
              variables &&
              Object.prototype.hasOwnProperty.call(variables, 'codeList')
            ) {
              return [...incoming, ...existing]
            }

            return incoming
          },
        },
        codeID: {
          merge(existing: any[] = [], incoming: any[], { variables }) {
            // If variables contains codeList, the codes are being added (addCodes)
            if (
              variables &&
              Object.prototype.hasOwnProperty.call(variables, 'codeList')
            ) {
              return [...incoming, ...existing]
            }

            return incoming
          },
        },
        createdTime: {
          merge(existing: any[] = [], incoming: any[], { variables }) {
            // If variables contains codeList, the codes are being added (addCodes)
            if (
              variables &&
              Object.prototype.hasOwnProperty.call(variables, 'codeList')
            ) {
              return [...incoming, ...existing]
            }

            return incoming
          },
        },
        fullLink: {
          merge(existing: any[] = [], incoming: any[], { variables }) {
            // If variables contains codeList, the codes are being added (addCodes)
            if (
              variables &&
              Object.prototype.hasOwnProperty.call(variables, 'codeList')
            ) {
              return [...incoming, ...existing]
            }

            return incoming
          },
        },
        shortLink: {
          merge(existing: any[] = [], incoming: any[], { variables }) {
            // If variables contains codeList, the codes are being added (addCodes)
            if (
              variables &&
              Object.prototype.hasOwnProperty.call(variables, 'codeList')
            ) {
              return [...incoming, ...existing]
            }

            return incoming
          },
        },
        versionNumber: {
          // createNewLink mutation returns object for newly created links
          // Merge them with cached items
          merge(existing: any[] = [], incoming: any[], { variables }) {
            // If variables contains codeList, the codes are being added (addCodes)
            if (
              variables &&
              Object.prototype.hasOwnProperty.call(variables, 'codeList')
            ) {
              return [...incoming, ...existing]
            }

            return incoming
          },
        },
        totalCodes: {
          merge(existing = 0, incoming: number, { variables }) {
            // Adding codes - include in total
            if (Object.prototype.hasOwnProperty.call(variables, 'codeList')) {
              return existing + incoming
            }

            return incoming
          },
        },
      },
      keyFields: ['accountID', 'linkID'],
    },
    CodeMetricData: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
      },
      keyFields: ['accountID'],
    },
    LinkAuditQueries: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
      },
      keyFields: ['accountID'],
    },
    LinkAuditTable: {
      keyFields: ['pageData', ['destination']],
    },
    UplifterIDSequentialTotal: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
      },
      keyFields: ['accountID'],
    },
    ReportQueries: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
        listSavedLinkPerformanceReportsGQL: {
          keyArgs: () => 'ListSavedLinkPerformanceReportsGQL',
        },
        listSavedLostLinksReports: {
          keyArgs: () => 'ListSavedLostLinksReports',
        },
      },
      keyFields: ['accountID'],
    },
    MarketingFunnelQueries: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
      },
      keyFields: ['accountID'],
    },
    MarketingFunnelReport: {
      keyFields: ['boardID'],
    },
  },
})

const client = new ApolloClient({
  link: from([errorLink, authLink, retryLink, httpLink]),
  cache,
  connectToDevTools: process.env.REACT_APP_NODE_ENV !== 'production',
})

export default client
