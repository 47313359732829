import React, { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'

import { BetaLabel, BulletCounter } from './counter'
import Link from './link'
import SiteLogo from './site-logo'
import { Heading } from './typography'
import { currentUserDetails, dataSourceReactive } from '../api/apollo/variables'
import { listSavedLostLinksReports } from '../api/graphql/report-client'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import { getUpdateRequestList } from '../api/graphql/track-edit-client'
import { getUserAccounts } from '../api/graphql/user-client'
import { getAvailableModules } from '../api/graphql/workspace-client'
import UpgradeRocket from '../assets/svgs/upgrade-rocket.svg'
import {
  TrackIcon,
  ReportIcon,
  SettingsIcon,
  ConnectIcon,
} from '../assets/svgs/menu/module-icons'
import { brandName, matchTypesDropDown } from '../core/constants'
import { isAdminUser, returnUnique } from '../helpers'
import useCustomLinks from '../hooks/useCustomLinks'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import styles from '../styles/desktop-nav.module.scss'

interface DesktopNavProps {
  justLogo?: boolean
  onboardingNotifications?: number
}

export default function DesktopNav({
  justLogo = false,
  onboardingNotifications,
}: DesktopNavProps) {
  const {
    workspaceID,
    userPermission,
    companySubscriptionLevel,
    companyID,
  } = useReactiveVar(currentUserDetails)
  const dataSource = useReactiveVar(dataSourceReactive)

  const logAction = useLogAction()

  const { isEnterprise } = useSubscriptionLevel()
  const { canUseCustomLinks: canUseShortLinks } = useCustomLinks()

  const { data: accountData } = useQuery(getAvailableModules)
  const [fetchUpdateRequestList, { data: updateRequestData }] = useLazyQuery(
    getUpdateRequestList,
    {
      fetchPolicy: 'cache-first',
    },
  )
  const [
    fetchGeneratorFieldTypes,
    { data: generatorFieldsData },
  ] = useLazyQuery(getCampaignCodeGenerator, {
    fetchPolicy: 'cache-first',
  })
  const [getUserAccountProfiles] = useLazyQuery(getUserAccounts)
  const [
    getSavedOtherLinksReports,
    { data: savedOtherLinksReportsData },
  ] = useLazyQuery(listSavedLostLinksReports)

  const [requestCounter, setRequestCounter] = useState<number | null>(null)

  // Only fetch dropdown requests if user is an admin user
  useEffect(() => {
    if (!workspaceID) return

    const fetchTrackData = async () => {
      await fetchGeneratorFieldTypes()
      await fetchUpdateRequestList()
    }

    if (isAdminUser(userPermission)) {
      fetchTrackData()
    }
  }, [workspaceID, userPermission])

  const { trackAvailable, reportAvailable } = useMemo(() => {
    // Track and Report should be seen straight away
    if (!accountData)
      return {
        trackAvailable: true,
        reportAvailable: true,
      }

    return accountData.currentAccount
  }, [accountData])

  const dataSourceIsGoogle = useMemo(() => {
    return dataSource && dataSource.connectionSource === 'google'
  }, [dataSource])

  const showDropdowns = useMemo(() => {
    if (!generatorFieldsData) return false

    return generatorFieldsData.campaignCodeGenerator.paramDefs.some(
      ({ fieldType }) => fieldType === 'select',
    )
  }, [generatorFieldsData])

  // Get valid requests for the user
  useEffect(() => {
    if (!updateRequestData || !isAdminUser(userPermission) || !companyID) return

    const getRequests = async () => {
      const allPendingRequests =
        updateRequestData.track.updateRequestList.pendingRequests

      if (allPendingRequests.length === 0) return

      const { data: userAccountsData } = await getUserAccountProfiles()

      if (userAccountsData) {
        const { userAccountProfiles } = userAccountsData.currentUser

        if (userAccountProfiles.length > 0) {
          const selectedAccounts: {
            accountName: string
            accountID: string
          }[] = returnUnique(userAccountProfiles, 'accountID')
            .filter(
              (account: AccountInfo) =>
                account.companyID === companyID &&
                (account.userPermission === 'admin' ||
                  account.userPermission === 'support'),
            )
            .map((account: AccountInfo) => {
              return {
                accountName: account.accountName,
                accountID: account.accountID,
              }
            })

          const availableAccountIDs = selectedAccounts.map(
            (account) => account.accountID,
          )

          const validPendingRequests = allPendingRequests.filter((request) => {
            return (
              availableAccountIDs.indexOf(request.updateRequestAccountID) >
                -1 &&
              request.changeRequests &&
              !request.changeRequests.every((changeRequest) => {
                return (
                  changeRequest.newOptions &&
                  changeRequest.newOptions.every(
                    ({ approved, rejected }) => approved || rejected,
                  )
                )
              })
            )
          })

          setRequestCounter(validPendingRequests.length || null)
        }
      }
    }

    getRequests()
  }, [updateRequestData, userPermission, companyID])

  // Allows report page to auto-filter to workspace's parameters on load
  const reportPerformanceFilterOptions = useMemo(() => {
    const initialValues = matchTypesDropDown
      .filter(
        (option) =>
          option.value === 'full' ||
          (canUseShortLinks && option.value === 'shortuplcode'),
      )
      .map(({ name, value }) => ({ name, value }))

    if (!generatorFieldsData) {
      return initialValues
    }

    return [
      ...initialValues,
      ...generatorFieldsData.campaignCodeGenerator.paramDefs
        .filter(({ metaParameter, required }) => !metaParameter && required)
        .map(({ fieldName, fieldID }) => ({
          name: fieldName,
          value: fieldID,
        })),
    ]
  }, [generatorFieldsData, canUseShortLinks])

  useEffect(() => {
    if (!dataSourceIsGoogle || !workspaceID) return

    getSavedOtherLinksReports()
  }, [dataSourceIsGoogle, workspaceID])

  const savedOtherLinksReports = useMemo(() => {
    if (!savedOtherLinksReportsData) return []

    return savedOtherLinksReportsData.report.listSavedLinkAuditReports.savedReports.map(
      ({ savedReportID, reportName }) => {
        return {
          savedReportID,
          reportName,
        }
      },
    )
  }, [savedOtherLinksReportsData])

  return (
    <nav className={styles.nav}>
      <Link newTab={false} href="/welcome">
        <SiteLogo className={styles.logo} />
        {!!onboardingNotifications && (
          <BulletCounter
            count={onboardingNotifications}
            className={styles.onboardingCounter}
          />
        )}
      </Link>

      {!justLogo && (
        <>
          {trackAvailable && (
            <Link
              newTab={false}
              className={styles.navItem}
              style={{ gap: 6 }}
              href="/track/create-links"
              data-name="track"
            >
              <TrackIcon />
              <Heading type={3}>Track</Heading>
              <div className={styles.subNav}>
                <Link
                  newTab={false}
                  className={styles.subNavItem}
                  href="/track/learn"
                  data-name="sub-track"
                >
                  Learn
                </Link>
                <Link
                  newTab={false}
                  className={styles.subNavItem}
                  href="/track/create"
                  data-name="sub-track"
                >
                  Create links
                </Link>
                <Link
                  newTab={false}
                  className={styles.subNavItem}
                  href="/track/view"
                  data-name="sub-track"
                >
                  View links
                </Link>
                {isAdminUser(userPermission) && showDropdowns && (
                  <Link
                    newTab={false}
                    className={styles.subNavItem}
                    href="/track/edit-dropdowns"
                    data-name="sub-track"
                  >
                    Edit dropdowns
                    <BulletCounter
                      count={requestCounter}
                      className={styles.counter}
                    />
                  </Link>
                )}
                {isAdminUser(userPermission) && (
                  <Link
                    newTab={false}
                    className={styles.subNavItem}
                    href="/track/edit-parameters-and-rules"
                    data-name="sub-track"
                  >
                    Edit parameters
                  </Link>
                )}
                {isEnterprise && isAdminUser(userPermission) && (
                  <Link
                    newTab={false}
                    className={styles.subNavItem}
                    href="/track/edit-app-destinations"
                    data-name="sub-track"
                  >
                    Edit app destinations
                  </Link>
                )}
              </div>
              <BulletCounter
                count={requestCounter}
                className={styles.navItemCounter}
              />
            </Link>
          )}

          {reportAvailable && (
            <Link
              newTab={false}
              className={styles.navItem}
              href="/report/performance"
              data-name="report"
            >
              <ReportIcon />
              <Heading type={3}>Report</Heading>
              <div className={styles.subNav}>
                <Link
                  newTab={false}
                  className={styles.subNavItem}
                  href="/report/performance"
                  data-name="sub-report"
                >
                  {brandName} links
                  {reportPerformanceFilterOptions.length > 1 && (
                    <div className={styles.subNav}>
                      {reportPerformanceFilterOptions.map(({ name, value }) => {
                        return (
                          <Link
                            newTab={false}
                            className={styles.subNavItem}
                            href={`/report/performance?compare=${value}`}
                            data-name={`sub-report-${value}`}
                          >
                            {name}
                          </Link>
                        )
                      })}
                    </div>
                  )}
                </Link>
                {dataSource && dataSource.connectionSource !== 'adobe' && (
                  <Link
                    newTab={false}
                    className={styles.subNavItem}
                    href="/report/marketing-journeys"
                    data-name="sub-report"
                  >
                    Marketing journeys
                    <BetaLabel />
                  </Link>
                )}
                {dataSourceIsGoogle && (
                  <>
                    {savedOtherLinksReports.length > 0 &&
                      savedOtherLinksReports.map(
                        ({ savedReportID, reportName }) => {
                          return (
                            <Link
                              newTab={false}
                              className={styles.subNavItem}
                              href={`/report/other-links-audit?reportID=${savedReportID}`}
                              data-name={`sub-report-other-links-${savedReportID}`}
                            >
                              {reportName}
                            </Link>
                          )
                        },
                      )}
                    <Link
                      newTab={false}
                      className={styles.subNavItem}
                      href="/report/other-links-audit"
                      data-name="sub-report"
                    >
                      Other links
                    </Link>
                  </>
                )}
                <Link
                  newTab={false}
                  className={styles.subNavItem}
                  href="/report/usage"
                  data-name="sub-report"
                >
                  Value and usage
                </Link>
                <Link
                  newTab={false}
                  className={styles.subNavItem}
                  href="/report/dashboards"
                  data-name="sub-report"
                >
                  Custom embedded
                </Link>
              </div>
            </Link>
          )}

          {/* Explain module should be hidden for all users */}
          {/* {explainAvailable && (
            <Link
              newTab={false}
              className={styles.navItem}
              href="/explain"
              data-name="explain"
            >
              <ExplainIcon />
              <Heading type={3}>Explain</Heading>
            </Link>
          )} */}

          {isAdminUser(userPermission) && (
            <Link
              newTab={false}
              className={styles.navItem}
              href="/connect"
              data-name="connect"
            >
              <ConnectIcon />
              <Heading type={3}>Connect</Heading>
            </Link>
          )}

          {companySubscriptionLevel && !isEnterprise && (
            <Link
              newTab={false}
              className={styles.navItem}
              href="/upgrade"
              onPress={() => {
                // @ts-ignore
                if (window.dataLayer && window.dataLayer.push) {
                  // @ts-ignore
                  window.dataLayer.push({
                    event: 'click-upgrade-nav-button',
                  })
                }

                logAction({
                  variables: {
                    action: 'click-upgrade-nav-button',
                    websiteSection: 'upgrade',
                    pagePath: window.location.pathname,
                    functionName: 'clickUpgrade',
                  },
                })
              }}
              data-name="upgrade"
            >
              <img src={UpgradeRocket} alt="Upgrade" />
              <Heading type={3}>Upgrade</Heading>
            </Link>
          )}

          <Link
            newTab={false}
            className={styles.navItem}
            href="/settings"
            data-name="settings"
          >
            <SettingsIcon />
            <Heading type={3}>Settings</Heading>
          </Link>
        </>
      )}
    </nav>
  )
}
