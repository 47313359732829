import React, { useState, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import moment from 'moment'
import { nanoid } from 'nanoid'

import StyledDatePicker from './date-picker'
import Input from './input'
import {
  explainDataReactive,
  explainMetricsDataReactive,
} from '../api/apollo/legacy-reducers'
import { defaultAnomalyDateRangeLabel } from '../core/constants'
import { getAnomalyDateRange } from '../helpers'
import { yesterday } from '../helpers/report-module'
import styles from '../styles/date-range-tabs.module.scss'

interface Props {
  selector: string
  onChange?: any
}

export default function DateRangeTabs({
  selector: anomalySelector,
  onChange,
}: Props) {
  /**
   * Old types used in V1 API
   * Will eventually be rebuilt
   */
  const explainData = useReactiveVar(explainDataReactive)
  const explainMetricsData = useReactiveVar(explainMetricsDataReactive)
  // const explainAnomaliesData = useReactiveVar(explainAnomaliesDataReactive)

  const [showDateSelector, setShowDateSelector] = useState(false)
  const [selectedValue, setSelectedValue] = useState('')
  const [startDate, setStartDate] = useState<Date | null>(yesterday)
  const [endDate, setEndDate] = useState<Date | null>(yesterday)

  useEffect((): void => {
    if (selectedValue === '') {
      setSelectedValue(anomalySelector)
    } else if (explainData.settings.dateRange !== selectedValue) {
      if (!onChange) {
        // ! V1 endpoint no longer used
        // explainDataReactive({
        //   ...explainData,
        //   settings: { dateRange: selectedValue },
        // })
        // await make.put({
        //   endpoint: 'update-explain-page-layout',
        //   data: {
        //     ...explainData,
        //     settings: { dateRange: selectedValue },
        //   },
        // })
      }
    }
  }, [selectedValue, setSelectedValue, anomalySelector])

  const requestNewData = async (data) => {
    explainMetricsData.currentKeyMetrics.forEach((item) => {
      // ! V1 endpoint no longer used
      // const { metricID } = item
      // if (!metricID) return
      // try {
      //   let to = data.to
      //   let from = data.from
      //   const { selector } = data
      //   // Start search (loading)
      //   explainAnomaliesDataReactive({
      //     ...explainAnomaliesData,
      //     [metricID]: {
      //       ...defaultAnomalies,
      //       callData: {
      //         breakdownLoading: [],
      //         loading: true,
      //         error: false,
      //       },
      //       metricID,
      //     },
      //   })
      //   if (!to || !from) {
      //     const now = new Date(Date.now())
      //     to = moment(now).subtract(1, 'days').format('YYYYMMDD')
      //     from = moment(now)
      //       .subtract(defaultAnomalyDateRange, 'days')
      //       .format('YYYYMMDD')
      //   }
      //   const res = await make.get({
      //     endpoint: `get-range/${metricID}/${from}/${to}`,
      //   })
      //   explainAnomaliesDataReactive({
      //     ...explainAnomaliesData,
      //     [metricID]: {
      //       ...defaultAnomalies,
      //       ...explainAnomaliesData[metricID],
      //       callData: {
      //         breakdownLoading: [],
      //         loading: false,
      //         error: false,
      //       },
      //       lastRange: {
      //         ...explainAnomaliesData[metricID].lastRange,
      //         from,
      //         to,
      //         selector,
      //       },
      //     },
      //   })
      // } catch {
      //   explainAnomaliesDataReactive({
      //     ...explainAnomaliesData,
      //     [metricID]: {
      //       ...defaultAnomalies,
      //       callData: {
      //         breakdownLoading: [],
      //         loading: false,
      //         error: true,
      //       },
      //       metricID,
      //     },
      //   })
      // }
    })
  }

  const handleButtonSelection = (dateRange: string): void => {
    const { from, to } = getAnomalyDateRange(dateRange)
    setSelectedValue(dateRange)

    if (dateRange !== selectedValue) {
      const rangeObject = {
        from,
        to,
        selector: dateRange,
      }
      if (onChange) {
        onChange(rangeObject)
      } else {
        requestNewData(rangeObject)
      }
    }
  }

  // TODO: add support for multiple instances by making name of the element per instance
  const name = 'picker'

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabsWrapper}>
        <Input
          name={`select-date-${name}`}
          id={nanoid()}
          data-testid="30d-input"
          label="30d"
          type="radio"
          value="30"
          checked={selectedValue === 'Last 30days'}
          onClick={(event: React.MouseEvent): any => {
            handleButtonSelection('Last 30days')
            setShowDateSelector(false)
            event.preventDefault()
            event.stopPropagation()
          }}
        />
        <Input
          name={`select-date-${name}`}
          id={nanoid()}
          data-testid="90d-input"
          label="90d"
          type="radio"
          value="90"
          checked={selectedValue === 'Last 90days'}
          onClick={(event: React.MouseEvent): any => {
            handleButtonSelection('Last 90days')
            setShowDateSelector(false)
            event.preventDefault()
            event.stopPropagation()
          }}
        />
        <Input
          name={`select-date-${name}`}
          id={nanoid()}
          label="Year"
          type="radio"
          value="Year"
          checked={selectedValue === 'Year'}
          onClick={(event: React.MouseEvent): any => {
            handleButtonSelection('Year')
            setShowDateSelector(false)
            event.preventDefault()
            event.stopPropagation()
          }}
        />
        <Input
          name={`select-date-${name}`}
          id={nanoid()}
          label="Custom"
          type="radio"
          value="Custom"
          checked={
            selectedValue === 'Custom' || selectedValue.indexOf('-') !== -1
          }
          onClick={(event: React.MouseEvent): any => {
            setSelectedValue('Custom')
            setShowDateSelector(!showDateSelector)
            event.preventDefault()
            event.stopPropagation()
          }}
        />
      </div>
      {showDateSelector ? (
        <div className={styles.dateWrapper}>
          <div
            className={styles.datePicker}
            onClick={(e: React.SyntheticEvent): void => e.stopPropagation()}
            onKeyDown={(e: React.SyntheticEvent): void => e.stopPropagation()}
            role="button"
            tabIndex={-1}
          >
            <StyledDatePicker
              selectsRange
              isClearable={false}
              wrapperClassName={styles.calendar}
              startDate={startDate}
              endDate={endDate}
              onChange={(dates) => {
                const [start, end] = dates

                setStartDate(start)
                setEndDate(end)

                if (start && end) {
                  const from = moment(start).format('YYYYMMDD')
                  const to = moment(end).format('YYYYMMDD')

                  if (to !== from) {
                    const newSelector = `${from} - ${to}`
                    const rangeObject = {
                      from,
                      to,
                      selector: newSelector,
                    }
                    if (onChange) {
                      onChange(rangeObject)
                    } else {
                      requestNewData(rangeObject)
                    }
                    setSelectedValue(newSelector)

                    setShowDateSelector(false)
                  }
                }
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

DateRangeTabs.defaultProps = {
  selector: defaultAnomalyDateRangeLabel,
}
