import React, { useCallback, useMemo, useState } from 'react'

import Accordion from './accordion'
import Quiz from './quiz'
import { getUserData, saveUserData } from '../helpers/local-client'
import useOnboarding from '../hooks/useOnboarding'
import trackLearnContent from '../static-copy/track-learn'
import styles from '../styles/track-learn-accordion.module.scss'

interface TrackLearnAccordionProps {
  adobe?: boolean
}

const TrackLearnAccordion = ({ adobe = false }: TrackLearnAccordionProps) => {
  const { mergedOnboardingSections } = useOnboarding()

  const quizComplete = useMemo(() => {
    if (mergedOnboardingSections && mergedOnboardingSections.length > 0) {
      const learnOnboarding = mergedOnboardingSections.find(
        (section) => section.onboardingSectionID === 'learnCampaignLinks',
      )

      if (learnOnboarding && learnOnboarding.sectionCompleted) {
        return true
      }
    }

    return false
  }, [mergedOnboardingSections])

  const saveState = getUserData()

  // Get saved completion states for all sections of Track>Learn
  const initialProgressionState =
    saveState && saveState.toggleBoxState
      ? (saveState.toggleBoxState as boolean[])
      : Array(11).fill(false, 0)

  const [progressionState, setProgressionState] = useState(
    initialProgressionState,
  )

  const updateProgressionState = useCallback((index: number) => {
    setProgressionState((curr) => {
      const newState = [...curr]

      newState.splice(index, 1, true)

      saveUserData({ toggleBoxState: newState })

      return newState
    })
  }, [])

  // Auto-open tabs based on query
  const urlParams = new URLSearchParams(window.location.search)
  const lessonToShow = urlParams.get('lesson')

  const sections = trackLearnContent[adobe ? 'adobe' : 'google']

  return (
    <Accordion
      id="trackLearn"
      allowMultipleOpen
      progressionButtonText="Next lesson"
      initialOpenState={
        lessonToShow
          ? sections.map((_, index) => index === parseInt(lessonToShow, 10) - 1)
          : undefined
      }
      accordionItems={sections.map(({ heading, content }, index) => {
        return {
          key: heading,
          header: {
            checked:
              index === sections.length - 1
                ? quizComplete
                : progressionState[index],
            title: heading,
          },
          expandedContent: (
            <div className={styles.accordionInner}>
              {index === sections.length - 1 ? (
                <Quiz isComplete={quizComplete} />
              ) : (
                content
              )}
            </div>
          ),
        }
      })}
      onToggle={(index, state) => {
        // Only set as complete if being closed
        if (state === 'close') {
          updateProgressionState(index)
        }
      }}
    />
  )
}

export default TrackLearnAccordion
