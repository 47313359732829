import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import { useHistory } from 'react-router-dom'
import ReactSwitch from 'react-switch'
import moment from 'moment'
import classNames from 'classnames'
import numeral from 'numeraljs'

import Button, { CopyButton } from './button'
import BulkDeleteModal from './bulk-delete-modal'
import BulkEdit from './bulk-edit-modal'
import BulkImportHistorical from './bulk-import-modal'
import ButtonDropdown, {
  DropdownButtonItem,
  DropdownLabel,
} from './button-dropdown'
import { ButtonRow } from './button-row'
import { UrlStatus, UrlValidationMessage } from './url-validation-message'
import HeaderPanel, { Panel } from './header-panel'
import Input, { SearchInput } from './input'
import Link from './link'
import Loader, { LoadingLabel, Preloader } from './loader'
import Modal from './modal'
import ObservepointModal from './observepoint-modal'
import OrderArrow from './order-arrow'
import QRCodeModal from './qr-code-modal'
import { SelectBoxChecklist } from './select-box'
import ShareCampaignCodesModal, {
  ShareModalState,
} from './share-campaign-codes-button'
import { CustomLinkFull } from './custom-link-fields'
import { FullPageTable } from './table'
import Tooltip from './tooltip'
import { EditHistoryModal } from './track-view-edit-history'
import { RequestShortLinksModal } from './upgrade-modals'
import { currentUserDetails, dataSourceReactive } from '../api/apollo/variables'
import { sendFeatureRequest } from '../api/graphql/support-client'
import {
  getCampaignCodeGenerator,
  getUplifterIDCurrentTotal,
} from '../api/graphql/track-create-client'
import {
  addShortLinkExistingCode,
  getDeepLinkDetails,
  getMinCodesByAccount,
  getStoredCodeStats,
  getUrlValidationStatus,
  updateCode,
} from '../api/graphql/track-view-client'
import { getAvailableModules } from '../api/graphql/workspace-client'
import {
  DownloadCodes,
  downloadCodesByAccount,
  getAdobeCodesDownloadLink,
} from '../api/REST/track-client'
import EditIcon from '../assets/edit.svg'
import { ReportIcon } from '../assets/svgs/menu/module-icons'
import { PeopleIcon, PersonIcon } from '../assets/svgs/people-icons'
import ObservepointLogo from '../assets/logos/observepoint-logo.png'
import {
  allUplifterMetrics,
  codeStatus,
  dateFormatShort,
  messages,
  supportEmail,
} from '../core/constants'
import {
  copyString,
  getItemByKeyValue,
  isAdminUser,
  isValidUrl,
} from '../helpers'
import { saveFormDataOld } from '../helpers/track-create'
import {
  downloadSpecificCodes,
  formatMetricValue,
  getAnchorFromString,
  getCustomDomainID,
  getDomain,
} from '../helpers/track-module'
import {
  cloneAndEditBuildForm,
  HeaderColumnProps,
  MetricDropdownItem,
  TableLinkWithData,
  TrackViewFilterProps,
  buildSearchTypeList,
  buildTableHeaders,
  buildTableLinks,
  filterToMinCodesVars,
  getEarliestDataDate,
  getWorkspaceParams,
  MinimalParamDetails,
  RefetchOptions,
} from '../helpers/track-view'
import useCustomLinks from '../hooks/useCustomLinks'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import useUrlValidation from '../hooks/useUrlValidation'
import { getUserData, saveUserData } from '../helpers/local-client'
import styles from '../styles/track-view-table.module.scss'
import {
  GetStoredCodesStatsQuery,
  GetUplifterIdCurrentTotalQuery,
} from '../__gql-types__/graphql'

type EditLinkValueModalProps =
  | { visible: false }
  | {
      visible: true
      paramID?: string
      fullLink: TableLinkWithData
    }

interface EditLinkParameterModalProps {
  paramID: string
  linkToEdit: TableLinkWithData
  onToggle: (refetch?: boolean) => Promise<void>
}

const EditLinkParameterModal = ({
  paramID,
  linkToEdit,
  onToggle,
}: EditLinkParameterModalProps) => {
  const dataSource = useReactiveVar(dataSourceReactive)

  const logAction = useLogAction()

  const [editCode, { loading, error }] = useMutation(updateCode)

  const { linkID, params } = linkToEdit || {}

  const initialValue =
    getItemByKeyValue(params, 'paramID', paramID)?.paramValue || ''

  const [updatedValue, setUpdatedValue] = useState(initialValue)

  const saveValue = useCallback(
    async (value: string) => {
      await editCode({
        variables: {
          codeID: linkID,
          paramDefs: params?.map(({ paramID: fieldID, paramValue }) => ({
            fieldID,
            optionName: fieldID === paramID ? value : paramValue,
          })),
        },
      })

      await logAction({
        variables: {
          action: `update-code-parameter`,
          functionName: 'saveValue',
          extra: JSON.stringify({
            codeID: linkID,
            fieldID: paramID,
            newName: updatedValue,
            prevName: initialValue,
          }),
          pagePath: '/track/view-links',
          websiteSection: 'track',
        },
      })

      await onToggle(true)
    },
    [linkID, initialValue],
  )

  return (
    <Modal
      setIsOpen={() => onToggle()}
      isWarning
      headerColor="pink"
      modalHeader="Edit this parameter?"
      noText="Cancel"
      yesText="Save"
      yesButtonDisabled={initialValue === updatedValue}
      yesButtonLoading={loading}
      onYes={async () => {
        await saveValue(updatedValue)
      }}
      beforeClose={() => {
        logAction({
          variables: {
            action: 'cancel-edit-modal-parameter',
            pagePath: '/track/view-links',
            functionName: 'closeModal',
            websiteSection: 'track',
          },
        })
      }}
      footerContent={
        error ? (
          <p className={styles.footNoteError}>
            Error updating parameter. Please contact{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
          </p>
        ) : undefined
      }
    >
      <p>
        This action will recategorise all data associated with this link{' '}
        <strong>
          in this tool
          {dataSource && dataSource.connectionSource === 'adobe'
            ? ' and Adobe Analytics'
            : ', but not Google Analytics'}
        </strong>
        .
      </p>
      <p>
        It will not automatically change the landing page with campaign code,
        however you can edit this separately.
      </p>
      <p>If you understand the impact, edit below and save:</p>
      <Input
        id={`link-${linkID}`}
        name="name"
        value={updatedValue}
        onKeyUp={async (event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            await saveValue(updatedValue)
          }
        }}
        beforeChange={(newValue) => newValue.replace(/\n|\r/gi, '')}
        onValueChange={(value) => setUpdatedValue(value)}
      />
    </Modal>
  )
}

interface EditLinkUrlModalProps {
  linkToEdit: TableLinkWithData
  onToggle: (refetch?: boolean) => Promise<void>
  masterPrefix?: string
  uplifterIdData?: GetUplifterIdCurrentTotalQuery
}

const EditLinkUrlModal = ({
  linkToEdit,
  onToggle,
  masterPrefix = '?',
  uplifterIdData,
}: EditLinkUrlModalProps) => {
  const { linkID, fullLink, shortLink, deepLinkServiceID: deepLinkDomain } =
    linkToEdit || {}

  const logAction = useLogAction()
  const { availableDomains: availableShortLinkDomains } = useCustomLinks()
  const {
    quick,
    intensive,
    validateUrls,
    validationResults,
  } = useUrlValidation()

  const [
    fetchDeepLinkDetails,
    {
      data: deepLinkDetailsData,
      loading: fetchingDeepLinkDetails,
      error: deepLinkDetailsFetchError,
    },
  ] = useLazyQuery(getDeepLinkDetails)

  const [editCode, { loading, error }] = useMutation(updateCode)

  const [updatedValue, setUpdatedValue] = useState('')
  const [uplifterIDMismatch, setUplifterIDMismatch] = useState(false)

  // Get short link domain ID
  const customDomainID = useMemo(() => {
    return availableShortLinkDomains.find(
      (dom) => dom.optionName === getCustomDomainID(getDomain(shortLink || '')),
    )?.optionValue
  }, [shortLink, availableShortLinkDomains])

  // Get full deep link data if deepLinkServiceID is present
  useEffect(() => {
    if (deepLinkDomain && linkID) {
      fetchDeepLinkDetails({
        variables: { codeID: linkID, deepLinkServiceID: deepLinkDomain },
      })
    }
  }, [deepLinkDomain, linkID])

  const deepLinkConfig = useMemo(() => {
    if (!deepLinkDetailsData) return null

    return deepLinkDetailsData.track.deepLinkQueries.getDeeplinkDetails
  }, [deepLinkDetailsData])

  const initialLinkValue = useMemo(() => {
    if (fetchingDeepLinkDetails) return null

    // If link is a deep link, the value for `fullLink` contains unnecessary text
    // Need to build the link from deepLinkConfig
    return deepLinkConfig
      ? `${deepLinkConfig.fallBackURL}${masterPrefix}${deepLinkConfig.analyticsContext}`
      : fullLink
  }, [deepLinkConfig])

  // Initialise field values and reset when updated
  useEffect(() => {
    if (initialLinkValue) {
      setUpdatedValue(initialLinkValue)
    }
  }, [initialLinkValue])

  // Prevents editing UplifterID if present in initialLinkValue
  const formatInput = useCallback(
    (input: string) => {
      setUplifterIDMismatch(false)

      // Remove new line if present
      const cleanedInput = input.replace(/\n|\r/gi, '')

      if (!uplifterIdData) return cleanedInput

      // Do not allow users to edit the Uplifter ID
      const {
        isEnabled,
        prefix,
        acctPrefix,
      } = uplifterIdData.track.currentSequentialCodeID

      // URL being edited did not have an Uplifter ID
      if (
        !isEnabled ||
        !prefix ||
        updatedValue.indexOf(`${prefix}${acctPrefix}`) === -1
      ) {
        return cleanedInput
      }

      if (cleanedInput.indexOf(prefix) === -1) {
        setUplifterIDMismatch(true)
        return updatedValue
      }

      const origSplitVals = updatedValue.split(prefix)
      const newSplitVals = cleanedInput.split(prefix)

      if (newSplitVals.length > 1 && origSplitVals.length > 1) {
        const useOrigAnchor = getAnchorFromString(updatedValue)

        const useNewAnchor = getAnchorFromString(cleanedInput)

        const origFullIdString = `${prefix}${
          origSplitVals[origSplitVals.length - 1]
        }`.replace(useOrigAnchor, '')

        const newFullIdString = `${prefix}${
          newSplitVals[newSplitVals.length - 1]
        }`.replace(useNewAnchor, '')

        // Do not add change if trying to update Uplifter ID
        if (origFullIdString !== newFullIdString) {
          setUplifterIDMismatch(true)
          return updatedValue
        }
      }

      return cleanedInput
    },
    [uplifterIdData, updatedValue],
  )

  const saveValue = useCallback(
    async (value: string) => {
      const splitUrl = value.split(masterPrefix)

      await editCode({
        variables: {
          codeID: linkID,
          fullCode: deepLinkConfig ? undefined : value,
          customDomainID: customDomainID || undefined,
          deepLinkConfig: deepLinkConfig
            ? {
                appGroupID: deepLinkConfig.appGroupID,
                deepLinkServiceID: deepLinkConfig.deepLinkServiceID,
                deepLinkShortLink: deepLinkConfig.deepLinkShortLink,
                fallBackURL: splitUrl[0],
                analyticsContext: splitUrl[1],
                // TODO: Redirect context needs an array of optionIDs from the appGroup's Context options
                // Can only fetch that based on the optionValue, from the redirectContext in getDeepLinkDetails, which is a stringified JSON object
                // redirectContext: ['<Array of selected optionIDs>'],
              }
            : undefined,
        },
      })

      await logAction({
        variables: {
          action: `update-code-link`,
          functionName: 'saveValue',
          extra: JSON.stringify({
            codeID: linkID,
            newLink: value,
            prevLink: initialLinkValue,
          }),
          pagePath: '/track/view-links',
          websiteSection: 'track',
        },
      })

      await onToggle(true)
    },
    [linkID, customDomainID, deepLinkConfig, masterPrefix],
  )

  return (
    <Modal
      setIsOpen={() => onToggle()}
      isWarning
      headerColor="pink"
      modalHeader={
        deepLinkDomain
          ? 'Edit the fallback url for this app link?'
          : 'Edit this landing page with campaign link?'
      }
      noText="Cancel"
      yesText="Save"
      yesButtonDisabled={initialLinkValue === updatedValue}
      yesButtonLoading={loading}
      onYes={async () => {
        await saveValue(updatedValue)
      }}
      beforeClose={() => {
        logAction({
          variables: {
            action: `cancel-edit-modal-link`,
            pagePath: '/track/view-links',
            functionName: 'closeModal',
            websiteSection: 'track',
          },
        })
      }}
      footerContent={
        error ? (
          <p className={styles.footNoteError}>
            Error updating link. Please contact{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
          </p>
        ) : undefined
      }
    >
      <p>This action will change:</p>
      <ul>
        <li>
          {deepLinkDomain
            ? 'The fallback URL for this app link'
            : 'The landing page for live short links'}
        </li>
        {deepLinkDomain && <li>The campaign data passed to the app</li>}
        <li>The metric data matched and returned in this tool</li>
      </ul>
      <p>If this link has been used, we recommend creating a new link.</p>
      <p>If you understand the impact, edit below and save:</p>
      {fetchingDeepLinkDetails ? (
        <Preloader />
      ) : (
        <>
          {deepLinkDetailsFetchError ? (
            <p className={styles.footNoteError}>
              Error fetching link details. Please contact{' '}
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
            </p>
          ) : (
            <>
              <Input
                id={`link-${linkID}`}
                name="name"
                value={updatedValue}
                delay={800}
                type="textArea"
                multilineInput
                maxLength={2048}
                beforeChange={(newValue) => formatInput(newValue)}
                onValueChange={(value) => {
                  setUpdatedValue(value)

                  const landingPage = value.split(masterPrefix)

                  // Check landing page status
                  if (isValidUrl(landingPage[0])) {
                    const newUrl = landingPage[0]

                    validateUrls([newUrl])
                  }
                }}
                onKeyUp={async (
                  event: React.KeyboardEvent<HTMLInputElement>,
                ) => {
                  if (event.key === 'Enter') {
                    await saveValue(updatedValue)
                  }
                }}
              />
              {uplifterIDMismatch && (
                <p className={styles.errorMsg}>Can't edit Smart link ID.</p>
              )}
              {isValidUrl(updatedValue) &&
                initialLinkValue !== updatedValue &&
                (quick || intensive) && (
                  <UrlValidationMessage
                    key={updatedValue}
                    url={updatedValue.split(masterPrefix)[0]}
                    validationDetails={
                      validationResults[updatedValue.split(masterPrefix)[0]]
                    }
                  />
                )}
              {/* TODO: Add deepLinkConfig fields (redirectContext) */}
              {/* {deepLinkConfig && (
            <>
              <p>App link TBC</p>
              <p>
                <code>{JSON.stringify(deepLinkConfig, null, 2)}</code>
              </p>
            </>
          )} */}
            </>
          )}
        </>
      )}
    </Modal>
  )
}

interface TrackViewSearchProps {
  filter: TrackViewFilterProps
  setFilter: React.Dispatch<React.SetStateAction<TrackViewFilterProps>>
  refetchData: (queryVars: TrackViewFilterProps) => Promise<void>
  loading?: boolean
  searchTypeList: { name: string; value: string }[]
  totalLinks?: number
  currentLinks?: number | null
}

export const TrackViewSearch = memo(
  ({
    filter,
    setFilter,
    refetchData,
    loading = false,
    searchTypeList,
    totalLinks = 0,
    currentLinks = null,
  }: TrackViewSearchProps) => {
    const [currentSearchFilter, setCurrentSearchFilter] = useState<
      Pick<TrackViewFilterProps, 'searchType' | 'searchTerm'>
    >({ searchType: filter.searchType, searchTerm: filter.searchTerm })

    // Check if a filter has been applied
    const isFiltered = !!filter.searchTerm || filter.searchType !== 'any'

    // Filters can only be updated if they have been changed
    const currentFilterMatchesSetFilter =
      currentSearchFilter.searchTerm === filter.searchTerm &&
      currentSearchFilter.searchType === filter.searchType

    // A non-empty filter has been applied and the new one being set is empty
    const currentFilterIsEmpty =
      (filter.searchType !== 'any' || !!filter.searchTerm) &&
      currentSearchFilter.searchTerm === '' &&
      currentSearchFilter.searchType === 'any'

    // Button should be enabled to clear the current filter or apply a changed filter
    const searchButtonDisabled =
      loading ||
      (!isFiltered && currentFilterMatchesSetFilter) ||
      (!currentFilterMatchesSetFilter && !currentSearchFilter.searchTerm)

    const showClear =
      loading ||
      (isFiltered && currentFilterMatchesSetFilter) ||
      currentFilterIsEmpty

    const applyFilter = () => {
      const filterToApply = showClear
        ? {
            searchType: 'any',
            searchTerm: '',
          }
        : currentSearchFilter

      // Reset currentfilter
      if (showClear) {
        setCurrentSearchFilter(filterToApply)
      }

      setFilter((curr) => ({
        ...curr,
        ...filterToApply,
        activePage: 1,
      }))

      refetchData({
        ...filter,
        ...filterToApply,
        activePage: 1,
        sortBy: filter.bidirectionalSortKey || filter.sortBy,
      })
    }

    return (
      <div className={styles.searchContainer}>
        <SearchInput
          id="track-view-search"
          searchTypeList={searchTypeList}
          selectValue={currentSearchFilter.searchType}
          onChangeSearchType={(searchType) => {
            setCurrentSearchFilter((curr) => ({
              ...curr,
              searchType,
            }))
          }}
          value={currentSearchFilter.searchTerm}
          onChange={(newValue) => {
            setCurrentSearchFilter((curr) => ({
              ...curr,
              searchTerm: newValue || '',
            }))
          }}
          onKeyUp={(e) => {
            if (
              !searchButtonDisabled &&
              !currentFilterMatchesSetFilter &&
              e.key === 'Enter'
            ) {
              applyFilter()
            }
          }}
        />
        <div className={styles.searchSummary}>
          <Button
            variant="secondary"
            className={styles.searchButton}
            isDisabled={searchButtonDisabled}
            onPress={applyFilter}
          >
            {showClear ? 'Clear' : 'Search'}
          </Button>
          {loading ? (
            <div className={styles.searchLoading}>
              <Preloader className={styles.searchPreloader} />
              <LoadingLabel label="Retrieving links" />
            </div>
          ) : (
            <p className={styles.searchCount}>
              {currentLinks && currentLinks < totalLinks
                ? `${numeral(currentLinks).format('0,0')}/`
                : ''}
              {numeral(totalLinks).format('0,0')} link{totalLinks !== 1 && 's'}
            </p>
          )}
        </div>
      </div>
    )
  },
)

interface TrackViewMetricsProps {
  codesMetricData?: GetStoredCodesStatsQuery
  loading?: boolean
  selectedMetrics: MetricDropdownItem[]
  setSelectedMetrics: React.Dispatch<React.SetStateAction<MetricDropdownItem[]>>
}

export const TrackViewMetrics = memo(
  ({
    codesMetricData,
    loading = false,
    selectedMetrics,
    setSelectedMetrics,
  }: TrackViewMetricsProps) => {
    const [metricsList, setMetricList] = useState<MetricDropdownItem[]>([])
    const [earliestDataDate, setEarliestDataDate] = useState<number | null>(
      null,
    )

    const earliestClickDate = codesMetricData
      ? codesMetricData.storedCodeStats?.earliestClickDate || null
      : null

    const earliestMetricDate = codesMetricData
      ? codesMetricData.storedCodeStats?.earliestMetricDate || null
      : null

    const earliestDeepLinkDate = codesMetricData
      ? codesMetricData.storedCodeStats?.earliestDeepLinkDate || null
      : null

    useEffect(() => {
      if (!codesMetricData || Object.keys(codesMetricData).length === 0) return

      const { storedCodeStats } = codesMetricData

      const metrics = storedCodeStats.metricValues
        .map(({ metricID, displayName, helpText }) => ({
          metricID,
          displayName,
          helpText,
        }))
        // Don't show the following metrics while they're broken
        .filter(
          ({ metricID }) =>
            [
              'shortLinkInitialClicks',
              'shortLinkReturningClicks',
              'shortLinkReturningClicksPercentage',
            ].indexOf(metricID) === -1,
        )
        // Only show metrics if data exists for them
        .filter(({ metricID }) => {
          const metricsToShow: string[] = []

          if (earliestClickDate) metricsToShow.push('shortLinkClicks')
          if (earliestDeepLinkDate) {
            metricsToShow.push('deepLinkClicks')
            metricsToShow.push('deepLinkInAppClicks')
            metricsToShow.push('deepLinkClicksToAppStore')
          }

          if (!earliestMetricDate) return metricsToShow.includes(metricID)

          // Show all metrics, unless Uplifter metric has no data
          return allUplifterMetrics.includes(metricID)
            ? metricsToShow.includes(metricID)
            : true
        })

      setMetricList(metrics)
      setEarliestDataDate(getEarliestDataDate(codesMetricData))
    }, [codesMetricData])

    let placeholder = 'No metrics available'

    if (loading) {
      placeholder = 'Loading metrics'
    } else if (earliestDataDate) {
      placeholder = `Metrics (from ${moment(new Date(earliestDataDate)).format(
        dateFormatShort,
      )})`
    }

    return (
      <SelectBoxChecklist
        id="select-key-metrics"
        className={styles.metricsSelector}
        allLabel="All metrics"
        isLoading={loading}
        isDisabled={metricsList.length === 0 && !loading}
        placeholder={placeholder}
        labelKey="displayName"
        valueKey="metricID"
        value={metricsList.filter((metric) =>
          selectedMetrics.find(({ metricID }) => metricID === metric.metricID),
        )}
        options={metricsList}
        onChange={(newValue) => {
          setSelectedMetrics(newValue as MetricDropdownItem[])
        }}
      />
    )
  },
)

interface TrackViewActionsProps {
  selectedLinks: string[]
  filter: TrackViewFilterProps
  tableRows: TableLinkWithData[]
  loadingLinks?: boolean
  loadingStats?: boolean
  refetchData: () => Promise<void>
  workspaceID: string
  masterPrefix?: string
  paramDefs: MinimalParamDetails[]
}

interface ActionStatus {
  action: string
  loading: boolean
  success?: string
  error?: string
}

export const TrackViewActions = memo(
  ({
    selectedLinks,
    filter,
    tableRows,
    loadingLinks,
    loadingStats,
    refetchData,
    workspaceID,
    masterPrefix,
    paramDefs,
  }: TrackViewActionsProps) => {
    const { userPermission } = useReactiveVar(currentUserDetails)
    const dataSource = useReactiveVar(dataSourceReactive)

    const logAction = useLogAction()

    const history = useHistory()

    const [requestFeature] = useMutation(sendFeatureRequest)

    const [actionStatus, setActionStatus] = useState<ActionStatus | null>(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showBulkEditModal, setShowBulkEditModal] = useState(false)
    const [showBulkImportModal, setShowBulkImportModal] = useState(false)
    const [showQrModal, setShowQrModal] = useState(false)
    const [showObservepointModal, setShowObservepointModal] = useState(false)

    const [shareModalState, setShareModalState] = useState<ShareModalState>({
      active: false,
      shared: false,
      typedValue: '',
      shareEmails: [],
      note: '',
      subject: '',
    })

    const selectedLinksFull = tableRows
      .filter((row) => selectedLinks.includes(row.linkID))
      .map(
        ({ linkID, fullLink, shortLink, createdInfo: { author }, params }) => ({
          cID: linkID,
          sL: shortLink || '',
          c: fullLink,
          e: author,
          params,
        }),
      )

    return (
      <>
        <div>
          <ButtonDropdown
            buttonText="Actions"
            containerClassName={styles.actionsButton}
            loading={actionStatus?.loading}
          >
            {/* Download codes */}
            <DropdownButtonItem
              key="downloadCodes"
              isDisabled={
                loadingLinks ||
                loadingStats ||
                (actionStatus?.action === 'downloadCodes' &&
                  actionStatus?.loading)
              }
              onPress={async () => {
                setActionStatus({
                  action: 'downloadCodes',
                  loading: true,
                })

                const data: DownloadCodes = {}

                if (selectedLinks.length > 0) {
                  data.codeIDList = [...selectedLinks]
                } else {
                  const {
                    dimensionFilter,
                    orderBy,
                    filterByCurrentUser,
                  } = filterToMinCodesVars(filter)

                  data.dimensionFilter = dimensionFilter
                  data.orderBy = orderBy
                  data.filterByCurrentUser = filterByCurrentUser
                }

                const csv = await downloadCodesByAccount(data, true)

                if (!csv) {
                  setActionStatus({
                    action: 'downloadCodes',
                    loading: false,
                    error:
                      'Unable to download codes. Please try again or contact support.',
                  })

                  return
                }

                await downloadSpecificCodes(csv, true)

                logAction({
                  variables: {
                    action: 'download-codes',
                    functionName: 'downloadCodesByAcciont',
                    pagePath: '/track/view-links',
                    websiteSection: 'track',
                    extra:
                      selectedLinks.length > 0
                        ? JSON.stringify(selectedLinks)
                        : 'All codes',
                  },
                })

                setActionStatus(null)
              }}
            >
              {selectedLinks.length === 0 ? (
                <>Download all {filter.searchTerm ? '(filtered)' : ''}</>
              ) : (
                `Download selected (${numeral(selectedLinks.length).format(
                  '0,0',
                )})`
              )}
            </DropdownButtonItem>
            {/* Share codes */}
            <DropdownButtonItem
              key="shareCodes"
              isDisabled={selectedLinks.length === 0}
              onPress={() => {
                setShareModalState({
                  active: true,
                  shared: false,
                  typedValue: '',
                  shareEmails: [],
                  note: '',
                  subject: '',
                })
              }}
            >
              <Tooltip
                id="share-links-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinks.length === 0
                    ? 'Select some links first to perform this action.'
                    : ''
                }
              >
                Share selected ({numeral(selectedLinks.length).format('0,0')})
              </Tooltip>
            </DropdownButtonItem>
            {/* Delete codes */}
            <DropdownButtonItem
              key="deleteCodes"
              isDisabled={selectedLinks.length === 0}
              onPress={() => setShowDeleteModal(true)}
            >
              <Tooltip
                id="delete-links-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinks.length === 0
                    ? 'Select some links first to perform this action.'
                    : ''
                }
              >
                Delete selected ({numeral(selectedLinks.length).format('0,0')})
              </Tooltip>
            </DropdownButtonItem>
            {/* Clone and edit */}
            <DropdownButtonItem
              key="cloneAndEdit"
              isDisabled={selectedLinks.length === 0}
              onPress={() => {
                const { urls, data } = cloneAndEditBuildForm(
                  tableRows,
                  selectedLinks,
                  masterPrefix,
                  paramDefs,
                )

                saveFormDataOld({
                  url: urls,
                  form: data,
                  currentAccount: workspaceID,
                  formType: selectedLinks.length === 1 ? 'single' : 'multi',
                })

                logAction({
                  variables: {
                    action: 'clicked-clone-and-edit',
                    functionName: 'cloneAndEdit',
                    pagePath: '/track/view-links',
                    websiteSection: 'track',
                    extra: JSON.stringify(selectedLinks),
                  },
                })

                history.push('/track/create-links')
              }}
            >
              <Tooltip
                id="clone-links-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinks.length === 0
                    ? 'Select some links first to perform this action.'
                    : ''
                }
              >
                Clone and edit
              </Tooltip>
            </DropdownButtonItem>
            <React.Fragment key="adminActions">
              {isAdminUser(userPermission) && (
                <>
                  {/* Bulk edit */}
                  <DropdownButtonItem
                    onPress={() => setShowBulkEditModal(true)}
                  >
                    Bulk edit
                  </DropdownButtonItem>
                  {/* Import historical codes */}
                  <DropdownButtonItem
                    onPress={() => setShowBulkImportModal(true)}
                  >
                    Import historical links
                  </DropdownButtonItem>
                </>
              )}
            </React.Fragment>
            {/* Download QR code */}
            <DropdownButtonItem
              key="qrCode"
              isDisabled={selectedLinks.length !== 1}
              onPress={() => setShowQrModal(true)}
            >
              <Tooltip
                id="qr-code-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinks.length === 0
                    ? 'Select a single link first to perform this action.'
                    : ''
                }
              >
                Download QR code
              </Tooltip>
            </DropdownButtonItem>
            {/* Copy short links */}
            <DropdownButtonItem
              key="copyShortLinks"
              isDisabled={
                selectedLinksFull.filter(({ sL }) => !!sL).length === 0
              }
              onPress={() => {
                const selectedShortLinks = selectedLinksFull.map(
                  (selCode) => selCode.sL,
                )

                copyString(
                  selectedShortLinks.length < 2
                    ? selectedShortLinks[0]
                    : selectedShortLinks.join('\n'),
                )

                logAction({
                  variables: {
                    action: 'copy-codes-short-links',
                    functionName: '',
                    pagePath: '/track/view-links',
                    websiteSection: 'track',
                    extra: JSON.stringify(selectedLinks),
                  },
                })
              }}
            >
              <Tooltip
                id="short-link-copy-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinks.length === 0
                    ? 'Select some links first to perform this action.'
                    : ''
                }
              >
                Copy short links ({numeral(selectedLinks.length).format('0,0')})
              </Tooltip>
            </DropdownButtonItem>
            {/* Copy full links */}
            <DropdownButtonItem
              key="copyFullLinks"
              isDisabled={selectedLinksFull.length === 0}
              onPress={() => {
                const selectedLongLinks = selectedLinksFull.map(
                  (selCode) => selCode.c,
                )

                copyString(
                  selectedLongLinks.length < 2
                    ? selectedLongLinks[0]
                    : selectedLongLinks.join('\n'),
                )

                logAction({
                  variables: {
                    action: 'copy-codes-full-links',
                    functionName: '',
                    pagePath: '/track/view-links',
                    websiteSection: 'track',
                    extra: JSON.stringify(selectedLinks),
                  },
                })
              }}
            >
              <Tooltip
                id="full-link-copy-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinks.length === 0
                    ? 'Select some links first to perform this action.'
                    : ''
                }
              >
                Copy full links ({numeral(selectedLinks.length).format('0,0')})
              </Tooltip>
            </DropdownButtonItem>
            <DropdownLabel key="observepointLabel">
              <img
                src={ObservepointLogo}
                className={styles.observepointIcon}
                alt="Observepoint"
              />
            </DropdownLabel>
            {/* Observepoint page scan */}
            <DropdownButtonItem
              key="observepointScan"
              isDisabled={selectedLinksFull.length !== 1}
              onPress={() => {
                setShowObservepointModal(true)

                logAction({
                  variables: {
                    action: 'open-observepoint-scan-modal',
                    functionName: 'observePointScan',
                    pagePath: window.location.pathname,
                    websiteSection: 'track',
                    extra: selectedLinksFull[0].c,
                  },
                })
              }}
            >
              <Tooltip
                id="scan-page-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinksFull.length === 0
                    ? 'Select a single link first to perform this action.'
                    : ''
                }
              >
                Scan page for issues
              </Tooltip>
            </DropdownButtonItem>
            <React.Fragment key="adobeActions">
              {dataSource && dataSource.connectionSource === 'adobe' && (
                <>
                  <DropdownLabel key="adobeLabel">
                    <img
                      src="/aa-logo.svg"
                      alt="AA"
                      style={{
                        marginTop: -2,
                        width: 18,
                        verticalAlign: 'middle',
                        marginRight: 8,
                      }}
                    />
                    <>Adobe Analytics</>
                  </DropdownLabel>
                  {/* Export to Adobe */}
                  <DropdownButtonItem
                    key="adobeExport"
                    className={styles.softDisable}
                    onPress={async () => {
                      // ! V1 endpoint no longer used
                      // await make.get({ endpoint: 'generate-saint-file' })

                      logAction({
                        variables: {
                          action: 'click-generate-saint-file',
                          websiteSection: 'track',
                          pagePath: window.location.pathname,
                          functionName: 'clickGenerateSaintFile',
                          extra: 'form',
                        },
                      })

                      requestFeature({
                        variables: {
                          page: 'track>view',
                          message:
                            'API V1 request triggered: generateSaintFile',
                        },
                      })
                    }}
                  >
                    Export to Adobe
                  </DropdownButtonItem>
                  {/* Download Adobe file */}
                  <DropdownButtonItem
                    key="adobeFile"
                    onPress={async () => {
                      setActionStatus({
                        action: 'adobeFile',
                        loading: true,
                      })

                      await getAdobeCodesDownloadLink()

                      setActionStatus(null)
                    }}
                  >
                    Download Adobe file
                  </DropdownButtonItem>
                </>
              )}
            </React.Fragment>
          </ButtonDropdown>
          {actionStatus?.error && (
            <p className={classNames(styles.actionMsg, styles.error)}>
              {actionStatus.error}
            </p>
          )}
          {actionStatus?.success && (
            <p className={classNames(styles.actionMsg, styles.success)}>
              {actionStatus.success}
            </p>
          )}
        </div>
        {showDeleteModal && (
          <BulkDeleteModal
            setShowModal={setShowDeleteModal}
            selectedCodes={selectedLinksFull}
            refetchData={refetchData}
          />
        )}
        {shareModalState.active && selectedLinks.length > 0 && (
          <ShareCampaignCodesModal
            selectedCodes={selectedLinks}
            shareModalState={shareModalState}
            setShareModalState={setShareModalState}
          />
        )}
        {showBulkEditModal && <BulkEdit setShowModal={setShowBulkEditModal} />}
        {showBulkImportModal && (
          <BulkImportHistorical
            setShowModal={setShowBulkImportModal}
            refetchData={refetchData}
          />
        )}
        {showQrModal && selectedLinks.length === 1 && (
          <QRCodeModal
            code={selectedLinksFull[0].sL || selectedLinksFull[0].c}
            setShowModal={setShowQrModal}
            section="track-view"
          />
        )}
        {showObservepointModal && (
          <ObservepointModal
            setShowModal={setShowObservepointModal}
            link={selectedLinksFull[0].c}
          />
        )}
      </>
    )
  },
)

interface TrackViewHeaderProps {
  tableHeaderColumns: HeaderColumnProps[]
  allLinksSelected?: boolean
  setAllLinksSelected: (checked: boolean) => void
  sortBy: string
  orderAsc: boolean
  refetchData: (
    queryVars: Pick<TrackViewFilterProps, 'sortBy' | 'isMetric'> & {
      bidirectionalSortKey?: string
    },
  ) => void
  showUplifterIdColumn?: boolean
}

export const TrackViewHeader = memo(
  ({
    tableHeaderColumns,
    allLinksSelected = false,
    setAllLinksSelected,
    sortBy,
    orderAsc,
    refetchData,
    showUplifterIdColumn = false,
  }: TrackViewHeaderProps) => {
    return (
      <tr className={styles.tableHeaderRow}>
        <th className={styles.checkboxCell}>
          <Input
            type="checkbox"
            name="allNone"
            id="allNone"
            className={styles.checkboxItem}
            checked={allLinksSelected}
            label=" "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target

              setAllLinksSelected(checked)
            }}
          />
        </th>
        {tableHeaderColumns.map(
          ({ key, name, tooltipMsg, bidirectionalSortKeys, isMetric }) => {
            return (
              <th
                key={key}
                onClick={() => {
                  const newFilters: Pick<
                    TrackViewFilterProps,
                    'sortBy' | 'isMetric'
                  > & {
                    bidirectionalSortKey?: string
                  } = {
                    sortBy: key,
                    isMetric,
                  }

                  // Special case - bidirectional sort
                  if (bidirectionalSortKeys) {
                    const currentIndex = bidirectionalSortKeys.indexOf(sortBy)
                    const nextIndex =
                      currentIndex === bidirectionalSortKeys.length - 1
                        ? 0
                        : currentIndex + 1

                    newFilters.bidirectionalSortKey =
                      bidirectionalSortKeys[nextIndex]
                  }

                  refetchData(newFilters)
                }}
              >
                <div className={styles.tableColumnHeaderContainer}>
                  <Tooltip
                    id={`${key}-tooltip`}
                    useIcon
                    clickable
                    tooltipPosition="bottom"
                    tooltipPositionStrategy="fixed"
                    tooltipMessage={tooltipMsg}
                  >
                    {name}
                  </Tooltip>
                  {key === 'shortLinkClicks' && (
                    <Tooltip
                      id={`${key}-tooltip-warning`}
                      className={styles.warningTooltip}
                      tooltipPosition="bottom"
                      tooltipPositionStrategy="fixed"
                      tooltipMessage={`\n\n**Apologies:** Due to an internal error, no short link clickthrough data was collected between 5pm 17th Jun 2024 and 4pm 2nd July 2024.`}
                    >
                      !
                    </Tooltip>
                  )}
                  <OrderArrow
                    className={styles.orderArrow}
                    currentKey={
                      bidirectionalSortKeys &&
                      bidirectionalSortKeys.indexOf(sortBy) > -1
                        ? sortBy
                        : key
                    }
                    sortKey={sortBy}
                    orderAsc={bidirectionalSortKeys ? undefined : orderAsc}
                    type={bidirectionalSortKeys ? 'bidirectional' : undefined}
                  />
                </div>
              </th>
            )
          },
        )}
        {showUplifterIdColumn && <th>UplifterID</th>}
      </tr>
    )
  },
)

interface ShortLinkCellProps {
  codeID: string
  index: number
  shortLinkEditActiveIndex: number | null
  setShortLinkEditActiveIndex: React.Dispatch<
    React.SetStateAction<number | null>
  >
  setCurrentShortLink: React.Dispatch<React.SetStateAction<string | null>>
}

const ShortLinkEditCell = ({
  codeID,
  index,
  shortLinkEditActiveIndex,
  setShortLinkEditActiveIndex,
  setCurrentShortLink,
}: ShortLinkCellProps) => {
  const logAction = useLogAction()

  const {
    canUseCustomLinks: canUseShortLinks,
    useAliases,
    fetchingAliases,
  } = useCustomLinks()

  const [addShortLink, { loading }] = useMutation(addShortLinkExistingCode)

  const [showShortLinkModal, setShowShortLinkModal] = useState(false)
  const [shortLink, setShortLink] = useState<{
    domain: string
    alias: string
    status: UrlStatus
  }>({
    domain: '',
    alias: '',
    status: '',
  })

  if (index === shortLinkEditActiveIndex) {
    return (
      <div className={styles.addShortLink}>
        <div>
          <CustomLinkFull
            onDomainChange={(domain) =>
              setShortLink((curr) => ({ ...curr, domain }))
            }
            onAliasChange={(alias) =>
              setShortLink((curr) => ({ ...curr, alias }))
            }
            onStatusChange={(status) =>
              setShortLink((curr) => ({ ...curr, status }))
            }
            domainSelectorClassName={styles.domainSelector}
            aliasClassName={styles.aliasField}
          />
        </div>
        <ButtonRow className={styles.actionButtons}>
          <Button
            loading={
              loading ||
              shortLink.status === 'refetching' ||
              shortLink.alias === ''
            }
            isDisabled={
              loading ||
              fetchingAliases ||
              shortLink.alias === '' ||
              (!!shortLink.status && shortLink.status !== 'valid')
            }
            variant="text"
            color="pink"
            onPress={async () => {
              const { data } = await addShortLink({
                variables: {
                  newShortLinkID: shortLink.alias,
                  customDomainID: getCustomDomainID(shortLink.domain),
                  codeID,
                },
              })

              if (data) {
                setShortLinkEditActiveIndex(null)

                // Make sure the link can't be used again
                useAliases([shortLink.alias])

                setCurrentShortLink(data.track.addShortLinkExistingCode.sL)
              }
            }}
          >
            Save
          </Button>
          <Button
            variant="text"
            color="grey"
            onPress={() => setShortLinkEditActiveIndex(null)}
          >
            Cancel
          </Button>
        </ButtonRow>
      </div>
    )
  }

  return (
    <>
      <span className={styles.emptyShortLink}>
        <Button
          className={styles.addShortLinkButton}
          variant="secondary"
          onPress={() => {
            if (canUseShortLinks) {
              setShortLinkEditActiveIndex(index)
              return
            }

            // Disabled for unpaid accounts - show modal
            // @ts-ignore
            if (window.dataLayer && window.dataLayer.push) {
              // @ts-ignore
              window.dataLayer.push({
                event: 'click-shortlink-upgrade-blocker',
              })
            }

            logAction({
              variables: {
                action: 'click-shortlink-upgrade-blocker',
                websiteSection: 'track',
                pagePath: window.location.pathname,
                functionName: 'clickUpgrade',
                extra: 'form',
              },
            })

            setShowShortLinkModal(true)
          }}
        >
          Create
        </Button>
      </span>
      {showShortLinkModal && (
        <RequestShortLinksModal onHideModal={setShowShortLinkModal} />
      )}
    </>
  )
}

interface TrackViewLinkRowProps {
  link: TableLinkWithData
  linkIndex: number
  isSelected?: boolean
  setIsSelected: (checked: boolean) => void
  loadingInfo?: boolean
  loadingStats?: boolean
  showUplifterIdColumn?: boolean
  canEdit?: boolean
  setEditModal: React.Dispatch<React.SetStateAction<EditLinkValueModalProps>>
  setViewEditHistoryModal: React.Dispatch<
    React.SetStateAction<{
      visible: boolean
      linkID?: string
    }>
  >
  logAction: (vars: any) => void
  workspaceID: string
  shortLinkEditActiveIndex: number | null
  setShortLinkEditActiveIndex: React.Dispatch<
    React.SetStateAction<number | null>
  >
  isAdobe?: boolean
  reportModuleAvailable?: boolean
}

export const TrackViewLinkRow = memo(
  ({
    link,
    linkIndex,
    isSelected = false,
    setIsSelected,
    loadingInfo = false,
    loadingStats = false,
    showUplifterIdColumn = false,
    canEdit = false,
    setEditModal,
    setViewEditHistoryModal,
    logAction,
    workspaceID,
    shortLinkEditActiveIndex,
    setShortLinkEditActiveIndex,
    isAdobe = false,
    reportModuleAvailable = true,
  }: TrackViewLinkRowProps) => {
    const {
      linkID,
      createdInfo,
      fullLink,
      hasNoLandingPage,
      shortLink,
      params,
      uplifterIDValue,
      status,
      linkValidation,
      metricData,
      deepLinkServiceID,
    } = link

    const { quick, intensive } = useUrlValidation()

    // Used to spoof immediate update of short link
    // TODO: Update cached value in Apollo instead
    const [currentShortLink, setCurrentShortLink] = useState(shortLink)

    // Prevent report page from switching to short link as metric if it has no data
    const hideShortLinkMetric =
      metricData?.find((m) => m.metricID === 'shortLinkClicks')?.metricValue ===
        0 || false

    // Should not allow newly live links to have reports created
    const createdToday =
      moment
        .unix(parseInt(createdInfo.createdTime, 10))
        .format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')

    return (
      <tr className={styles.tableLinkRow}>
        <td className={styles.checkboxCell}>
          <Input
            type="checkbox"
            name={`checkbox-${linkID}`}
            id={`checkbox-${linkID}`}
            className={styles.checkboxItem}
            checked={isSelected}
            label=" "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target as HTMLInputElement

              setIsSelected(checked)
            }}
          />
        </td>
        <td className={styles.createdCell}>
          <p className={styles.createdDateLine}>
            <span className={styles.createdDate}>
              {moment
                .unix(parseInt(createdInfo.createdTime, 10))
                .format(dateFormatShort)}
            </span>
            <span className={styles.createdTime}>
              {moment
                .unix(parseInt(createdInfo.createdTime, 10))
                .format('HH:mm')}
            </span>
          </p>
          <span className={styles.createdUser}>
            {createdInfo.versionNumber > 1 ? (
              <Button
                variant="text"
                color="pink"
                className={styles.editHistoryButton}
                onPress={() =>
                  setViewEditHistoryModal({ visible: true, linkID })
                }
              >
                Edited
              </Button>
            ) : (
              ''
            )}
            <span>{createdInfo.author || 'deleted user'}</span>
          </span>
        </td>
        <td
          className={classNames(styles.shortLinkCell, {
            [styles.shortLinkEditActive]:
              linkIndex === shortLinkEditActiveIndex,
          })}
        >
          <div className={styles.cellWrapper}>
            {currentShortLink ? (
              <>
                <span className={styles.linkCode}>{currentShortLink}</span>{' '}
                <div className={styles.cellActions}>
                  <CopyButton
                    className={styles.copyButton}
                    value={currentShortLink}
                  />
                </div>
              </>
            ) : (
              <ShortLinkEditCell
                codeID={linkID}
                index={linkIndex}
                shortLinkEditActiveIndex={shortLinkEditActiveIndex}
                setShortLinkEditActiveIndex={setShortLinkEditActiveIndex}
                setCurrentShortLink={setCurrentShortLink}
              />
            )}
          </div>
        </td>
        <td className={styles.fullLinkCell}>
          <div className={styles.fullLinkContainer}>
            <div className={styles.cellWrapper}>
              <span style={{ whiteSpace: 'pre-line' }}>{fullLink}</span>
              <div className={styles.cellActions}>
                <CopyButton value={fullLink} />
                {canEdit && (
                  <Button
                    variant="iconOnly"
                    className={styles.editButton}
                    icon={{
                      src: EditIcon,
                      alt: 'Edit',
                      imgHeight: 20,
                    }}
                    onPress={() => {
                      setEditModal({
                        visible: true,
                        fullLink: link,
                      })

                      logAction({
                        variables: {
                          action: 'open-edit-modal-parameter',
                          functionName: '',
                          pagePath: '/track/view-links',
                          websiteSection: 'track',
                        },
                      })
                    }}
                  />
                )}
              </div>
            </div>
            {!deepLinkServiceID && (quick || intensive) && (
              <UrlStatus
                url={fullLink}
                validatedUrl={linkValidation}
                createdToday={createdToday}
                hasMetricData={!!(metricData && metricData.length > 0)}
                hasNoLandingPage={!!hasNoLandingPage}
              />
            )}
          </div>
        </td>
        <td className={styles.statusCell}>
          {loadingInfo || loadingStats ? (
            <Loader className={styles.loadingText} />
          ) : (
            <>
              {status ? (
                <Button
                  variant="plainBox"
                  className={classNames(styles.button, {
                    [styles.unknownButton]: status === codeStatus.na,
                    [styles.newButton]:
                      status === codeStatus.new ||
                      (status === codeStatus.live && createdToday),
                    [styles.unusedButton]: status === codeStatus.unused,
                    [styles.liveButton]:
                      status === codeStatus.live && !createdToday,
                    [styles.endedButton]: status === codeStatus.ended,
                  })}
                  onPress={() => {
                    if (!reportModuleAvailable) return

                    if (
                      [codeStatus.live, codeStatus.ended].indexOf(status) >
                        -1 &&
                      !(codeStatus.live && createdToday)
                    ) {
                      logAction({
                        variables: {
                          action: 'view-one-click-report-link',
                          functionName: '',
                          pagePath: '/track/view-links',
                          websiteSection: 'track',
                          extra: linkID,
                        },
                      })

                      window.open(
                        `/report/performance?linkID=${linkID}&accountID=${workspaceID}${
                          hideShortLinkMetric ? '&hideShortLinkMetric=true' : ''
                        }`,
                        '_blank',
                      )
                    }
                  }}
                >
                  <Tooltip
                    id={`${linkID}-status-tooltip`}
                    className={styles.statusTooltip}
                    maxWidth={250}
                    tooltipPosition="top"
                    tooltipMessage={
                      status === 'Live' && createdToday
                        ? `Created in the last 30 days, but does not yet have ${
                            isAdobe ? 'Adobe ' : 'Google '
                          }Analytics data.`
                        : `${messages.linkStatus[status]}${
                            reportModuleAvailable &&
                            ((status === codeStatus.live && !createdToday) ||
                              status === codeStatus.ended)
                              ? ' Click to view report.'
                              : ''
                          }`
                    }
                  >
                    {status === 'Live' && createdToday ? 'New' : status}
                  </Tooltip>
                  {reportModuleAvailable &&
                    [codeStatus.live, codeStatus.ended].indexOf(status) > -1 &&
                    !(codeStatus.live && createdToday) && <ReportIcon />}
                </Button>
              ) : (
                ''
              )}
            </>
          )}
        </td>
        {metricData &&
          metricData.map(
            ({ metricValue, metricName, metricID, units, isHidden }) => {
              if (isHidden) return null

              if (loadingStats) {
                return (
                  <td key={`${linkID}-${metricID}`}>
                    <Loader className={styles.loadingText} />
                  </td>
                )
              }

              return (
                <td key={`${linkID}-${metricID}`}>
                  {metricName === 'Short link clickthroughs' && !shortLink
                    ? 'N/A'
                    : formatMetricValue(metricValue || 0, units)}
                </td>
              )
            },
          )}
        {params.map(({ paramID, paramName, paramValue, isHidden }) => {
          // Do not add columns not present in the header
          if (isHidden) return null

          if (loadingInfo) {
            return (
              <td key={`${linkID}-${paramID}`}>
                <Loader className={styles.loadingText} />
              </td>
            )
          }

          return (
            <td key={`${linkID}-${paramID}`}>
              <div className={styles.cellWrapper}>
                <span>{paramValue}</span>
                <div className={styles.cellActions}>
                  {reportModuleAvailable && !!paramValue && (
                    <Button
                      variant="plainBox"
                      className={styles.createParameterReportButton}
                      aria-label="Create report from parameter value"
                      onPress={() => {
                        if (!reportModuleAvailable) return

                        window.open(
                          `/report/performance?parameterID=${paramID}&parameterName=${paramName}&parameterValue=${paramValue}&accountID=${workspaceID}`,
                          '_blank',
                        )

                        logAction({
                          variables: {
                            action: 'view-one-click-report-parameter',
                            functionName: '',
                            pagePath: '/track/view-links',
                            websiteSection: 'track',
                            extra: JSON.stringify({
                              paramID,
                              paramValue,
                            }),
                          },
                        })
                      }}
                    >
                      <Tooltip
                        id={`${linkID}-${paramID}-report-tooltip`}
                        tooltipMessage={`Create a report for all ${paramValue} links.`}
                      >
                        <ReportIcon />
                      </Tooltip>
                    </Button>
                  )}
                  {canEdit && (
                    <Tooltip
                      id={`edit-${linkID}-${paramID}`}
                      tooltipMessage={`Edit this ${paramName}.`}
                      className={styles.editTooltip}
                    >
                      <Button
                        variant="iconOnly"
                        className={styles.editButton}
                        icon={{
                          src: EditIcon,
                          alt: 'Edit',
                          imgHeight: 20,
                        }}
                        onPress={() => {
                          setEditModal({
                            visible: true,
                            paramID,
                            fullLink: link,
                          })

                          logAction({
                            variables: {
                              action: 'open-edit-modal-parameter',
                              functionName: '',
                              pagePath: '/track/view-links',
                              websiteSection: 'track',
                            },
                          })
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            </td>
          )
        })}
        {showUplifterIdColumn && <td>{uplifterIDValue || ''}</td>}
      </tr>
    )
  },
)

const TrackViewTable = () => {
  const { workspaceID, userPermission, userEmail } = useReactiveVar(
    currentUserDetails,
  )

  const dataSource = useReactiveVar(dataSourceReactive)

  const isAdmin = isAdminUser(userPermission)

  const { fullOnboardingSections } = useOnboarding()

  const { availableDomains: availableDeepLinkDomains } = useCustomLinks(
    'appLink',
  )

  const hasCreatedLinks = useMemo(() => {
    if (fullOnboardingSections.user.length === 0) {
      return null
    }

    const hasCreatedLinksSection = fullOnboardingSections.user.find(
      (section) => section.onboardingSectionID === 'createCampaignLink',
    )

    return !!(hasCreatedLinksSection && hasCreatedLinksSection.sectionCompleted)
  }, [fullOnboardingSections])

  const logAction = useLogAction()

  // Should only be updated after the first fetch
  const [totalLinks, setTotalLinks] = useState(0)
  const [currentLinks, setCurrentLinks] = useState(0)
  const [selectedMetrics, setSelectedMetrics] = useState<MetricDropdownItem[]>(
    [],
  )
  const [filter, setFilter] = useState<TrackViewFilterProps>({
    codeIDList: undefined,
    activePage: 1,
    rowsPerPage: 10,
    sortBy: 'createdTime',
    sortDirection: 'DESC',
    searchType: 'any',
    searchTerm: '',
    filterByCurrentUser: true,
  })
  const [selectedLinks, setSelectedLinks] = useState<string[]>([])
  const [editModal, setEditModal] = useState<EditLinkValueModalProps>({
    visible: false,
  })
  const [viewEditHistoryModal, setViewEditHistoryModal] = useState<{
    visible: boolean
    linkID?: string
  }>({ visible: false })
  const [tableHasNoData, setTableHasNoData] = useState(false)
  const [tableRows, setTableRows] = useState<TableLinkWithData[]>([])
  const [shortLinkEditActiveIndex, setShortLinkEditActiveIndex] = useState<
    number | null
  >(null)
  const [createdLinksCheck, setCreatedLinksCheck] = useState(false)
  const [initialFetchComplete, setInitialFetchComplete] = useState(false)
  const [refetchStatusComplete, setRefetchStatusComplete] = useState(true)
  const [metricQueryIsFirst, setMetricQueryIsFirst] = useState(false)

  const { data: accountData } = useQuery(getAvailableModules)
  const { data: generatorData, loading: loadingGenerator } = useQuery(
    getCampaignCodeGenerator,
  )
  const { data: uplifterIdData } = useQuery(getUplifterIDCurrentTotal)

  const reportModuleAvailable = accountData?.currentAccount.reportAvailable

  const [
    fetchMinCodes,
    { data: codesData, loading: loadingCodes, error: minCodesError },
  ] = useLazyQuery(getMinCodesByAccount, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })
  const [
    codeUrlValidation,
    { data: codeValidationData, loading: loadingValidationStatus },
  ] = useLazyQuery(getUrlValidationStatus, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })
  const [
    fetchMetricData,
    {
      data: codesMetricData,
      loading: loadingStatsStatus,
      error: codeMetricsError,
    },
  ] = useLazyQuery(getStoredCodeStats, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  // Check if user has created links before
  // To set initial state of 'Your links' toggle
  useEffect(() => {
    if (!userEmail || hasCreatedLinks === null) {
      return
    }

    const savedState = getUserData()
    const savedYourLinks = savedState?.trackViewYourLinks

    if (typeof savedYourLinks === 'boolean') {
      setFilter((curr) => ({ ...curr, filterByCurrentUser: savedYourLinks }))
    } else if (hasCreatedLinks === false) {
      setFilter((curr) => ({ ...curr, filterByCurrentUser: false }))
    }

    setCreatedLinksCheck(true)
  }, [hasCreatedLinks, userEmail])

  // Initial data fetch - only runs once
  useEffect(() => {
    if (
      !workspaceID ||
      !createdLinksCheck ||
      !userEmail ||
      initialFetchComplete
    ) {
      return
    }

    const initialFetch = async () => {
      const { data } = await fetchMinCodes({
        variables: filterToMinCodesVars(filter),
      })

      if (!data) return

      setTotalLinks(data.minCodesByAccount.totalCodes)
      setCurrentLinks(data.minCodesByAccount.totalCodes)

      if (data.minCodesByAccount.codeID.length === 0) {
        setTableHasNoData(true)
        return
      }

      await codeUrlValidation({
        variables: {
          codeIDList: data.minCodesByAccount.codeID,
        },
      })

      await fetchMetricData({
        variables: {
          codeIDList: data.minCodesByAccount.codeID.filter((c) => !!c),
        },
      })

      setInitialFetchComplete(true)
    }

    initialFetch()
  }, [workspaceID, createdLinksCheck, filter, initialFetchComplete])

  const refetchData = useCallback(
    async (
      queryVars: TrackViewFilterProps,
      { recalculateTotals = false }: RefetchOptions = {
        recalculateTotals: false,
      },
    ) => {
      setTableRows([])
      setTableHasNoData(false)
      setSelectedLinks([])
      setRefetchStatusComplete(false)

      const variables = filterToMinCodesVars(queryVars)

      // Fetching stats first is based on the sortBy value
      if (queryVars.isMetric) {
        setMetricQueryIsFirst(true)

        const { data } = await fetchMetricData({ variables })

        if (!data) return

        if (recalculateTotals) {
          setTotalLinks(data.storedCodeStats.totalCodes)
        }

        setCurrentLinks(data.storedCodeStats.totalCodes)

        const codeIDList = data.storedCodeStats.codeIDs.filter((c) => !!c)

        if (codeIDList.length === 0) {
          setTableHasNoData(true)
          return
        }

        await codeUrlValidation({
          variables: {
            codeIDList,
          },
        })

        await fetchMinCodes({
          variables: {
            codeIDList,
            limit: variables.limit,
            offset: undefined,
            orderBy: undefined,
            dimensionFilter: undefined,
          },
        })

        setRefetchStatusComplete(true)

        return
      }

      setMetricQueryIsFirst(false)

      const { data } = await fetchMinCodes({ variables })

      if (!data) return

      if (recalculateTotals) {
        setTotalLinks(data.minCodesByAccount.totalCodes)
      }

      setCurrentLinks(data.minCodesByAccount.totalCodes)

      const codeIDList = data.minCodesByAccount.codeID.filter((c) => !!c)

      if (codeIDList.length === 0) {
        setTableHasNoData(true)
        return
      }

      await codeUrlValidation({
        variables: {
          codeIDList,
        },
      })

      await fetchMetricData({
        variables: {
          codeIDList: data.minCodesByAccount.codeID.filter((c) => !!c),
          limit: variables.limit,
          offset: undefined,
          orderBy: undefined,
          dimensionFilter: undefined,
        },
      })

      setRefetchStatusComplete(true)
    },
    [codesData, codesMetricData, codeValidationData],
  )

  // Filters out parameters that aren't available to the current workspace
  const params = getWorkspaceParams(workspaceID, generatorData)

  // Full list of columns the data can be searched on
  const searchTypeList = buildSearchTypeList(params)

  const showUplifterIdColumn = !!(
    uplifterIdData && uplifterIdData.track.currentSequentialCodeID.isEnabled
  )

  const tableHeaderColumns: HeaderColumnProps[] = useMemo(() => {
    return buildTableHeaders({ params, selectedMetrics })
  }, [selectedMetrics, params])

  useEffect(() => {
    if (tableHasNoData) {
      setTableRows([])
      return
    }

    const _tableRows = buildTableLinks({
      params,
      minCodes: codesData?.minCodesByAccount,
      codeStats: codesMetricData?.storedCodeStats,
      codeValidationData: codeValidationData?.track.trackValidationResults,
      selectedMetrics,
      masterPrefix: generatorData?.campaignCodeGenerator.masterPrefix,
      uplifterIdPrefix:
        uplifterIdData?.track.currentSequentialCodeID.prefix || undefined,
      availableDeepLinkDomains,
    })

    setTableRows(_tableRows)
  }, [
    availableDeepLinkDomains,
    codesData,
    codesMetricData,
    uplifterIdData,
    codeValidationData,
    generatorData,
    tableHasNoData,
    selectedMetrics,
    refetchStatusComplete,
  ])

  const loadingTableData =
    loadingGenerator ||
    loadingCodes ||
    loadingValidationStatus ||
    loadingStatsStatus

  return (
    <>
      <HeaderPanel className={styles.headerPanel}>
        <Panel>
          <TrackViewSearch
            filter={filter}
            setFilter={setFilter}
            loading={!createdLinksCheck || loadingCodes}
            // Takes longer but waits for metric data too
            // loading={loadingTableData}
            searchTypeList={searchTypeList}
            totalLinks={totalLinks}
            currentLinks={currentLinks}
            refetchData={refetchData}
          />
        </Panel>
        <Panel className={styles.tableActions}>
          <div className={styles.switchContainer}>
            <p className={styles.toggleLabel}>
              <Tooltip
                id="your-links-tooltip"
                useIcon
                tooltipMessage={
                  <>
                    <p>
                      <strong>Your links:</strong> Only show links created by
                      you.
                    </p>
                    <p>
                      <strong>All links:</strong> Show links created by everyone
                      in this workspace.
                    </p>
                  </>
                }
              >
                {filter.filterByCurrentUser ? 'Your' : 'All'} links
              </Tooltip>
            </p>
            <ReactSwitch
              checked={!!filter.filterByCurrentUser}
              onChange={async () => {
                const newValue = !filter.filterByCurrentUser

                const newFilter = {
                  ...filter,
                  activePage: 1,
                  sortBy: filter.bidirectionalSortKey || filter.sortBy,
                  filterByCurrentUser: newValue,
                }

                setFilter(newFilter)

                // Refetch
                await refetchData(newFilter, { recalculateTotals: !newValue })

                logAction({
                  variables: {
                    action: 'track-view-toggle-your-links',
                    pagePath: '/track/view-links',
                    functionName: 'yourLinksToggle',
                    websiteSection: 'track',
                    extra: newValue ? 'Filter by user' : 'All users',
                  },
                })

                saveUserData({ trackViewYourLinks: newValue })
              }}
              onColor="#e61969"
              offColor="#718096"
              width={70}
              height={30}
              checkedIcon={<PersonIcon className={styles.toggleIcon} />}
              uncheckedIcon={<PeopleIcon className={styles.toggleIcon} />}
            />
          </div>
          <TrackViewMetrics
            codesMetricData={codesMetricData}
            loading={loadingCodes || loadingStatsStatus}
            selectedMetrics={selectedMetrics}
            setSelectedMetrics={setSelectedMetrics}
          />
          <TrackViewActions
            selectedLinks={selectedLinks}
            filter={filter}
            tableRows={tableRows}
            loadingLinks={loadingCodes}
            loadingStats={loadingStatsStatus}
            refetchData={() => refetchData(filter)}
            workspaceID={workspaceID}
            masterPrefix={generatorData?.campaignCodeGenerator.masterPrefix}
            paramDefs={params}
          />
        </Panel>
      </HeaderPanel>
      <FullPageTable
        tableClassName={classNames(styles.linksTable, {
          [styles.loadingTable]:
            loadingTableData || (tableRows.length === 0 && !tableHasNoData),
        })}
        loadingStatus={
          loadingTableData || (tableRows.length === 0 && !tableHasNoData)
        }
        loadingColCount={
          // Checkbox
          1 + tableHeaderColumns.length + (showUplifterIdColumn ? 1 : 0)
        }
        noDataFound={
          tableHasNoData ||
          !!minCodesError ||
          (metricQueryIsFirst && !!codeMetricsError)
        }
        noDataMsg={
          // Show different message if current user hasn't created links
          filter.filterByCurrentUser && !filter.searchTerm ? (
            <p style={{ margin: 0 }}>
              No links created.{' '}
              <Link type="arrowForward" href="/track/create" newTab={false}>
                Create your first link
              </Link>
            </p>
          ) : (
            <p style={{ margin: 0 }}>
              No links for this search.{' '}
              <Link
                type="arrowForward"
                href="https://support.uplifter.ai/hc/en-us/articles/19342815392029-Why-can-t-I-see-any-data-in-my-reports-graphs-tables"
              >
                Get support
              </Link>
            </p>
          )
        }
        paginationData={{
          totalRows: currentLinks,
          rowsPerPage: filter.rowsPerPage,
          pages: Math.ceil(currentLinks / filter.rowsPerPage),
          activePage: filter.activePage,
        }}
        onPageChange={(newPage) => {
          setFilter((curr) => ({
            ...curr,
            activePage: newPage,
          }))

          refetchData({
            ...filter,
            activePage: newPage,
            sortBy: filter.bidirectionalSortKey || filter.sortBy,
          })
        }}
        onRowsChange={(newRows) => {
          setFilter((curr) => ({
            ...curr,
            rowsPerPage: newRows,
            activePage: 1,
          }))

          refetchData({
            ...filter,
            rowsPerPage: newRows,
            activePage: 1,
            sortBy: filter.bidirectionalSortKey || filter.sortBy,
          })
        }}
        headerRow={
          <TrackViewHeader
            tableHeaderColumns={tableHeaderColumns}
            allLinksSelected={selectedLinks.length === filter.rowsPerPage}
            setAllLinksSelected={(checked) => {
              setSelectedLinks(
                checked ? tableRows.map(({ linkID }) => linkID) : [],
              )
            }}
            sortBy={filter.bidirectionalSortKey || filter.sortBy}
            orderAsc={filter.sortDirection === 'ASC'}
            refetchData={({ sortBy, bidirectionalSortKey, isMetric }) => {
              const newFilter: TrackViewFilterProps = {
                ...filter,
                isMetric,
                activePage: 1,
                sortDirection:
                  (sortBy === filter.sortBy &&
                    filter.sortDirection === 'DESC') ||
                  bidirectionalSortKey
                    ? 'ASC'
                    : 'DESC',
              }

              if (sortBy !== filter.sortBy) {
                newFilter.sortBy = sortBy
              }

              setFilter({
                ...newFilter,
                bidirectionalSortKey: bidirectionalSortKey || undefined,
              })

              refetchData({
                ...newFilter,
                sortBy: bidirectionalSortKey || sortBy,
              })
            }}
            showUplifterIdColumn={showUplifterIdColumn}
          />
        }
      >
        <tbody>
          {tableRows.map((link, linkIndex) => {
            return (
              <TrackViewLinkRow
                key={link.linkID}
                link={link}
                linkIndex={linkIndex}
                isSelected={selectedLinks.indexOf(link.linkID) > -1}
                setIsSelected={(checked) => {
                  setSelectedLinks((currSelectedLinks) => {
                    if (checked) {
                      return [...currSelectedLinks, link.linkID]
                    }

                    const newSelectedLinks = [...currSelectedLinks]
                    const selectedLinkIndex = newSelectedLinks.indexOf(
                      link.linkID,
                    )

                    if (selectedLinkIndex !== -1) {
                      newSelectedLinks.splice(selectedLinkIndex, 1)
                    }

                    return newSelectedLinks
                  })
                }}
                loadingInfo={loadingCodes}
                loadingStats={loadingStatsStatus}
                showUplifterIdColumn={showUplifterIdColumn}
                canEdit={
                  isAdmin ||
                  userEmail.toLowerCase() ===
                    link.createdInfo.author.toLowerCase()
                }
                setEditModal={setEditModal}
                setViewEditHistoryModal={setViewEditHistoryModal}
                logAction={logAction}
                workspaceID={workspaceID}
                shortLinkEditActiveIndex={shortLinkEditActiveIndex}
                setShortLinkEditActiveIndex={setShortLinkEditActiveIndex}
                isAdobe={
                  !!dataSource && dataSource.connectionSource === 'adobe'
                }
                reportModuleAvailable={reportModuleAvailable}
              />
            )
          })}
        </tbody>
      </FullPageTable>
      {editModal.visible && (
        <>
          {editModal.paramID ? (
            <EditLinkParameterModal
              paramID={editModal.paramID}
              linkToEdit={editModal.fullLink}
              onToggle={async (refetch = false) => {
                setEditModal({ visible: false })

                if (refetch) {
                  await refetchData(filter)
                }
              }}
            />
          ) : (
            <EditLinkUrlModal
              linkToEdit={editModal.fullLink}
              onToggle={async (refetch = false) => {
                setEditModal({ visible: false })

                if (refetch) {
                  await refetchData(filter)
                }
              }}
              masterPrefix={generatorData?.campaignCodeGenerator.masterPrefix}
              uplifterIdData={uplifterIdData}
            />
          )}
        </>
      )}
      {viewEditHistoryModal.visible && (
        <EditHistoryModal
          onToggle={() => setViewEditHistoryModal({ visible: false })}
          linkID={viewEditHistoryModal.linkID}
        />
      )}
    </>
  )
}

export default TrackViewTable
