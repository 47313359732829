import React, {
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from 'react'
import { useReactiveVar } from '@apollo/client'
import moment from 'moment'
import { nanoid } from 'nanoid'
import _ from 'lodash'

import StyledDatePicker from './date-picker'
import Input, { ClickEditInput } from './input'
import Pagination from './pagination'
import SelectBox from './select-box'
import { CustomLinkAlias } from './custom-link-fields'
import Table from './table'
import TopScrollbar from './top-scrollbar'
import {
  currentUserDetails,
  customLinkAliasesByDomain,
} from '../api/apollo/variables'
import { CampaignCodeGeneratorStructure, GeneratorFields } from '../api/types'
import { hashCode, paginateData, prepareInput } from '../helpers'
import { generateCode, GeneratedCode } from '../helpers/track-create'
import {
  filterList,
  FullValidationCheck,
  getAnchorFromString,
  getParentValue,
  isFieldAChild,
  minBatchShortLinks,
  ParentSelectFields,
} from '../helpers/track-module'
import useCustomLinks from '../hooks/useCustomLinks'
import styles from '../styles/campaign-code-generator.module.scss'

interface EmailPreviewTableProps {
  form: CampaignCodeGeneratorStructure | null
  validationChecks: FullValidationCheck[]
  url?: string[]
  emailLinks: GeneratedCode[]
  setEmailLinks: Dispatch<SetStateAction<GeneratedCode[]>>
  linkType: string
  previewTableShortLinks: { [code: string]: string }
  setPreviewTableShortLinks: Dispatch<
    SetStateAction<{ [code: string]: string }>
  >
  setShortLinkStatus: Dispatch<SetStateAction<UrlStatus>>
}

export default function EmailPreviewTable({
  form,
  validationChecks,
  url = [],
  linkType,
  emailLinks,
  setEmailLinks,
  previewTableShortLinks,
  setPreviewTableShortLinks,
  setShortLinkStatus,
}: EmailPreviewTableProps): React.ReactElement | null {
  const { workspaceID } = useReactiveVar(currentUserDetails)
  const aliasesByDomain = useReactiveVar(customLinkAliasesByDomain)

  const {
    selectedDomain,
    fetchNewAliases,
    fetchingAliases,
    replaceAlias,
    replaceBatchAlias,
  } = useCustomLinks()

  const [useBatch, setUseBatch] = useState(false)

  // Fetch required number of new aliases
  useEffect(() => {
    if (
      !(linkType === 'short' || linkType === 'combined') ||
      !aliasesByDomain[selectedDomain]
    )
      return

    const newPreviewSLs = {}

    if (emailLinks.length >= minBatchShortLinks) {
      setUseBatch(true)
      // Batch alias already exists - use that
      if (aliasesByDomain[selectedDomain].batch) {
        emailLinks.forEach(({ code }) => {
          newPreviewSLs[code] = aliasesByDomain[selectedDomain].batch
        })

        // Set all privew SLs to be the same - they won't be shown
        setPreviewTableShortLinks(newPreviewSLs)
        return
      }

      // Will update the batch alias only
      fetchNewAliases(emailLinks.length)

      return
    }

    setUseBatch(false)

    emailLinks.forEach(({ code }, index) => {
      newPreviewSLs[code] =
        aliasesByDomain[selectedDomain].individual[index] || ''
    })
    setPreviewTableShortLinks(newPreviewSLs)

    const existingAliasesCount =
      aliasesByDomain[selectedDomain].individual.length

    if (existingAliasesCount >= emailLinks.length) return

    fetchNewAliases(emailLinks.length - existingAliasesCount)
  }, [aliasesByDomain])

  const [activePage, setActivePage] = useState(1)
  const [pages, setPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [allChecked, setAllChecked] = useState(true)

  const [formPerRow, setFormPerRow] = useState(
    Array.from(emailLinks, (item) => form),
  )

  const scrollRef = useRef<HTMLDivElement>(null)
  const ref = useRef(null)

  const paginatedData = useMemo(() => {
    setPages(Math.ceil(emailLinks.length / rowsPerPage))

    return paginateData(emailLinks, rowsPerPage)
  }, [emailLinks, rowsPerPage])

  if (
    !form ||
    emailLinks.length === 0 ||
    (linkType === 'short' && !aliasesByDomain[selectedDomain])
  )
    return null

  return (
    <>
      {useBatch && (
        <div className={styles.batchShortLinkRow}>
          <p>
            Your short links will be built once you click 'Create campaign
            links'. They will all start with the following pattern:
          </p>
          <div>
            <CustomLinkAlias
              domainID={selectedDomain}
              fetchingAliases={fetchingAliases}
              alias={aliasesByDomain[selectedDomain].batch || ''}
              nLinks={emailLinks.length}
              replaceBatchAlias={replaceBatchAlias}
              canUseCustom={false}
              setCustomLinkStatus={setShortLinkStatus}
            />
          </div>
        </div>
      )}
      <div className={styles.tableContainer}>
        <TopScrollbar ref={scrollRef}>
          <Table ref={ref} className={styles.table}>
            <thead>
              <tr>
                <th className={styles.checkboxCell}>
                  <Input
                    type="checkbox"
                    id="allNone"
                    name="allNone"
                    checked={allChecked}
                    className={styles.selectItem}
                    label=" "
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      const { checked } = e.target as HTMLInputElement

                      setAllChecked(checked)

                      const setCodes = emailLinks.map((item) => {
                        return {
                          ...item,
                          selected: checked,
                        }
                      })

                      setEmailLinks(setCodes)
                    }}
                  />
                </th>
                {linkType === 'short' && !useBatch && (
                  <th className={styles.shortLinkCell}>Short link</th>
                )}
                {url.length > 0 && (
                  <th key={nanoid()} className={styles.otherColumn}>
                    Original link
                  </th>
                )}
                {form &&
                  form.paramDefs.map(
                    (
                      field: GeneratorFields,
                      index: number,
                    ): React.ReactElement | null => {
                      const showCol = !emailLinks.every((code) => {
                        return code.pDfs[index][0] === ''
                      })

                      if (!showCol) {
                        return null
                      }

                      return (
                        <th key={nanoid()} className={styles.otherColumn}>
                          {field.fieldName}
                          {field.metaParameter ? (
                            <b className={styles.optional}> (meta)</b>
                          ) : (
                            ''
                          )}
                        </th>
                      )
                    },
                  )}
              </tr>
            </thead>
            <tbody>
              {paginatedData.length > 0 &&
                paginatedData[activePage - 1] &&
                paginatedData[activePage - 1].map(
                  (field: GeneratedCode, index: number) => {
                    // Index is based on pagination, not actual index in links
                    const paginatedIndex =
                      (activePage - 1) * rowsPerPage + index

                    const useAnchor = getAnchorFromString(field.tC)

                    return (
                      <tr
                        key={JSON.stringify(field)}
                        className={styles.otherColumn}
                      >
                        <td className={styles.checkboxCell}>
                          <Input
                            type="checkbox"
                            id={nanoid()}
                            name={nanoid()}
                            checked={field.selected}
                            className={styles.selectItem}
                            label=" "
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ): void => {
                              const { checked } = e.target as HTMLInputElement

                              const setCodes = emailLinks.map((item) => {
                                if (field.code === item.code) {
                                  return {
                                    ...item,
                                    selected: checked,
                                  }
                                }
                                return item
                              })

                              setAllChecked(
                                setCodes.every((code) => code.selected),
                              )

                              setEmailLinks(setCodes)
                            }}
                          />
                        </td>
                        {linkType === 'short' && !useBatch && (
                          <td className={styles.shortLinkCell}>
                            <CustomLinkAlias
                              domainID={selectedDomain}
                              fetchingAliases={fetchingAliases}
                              alias={previewTableShortLinks[field.code] || ''}
                              index={aliasesByDomain[
                                selectedDomain
                              ].individual.indexOf(
                                previewTableShortLinks[field.code],
                              )}
                              replaceAlias={replaceAlias}
                              canUseCustom={false}
                              setCustomLinkStatus={setShortLinkStatus}
                            />
                          </td>
                        )}
                        {url.length > 0 && (
                          <td className={styles.urlCol}>
                            <span>
                              {field.url}
                              {useAnchor}
                            </span>
                          </td>
                        )}
                        {field.pDfs.map((item, paramIndex) => {
                          const showCol = !emailLinks.every((code) => {
                            return code.pDfs[paramIndex][0] === ''
                          })

                          if (!showCol) return null

                          const fullParam = formPerRow[
                            paginatedIndex
                          ]?.paramDefs.find(
                            (param) => param.fieldID === item[3],
                          )

                          if (
                            !fullParam ||
                            (fullParam.copyFromField &&
                              fullParam.copyFromField.length > 0) ||
                            fullParam.fieldType === 'fixed'
                          )
                            return <td key={nanoid()}>{item[0]}</td>

                          if (
                            fullParam.fieldType === 'select' &&
                            fullParam.selectFields
                          ) {
                            const list = filterList(
                              formPerRow[
                                paginatedIndex
                              ] as CampaignCodeGeneratorStructure,
                              fullParam,
                              workspaceID,
                              true,
                            )

                            const isChild = isFieldAChild(fullParam)

                            let selectValue = item[4] || ''

                            let parentValue: ParentSelectFields[] = []

                            if (isChild) {
                              parentValue = getParentValue(
                                formPerRow[
                                  paginatedIndex
                                ] as CampaignCodeGeneratorStructure,
                                fullParam,
                              )

                              if (parentValue.length > 0) {
                                const alwaysShownOptions = fullParam.selectFields
                                  ? fullParam.selectFields
                                      .filter(
                                        (selectField) =>
                                          !selectField.optionFilter ||
                                          selectField.optionFilter[0]
                                            .parentOptionIDs.length === 0,
                                      )
                                      .map(
                                        (selectField) => selectField.optionID,
                                      )
                                  : []

                                if (
                                  alwaysShownOptions.length === 1 &&
                                  selectValue === ''
                                ) {
                                  // eslint-disable-next-line prefer-destructuring
                                  selectValue = alwaysShownOptions[0]
                                }
                              }
                            }

                            const options = list
                              .concat()
                              .sort((p, n) =>
                                p.optionName > n.optionName ? 1 : -1,
                              )

                            return (
                              <td
                                style={{
                                  minWidth: 160,
                                }}
                                key={`${fullParam.fieldID}${JSON.stringify(
                                  list,
                                )}-${JSON.stringify(parentValue)}`}
                              >
                                <SelectBox
                                  id={`select-${hashCode(fullParam.fieldID)}`}
                                  className={styles.modalDropdown}
                                  menuPlacement="auto"
                                  labelKey="optionName"
                                  valueKey="optionID"
                                  value={options.find(
                                    (option) => option.optionID === selectValue,
                                  )}
                                  options={options}
                                  onChange={(newValue) => {
                                    if (newValue) {
                                      const updatedForm = _.cloneDeep(
                                        formPerRow[
                                          paginatedIndex
                                        ] as CampaignCodeGeneratorStructure,
                                      )

                                      const paramToChange = updatedForm.paramDefs.find(
                                        (param) =>
                                          param.fieldID === fullParam.fieldID,
                                      )

                                      if (
                                        paramToChange &&
                                        paramToChange.optionValue &&
                                        paramToChange.optionValue.length > 0
                                      ) {
                                        paramToChange.optionValue.splice(
                                          0,
                                          1,
                                          newValue.optionID,
                                        )

                                        // Update the form structure for this link only to deal with parent-child
                                        setFormPerRow((curr) => {
                                          const newFormPerRow = _.cloneDeep(
                                            curr,
                                          )

                                          newFormPerRow.splice(
                                            paginatedIndex,
                                            1,
                                            updatedForm,
                                          )

                                          return newFormPerRow
                                        })

                                        const newCode = generateCode(
                                          [field.urlWithHash || field.url],
                                          updatedForm,
                                        )[0]

                                        const currCode =
                                          emailLinks[paginatedIndex].code

                                        // Reset short link to correct new code
                                        if (fullParam.metaParameter !== true) {
                                          setPreviewTableShortLinks((curr) => {
                                            const newCodesWithShortLinks = {
                                              ...curr,
                                            }

                                            newCodesWithShortLinks[
                                              newCode.code
                                            ] = newCodesWithShortLinks[currCode]

                                            delete newCodesWithShortLinks[
                                              currCode
                                            ]

                                            return newCodesWithShortLinks
                                          })
                                        }

                                        setEmailLinks((curr) => {
                                          const newEmailLinks = _.cloneDeep(
                                            curr,
                                          )

                                          newEmailLinks.splice(
                                            paginatedIndex,
                                            1,
                                            newCode,
                                          )

                                          return newEmailLinks
                                        })
                                      }
                                    }
                                  }}
                                />
                              </td>
                            )
                          }

                          if (
                            fullParam.fieldType === 'date' &&
                            fullParam.dateFormat
                          ) {
                            const dateValue =
                              item[0] !== ''
                                ? moment(item[0], fullParam.dateFormat).toDate()
                                : null

                            const useDateFormat = fullParam.dateFormat
                              .replace(/Y/gi, 'y')
                              .replace(/D/gi, 'd')
                              .replace(/(\[Q\])/gi, 'QQ')

                            return (
                              <td
                                style={{
                                  minWidth: 160,
                                }}
                                key={fullParam.fieldID}
                              >
                                <StyledDatePicker
                                  wrapperClassName={styles.dateWrapper}
                                  className={styles.dateInput}
                                  dateFormat={useDateFormat}
                                  isClearable={false}
                                  showYearPicker={
                                    fullParam.dateFormat.toLowerCase() ===
                                    'yyyy'
                                  }
                                  showMonthYearPicker={
                                    fullParam.dateFormat.toLowerCase() ===
                                    'yyyymm'
                                  }
                                  showQuarterYearPicker={
                                    fullParam.dateFormat.toLowerCase() ===
                                    'yyyy[q]q'
                                  }
                                  selected={dateValue}
                                  onChange={(date): void => {
                                    let value = ''

                                    if (date !== null && fullParam.dateFormat) {
                                      const dateF = moment(
                                        date.toString(),
                                      ).format(fullParam.dateFormat)
                                      value = dateF
                                    }

                                    const updatedForm = _.cloneDeep(
                                      formPerRow[
                                        paginatedIndex
                                      ] as CampaignCodeGeneratorStructure,
                                    )

                                    const paramToChange = updatedForm.paramDefs.find(
                                      (param) =>
                                        param.fieldID === fullParam.fieldID,
                                    )

                                    if (
                                      paramToChange &&
                                      paramToChange.optionValue &&
                                      paramToChange.optionValue.length > 0
                                    ) {
                                      paramToChange.optionValue.splice(
                                        0,
                                        1,
                                        value,
                                      )

                                      const newCode = generateCode(
                                        [field.urlWithHash || field.url],
                                        updatedForm,
                                      )[0]

                                      const currCode =
                                        emailLinks[paginatedIndex].code

                                      // Reset short link to correct new code
                                      if (fullParam.metaParameter !== true) {
                                        setPreviewTableShortLinks((curr) => {
                                          const newCodesWithShortLinks = {
                                            ...curr,
                                          }

                                          newCodesWithShortLinks[newCode.code] =
                                            newCodesWithShortLinks[currCode]

                                          delete newCodesWithShortLinks[
                                            currCode
                                          ]

                                          return newCodesWithShortLinks
                                        })
                                      }

                                      setEmailLinks((curr) => {
                                        const newEmailLinks = _.cloneDeep(curr)

                                        newEmailLinks.splice(
                                          paginatedIndex,
                                          1,
                                          newCode,
                                        )

                                        return newEmailLinks
                                      })
                                    }
                                  }}
                                />
                              </td>
                            )
                          }

                          return (
                            <td key={fullParam.fieldID}>
                              <ClickEditInput
                                id={fullParam.fieldID}
                                name={fullParam.fieldName}
                                value={item[0]}
                                beforeChange={(inputValue) => {
                                  if (fullParam.metaParameter !== true) {
                                    return prepareInput(
                                      inputValue,
                                      validationChecks,
                                    )
                                  }

                                  return inputValue
                                }}
                                onChange={(value) => {
                                  // Update value for this entry (pDf for indexes 0 and 1)
                                  const updatedForm = _.cloneDeep(
                                    formPerRow[
                                      paginatedIndex
                                    ] as CampaignCodeGeneratorStructure,
                                  )

                                  const paramToChange = updatedForm.paramDefs.find(
                                    (param) =>
                                      param.fieldID === fullParam.fieldID,
                                  )

                                  if (
                                    paramToChange &&
                                    paramToChange.optionValue &&
                                    paramToChange.optionValue.length > 0
                                  ) {
                                    paramToChange.optionValue.splice(
                                      0,
                                      1,
                                      value,
                                    )

                                    const newCode = generateCode(
                                      [field.urlWithHash || field.url],
                                      updatedForm,
                                    )[0]

                                    const currCode =
                                      emailLinks[paginatedIndex].code

                                    // Reset short link to correct new code
                                    if (fullParam.metaParameter !== true) {
                                      setPreviewTableShortLinks((curr) => {
                                        const newCodesWithShortLinks = {
                                          ...curr,
                                        }

                                        newCodesWithShortLinks[newCode.code] =
                                          newCodesWithShortLinks[currCode]

                                        delete newCodesWithShortLinks[currCode]

                                        return newCodesWithShortLinks
                                      })
                                    }

                                    setEmailLinks((curr) => {
                                      const newEmailLinks = _.cloneDeep(curr)

                                      newEmailLinks.splice(
                                        paginatedIndex,
                                        1,
                                        newCode,
                                      )

                                      return newEmailLinks
                                    })
                                  }
                                }}
                              />
                            </td>
                          )
                        })}
                      </tr>
                    )
                  },
                )}
            </tbody>
          </Table>
        </TopScrollbar>
      </div>
      <Pagination
        pages={pages}
        activePage={activePage}
        onChange={(index) => setActivePage(index)}
        rowsPerPageData={{
          rowsPerPage,
          maxRowsPerPage: 50,
          totalRows: emailLinks.length,
          onChange: (newRowsPerPage) => {
            setRowsPerPage(newRowsPerPage)
            setActivePage(1)
          },
        }}
      />
    </>
  )
}
