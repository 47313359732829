import _ from 'lodash'
import moment from 'moment'
// eslint-disable-next-line import/no-unresolved
import { RelationType } from 'react-archer/lib/types'

import { brandName, dateFormatShort } from '../core/constants'
import { getItemByKeyValue } from '.'
import {
  CardConnectionsRefItem,
  DashboardGraphData,
  Granularity,
  GranularitySelectOptions,
  ReportDate,
} from '../types/report-module'
import { GetAccountDataSourceQuery } from '../__gql-types__/graphql'

export const today = new Date(Date.now())
export const yesterday = new Date(Date.now())
yesterday.setDate(yesterday.getDate() - 1)

export const getQuarter = (dateS?: string) => {
  if (dateS) {
    const d = moment(dateS, 'YYYY-MM-DD')
    const month = d.month() + 1
    const q = Math.ceil(month / 3)
    return `Q${q} ${d.format('YYYY')}`
  }
  return ''
}

export const reportDates: ReportDate[] = [
  {
    displayName: 'custom date range',
    metricValue: 'custom',
    label: 'custom',
    granularity: 'daily',
  },
  {
    displayName: `this month (${moment(today).format('MMMM YYYY')})`,
    metricValue: 'thisMonth',
    label: 'this month',
    granularity: 'daily',
  },
  {
    displayName: `last month (${moment(today)
      .subtract(1, 'month')
      .format('MMMM YYYY')})`,
    metricValue: 'lastMonth',
    label: 'the last month',
    granularity: 'daily',
  },
  {
    displayName: `this quarter (${getQuarter(
      moment(today).format('YYYY-MM-DD'),
    )})`,
    metricValue: 'thisQuarter',
    label: 'this quarter',
    granularity: 'weekly',
  },
  {
    displayName: `last quarter (${getQuarter(
      moment(today).subtract(1, 'quarter').format('YYYY-MM-DD'),
    )})`,
    metricValue: 'lastQuarter',
    label: 'the last quarter',
    granularity: 'weekly',
  },
  {
    displayName: `this year to date (${moment(today).format('YYYY')})`,
    metricValue: 'ytd',
    label: 'the 1st January this year',
    granularity: 'monthly',
  },
  {
    displayName: `last year (${moment(today)
      .subtract(1, 'year')
      .format('YYYY')})`,
    metricValue: 'lastYear',
    label: 'the last full calendar year',
    granularity: 'monthly',
  },
  {
    displayName: 'last 7 full days',
    metricValue: 'l7d',
    label: 'the last 7 days',
    granularity: 'daily',
  },
  {
    displayName: 'last 30 full days',
    metricValue: 'l30d',
    label: 'the last 30 days',
    granularity: 'daily',
  },
  {
    displayName: 'last 60 full days',
    metricValue: 'l60d',
    label: 'the last 60 days',
    granularity: 'weekly',
  },
  {
    displayName: 'last 90 full days',
    metricValue: 'l90d',
    label: 'the last 90 days',
    granularity: 'weekly',
  },
  {
    displayName: 'last 365 full days',
    metricValue: 'thisYear',
    label: 'the last 12 months',
    granularity: 'monthly',
  },
]

export const granularityFilterData: GranularitySelectOptions[] = [
  { name: 'Month', value: 'monthly' },
  { name: 'Quarter', value: 'quarterly' },
  { name: 'Week', value: 'weekly' },
  { name: 'Day', value: 'daily' },
]

// For lost links report
export const paramNameMapper = {
  // GA4 names
  sessionSource: 'utm_source',
  sessionMedium: 'utm_medium',
  sessionCampaignName: 'utm_campaign',
  sessionManualTerm: 'utm_term',
  sessionManualAdContent: 'utm_content',
  sessionCampaignId: 'utm_id',
  marketingTactic: 'utm_marketing_tactic',
  creativeFormat: 'utm_creative_format',
  sourcePlatform: 'utm_source_platform',
  // UA names
  fullSource: 'utm_source',
  fullMedium: 'utm_medium',
  fullCampaign: 'utm_campaign',
  fullTerm: 'utm_term',
  fullContent: 'utm_content',
}

export const metricNameMapper = {
  sessions: 'Sessions',
  'Total not in uplifter': 'Other link sessions',
  'Total in uplifter': `${brandName} link sessions`,
}

export const setGranularityByDateRange = (
  startDate: Date,
  endDate: Date,
): Granularity => {
  const daysDifference = Math.round(
    (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24),
  )

  switch (true) {
    // 2 years
    case daysDifference > 730:
      return 'quarterly'
    // 3 months
    case daysDifference > 90:
      return 'monthly'
    // 1 month
    case daysDifference > 30:
      return 'weekly'
    default:
      return 'daily'
  }
}

export const getDateRangeLabel = (startDate?: string, endDate?: string) => {
  if (!startDate) return ''

  const currentDateItem = getItemByKeyValue(
    reportDates,
    'metricValue',
    startDate,
  )

  if (currentDateItem && currentDateItem.label && currentDateItem.metricValue) {
    return ` over ${currentDateItem.label}`
  }

  const from = moment(startDate).format(dateFormatShort)

  if (!endDate) return `from ${from}`

  const to = moment(endDate).format(dateFormatShort)

  return ` from ${from} to ${to}`
}

export const getDataSourceDescription = (
  dataSourceData?: GetAccountDataSourceQuery,
  bold = true,
) => {
  if (!dataSourceData || !dataSourceData.currentAccount.dataSource) return ''

  const metricsFrom = bold ? '**Metrics from:**' : 'Metrics from:'
  const notConnected = bold ? '*(Not connected)*' : '(Not connected)'

  if (!dataSourceData.currentAccount.dataSource.connected) {
    return `${metricsFrom} ${notConnected}`
  }

  const {
    adobeReportSuiteName,
    adobeSegmentName,
    ga4PropertyName,
    gaPropertyName,
    gaViewName,
  } = dataSourceData.currentAccount.dataSource

  let formattedName = bold ? '**TBC**' : 'TBC'

  switch (dataSourceData.currentAccount.dataSource.kind) {
    case 'GA_VIEW':
      formattedName = formattedName.replace('TBC', gaPropertyName as string)
      return `${metricsFrom} Google Analytics (UA), property: ${formattedName}, view: ${
        bold ? `**${gaViewName}**` : gaViewName
      }`
    case 'GA4_PROP':
      formattedName = formattedName.replace('TBC', ga4PropertyName as string)
      return `${metricsFrom} Google Analytics (GA4), property: ${formattedName}`
    case 'AA_REPORT':
      formattedName = formattedName.replace(
        'TBC',
        adobeReportSuiteName as string,
      )
      return `Report suite: ${formattedName}${
        adobeSegmentName
          ? ` ${
              bold
                ? `*(segment applied: ${adobeSegmentName})*`
                : `(segment applied: ${adobeSegmentName})`
            }`
          : ''
      }`
    default:
      return `${metricsFrom} ${notConnected}`
  }
}

/** Used to reduce data window for performance report graph.
 * It removes all data columns (x-axis) that have no data from the start and end of the full data set.
 * Preserves all central data, including zero values.
 */
export const stripZeroValuesFromGraphData = ({
  graphDateRange,
  graphData,
}: {
  graphDateRange: string[]
  graphData: DashboardGraphData[]
}): { graphDateRange: string[]; graphData: DashboardGraphData[] } => {
  // Initialise condensed data set
  const _graphDateRange: string[] = []
  const _graphData = graphData.reduce((acc, curr) => {
    acc.push({
      dimensionName: curr.dimensionName,
      dimensionValues: [],
    })

    return acc
  }, [] as DashboardGraphData[])

  let prevDatesHaveData = false

  // Forward pass - strip start dates
  graphDateRange.forEach((date, dateIndex) => {
    let dateHasData = false
    let dimIndex = 0

    while (dimIndex < graphData.length) {
      // 0 is falsy
      if (graphData[dimIndex].dimensionValues[dateIndex]) {
        dateHasData = true
        prevDatesHaveData = true
        break
      }

      dimIndex += 1
    }

    if (dateHasData || prevDatesHaveData) {
      _graphDateRange.push(date)
      graphData.forEach((dimensionData, dimensionIndex) => {
        _graphData[dimensionIndex].dimensionValues.push(
          dimensionData.dimensionValues[dateIndex],
        )
      })
    }
  })

  // Backwards pass - strip end dates
  let laterDatesHaveData = false
  let endIndex = _graphDateRange.length - 1

  while (!laterDatesHaveData && endIndex >= 0) {
    let dateHasData = false
    let dimIndex = 0

    while (dimIndex < _graphData.length) {
      // 0 is falsy
      if (_graphData[dimIndex].dimensionValues[endIndex]) {
        dateHasData = true
        laterDatesHaveData = true
        break
      }

      dimIndex += 1
    }

    if (!dateHasData) {
      _graphDateRange.pop()
      _graphData.forEach((dimensionData) => {
        dimensionData.dimensionValues.pop()
      })
    }

    endIndex -= 1
  }

  return {
    graphDateRange: _graphDateRange,
    graphData: _graphData,
  }
}

/* *************************************************** */
/* Marketing Funnel Report functions                   */
/* *************************************************** */

/** Filters a card's connections to only show those for the selected metrics */
export const filterConnections = (
  selectedCardID: string,
  connections: CardConnectionsRefItem['connections'],
  metricsToShow: {
    metricName: string
    metricValue?: number | null
  }[],
) => {
  return connections.filter(
    ({
      connectedFrom,
      connectedTo,
      secondaryConnectionIDs,
      connectionMetricName,
      connectionMetricValue,
      hideConnection,
    }) => {
      if (connectionMetricValue === 0 || hideConnection) {
        return false
      }

      if (
        connectedFrom !== selectedCardID &&
        connectedTo !== selectedCardID &&
        (!secondaryConnectionIDs ||
          !secondaryConnectionIDs.includes(selectedCardID))
      ) {
        return false
      }

      return metricsToShow.find(
        ({ metricName }) => connectionMetricName === metricName,
      )
    },
  )
}

interface MergedConnection {
  connectedFrom: string
  connectedTo: string
  metrics: {
    connectionMetricName: string
    connectionMetricValue: number
  }[]
}

/** Combines connections between the same cards, grouping their metrics */
export const mergeConnections = (
  selectedCardID: string,
  currentCardRef: CardConnectionsRefItem,
  metricsToShow: {
    metricName: string
    metricValue?: number | null
  }[],
): MergedConnection[] => {
  const mainConnections = filterConnections(
    selectedCardID,
    currentCardRef.connections,
    metricsToShow,
  )

  const additionalConnections = filterConnections(
    selectedCardID,
    currentCardRef.additionalConnections,
    metricsToShow,
  )

  const filteredConnections = [...mainConnections, ...additionalConnections]

  const mergedConnections: {
    connectedFrom: string
    connectedTo: string
    metrics: {
      connectionMetricName: string
      connectionMetricValue: number
    }[]
  }[] = []

  // Aggregate connections between the same cards by metric
  filteredConnections.forEach((connection) => {
    const existingConnectionIndex = mergedConnections.findIndex(
      (mergedConnection) =>
        mergedConnection.connectedFrom === connection.connectedFrom &&
        mergedConnection.connectedTo === connection.connectedTo,
    )

    if (existingConnectionIndex > -1) {
      mergedConnections.splice(existingConnectionIndex, 1, {
        ...mergedConnections[existingConnectionIndex],
        metrics: [
          ...mergedConnections[existingConnectionIndex].metrics,
          {
            connectionMetricName: connection.connectionMetricName,
            connectionMetricValue: connection.connectionMetricValue,
          },
        ],
      })
    } else {
      mergedConnections.push({
        connectedFrom: connection.connectedFrom,
        connectedTo: connection.connectedTo,
        metrics: [
          {
            connectionMetricName: connection.connectionMetricName,
            connectionMetricValue: connection.connectionMetricValue,
          },
        ],
      })
    }
  })

  // Aggregate duplicated metrics
  const aggregatedMergedConnections = mergedConnections.map(
    (mergedConnection) => {
      const metricsList = mergedConnection.metrics.reduce<
        typeof mergedConnection.metrics
      >((acc, metric) => {
        const existingMetricIndex = acc.findIndex(
          (accMetric) =>
            accMetric.connectionMetricName === metric.connectionMetricName,
        )

        if (existingMetricIndex > -1) {
          acc.splice(existingMetricIndex, 1, {
            ...acc[existingMetricIndex],
            connectionMetricValue:
              acc[existingMetricIndex].connectionMetricValue +
              metric.connectionMetricValue,
          })
        } else {
          acc.push(metric)
        }

        return acc
      }, [])

      return {
        ...mergedConnection,
        metrics: metricsList,
      }
    },
  )

  return aggregatedMergedConnections
}

/** Build object compatible with react-archer line-drawing package */
export const transformConnections = (
  connections: MergedConnection[],
): RelationType[] => {
  return connections.map(({ connectedFrom, connectedTo, metrics }) => {
    const leftToRight =
      connectedFrom.startsWith('dimension_') ||
      connectedTo.startsWith('metric_')

    return {
      targetId: connectedTo,
      targetAnchor: leftToRight ? 'left' : 'right',
      sourceAnchor: leftToRight ? 'right' : 'left',
      // label: (
      //   <div>
      //     {metrics.map(({ connectionMetricName, connectionMetricValue }) => {
      //       return (
      //         <span key={connectionMetricName} style={{ display: 'block' }}>
      //           {connectionMetricName}:{' '}
      //           {numeral(connectionMetricValue).format('0,0')}
      //         </span>
      //       )
      //     })}
      //   </div>
      // ),
      // order: connIndex,
      style: {
        strokeWidth: 2,
        strokeColor: '#718096',
      },
    }
  })
}
