import React, { useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { Switch, Redirect, RouteProps } from 'react-router'
import { BrowserRouter, Route } from 'react-router-dom'
import moment from 'moment'

// Pages
import Login from './pages/login'
import GetStartedPage from './pages/get-started-page'
import Register from './pages/register'
import ForgotPassword from './pages/forgot-password'
import ResetPassword from './pages/reset-password'
import MarketingOptOut from './pages/marketing-optout'
import WelcomePage from './pages/welcome-page'
import Share from './pages/share'
import DirectDownload from './pages/direct-download'
import ConnectPage from './pages/connect'
import SettingsPage from './pages/settings'
import UpgradePage from './pages/upgrade'
import TrackLearn from './pages/track-learn'
import TrackCreate from './pages/track-create'
import TrackView from './pages/track-view'
import TrackEditDropdowns from './pages/track-edit-dropdowns'
import TrackEditParametersAndRules from './pages/track-edit-parameters-and-rules'
import TrackEditAppDestinations from './pages/track-edit-app-destinations'
import PerformanceReportPage from './pages/performance-report-page'
import ReportMarketingJourneysPage from './pages/report-marketing-journeys'
import LostLinksReportPage from './pages/lost-links-report'
import UsageReportPage from './pages/usage-report-page'
import ReportCustomDashboards from './pages/report-custom-dashboards'
import Explain from './pages/explain'
import AnomalyBreakdown from './pages/anomaly-breakdown'
import NotFound from './pages/not-found'

import {
  pageRedirect,
  loggedInState,
  currentUserDetails,
  loginForm,
  dataSourceReactive,
  DataSourceDetails,
} from './api/apollo/variables'
import { isLoggedIn } from './api/REST/auth-client'
import { getCompanyDetails } from './api/graphql/company-client'
import { getCampaignCodeGenerator } from './api/graphql/track-create-client'
import { getUpdateRequestList } from './api/graphql/track-edit-client'
import { getPreferredHomepage, getUserInfo } from './api/graphql/user-client'
import { getAccountDataSource } from './api/graphql/workspace-client'
import { sendGaCampaignData, SignupMethod } from './api/REST/account-client'
import { LoadingLogo } from './components/loader'
import {
  adobeDataSource,
  defaultHomepageSlugs,
  gaMeasurementID,
  googleDataSources,
} from './core/constants'
import { getCompanySubscriptionLevel, getToken, isAdminUser } from './helpers'
import {
  removeCustomLinkFromStorage,
  SavedCustomLink,
} from './helpers/custom-links'
import useAuthenticate from './hooks/useAuthenticate'
import useLogAction from './hooks/useLogAction'
import { OAuthRedirect } from './integrations/redirect-handler'
import {
  getLocalItem,
  setLocalItem,
  removeLocalItem,
} from './helpers/local-client'

interface PrivateRouteProps extends RouteProps {
  minSubscriptionLevel?: 'team' | 'enterprise'
  /** Only admin users will be able to see this page */
  adminOnly?: boolean
  /**
   * If page is not available to users (e.g. not an admin), it will redirect here.
   * Defaults to welcome page
   */
  redirectTo?: string
}

/** Pages that are only available to logged in users */
const PrivateRoute = ({
  minSubscriptionLevel,
  adminOnly,
  redirectTo,
  ...props
}: PrivateRouteProps) => {
  const { authenticated, isNewlyCreated } = useReactiveVar(loggedInState)

  // Used to check if the userID is set in the cache yet
  const userDetails = useReactiveVar(currentUserDetails)

  // Check if generator has select fields
  // Used to display notifications for admin users
  const [fetchGeneratorFieldTypes] = useLazyQuery(getCampaignCodeGenerator, {
    pollInterval: 240000,
    nextFetchPolicy: 'network-only',
  })

  const [authenticatedUserDetails, { data: authUserData }] = useLazyQuery(
    getUserInfo,
  )
  const [fetchCompanyDetails, { data: companyData }] = useLazyQuery(
    getCompanyDetails,
  )
  const [fetchDataSource, { data: dataSourceData }] = useLazyQuery(
    getAccountDataSource,
  )

  // Fetch update requests every 2 minutes
  const [refetchUpdateRequests] = useLazyQuery(getUpdateRequestList, {
    pollInterval: 240000,
    nextFetchPolicy: 'network-only',
  })

  const logAction = useLogAction()

  const pathName = props?.location?.pathname || ''

  const search = props?.location?.search || ''

  const hasToken = search.indexOf('token=') !== -1

  // Assign local variable to redirect to
  useEffect(() => {
    if (hasToken) return

    if (!authenticated) {
      pageRedirect(pathName + search)
    }
  }, [authenticated, pathName])

  // Used to fire account created GA metric
  useEffect(() => {
    // @ts-ignore
    if (isNewlyCreated && window.dataLayer && window.dataLayer.push) {
      // @ts-ignore
      window.dataLayer.push({
        event: 'create_account_complete',
      })
    }
  }, [isNewlyCreated])

  // Fetch data on first private page load to set cached values
  // Also allow Clarity to set cookies on the logged-in site
  useEffect(() => {
    if (authenticated || hasToken) {
      if (window.clarity) {
        window.clarity('consent')
      }

      const section = pathName.match(/(^\/.+\/)|(\/.+$)/gi)

      const logActionCall = async () => {
        await logAction({
          variables: {
            action: `page-load${pathName}`,
            functionName: 'pageLoad',
            pagePath: pathName,
            websiteSection: section ? section[0].replace(/\//gi, '') : '',
          },
        })
      }

      if (section && section.length > 0) {
        logActionCall()
      }

      if (userDetails.userID === '') {
        authenticatedUserDetails()
        fetchCompanyDetails()
      }
    }
  }, [authenticated, hasToken, pathName])

  // When successfully authenticated, cache core user info
  // Then fetch track requests and generator if user is admin
  useEffect(() => {
    if (!authUserData) return

    const {
      currentAccount: {
        accountID,
        accountName,
        companyID,
        companyName,
        isDemo,
      },
      currentUser: {
        userID,
        email: userEmail,
        firstName: userFirstName,
        lastName: userLastName,
        currentAccountPermission: userPermission,
        whitelabelAdmin,
      },
    } = authUserData

    // Check if the account connects to Google or Adobe
    fetchDataSource()

    currentUserDetails({
      userID,
      userEmail,
      userFirstName,
      userLastName,
      workspaceID: accountID,
      workspaceName: accountName,
      userPermission: userPermission as PermissionLevel,
      companyID,
      companyName,
      isDemoAccount: isDemo,
      whiteLabelAdminDomain: whitelabelAdmin,
    })

    // For debugging in Sentry
    Sentry.setUser({
      id: userID,
      email: userEmail,
    })

    if (isAdminUser(authUserData.currentUser.currentAccountPermission)) {
      // Only fetch update requests for users that have permission to approve them
      fetchGeneratorFieldTypes()
      refetchUpdateRequests()
    }

    // Tom wants a debug object on staging
    if (process.env.REACT_APP_NODE_ENV !== 'production') {
      console.log(
        `User ID: ${userEmail}\n${userID}\nWorkspace ID: ${accountName}\n${accountID}\nCompany ID: ${companyName}\n${companyID}\nToken\n${getToken()}`,
      )
    }
  }, [authUserData])

  // Check and set company billing level
  useEffect(() => {
    if (companyData && !userDetails.companySubscriptionLevel) {
      currentUserDetails({
        ...userDetails,
        companySubscriptionLevel: companyData.currentCompany.subscriptionLevel,
      })

      // Set MS Clarity segment for session recording filtering
      if (window.clarity) {
        window.clarity(
          'set',
          'companySubscriptionLevel',
          getCompanySubscriptionLevel(
            companyData.currentCompany.subscriptionLevel,
          ),
        )
      }
    }
  }, [companyData, userDetails])

  // Check connected data source
  useEffect(() => {
    if (!dataSourceData) return

    if (dataSourceData.currentAccount.dataSource) {
      // @ts-ignore
      const dataSourceToSet: DataSourceDetails = {}

      if (
        googleDataSources.includes(
          dataSourceData.currentAccount.dataSource.kind,
        )
      ) {
        dataSourceToSet.connectionSource = 'google'
        dataSourceToSet.requiresReconnect = !dataSourceData.currentAccount
          .dataSource.connected
      } else if (
        dataSourceData.currentAccount.dataSource.kind === adobeDataSource
      ) {
        dataSourceToSet.connectionSource = 'adobe'
        dataSourceToSet.requiresReconnect = !dataSourceData.currentAccount
          .dataSource.connected
      } else {
        dataSourceToSet.connectionSource = 'not-connected'
      }

      dataSourceReactive(dataSourceToSet)
    }
  }, [dataSourceData])

  useEffect(() => {
    if (!userDetails) return

    if (window.clarity) {
      if (userDetails.companyID)
        window.clarity('set', 'companyID', userDetails.companyID)
      if (userDetails.companyName)
        window.clarity('set', 'companyName', userDetails.companyName)
      if (userDetails.workspaceID)
        window.clarity('set', 'workspaceID', userDetails.workspaceID)
      if (userDetails.workspaceName)
        window.clarity('set', 'workspaceName', userDetails.workspaceName)
      if (userDetails.userID)
        window.clarity('set', 'userID', userDetails.userID)
      if (userDetails.userEmail)
        window.clarity('set', 'userEmail', userDetails.userEmail)
      if (userDetails.userPermission)
        window.clarity('set', 'userPermission', userDetails.userPermission)
    }
  }, [userDetails])

  if (authenticated || hasToken) {
    if (!userDetails.userPermission || !userDetails.companySubscriptionLevel) {
      return <LoadingLogo fullScreen />
    }

    const currentSubscriptionLevel = getCompanySubscriptionLevel(
      userDetails.companySubscriptionLevel,
    )
    const { isDemoAccount } = userDetails

    if (
      (minSubscriptionLevel === 'enterprise' &&
        currentSubscriptionLevel !== 'enterprise' &&
        !isDemoAccount) ||
      (minSubscriptionLevel === 'team' &&
        (currentSubscriptionLevel === 'free' ||
          currentSubscriptionLevel === 'microsoft_free') &&
        !isDemoAccount)
    ) {
      return (
        <Redirect
          to={{
            pathname: '/upgrade',
          }}
        />
      )
    }

    if (adminOnly && !isAdminUser(userDetails.userPermission)) {
      return (
        <Redirect
          to={{
            pathname: redirectTo || '/login',
          }}
        />
      )
    }

    return <Route {...props} />
  }

  return (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  )
}

// only when the user is logged out
const NonPrivateRoute = ({ ...props }: RouteProps) => {
  const { authenticated } = useReactiveVar(loggedInState)
  const redirect = useReactiveVar(pageRedirect)

  const { authMicrosoft, authGoogle, authOkta } = useAuthenticate()

  const [loading, setLoading] = useState(true)

  // If user has a SSO key in localStorage, attempt to login with this
  useEffect(() => {
    const attemptReauth = async () => {
      const microsoftKey = getLocalItem('ms-microsoftKey')
      const googleKey = getLocalItem('ga-googleKey')
      const oktaKey = getLocalItem('okta-oktaKey')

      if (microsoftKey) {
        await authMicrosoft({ microsoftKey })
      } else if (googleKey) {
        await authGoogle({ googleKey })
      } else if (oktaKey) {
        await authOkta({ oktaKey })
      }

      setLoading(false)
    }

    attemptReauth()
  }, [])

  const [
    getPreferredHomepageQuery,
    { data: homepageData, error: homepageError },
  ] = useLazyQuery(getPreferredHomepage)

  useEffect(() => {
    if (authenticated) getPreferredHomepageQuery()
  }, [authenticated])

  if (loading)
    // Wait for reauth
    return <LoadingLogo fullScreen />

  if (!authenticated) return <Route {...props} />

  if (redirect)
    return (
      <Redirect
        to={{
          pathname: redirect,
        }}
      />
    )

  const hasQuery = redirect && redirect.indexOf('?') !== -1

  if (hasQuery) {
    const [pathname, search] = redirect.split('?')

    return (
      <Redirect
        to={{
          pathname,
          search: `?${search}`,
        }}
      />
    )
  }

  if (homepageError)
    return (
      <Redirect
        to={{
          pathname: '/welcome',
        }}
      />
    )

  if (!homepageData)
    // Wait for preferred HP info
    return <LoadingLogo fullScreen />

  const { currentAccount, currentUser } = homepageData

  const usePath =
    currentAccount[`${currentUser.preferredHomepage}Available`] ||
    currentUser.preferredHomepage === 'welcome'
      ? defaultHomepageSlugs[currentUser.preferredHomepage]
      : '/welcome'

  return (
    <Redirect
      to={{
        pathname: usePath || '/welcome',
      }}
    />
  )
}

interface OAuthRedirectSSOProps {
  source: 'google' | 'microsoft' | 'okta'
}

const OAuthRedirectSSO = ({ source }: OAuthRedirectSSOProps) => {
  const { authenticated } = useReactiveVar(loggedInState)
  const login = useReactiveVar(loginForm)

  const {
    authGoogle,
    authOkta,
    authMicrosoft,
    registerUser,
    createNewClient,
  } = useAuthenticate()

  const [
    getPreferredHomepageQuery,
    { data: preferredHomepageData, error: preferredHomepageError },
  ] = useLazyQuery(getPreferredHomepage)

  const preferredHomepage = preferredHomepageData
    ? preferredHomepageData.currentUser.preferredHomepage
    : ''

  const {
    onFail: { attempts, message },
  } = login

  const { hash, search } = window.location
  const hashQuery = new URLSearchParams(hash.split('#').pop())
  const searchQuery = new URLSearchParams(search)

  // TODO: Use Oauth response state object instead of local storage?
  const returnUrl = getLocalItem('oauth-return-url')
  const forceReauth = getLocalItem('force-reauth')
  removeLocalItem('force-reauth')

  const erroredButHasAccount =
    source === 'microsoft' && message.indexOf('already got an account') !== -1

  useEffect(() => {
    if (authenticated && !forceReauth && !returnUrl) {
      getPreferredHomepageQuery()

      return
    }

    // Used for updating user email
    // See profile-settings component
    if (forceReauth && forceReauth.reauth) {
      const queryCode = searchQuery?.get('code')

      if (forceReauth.type === 'ms') {
        const idToken = hashQuery?.get('id_token')
        setLocalItem('ms-id-token', idToken)
      } else if (forceReauth.type === 'ga') {
        setLocalItem('ga-code', queryCode)
      } else if (forceReauth.type === 'okta') {
        setLocalItem('okta-code', queryCode)

        const savedClientId = getLocalItem('okta-client-id')
        if (!savedClientId) {
          setLocalItem('okta-client-id', searchQuery?.get('state') || '')
        }
      }

      return
    }

    if (!authenticated || erroredButHasAccount) {
      const queryCode = searchQuery?.get('code')

      const msNonce = getLocalItem('ms-nonce')
      const msAction = getLocalItem('ms-action')

      const gaNonce = getLocalItem('ga-nonce')
      const gaState = getLocalItem('ga-state')
      const gaAction = getLocalItem('ga-action')

      const oktaState = getLocalItem('okta-state')
      const oktaAction = getLocalItem('okta-action')
      const oktaClientID =
        searchQuery?.get('state') || getLocalItem('okta-client-id') || ''

      switch (source) {
        case 'okta':
          removeLocalItem('okta-state')

          setLocalItem('okta-action', 'progress')

          if (!oktaAction || oktaAction === 'login') {
            authOkta({
              code: queryCode || '',
              clientID: oktaClientID,
            })
          } else if (oktaState) {
            if (oktaAction === 'register') {
              const {
                fName,
                lName,
                token,
                acceptedMarketing,
                planInterest,
              } = oktaState

              const state = {
                fName,
                lName,
                token,
                signUpMethod: 'okta' as SignupMethod,
                code: queryCode,
                acceptedMarketing,
                planInterest,
                oktaClientID,
              }
              registerUser(state)
            }
          }

          break
        case 'microsoft':
          if (hash && hash.indexOf('#') !== -1) {
            setLocalItem('ms-action', 'progress')

            const idToken = hashQuery?.get('id_token')

            const msState = getLocalItem('ms-state')
            removeLocalItem('ms-state')

            if (erroredButHasAccount || !msAction || msAction === 'login') {
              // User has attempted to register/create new company but already exists
              authMicrosoft({ id_token: idToken || '', nonce: msNonce })
            } else if (msState) {
              const isParsed = typeof msState === 'object' && msState !== null

              if (isParsed && msAction === 'register') {
                const {
                  fName,
                  lName,
                  token,
                  acceptedMarketing,
                  planInterest,
                } = msState

                const state = {
                  fName,
                  lName,
                  token,
                  signUpMethod: 'microsoft' as SignupMethod,
                  nonce: msNonce,
                  id_token: idToken || '',
                  acceptedMarketing,
                  planInterest,
                }

                registerUser(state)
              } else if (isParsed && msAction === 'create') {
                const {
                  fName,
                  lName,
                  email,
                  phoneNum,
                  organisation,
                  acceptedMarketing,
                  planInterest,
                } = msState

                const state = {
                  fName,
                  lName,
                  signUpMethod: 'microsoft' as SignupMethod,
                  nonce: msNonce,
                  id_token: idToken,
                  email,
                  phoneNum,
                  organisation,
                  acceptedMarketing,
                  planInterest,
                }

                createNewClient(state)
              }
            }
          }
          break
        case 'google':
          removeLocalItem('ga-state')

          setLocalItem('ga-action', 'progress')

          if (!gaAction || gaAction === 'login') {
            authGoogle({
              code: queryCode || '',
              nonce: gaNonce,
            })
          } else if (gaState) {
            const isParsed = typeof gaState === 'object' && gaState !== null

            if (isParsed && gaAction === 'register') {
              const {
                fName,
                lName,
                token,
                acceptedMarketing,
                planInterest,
              } = gaState

              const state = {
                fName,
                lName,
                token,
                signUpMethod: 'google' as SignupMethod,
                nonce: gaNonce,
                code: queryCode,
                acceptedMarketing,
                planInterest,
              }

              registerUser(state)
            } else if (isParsed && gaAction === 'create') {
              const {
                fName,
                lName,
                email,
                phoneNum,
                organisation,
                acceptedMarketing,
                planInterest,
              } = gaState

              const state = {
                fName,
                lName,
                signUpMethod: 'google' as SignupMethod,
                nonce: gaNonce,
                code: queryCode,
                email,
                phoneNum,
                organisation,
                acceptedMarketing,
                planInterest,
              }

              createNewClient(state)
            }
          }
          break
        default:
          break
      }
    }
  }, [authenticated, erroredButHasAccount])

  if (!erroredButHasAccount && attempts && attempts > 0) {
    return <Redirect to={{ pathname: '/login' }} />
  }

  if (authenticated) {
    if (returnUrl) {
      removeLocalItem('oauth-return-url')

      return (
        <Redirect
          to={{
            pathname: returnUrl.split('?')[0],
            search: returnUrl.split('?')[1],
          }}
        />
      )
    }

    if (preferredHomepageError) {
      return <Redirect to={{ pathname: '/welcome' }} />
    }

    if (!preferredHomepageData) {
      // Wait for preferred HP info
      return <LoadingLogo fullScreen />
    }

    const usePath =
      preferredHomepageData.currentAccount[`${preferredHomepage}Available`] ||
      preferredHomepage === 'welcome'
        ? defaultHomepageSlugs[preferredHomepage]
        : '/welcome'

    return <Redirect to={{ pathname: usePath || '/welcome' }} />
  }

  return <LoadingLogo fullScreen />
}

export default function Router(): React.ReactElement | null {
  const userLoggedInState = useReactiveVar(loggedInState)

  const [campaignDataSent, setCampaignDataSent] = useState(false)

  // Send GA campaign data for measurement protocol
  useEffect(() => {
    if (campaignDataSent) return

    // Check UTM params in URL
    // They can be carried over from uplifter.ai
    // See custom scripts on Squarespace: https://uplifter.squarespace.com/config/pages/website-tools/code-injection
    // Saved to sessionStorage so they can be passed to Pipedrive on new account signup
    // sessionStorage item is used in createClient function in `useAuthenticate` hook
    const urlParams = new URLSearchParams(window.location.search)

    // Get utm_source, utm_medium and utm_campaign if present
    const utm_source = urlParams.get('utm_source')
    const utm_medium = urlParams.get('utm_medium')
    const utm_campaign = urlParams.get('utm_campaign')
    const utm_term = urlParams.get('utm_term')
    const utm_content = urlParams.get('utm_content')

    if (
      !utm_source &&
      !utm_medium &&
      !utm_campaign &&
      !utm_term &&
      !utm_content
    ) {
      return
    }

    window.sessionStorage.setItem(
      'utmParams',
      JSON.stringify({
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
      }),
    )

    const getGaClientID = () => {
      const cookie = {}
      document.cookie.split(';').forEach(function (el) {
        const splitCookie = el.split('=')
        const key = splitCookie[0].trim()
        const value = splitCookie[1]
        cookie[key] = value
      })

      // @ts-ignore
      return cookie._ga?.substring(6) || null
    }

    const gaClientID = getGaClientID()

    const getGaSessionID = () => {
      const pattern = new RegExp(
        `_ga_${gaMeasurementID.replace('G-', '')}=GS\\d\\.\\d\\.(.+?)(?:;|$)`,
      )
      const match = document.cookie.match(pattern)
      const parts = match?.[1].split('.')

      if (!parts) {
        // Cookie not yet available; wait a bit and try again.
        return null
      }

      return parts[0]
    }
    const gaSessionID = getGaSessionID()

    if (gaClientID && gaSessionID) {
      setCampaignDataSent(true)

      sendGaCampaignData({
        gaClientID,
        gaSessionID,
        // campaign_id: utm_id || undefined,
        campaign: utm_campaign || undefined,
        source: utm_source || undefined,
        medium: utm_medium || undefined,
        term: utm_term || undefined,
        content: utm_content || undefined,
      })
    }
  }, [campaignDataSent, window.location])

  // Check if current browser is logged in
  const hasToken = useMemo(() => {
    return !!userLoggedInState.token
  }, [userLoggedInState.token])

  // Check auth state every few minutes
  useEffect(() => {
    const checkAuth = async () => {
      const auth = await isLoggedIn()

      loggedInState({
        ...userLoggedInState,
        authenticated: !!auth,
        checked: true,
      })
    }

    if (!userLoggedInState.checked && hasToken) {
      checkAuth()
    }

    const interval = setInterval(() => {
      checkAuth()
    }, 250000)

    return function cleanup() {
      clearInterval(interval)
    }
  }, [userLoggedInState.checked, userLoggedInState.authenticated, hasToken])

  // Reset shortLink aliases that are expired or unavailable
  useEffect(() => {
    const userSessionData = getLocalItem('user-session-data')

    if (!userSessionData || typeof userSessionData !== 'object') return

    Object.keys(userSessionData).forEach((item) => {
      // Check if item is a shortLink holder
      if (
        !Array.isArray(userSessionData[item]) ||
        userSessionData[item].length === 0 ||
        typeof userSessionData[item][0] !== 'object' ||
        !Object.prototype.hasOwnProperty.call(
          userSessionData[item][0],
          'shortLinkID',
        )
      )
        return // Check if shortLink is unavailable or expired
      ;(userSessionData[item] as SavedCustomLink[]).forEach(
        ({ shortLinkID, availableUntil, isAvailable }) => {
          if (!isAvailable) {
            // Delete it
            removeCustomLinkFromStorage(shortLinkID, item)

            return
          }

          const now = new Date(Date.now())

          const diff = moment(availableUntil).utc().diff(moment(now).utc())
          const isExpired = diff < 0

          if (isExpired) {
            // Delete it
            removeCustomLinkFromStorage(shortLinkID, item)
          }
        },
      )
    })
  }, [])

  if (hasToken && !userLoggedInState.checked) return <LoadingLogo fullScreen />

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          component={() => (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          )}
        />
        {/* Pages you must be logged out to see */}
        <Route exact path="/share/:shareLinkID" component={Share} />
        <Route
          exact
          path="/code/direct-download/:downloadId"
          component={DirectDownload}
        />
        <Route
          exact
          path="/user-marketing-optout/:userId"
          component={MarketingOptOut}
        />

        {/* Auth pages */}
        <NonPrivateRoute exact path="/login" component={Login} />
        <NonPrivateRoute exact path="/register" component={Register} />
        <NonPrivateRoute
          exact
          path="/forgot-password"
          component={ForgotPassword}
        />
        <NonPrivateRoute
          exact
          path="/reset-password"
          component={ResetPassword}
        />

        {/* Oauth callbacks */}
        <Route
          exact
          path="/auth/o365-redirect"
          component={() => <OAuthRedirectSSO source="microsoft" />}
        />
        <Route
          exact
          path="/auth/gsuite-redirect"
          component={() => <OAuthRedirectSSO source="google" />}
        />
        <Route
          exact
          path="/auth/okta-redirect"
          component={() => <OAuthRedirectSSO source="okta" />}
        />
        <Route
          exact
          path="/oauth2callback"
          component={() => <OAuthRedirect connectorID="googleAnalytics" />}
        />
        <Route
          exact
          path="/aa-oauth2callback"
          component={() => <OAuthRedirect connectorID="adobeAnalytics" />}
        />
        <Route
          exact
          path="/oauth2callback-monday"
          component={() => <OAuthRedirect connectorID="monday" />}
        />
        <Route
          exact
          path="/salesforce-oauth-callback"
          component={() => <OAuthRedirect connectorID="salesforcePardot" />}
        />
        <Route
          exact
          path="/salesforce-mc-oauth-callback"
          component={() => (
            <OAuthRedirect connectorID="salesforceMarketingCloud" />
          )}
        />

        {/* Onboarding */}
        <NonPrivateRoute
          exact
          path="/create-account"
          component={GetStartedPage}
        />
        <NonPrivateRoute
          exact
          path="/create-account-microsoft"
          component={() => <GetStartedPage microsoftMarketplace />}
        />

        {/* Track */}
        <PrivateRoute exact path="/track/learn" component={TrackLearn} />
        <PrivateRoute
          exact
          path="/track/generator-help"
          // Legacy Track>Learn URL changes
          component={() => (
            <Redirect
              to={{
                pathname: '/track/learn',
              }}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/track/create-links"
          component={TrackCreate}
        />
        {['/track/create', '/track/create-campaign-codes'].map((path) => (
          // Legacy Track>Create URL changes
          <PrivateRoute
            key={path}
            exact
            path={path}
            component={() => (
              <Redirect
                to={{
                  pathname: '/track/create-links',
                }}
              />
            )}
          />
        ))}
        <PrivateRoute exact path="/track/view-links" component={TrackView} />
        {['/track/view', '/track/view-campaign-codes'].map((path) => (
          <PrivateRoute
            // Legacy Track>View URL changes
            key={path}
            exact
            path={path}
            component={() => (
              <Redirect
                to={{
                  pathname: '/track/view-links',
                }}
              />
            )}
          />
        ))}
        <PrivateRoute
          exact
          path="/track/edit-dropdowns"
          adminOnly
          redirectTo="/track/create-links"
          component={TrackEditDropdowns}
        />
        <PrivateRoute
          exact
          path="/track/edit-campaign-codes"
          // Legacy Track>Edit Dropdowns URL changes
          component={() => (
            <Redirect
              to={{
                pathname: '/track/edit-dropdowns',
              }}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/track/edit-parameters-and-rules"
          adminOnly
          redirectTo="/track/create-links"
          component={TrackEditParametersAndRules}
        />
        <PrivateRoute
          exact
          path="/track/edit-app-destinations"
          minSubscriptionLevel="enterprise"
          adminOnly
          redirectTo="/track/create-links"
          component={TrackEditAppDestinations}
        />

        {/* Report */}
        <PrivateRoute
          exact
          path="/report/performance"
          component={PerformanceReportPage}
        />
        <PrivateRoute
          exact
          path="/report/other-links-audit"
          component={LostLinksReportPage}
        />
        <PrivateRoute
          exact
          path="/report/marketing-journeys"
          component={ReportMarketingJourneysPage}
        />
        <PrivateRoute exact path="/report/usage" component={UsageReportPage} />
        <PrivateRoute
          exact
          path="/report/dashboards"
          component={ReportCustomDashboards}
        />

        {/* Misc */}
        <PrivateRoute exact path="/welcome" component={WelcomePage} />
        <PrivateRoute exact path="/connect" component={ConnectPage} />
        <PrivateRoute exact path="/settings" component={SettingsPage} />
        <PrivateRoute exact path="/upgrade" component={UpgradePage} />

        {/* Explain (Legacy - not used) */}
        <PrivateRoute exact path="/explain" component={Explain} />
        <PrivateRoute
          exact
          path="/explain/anomaly/:metricID/:anomDate"
          component={AnomalyBreakdown}
        />
        {/* 404 */}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}
