import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'

import Button from './button'
import StyledDatePicker from './date-picker'
import { FormField, FormLabel, FormRow } from './form'
import Input from './input'
import { RequestFieldModal } from './request-field'
import SelectBox from './select-box'
import Tooltip from './tooltip'
import { currentUserDetails } from '../api/apollo/variables'
import { brandName } from '../core/constants'
import { isAdminUser } from '../helpers'
import { GeneratorParameterValues } from '../helpers/track-create'
import { defaultValidationChecksValues } from '../helpers/track-module'
import useLogAction from '../hooks/useLogAction'
import { UpdateFormOptions } from '../hooks/useTrackCreateSavedValues'
import styles from '../styles/track-create-parameter-fields.module.scss'
import {
  GetCampaignCodeGeneratorQuery,
  ParamDef,
} from '../__gql-types__/graphql'

interface ParameterFieldProps {
  param: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs'][0]
  required: boolean
  currentValue: string
  onChange: (newValue: string, options?: UpdateFormOptions) => void
  hasSubmitError?: boolean
}

interface ParameterInputFieldProps extends ParameterFieldProps {
  validationChecks: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['validationChecks']
  submitOnEnterKey?: boolean
  onEnterKey: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

type FieldValidationError =
  | 'noSpaces'
  | 'lowerCase'
  | 'noSpecialCharacters'
  | 'valueIsTooLong'
  | 'multi'
  | null

const ParameterInputField = ({
  param,
  required,
  currentValue,
  onChange,
  validationChecks,
  hasSubmitError,
  submitOnEnterKey,
  onEnterKey,
}: ParameterInputFieldProps) => {
  const { fieldID, fieldName, metaParameter, forceLowerCase = true } = param

  const logAction = useLogAction()

  const [fieldValidationError, setFieldValidationError] = useState<
    FieldValidationError
  >(null)
  const [errorTracked, setErrorTracked] = useState(false)

  useEffect(() => {
    if (!currentValue) {
      setFieldValidationError(null)
    }
  }, [currentValue])

  const {
    replaceSpaces,
    globalLowerCase,
    noSpecialCharacters,
    maxUrlLength,
    maxQueryLength,
  } = useMemo(() => {
    const noSpaces = validationChecks.find(
      (check) => check.name === 'NO_SPACES',
    )
    const replaceSpacesWith = validationChecks.find(
      (check) => check.name === 'REPLACE_SPACES_WITH',
    )
    const lowerCaseCheck = validationChecks.find(
      (check) => check.name === 'ALL_LOWER_CASE',
    )
    const noSpecialCharsCheck = validationChecks.find(
      (check) => check.name === 'NO_SPECIAL_CHARS',
    )
    const urlLength = validationChecks.find(
      (check) => check.name === 'LIMIT_URL_LENGTH',
    )?.value
    const queryLength = validationChecks.find(
      (check) => check.name === 'LIMIT_QUERY_LENGTH',
    )?.value

    return {
      replaceSpaces: {
        enabled:
          typeof noSpaces?.enabled === 'boolean' ? noSpaces.enabled : true,
        value: replaceSpacesWith?.enabled
          ? replaceSpacesWith.value || '%20'
          : '%20',
      },
      globalLowerCase: lowerCaseCheck ? lowerCaseCheck.enabled : true,
      noSpecialCharacters: noSpecialCharsCheck
        ? noSpecialCharsCheck.enabled
        : true,
      maxUrlLength: parseInt(
        urlLength || defaultValidationChecksValues.LIMIT_URL_LENGTH,
        10,
      ),
      maxQueryLength: parseInt(
        queryLength || defaultValidationChecksValues.LIMIT_QUERY_LENGTH,
        10,
      ),
    }
  }, [validationChecks])

  const errorMsg = useMemo(() => {
    if (!fieldValidationError) return ''

    switch (fieldValidationError) {
      case 'noSpaces':
        return 'Spaces are not allowed.'
      case 'lowerCase':
        return 'Input must be lowercase.'
      case 'noSpecialCharacters':
        return 'Special characters (?&=) are not allowed.'
      case 'valueIsTooLong':
        return `Input is too long (max query length: ${maxQueryLength}, max full link length: ${maxUrlLength}).`
      default:
        return 'Validation rules have been applied to your input.'
    }
  }, [fieldValidationError, maxUrlLength, maxQueryLength])

  /** Formats text fields according to generator rules */
  const formatInput = useCallback(
    (inputValue: string) => {
      setFieldValidationError(null)

      // Meta parameters can remain unchanged
      if (metaParameter) {
        return inputValue
      }

      let outputValue = inputValue
      let newFieldValidationError: FieldValidationError = null

      // Spaces rule
      if (replaceSpaces.enabled && /\s/.test(inputValue)) {
        outputValue = outputValue.replaceAll(/\s/g, replaceSpaces.value)

        // Show message about spaces being replaced with URL encoding %20
        if (replaceSpaces.value === '%20') {
          newFieldValidationError = 'noSpaces'
        }
      }

      // Lowercase rule
      if ((globalLowerCase || forceLowerCase) && /[A-Z]/g.test(inputValue)) {
        outputValue = outputValue.toLowerCase()

        newFieldValidationError = newFieldValidationError
          ? 'multi'
          : 'lowerCase'
      }

      // No special characters rule
      if (noSpecialCharacters) {
        const specialCharsRegex = new RegExp(
          defaultValidationChecksValues.NO_SPECIAL_CHARS,
          'g',
        )

        if (specialCharsRegex.test(inputValue)) {
          outputValue = outputValue.replaceAll(specialCharsRegex, '')

          newFieldValidationError = newFieldValidationError
            ? 'multi'
            : 'noSpecialCharacters'

          if (!errorTracked) {
            // Ensure this does not fire on every character. Reset on blur
            logAction({
              variables: {
                action: 'track-error-parameter-special-chars',
                extra: '',
                websiteSection: 'track-create',
                functionName: 'updateValue',
                pagePath: '/track/create-links',
              },
            })

            setErrorTracked(true)
          }
        }
      }

      // Check input length against limits
      if (outputValue.length > Math.min(maxUrlLength, maxQueryLength)) {
        newFieldValidationError = 'valueIsTooLong'
      }

      if (outputValue.length > 0 && newFieldValidationError) {
        setFieldValidationError(newFieldValidationError)
      }

      return outputValue
    },
    [
      errorTracked,
      replaceSpaces,
      globalLowerCase,
      noSpecialCharacters,
      maxUrlLength,
      maxQueryLength,
    ],
  )

  return (
    <>
      <Input
        id={fieldID}
        name={fieldName}
        className={styles.inputField}
        delay={50}
        placeholder={`Type ${fieldName.toLowerCase()}`}
        showClear
        error={hasSubmitError || !!fieldValidationError}
        value={currentValue}
        beforeChange={(inputValue) => formatInput(inputValue)}
        onValueChange={(newVal) => {
          onChange(
            newVal,
            (required && !newVal) ||
              (!metaParameter &&
                newVal.length > Math.min(maxUrlLength, maxQueryLength))
              ? { errorsToAdd: [fieldID] }
              : { errorsToRemove: [fieldID] },
          )
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          // Prevent the form from submitting on Enter if not ready
          if (e.key === 'Enter' && !submitOnEnterKey) {
            e.preventDefault()
          }
        }}
        onKeyUp={onEnterKey}
        onPaste={(e) => {
          const newPastedText = formatInput(e.clipboardData.getData('Text'))

          onChange(
            newPastedText,
            (required && !newPastedText) ||
              (!metaParameter &&
                newPastedText.length > Math.min(maxUrlLength, maxQueryLength))
              ? { errorsToAdd: [fieldID] }
              : { errorsToRemove: [fieldID] },
          )
        }}
        onBlur={() => setErrorTracked(false)}
      />
      {!!errorMsg && <p className={styles.inputError}>{errorMsg}</p>}
    </>
  )
}

interface DropdownOption {
  __typename?: 'SelectField'
  hide: boolean
  optionID: string
  optionName: string
  optionValue: string
  optionFilter?: Array<{
    __typename?: 'OptionFilter'
    parentFieldID: string
    parentOptionIDs: Array<string>
  }> | null
}

interface GroupedDropdownOption {
  parentOptionID?: string
  label?: string
  options: DropdownOption[]
}

interface ParameterSelectFieldProps extends ParameterFieldProps {
  paramDefs: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs']
  formValues: GeneratorParameterValues
}

const ParameterSelectField = ({
  param,
  required,
  currentValue,
  paramDefs,
  formValues,
  onChange,
  hasSubmitError,
}: ParameterSelectFieldProps) => {
  const { userPermission, workspaceID } = useReactiveVar(currentUserDetails)

  const isAdmin = isAdminUser(userPermission)

  const history = useHistory()

  const { fieldID, fieldName, selectFields } = param

  const [showRequestFieldModal, setShowRequestFieldModal] = useState(false)

  /** Filtered options based on parent-child dependencies */
  const availableOptions: GroupedDropdownOption[] = useMemo(() => {
    if (!selectFields) return []

    const groupedOptions: GroupedDropdownOption[] = []

    const allValidOptions = selectFields.filter(({ hide }) => !hide)

    const optionsWithParents = allValidOptions.filter(({ optionFilter }) => {
      if (optionFilter && optionFilter.length > 0) {
        const { parentOptionIDs } = optionFilter[0]
        return !!(parentOptionIDs && parentOptionIDs.length > 0)
      }

      return false
    })

    let remainingValidOptions = _.cloneDeep(allValidOptions)

    // Build groups for options with parent dependencies
    optionsWithParents.forEach((option) => {
      const { optionFilter } = option

      if (!optionFilter || optionFilter.length === 0) return

      const { parentFieldID } = optionFilter[0]

      // Do not create groups for workspace-restricted options
      if (parentFieldID === 'account') return

      const optionParentValues = optionFilter[0].parentOptionIDs

      optionParentValues.forEach((parentOptionID) => {
        // Grouping should not be applied
        if (
          !formValues[parentFieldID] ||
          formValues[parentFieldID].length === 0
        ) {
          return
        }

        const optionIndex = remainingValidOptions.findIndex(
          (o) => o.optionID === option.optionID,
        )

        // Prevent this option from being added to the 'Always visible' group
        if (optionIndex > -1) {
          remainingValidOptions.splice(optionIndex, 1)
        }

        if (formValues[parentFieldID].includes(parentOptionID)) {
          // Parent's value has been selected
          // This option should be visible
          const groupToUpdate = groupedOptions.find(
            (group) => group.parentOptionID === parentOptionID,
          )

          if (groupToUpdate) {
            // If group already exists, add option to it
            groupToUpdate.options.push(option)
          } else {
            // Else, create new group with this option
            const parentParamFull = paramDefs.find(
              (p) => p.fieldID === parentFieldID,
            )

            if (
              !parentParamFull ||
              !parentParamFull.selectFields ||
              parentParamFull.selectFields.length === 0
            ) {
              return
            }

            const parentOptionFull = parentParamFull.selectFields.find(
              (field) => field.optionID === parentOptionID,
            )

            groupedOptions.push({
              parentOptionID,
              label: `${parentParamFull.fieldName}: ${parentOptionFull?.optionName}`,
              options: [option],
            })
          }
        }
      })
    })

    // Filter out options that don't meet workspace criteria
    remainingValidOptions = remainingValidOptions.filter((option) => {
      const { optionFilter } = option

      if (
        optionFilter &&
        optionFilter.length > 0 &&
        optionFilter[0].parentFieldID === 'account'
      ) {
        return optionFilter[0].parentOptionIDs.includes(workspaceID)
      }

      return true
    })

    groupedOptions.push({
      label: 'Always shown / Not assigned',
      options: remainingValidOptions,
    })

    // Multi form does not need to filter based on child param values
    // TODO
    // if (formType === 'multi') {
    //   return groupedOptions
    // }

    // Filter all groups based on values of children, if any
    return groupedOptions.map((group) => {
      // If parameter is a parent (other 'select' parameters have options that depend on it), it should only show valid options based on child values
      const childParams = paramDefs.filter((p) => {
        if (
          p.fieldID === fieldID ||
          p.fieldType !== 'select' ||
          !p.selectFields
        ) {
          return false
        }

        // Check if any select params have this param's ID in their optionFilters
        return p.selectFields.find(({ hide, optionFilter }) => {
          if (hide || !optionFilter || optionFilter.length === 0) {
            return false
          }

          const { parentFieldID, parentOptionIDs } = optionFilter[0]

          return !!(
            parentFieldID === fieldID &&
            parentOptionIDs &&
            parentOptionIDs.length > 0
          )
        })
      })

      if (childParams.length === 0) {
        return group
      }

      const filteredGroup = { ...group }

      // Check if child parameter has any values
      childParams.forEach((childParam) => {
        const childParamValues = formValues[childParam.fieldID] || []

        if (!childParam.selectFields || childParamValues.length === 0) {
          return
        }

        const selectedChildParamOptions = childParam.selectFields.filter(
          ({ optionID }) => {
            return childParamValues.includes(optionID)
          },
        )

        if (selectedChildParamOptions.length > 0) {
          // Filter group's options to valid values based on child values
          filteredGroup.options = group.options.filter(({ optionID }) => {
            return selectedChildParamOptions.find(({ optionFilter }) => {
              if (!optionFilter || optionFilter.length === 0) {
                return true
              }

              const { parentOptionIDs } = optionFilter[0]
              return parentOptionIDs.includes(optionID)
            })
          })
        }
      })

      return filteredGroup
    })
  }, [formValues, selectFields])

  const selectedValue = useMemo(() => {
    const flatOptions = availableOptions.reduce<DropdownOption[]>(
      (acc, curr, index) => {
        if (index === 0) acc.push(...curr.options)
        else {
          const optionsToAdd = curr.options.filter(
            ({ optionID }) =>
              !acc.find((existing) => existing.optionID === optionID),
          )

          acc.push(...optionsToAdd)
        }

        return acc
      },
      [],
    )

    return flatOptions.find(({ optionID }) => optionID === currentValue)
  }, [currentValue, availableOptions])

  return (
    <>
      <SelectBox
        id={`select-${fieldID}`}
        isClearable
        labelKey="optionName"
        valueKey="optionID"
        placeholder="Select value or start typing"
        noOptionsMessage={() => 'No valid options available'}
        aria-errormessage={`${fieldID}-error`}
        error={hasSubmitError}
        value={selectedValue}
        options={availableOptions}
        onChange={(newValue) => {
          const newValID = newValue?.optionID || ''

          onChange(
            newValID,
            required && !newValID
              ? { errorsToAdd: [fieldID] }
              : { errorsToRemove: [fieldID] },
          )
        }}
      >
        <Button
          variant="text"
          className={styles.addButton}
          onPressStart={() => {
            if (isAdmin) {
              history.push(`/track/edit-dropdowns?fieldID=${fieldID}`)
            } else {
              setShowRequestFieldModal(true)
            }
          }}
        >
          {isAdmin ? 'Add' : 'Request'} new {fieldName} +
        </Button>
      </SelectBox>
      {showRequestFieldModal && (
        <RequestFieldModal
          active={showRequestFieldModal}
          toggleActive={setShowRequestFieldModal}
          requestFieldName={fieldName}
        />
      )}
    </>
  )
}

const ParameterDateField = ({
  param,
  required,
  currentValue,
  onChange,
  hasSubmitError,
}: ParameterFieldProps) => {
  const { fieldID, dateFormat } = param

  const nonNullDateFormat = dateFormat || 'DD/MM/YYYY'

  const adjustedDateFormat = nonNullDateFormat
    .replace(/Y/gi, 'y')
    .replace(/D/gi, 'd')
    .replace(/(\[Q\])/gi, 'QQ')

  let dateValue: null | Date = null

  if (
    currentValue !== null &&
    currentValue !== '' &&
    moment(currentValue, nonNullDateFormat).isValid()
  ) {
    const dateFormatted = moment(currentValue, nonNullDateFormat).toDate() // .format(dateFormat)
    dateValue = dateFormatted
  }

  return (
    <StyledDatePicker
      id={fieldID}
      isClearable
      placeholderText={nonNullDateFormat}
      dateFormat={adjustedDateFormat}
      showYearPicker={nonNullDateFormat === 'yyyy'}
      showMonthYearPicker={nonNullDateFormat.toLowerCase() === 'yyyymm'}
      showQuarterYearPicker={nonNullDateFormat.toLowerCase() === 'yyyy[q]q'}
      selected={dateValue}
      isError={hasSubmitError}
      onChange={(date) => {
        // The value can never be a null
        // only empty string is permitted in this case
        let val = ''

        if (date !== null) {
          const dateF = moment(date.toString()).format(nonNullDateFormat)
          val = dateF
        }

        onChange(
          val,
          required && !val
            ? { errorsToAdd: [fieldID] }
            : { errorsToRemove: [fieldID] },
        )
      }}
    />
  )
}

interface GeneratorParameterFieldProps {
  param: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs'][0]
  required: boolean
  paramDefs: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs']
  validationChecks: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['validationChecks']
  savedValue?: string[]
  formValues: GeneratorParameterValues
  hasSubmitError?: boolean
  onChange: (newVal: string, options?: UpdateFormOptions) => void
  submitOnEnterKey?: boolean
  onEnterKey: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const GeneratorParameterField = ({
  param,
  required,
  paramDefs,
  validationChecks,
  savedValue,
  formValues,
  hasSubmitError,
  onChange,
  submitOnEnterKey,
  onEnterKey,
}: GeneratorParameterFieldProps) => {
  const { fieldID, fieldType } = param

  const [currentValue, setCurrentValue] = useState(
    Array.isArray(savedValue) && savedValue[0] ? savedValue[0] : '',
  )

  // Update field value on e.g. clearing the form
  useEffect(() => {
    if (!savedValue) {
      setCurrentValue('')
    } else if (savedValue[0] !== currentValue) {
      setCurrentValue(savedValue[0])
    }
  }, [savedValue])

  switch (fieldType) {
    case 'input':
      return (
        <ParameterInputField
          param={param}
          required={required}
          currentValue={currentValue}
          onChange={(newVal, options) => {
            setCurrentValue(newVal)
            onChange(newVal, options)
          }}
          validationChecks={validationChecks}
          hasSubmitError={hasSubmitError}
          submitOnEnterKey={submitOnEnterKey}
          onEnterKey={onEnterKey}
        />
      )
    case 'select':
      return (
        <ParameterSelectField
          key={`${fieldID}-${currentValue}`}
          param={param}
          required={required}
          currentValue={currentValue}
          paramDefs={paramDefs}
          formValues={formValues}
          onChange={(newValue, options) => {
            setCurrentValue(newValue || '')
            onChange(newValue || '', options)
          }}
          hasSubmitError={hasSubmitError}
        />
      )
    case 'date':
      return (
        <ParameterDateField
          key={`${fieldID}-${currentValue}`}
          param={param}
          required={required}
          currentValue={currentValue}
          onChange={(newValue, options) => {
            setCurrentValue(newValue || '')
            onChange(newValue || '', options)
          }}
          hasSubmitError={hasSubmitError}
        />
      )
    default:
      return null
  }
}

interface GeneratorParameterFieldsProps {
  generatedStructure:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']
    | null
  formValues: GeneratorParameterValues
  onChange: (
    fieldID: string,
    newVal: string,
    options?: UpdateFormOptions,
  ) => void
  /** If the rest of the form is ready to submit, pressing enter should submit the whole form */
  submitOnEnterKey?: boolean
  showErrorMessages?: boolean
  fieldsWithErrors?: string[] | null
}

const GeneratorParameterFields = ({
  generatedStructure,
  formValues,
  onChange,
  submitOnEnterKey,
  showErrorMessages,
  fieldsWithErrors,
}: GeneratorParameterFieldsProps) => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const formRowRefs = useRef<(HTMLDivElement | null)[]>([])

  const { paramDefs, validationChecks } = generatedStructure || {
    paramDefs: [],
    validationChecks: [],
  }

  if (!generatedStructure) return null

  return paramDefs.map((param: ParamDef, paramIndex: number) => {
    const {
      fieldAvailable,
      fieldType,
      fieldID,
      fieldName,
      required,
      metaParameter,
      helpText,
      copyFromField,
      parameterDependsOn,
    } = param

    // Only available fields should show in the form
    if (!fieldAvailable) return null

    // Fixed fields can't be changed - no need to show them
    if (fieldType === 'fixed') return null

    const copiedParams: string[] = []

    // Check if copied params exist in the generator
    if (copyFromField && copyFromField.length > 0) {
      copyFromField.forEach(({ copyFromID }) => {
        const foundParam = paramDefs.find((p) => p.fieldID === copyFromID)

        if (foundParam) {
          copiedParams.push(copyFromID)
        }
      })
    }

    // Copied params should not show in the form - their values are autogenerated
    if (copiedParams.length > 0) return null

    // If the field's dependencies aren't met, we must ignore its `required` value
    let paramIsRequired = parameterDependsOn ? false : required

    let tooltipExtra = ''

    // Only show the field if the dependency condition is met
    if (parameterDependsOn) {
      tooltipExtra +=
        '\n\n**This parameter only appears when certain dropdowns are selected.**'

      if (parameterDependsOn.parentFieldID === 'account') {
        // Specific restriction based on current workspace
        if (!parameterDependsOn.parentOptionIDs.includes(workspaceID)) {
          // Current workspace is not the correct one for this field
          if (formValues[fieldID] && formValues[fieldID].length > 0) {
            // Remove field's values from form and hide field
            onChange(fieldID, '', { errorsToRemove: [fieldID] })
          }

          return null
        }

        // If the parameter's visibility is workspace-dependent, use its `required` rule
        paramIsRequired = required
      } else {
        // Find the parent field in the generator
        const parentParam = paramDefs.find(
          (parent) => parent.fieldID === parameterDependsOn.parentFieldID,
        )

        if (parentParam) {
          // Check if parent param has any values
          if (
            !formValues[parentParam.fieldID] ||
            formValues[parentParam.fieldID].length === 0
          ) {
            // If not, remove current param's values (if any) and hide it
            if (
              (formValues[fieldID] && formValues[fieldID].length > 0) ||
              fieldsWithErrors?.includes(fieldID)
            ) {
              onChange(fieldID, '', { errorsToRemove: [fieldID] })
            }

            return null
          }

          let canShowParam = false
          let valueIndex = 0

          // Check if parent param's current value contains a value the dependent param needs
          while (valueIndex < formValues[parentParam.fieldID].length) {
            if (
              parameterDependsOn.parentOptionIDs.indexOf(
                formValues[parentParam.fieldID][valueIndex],
              ) > -1
            ) {
              canShowParam = true
              // Update param's required status
              paramIsRequired = required
              break
            }

            valueIndex += 1
          }

          if (!canShowParam) {
            // Remove param's values from form and hide
            if (
              (formValues[fieldID] && formValues[fieldID].length > 0) ||
              fieldsWithErrors?.includes(fieldID)
            ) {
              onChange(fieldID, '', { errorsToRemove: [fieldID] })
            }

            return null
          }
        }
      }

      // If param does not have a value but is required, add error message
      if (
        paramIsRequired &&
        (!formValues[fieldID] || !formValues[fieldID][0]) &&
        !fieldsWithErrors?.includes(fieldID)
      ) {
        onChange(fieldID, '', { errorsToAdd: [fieldID] })
      }
    }

    let optionalText = paramIsRequired ? '' : 'optional'

    if (metaParameter) {
      optionalText += `${paramIsRequired ? '' : ' '}meta`
      tooltipExtra = `\n\nThis metadata will only be visible in ${brandName}.`
    }

    const hasSubmitError =
      showErrorMessages && fieldsWithErrors?.includes(fieldID)

    return (
      <FormRow
        key={fieldID}
        ref={(el) => {
          formRowRefs.current[paramIndex] = el
        }}
        heading={paramIndex === 0 ? 'Parameters' : undefined}
        bottomBorder={false}
      >
        <FormLabel
          id={fieldID}
          optional={optionalText ? `(${optionalText})` : ''}
        >
          <Tooltip
            id={fieldID}
            useIcon
            tooltipPosition="right"
            tooltipMessage={`${helpText}${tooltipExtra}`}
          >
            {fieldName}
          </Tooltip>
        </FormLabel>
        <FormField>
          <GeneratorParameterField
            param={param}
            required={paramIsRequired}
            paramDefs={paramDefs}
            validationChecks={validationChecks}
            savedValue={formValues[fieldID]}
            formValues={formValues}
            hasSubmitError={hasSubmitError}
            onChange={(newVal, options) => onChange(fieldID, newVal, options)}
            submitOnEnterKey={
              submitOnEnterKey || paramIndex === paramDefs.length - 1
            }
            onEnterKey={(e) => {
              // If form is not ready to submit, tab to the next field instead
              if (
                e.key === 'Enter' &&
                !(submitOnEnterKey || paramIndex === paramDefs.length - 1)
              ) {
                const nextRow = formRowRefs.current[paramIndex + 1]

                if (nextRow) {
                  const nextRowInput = nextRow.querySelector(
                    'input',
                  ) as HTMLInputElement | null

                  if (nextRowInput) {
                    nextRowInput.focus()
                  }
                }
              }
            }}
          />
          {hasSubmitError && (
            <p className={styles.inputError}>
              You must enter a value for {fieldName.toLowerCase()}.
            </p>
          )}
        </FormField>
      </FormRow>
    )
  })
}

export default GeneratorParameterFields
