import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import _ from 'lodash'

import {
  RecentlyValidatedUrls,
  currentUserDetails,
  recentlyValidatedUrls,
} from '../api/apollo/variables'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import {
  intensiveUrlValidation,
  quickUrlValidation,
} from '../api/graphql/track-view-client'
import {
  FullValidationCheck,
  getValidationCheck,
  getValidationChecksObject,
} from '../helpers/track-module'

export default function useUrlValidation() {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const [getGenerator, { data: generatorData }] = useLazyQuery(
    getCampaignCodeGenerator,
  )

  // Wait for account ID so generator can be cached
  useEffect(() => {
    if (!workspaceID) return

    getGenerator()
  }, [workspaceID])

  const generatedStructure = useMemo(() => {
    if (!generatorData) return null

    return generatorData.campaignCodeGenerator
  }, [generatorData])

  const validationChecks: FullValidationCheck[] | null = useMemo(() => {
    return getValidationChecksObject(generatedStructure)
  }, [generatedStructure])

  const {
    quick,
    intensive,
    checkSpeed,
    checkRedirect,
    checkAnalytics,
  } = useMemo(() => {
    const urlExists = getValidationCheck(
      validationChecks,
      'CHECK_URL_EXISTS',
      true,
    ).enabled

    const urlSpeed = getValidationCheck(
      validationChecks,
      'CHECK_URL_SPEED',
      true,
    ).enabled

    const urlRedirect = getValidationCheck(
      validationChecks,
      'CHECK_URL_REDIRECT',
      true,
    ).enabled

    const urlAnalytics = getValidationCheck(
      validationChecks,
      'CHECK_URL_ANALYTICS',
      false,
    ).enabled

    return {
      quick: urlExists,
      intensive: urlSpeed || urlRedirect || urlAnalytics,
      checkSpeed: urlSpeed,
      checkRedirect: urlRedirect,
      checkAnalytics: urlAnalytics,
    }
  }, [validationChecks])

  const validationResults = useReactiveVar(recentlyValidatedUrls)

  const [quickValidation] = useLazyQuery(quickUrlValidation)
  const [intensiveValidation] = useLazyQuery(intensiveUrlValidation)

  const [quickValResults, setQuickValResults] = useState<RecentlyValidatedUrls>(
    {},
  )
  const [intensiveValResults, setIntensiveValResults] = useState<
    RecentlyValidatedUrls
  >({})

  // Update the stored values
  useEffect(() => {
    const newVal = _.cloneDeep(validationResults)

    Object.keys(quickValResults).forEach((url) => {
      newVal[url] = {
        ...newVal[url],
        ...quickValResults[url],
      }
    })

    Object.keys(intensiveValResults).forEach((url) => {
      newVal[url] = {
        ...newVal[url],
        ...intensiveValResults[url],
      }
    })

    recentlyValidatedUrls(newVal)
  }, [quickValResults, intensiveValResults])

  const validateUrls = useCallback(
    async (urlList: string[]) => {
      const results = _.cloneDeep(validationResults)
      const urlsToValidate = urlList.filter((url) => !results[url])

      if (urlsToValidate.length === 0) return

      if (quick) {
        const { data: quickVal } = await quickValidation({
          variables: { urlList: urlsToValidate },
        })

        if (quickVal) {
          quickVal.track.quickUrlValidation.validationResults.forEach(
            ({ testUrl, statusCode }) => {
              results[testUrl] = {
                ...results[testUrl],
                statusCode,
              }

              if (!(statusCode >= 200 && statusCode <= 299)) {
                // Exclude failing URLs from intensive validation
                const validateIndex = urlsToValidate.findIndex(
                  (url) => url === testUrl,
                )
                urlsToValidate.splice(validateIndex, 1)
              }
            },
          )
        }

        setQuickValResults(results)
      }

      // Check again: URLs to validate may be shorter if quick validation found failing URL
      if (urlsToValidate.length === 0) return

      if (intensive) {
        const { data: intensiveVal } = await intensiveValidation({
          variables: { urlList: urlsToValidate },
        })

        if (intensiveVal) {
          intensiveVal.track.intensiveUrlValidation.validationResults.forEach(
            ({
              statusCode,
              testUrl,
              badUrl,
              clickedCookieConsent,
              noAnalyticsTag,
              redirectedLandingPage,
              slowLandingPage,
              validatorReturned,
            }) => {
              results[testUrl] = {
                ...results[testUrl],
                // If intensive status code is -1 or does not match quick status code
                // This is an edge case that could display a faulty message
                // intensiveStatusCode introduced to prevent this clash
                intensiveStatusCode: statusCode,
                badUrl,
                clickedCookieConsent,
                noAnalyticsTag,
                redirectedLandingPage,
                slowLandingPage,
                validatorReturned,
              }
            },
          )
        }

        setIntensiveValResults(results)
      }
    },
    [validationResults, quick, intensive],
  )

  return {
    quick,
    intensive,
    checkSpeed,
    checkRedirect,
    checkAnalytics,
    validateUrls,
    validationResults,
  }
}
