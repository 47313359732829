import { getItemByKeyValue } from '.'
import { getAnchorFromString } from './track-module'
import { RecentlyValidatedUrl } from '../api/apollo/variables'
import { CodeType } from '../api/types'
import { AvailableDomain } from '../hooks/useCustomLinks'
import {
  DimensionFilter,
  GetCampaignCodeGeneratorQuery,
  GetMinCodesByAccountQuery,
  GetStoredCodesStatsQuery,
  GetUrlValidationStatusQuery,
} from '../__gql-types__/graphql'

export const minimumSearchTypeList = [
  { name: 'All', value: 'any' },
  {
    name: 'Created',
    value: 'createdTime',
  },
  {
    name: 'Created by',
    value: 'createdBy',
  },
  {
    name: 'Short link',
    value: 'shortLink',
  },
  {
    name: 'Full link',
    value: 'fullLink',
  },
]

// Used for bidirectional sorting of status column
export const statusItems = ['Live', 'Ended', 'New', 'Unused']

export interface HeaderColumnProps {
  key: string
  name: string
  tooltipMsg?: React.ReactNode
  bidirectionalSortKeys?: string[]
  isMetric?: boolean
}

export const minimumTableHeaders: HeaderColumnProps[] = [
  {
    key: 'createdTime',
    name: 'Created',
  },
  {
    key: 'shortLink',
    name: 'Short link',
    tooltipMsg:
      'A short, memorable link that redirects to the landing page with campaign code, capturing valuable clickthrough data.\n\nWhen available, use this link rather than the landing page with campaign code link.',
  },
  {
    key: 'fullLink',
    name: 'Destination with parameters',
    tooltipMsg:
      'The full website URL that visitors will reach for this campaign link.',
  },
  {
    key: 'status',
    name: 'Status',
    tooltipMsg:
      'The landing page [validation status](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-).',
    bidirectionalSortKeys: statusItems,
    isMetric: true,
  },
]

export interface TrackViewFilterProps {
  codeIDList?: string[]
  activePage: number
  rowsPerPage: number
  sortBy: string
  sortDirection?: 'ASC' | 'DESC'
  searchType: string
  searchTerm: string
  filterByCurrentUser?: boolean
  bidirectionalSortKey?: string
  isMetric?: boolean
}

export interface RefetchOptions {
  recalculateTotals: boolean
}

export interface RefetchCodesQueryVars {
  codeIDList?: string[]
  dimensionFilter?: DimensionFilter
  filterByCurrentUser?: boolean
  limit?: number
  offset?: number
  orderBy?: {
    sortDirection: 'ASC' | 'DESC'
    sortField: string
  }
}

export const filterToMinCodesVars = ({
  codeIDList,
  activePage,
  rowsPerPage,
  sortBy,
  sortDirection,
  searchType,
  searchTerm,
  filterByCurrentUser,
}: TrackViewFilterProps): RefetchCodesQueryVars => {
  return {
    codeIDList,
    dimensionFilter: {
      dimensionName: searchTerm,
      dimensionParameterID: searchType,
      dimensionOptions: [],
    },
    filterByCurrentUser,
    limit: rowsPerPage,
    offset: (activePage - 1) * rowsPerPage,
    orderBy: {
      sortDirection: sortDirection || 'DESC',
      sortField: sortBy,
    },
  }
}

export const getEarliestDataDate = (
  codesMetricData?: GetStoredCodesStatsQuery,
) => {
  let earliestDataDate: number | null = null

  const earliestClickDate = codesMetricData
    ? codesMetricData.storedCodeStats.earliestClickDate
    : null

  const earliestMetricDate = codesMetricData
    ? codesMetricData.storedCodeStats.earliestMetricDate
    : null

  if (codesMetricData) {
    const clickDate =
      earliestClickDate && earliestClickDate !== 'None'
        ? Date.parse(earliestClickDate)
        : null
    const metricDate =
      earliestMetricDate && earliestMetricDate !== 'None'
        ? Date.parse(earliestMetricDate)
        : null

    if (clickDate && metricDate) {
      earliestDataDate = Math.min(clickDate, metricDate)
    } else if (clickDate) {
      earliestDataDate = clickDate
    } else if (metricDate) {
      earliestDataDate = metricDate
    }
  }

  return earliestDataDate
}

export interface MinimalParamDetails {
  fieldID: string
  fieldName: string
  helpText: string
}

export const getWorkspaceParams = (
  workspaceID = '',
  generatorData?: GetCampaignCodeGeneratorQuery,
): MinimalParamDetails[] => {
  if (!generatorData) return []

  // Do not include fields blocked for the current workspace
  return generatorData.campaignCodeGenerator.paramDefs
    .filter(({ parameterDependsOn }) => {
      if (
        parameterDependsOn &&
        parameterDependsOn.parentFieldID === 'account' &&
        parameterDependsOn.parentOptionIDs.indexOf(workspaceID) === -1
      ) {
        return false
      }
      return true
    })
    .map(({ fieldID, fieldName, helpText, selectFields }) => ({
      fieldID,
      fieldName,
      helpText,
      selectFields,
    }))
}

export const buildSearchTypeList = (params: MinimalParamDetails[]) => {
  const searchTypeList = [...minimumSearchTypeList]

  searchTypeList.push(
    ...params.map(({ fieldID, fieldName }) => ({
      name: fieldName,
      value: fieldID,
    })),
  )

  return searchTypeList
}

export interface MetricDropdownItem {
  metricID: string
  displayName: string
  helpText?: string
}

export interface TableLinkWithData {
  linkID: string
  deepLinkServiceID: string | null
  createdInfo: {
    createdTime: string
    author: string
    versionNumber: number
  }
  hasNoLandingPage?: boolean
  fullLink: string
  shortLink: string | null
  params: {
    paramID: string
    paramName: string
    paramValue: string
    isHidden: boolean
  }[]
  uplifterIDValue?: string
  status?: CodeType | null
  linkValidation?: Pick<
    RecentlyValidatedUrl,
    | 'badUrl'
    | 'noAnalyticsTag'
    | 'redirectedLandingPage'
    | 'slowLandingPage'
    | 'statusCode'
  > | null
  metricData?: {
    metricID: string
    metricName: string
    metricValue: number
    units: string
    isHidden: boolean
  }[]
}

interface BuildTableLinksProps {
  params: MinimalParamDetails[]
  minCodes?: GetMinCodesByAccountQuery['minCodesByAccount']
  codeStats?: GetStoredCodesStatsQuery['storedCodeStats']
  codeValidationData?: GetUrlValidationStatusQuery['track']['trackValidationResults']
  selectedMetrics: MetricDropdownItem[]
  masterPrefix?: string
  uplifterIdPrefix?: string
  availableDeepLinkDomains: AvailableDomain[]
}

export const buildTableHeaders = ({
  params,
  selectedMetrics = [],
}: Pick<BuildTableLinksProps, 'params' | 'selectedMetrics'>) => {
  // Build table headers from params and metrics
  const tableHeaderColumns = [...minimumTableHeaders]

  if (selectedMetrics.length > 0) {
    tableHeaderColumns.push(
      ...selectedMetrics.map(({ metricID, displayName, helpText }) => ({
        key: metricID,
        name: displayName,
        tooltipMsg: helpText,
        isMetric: true,
      })),
    )
  }

  tableHeaderColumns.push(
    ...params.map((param) => ({
      key: param.fieldID,
      name: param.fieldName,
      tooltipMsg: param.helpText,
    })),
  )

  return tableHeaderColumns
}

export const buildTableLinks = ({
  params,
  minCodes,
  codeStats,
  codeValidationData,
  selectedMetrics = [],
  masterPrefix = '?',
  uplifterIdPrefix,
  availableDeepLinkDomains,
}: BuildTableLinksProps) => {
  const paramIDs = params.map(({ fieldID }) => fieldID)
  const selectedMetricIDs = selectedMetrics?.map(({ metricID }) => metricID)

  // Build table rows
  // Should have same number of params and metrics as header
  const tableRows: TableLinkWithData[] = []

  if (minCodes) {
    const {
      codeID,
      createdTime,
      author,
      versionNumber,
      fullLink,
      shortLink,
      minGenDef,
      codeDef,
    } = minCodes

    codeID.forEach((linkID, linkIndex) => {
      let deepLinkServiceID: string | null = null

      if (shortLink) {
        deepLinkServiceID =
          availableDeepLinkDomains.find(({ optionName }) =>
            shortLink[linkIndex].includes(optionName),
          )?.optionValue || null
      }

      const linkData: TableLinkWithData = {
        linkID,
        deepLinkServiceID,
        createdInfo: {
          createdTime: createdTime[linkIndex],
          author: author[linkIndex],
          versionNumber: versionNumber[linkIndex],
        },
        fullLink: fullLink[linkIndex],
        hasNoLandingPage: fullLink[linkIndex].indexOf(masterPrefix) === 0,
        shortLink: shortLink ? shortLink[linkIndex] : null,
        params: minGenDef.map(({ paramID, paramName }, paramIndex) => {
          return {
            paramID,
            paramName,
            paramValue: codeDef[linkIndex][paramIndex],
            // Hide params not shown for the workspace
            isHidden: paramIDs.indexOf(paramID) === -1,
          }
        }),
      }

      if (uplifterIdPrefix) {
        const splitVals = fullLink[linkIndex].split(uplifterIdPrefix)

        // ID prefix exists in link
        if (splitVals.length >= 2) {
          // Only thing after ID will be the anchor
          // Remove anchor from string
          const useAnchor = getAnchorFromString(fullLink[linkIndex])

          linkData.uplifterIDValue = splitVals[splitVals.length - 1].replace(
            useAnchor,
            '',
          )
        }
      }

      if (codeValidationData) {
        const fullValidation = codeValidationData[linkIndex]
        linkData.linkValidation = fullValidation
          ? {
              statusCode: fullValidation.statusCode,
              badUrl: fullValidation.badUrl,
              noAnalyticsTag: fullValidation.noAnalyticsTag,
              redirectedLandingPage: fullValidation.redirectedLandingPage,
              slowLandingPage: fullValidation.slowLandingPage,
            }
          : null
      }

      if (codeStats) {
        linkData.status = codeStats.status
          ? (codeStats.status[linkIndex] as CodeType)
          : null

        linkData.metricData = codeStats.metricValues.map(
          ({ metricID, displayName, totalMetricValues, units }) => ({
            metricID,
            metricName: displayName,
            metricValue: totalMetricValues[linkIndex],
            units,
            isHidden: selectedMetricIDs?.indexOf(metricID) === -1,
          }),
        )
      }

      tableRows.push(linkData)
    })
  }

  return tableRows
}

export const cloneAndEditBuildForm = (
  links: TableLinkWithData[],
  selectedLinks: string[],
  masterPrefix = '?',
  paramDefs: MinimalParamDetails[],
) => {
  const result: {
    urls: string[]
    data: { [fieldID: string]: { optionValue: string[] } }
  } = {
    urls: [],
    data: {},
  }

  const fullSelectedLinks = links.filter(({ linkID }) =>
    selectedLinks.includes(linkID),
  )

  fullSelectedLinks.forEach(({ fullLink, params }) => {
    const linkAnchor = getAnchorFromString(fullLink)

    const linkWithoutParams = fullLink.split(masterPrefix)

    const linkToUse = `${
      linkWithoutParams.length > 0 ? linkWithoutParams[0] : ''
    }${linkAnchor}`

    if (result.urls.indexOf(linkToUse) === -1) {
      result.urls.push(linkToUse)
    }

    params.forEach(({ paramID, paramValue }) => {
      if (!paramValue) return

      if (!result.data[paramID]) {
        result.data[paramID] = { optionValue: [] }
      }

      const paramDefValue = getItemByKeyValue(paramDefs, 'fieldID', paramID)

      let valueExists = false
      let useValue = paramValue

      if (paramDefValue !== -1) {
        valueExists = true
        const { selectFields } = paramDefValue

        if (selectFields) {
          const selectFieldValueFound = getItemByKeyValue(
            selectFields,
            'optionName',
            useValue,
          )

          valueExists = selectFieldValueFound !== -1

          if (!valueExists) return

          useValue = selectFieldValueFound.optionID
        }
      }

      if (result.data[paramID].optionValue.indexOf(useValue) === -1) {
        result.data[paramID].optionValue.push(useValue)
      }
    })
  })

  return result
}
