import React, { useState, useEffect, useMemo, useRef } from 'react'
import { MultiValue, SingleValue } from 'react-select'
import moment from 'moment'
import _ from 'lodash'
import { nanoid } from 'nanoid'
import ReactMarkdown from 'react-markdown'

import AddMultiValuesTags from './add-multi-values-tags'
import StyledDatePicker from './date-picker'
import Input, { Label } from './input'
import { FormRow, LabelSlot, FieldSlot } from './row'
import SelectBox from './select-box'
import Tooltip from './tooltip'
import { ValidationChecks } from '../api/types'
import { prepareInput, hashCode } from '../helpers'
import { isValidInput, makeLinkSecure } from '../helpers/track-create'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/campaign-code-generator-rows.module.scss'

const checks = {
  ALL_LOWER_CASE: 'lowercase',
  NO_SPECIAL_CHARS: 'no special characters',
  NO_SPACES: 'no spaces',
}

interface ListOption {
  optionName: string
  optionValue: string
  optionID: string
}

export interface GroupedOptions {
  parentID?: string
  label?: string
  options: ListOption[]
}

interface SelectionRowProps {
  optional?: boolean
  isMetaParameter?: boolean
  updateForm: (value: string[]) => void
  value: string[]
  name: string
  list: GroupedOptions[]
  tooltip: string
  id?: string
  children?: React.ReactNode
  className?: string
  single?: boolean
  defaultError?: boolean
  onValidation?: (valid: boolean) => void
}

export const SelectionRow = ({
  optional,
  isMetaParameter,
  updateForm,
  name,
  list,
  tooltip,
  id,
  children,
  className,
  value,
  single = true,
  defaultError = false,
  onValidation,
}: SelectionRowProps): React.ReactElement => {
  const showLabel: string[] = []

  if (optional) showLabel.push('optional')

  if (isMetaParameter) showLabel.push('meta')

  const useLabel = showLabel.join(' ')

  const validateValue = (val: string[]) => {
    if (onValidation && !optional && defaultError) {
      const valueNotSet =
        typeof val === 'undefined' ||
        (Array.isArray(val) && val.join('') === '')

      onValidation(!valueNotSet)
    }
  }

  // Get current value from nested list
  const currentValue = useMemo(() => {
    if (value.length === 0) return single ? null : []

    const flatList = list.reduce<ListOption[]>((acc, curr, index) => {
      const currVals = _.cloneDeep(curr.options)

      if (index === 0) acc.push(...currVals)
      else {
        const currValsToAdd = currVals.filter(
          ({ optionID }) =>
            !acc.find((existing) => existing.optionID === optionID),
        )

        acc.push(...currValsToAdd)
      }

      return acc
    }, [])

    if (single) {
      return flatList.find((item) => item.optionID === value[0]) || null
    }

    return flatList.filter(({ optionID }) => value.indexOf(optionID) > -1)
  }, [value, list.length])

  return (
    <FormRow className={className}>
      <LabelSlot className={styles.labelSlot}>
        <Label
          id={`select-${hashCode(name || id)}`}
          optional={`${useLabel !== '' ? `(${useLabel})` : ''}`}
        >
          <Tooltip
            id={`${id}-help-text-tooltip`}
            useIcon
            maxWidth={250}
            tooltipPosition="right"
            tooltipMessage={tooltip}
          >
            {name}
            {!optional && <span className={styles.asterix}>*</span>}
          </Tooltip>
        </Label>
      </LabelSlot>
      <FieldSlot>
        <SelectBox
          id={`select-${hashCode(name || id)}`}
          isClearable
          isMulti={!single}
          labelKey="optionName"
          valueKey="optionID"
          placeholder={`Select value${single ? '' : '(s)'} or start typing`}
          noOptionsMessage={() => 'Please select an existing value or...'}
          aria-errormessage={`${id}-error`}
          error={defaultError}
          value={currentValue}
          options={list}
          onChange={(newValue) => {
            if (single) {
              const singleVal = newValue as SingleValue<ListOption>
              updateForm([singleVal?.optionID || ''])
              validateValue([singleVal?.optionID || ''])
            } else {
              const multiVal = newValue as MultiValue<ListOption>
              updateForm(multiVal.map(({ optionID }) => optionID))
              validateValue(multiVal.map(({ optionID }) => optionID))
            }
          }}
        >
          {children}
        </SelectBox>
        {defaultError && value.join('') === '' && (
          <p id={`${id}-error`} className={styles.footNoteError}>
            You must select a value for {name.toLowerCase()}.
          </p>
        )}
      </FieldSlot>
    </FormRow>
  )
}

interface InputRowProps {
  optional?: boolean
  isMetaParameter?: boolean
  validation?: ValidationChecks[] | null
  updateForm: (value: string[]) => void
  onValidation?: (valid: boolean) => void
  validationMessage?: React.ReactNode
  value: string[]
  name: string
  tooltip: string
  id?: string
  className?: string
  single?: boolean
  children?: any
  onInputBlur?: any
  onSetTagStatus?: (tag: string) => null | 'invalid' | 'valid' | 'validating'
  defaultError?: boolean
  delay?: number
  submitOnEnter?: boolean
}

export const InputRow = ({
  optional,
  isMetaParameter,
  validation,
  onValidation,
  validationMessage,
  id,
  updateForm,
  value,
  name,
  tooltip,
  className,
  single = true,
  children = null,
  onInputBlur,
  onSetTagStatus,
  defaultError = false,
  delay,
  submitOnEnter = true,
}: InputRowProps): React.ReactElement => {
  const logAction = useLogAction()

  const formRowRef = useRef<HTMLDivElement>(null)

  const [error, setError] = useState(defaultError)
  const [pasteErrorBlock, setPasteErrorBlock] = useState(false)
  const [inputKey, setInputKey] = useState(nanoid())
  const [currentValue, setCurrentValue] = useState(value)
  const [pastedText, setPastedText] = useState('')
  const [errorTracked, setErrorTracked] = useState(false)
  const [duplicateValuePresent, setDuplicateValuePresent] = useState(false)
  const [landingPageHasSpacesError, setLandingPageHasSpacesError] = useState(
    false,
  )

  // Show message about spaces being replaced with URL encoding %20
  const encodeSpaces = useMemo(() => {
    if (validation) {
      const noSpaces = validation.find((item) => item.name === 'NO_SPACES')
      const replaceSpaces = validation.find(
        (item) => item.name === 'REPLACE_SPACES_WITH',
      )

      if (
        noSpaces &&
        replaceSpaces &&
        noSpaces.enabled &&
        !replaceSpaces.enabled
      ) {
        return true
      }
    }

    return false
  }, [validation])

  const errorMessage = !validation
    ? ''
    : validationMessage ||
      `We recommend ${validation
        .filter((item) => item.enabled && checks[item.name])
        .map((item) => {
          return checks[item.name]
        })
        .join(', ')}.`

  useEffect(() => {
    setError(defaultError)
  }, [defaultError])

  useEffect(() => {
    if (currentValue.join('') !== value.join('')) {
      setInputKey(nanoid())
      setCurrentValue(value)
    }
  }, [value])

  const showLabel: string[] = []
  if (optional) showLabel.push('optional')

  if (isMetaParameter) showLabel.push('meta')

  const useLabel = showLabel.join(' ')

  return (
    <FormRow ref={formRowRef} className={className}>
      <LabelSlot className={styles.labelSlot}>
        <Label
          id={`input-${hashCode(name || id)}`}
          optional={useLabel !== '' ? `(${useLabel})` : ''}
        >
          <Tooltip
            id={`${id}-help-text-tooltip`}
            useIcon
            maxWidth={250}
            tooltipPosition="right"
            tooltipMessage={tooltip}
          >
            {name}
            {!optional && <span className={styles.asterix}>*</span>}
          </Tooltip>
        </Label>
      </LabelSlot>
      <FieldSlot>
        {single ? (
          <Input
            delay={delay}
            showClear
            key={inputKey}
            placeholder={`Type ${name}`}
            className={styles.inputField}
            autoCorrect="off"
            autoCapitalize="none"
            autoComplete="off"
            spellCheck="false"
            error={error}
            id={`input-${hashCode(name || id)}`}
            name={`input-${hashCode(name)}`}
            value={currentValue}
            onBlur={(e) => {
              setErrorTracked(false)

              // Accomodate a delay in setting currentValue
              // This may happen if blur occurs too quickly
              const valueOnBlur = e.target.value
              setCurrentValue([valueOnBlur])

              if (onInputBlur) {
                if (id === 'landing-page') {
                  const validUrl = makeLinkSecure(valueOnBlur)

                  if (validUrl !== valueOnBlur) {
                    // Link did not have protocol
                    logAction({
                      variables: {
                        action: 'track-error-landing-page-missing-protocol',
                        extra: '',
                        websiteSection: 'track-create',
                        functionName: 'onBlur',
                        pagePath: '/track/create-links',
                      },
                    })

                    updateForm([validUrl])
                    onInputBlur([validUrl])
                    return
                  }
                }

                onInputBlur([valueOnBlur])
              }
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              // Prevent the form from submitting on Enter if not ready
              if (e.key === 'Enter' && !submitOnEnter) {
                e.preventDefault()
              }
            }}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter' && !submitOnEnter) {
                // Tab to the next field
                // Triggered on keyup to prevent keyup event occurring after focus on new element
                if (formRowRef.current) {
                  const nextRow = formRowRef.current.nextElementSibling

                  if (nextRow) {
                    const nextRowInput = nextRow.getElementsByTagName(
                      'input',
                    )[0]
                    nextRowInput.focus()
                  }
                }
              }
            }}
            onPaste={(e) => {
              setLandingPageHasSpacesError(false)

              // Specifically to block error message being shown about spaces being removed from pasted data
              const newPastedText = e.clipboardData.getData('Text')

              let preparedVal =
                isMetaParameter !== true
                  ? prepareInput(newPastedText, validation)
                  : newPastedText

              // Spaces should not be applied but should still show the error in this case
              if (id === 'landing-page' && /\s/.test(preparedVal)) {
                setLandingPageHasSpacesError(true)
                preparedVal = preparedVal.replaceAll(/\s/g, '')
              }

              let valid = true

              if (validation && onValidation && isMetaParameter !== true) {
                valid = isValidInput(preparedVal, validation)

                onValidation(valid)

                if (id !== 'landing-page' && !encodeSpaces) {
                  setError(!valid)
                }
              }

              if (valid) {
                setCurrentValue([preparedVal])
                updateForm([preparedVal])
              }
            }}
            beforeChange={(inputValue: string) => {
              setError(false)
              setLandingPageHasSpacesError(false)

              if (isMetaParameter !== true) {
                let preparedVal = prepareInput(inputValue, validation)

                // Spaces should not be applied but should still show the error in this case
                if (id === 'landing-page') {
                  // Spaces are not allowed in URLs
                  if (/\s/.test(preparedVal)) {
                    setLandingPageHasSpacesError(true)
                    preparedVal = preparedVal.replaceAll(/\s/g, '')
                  }

                  const valid = isValidInput(inputValue, validation)

                  if (!valid && !error) {
                    logAction({
                      variables: {
                        action: 'track-error-landing-page-special-chars',
                        extra: '',
                        websiteSection: 'track-create',
                        functionName: 'beforeChange',
                        pagePath: '/track/create-links',
                      },
                    })
                  }

                  setError(!valid)
                }

                // Show message about spaces being replaced with URL encoding %20
                if (id !== 'landing-page') {
                  if (encodeSpaces) {
                    setError(/\s/.test(inputValue))
                  }

                  if (preparedVal !== inputValue && !errorTracked) {
                    // Error tracked ensures this does not fire on every character
                    // Reset on blur
                    logAction({
                      variables: {
                        action: 'track-error-parameter-special-chars',
                        extra: '',
                        websiteSection: 'track-create',
                        functionName: 'onBlur',
                        pagePath: '/track/create-links',
                      },
                    })

                    setErrorTracked(true)
                  }
                }

                return preparedVal
              }

              return inputValue
            }}
            onValueChange={(val: string) => {
              let valid = true

              if (validation && onValidation && isMetaParameter !== true) {
                valid = isValidInput(val, validation)

                onValidation(valid)

                // Errors are handled beforeChange for landing page. See above
                if (id !== 'landing-page' && !encodeSpaces) {
                  setError(!valid)
                }
              }

              if (valid) {
                setCurrentValue([val])
                updateForm([val])
              }
            }}
          />
        ) : (
          <AddMultiValuesTags
            defaultError={defaultError}
            initialValue={value}
            enableCommaMultiItems
            className={styles.multiTags}
            type="modal"
            placeholder={`Type ${name} value(s)`}
            validation={pastedText !== '' ? validation : undefined}
            blockEnter={duplicateValuePresent}
            onPaste={(e) => {
              // Specifically to block error message being shown about spaces being removed from pasted data
              const newPastedText = e.clipboardData
                .getData('Text')
                .replaceAll(/(\r|\n)/g, '\t')

              if (
                id === 'landing-page' &&
                /\s/.test(newPastedText) &&
                !/[?=&]/.test(newPastedText)
              ) {
                setPasteErrorBlock(true)
              }

              setPastedText(newPastedText)
            }}
            beforeChange={(v: string) => {
              let inputValue = v
              setError(false)
              setLandingPageHasSpacesError(false)
              setDuplicateValuePresent(false)

              const valueExists = currentValue.find((val) => {
                if (val === inputValue) return true

                if (
                  id === 'landing-page' &&
                  val.replace('https://', '') ===
                    inputValue.replace('https://', '').replaceAll(/\s/g, '')
                ) {
                  return true
                }

                if (pastedText !== '' || /[;,\t\n]/gi.test(inputValue)) {
                  const splitVals = inputValue.split(/[;,\t\n]/gi)

                  const uniqueSplitVals = [...new Set(splitVals)]

                  if (uniqueSplitVals.length < splitVals.length) return true

                  const pastedTextContainsExistingValue = splitVals.find(
                    (splitVal) => {
                      if (val === splitVal) return true

                      if (
                        id === 'landing-page' &&
                        `https://${splitVal}` === val
                      ) {
                        return true
                      }

                      return false
                    },
                  )

                  if (pastedTextContainsExistingValue) return true

                  if (splitVals.indexOf(val) > -1) return true
                }

                return false
              })

              if (valueExists) {
                // Value already present
                setDuplicateValuePresent(true)
              }

              if (!isMetaParameter) {
                // Spaces should not be applied but should still show the error in this case
                if (id === 'landing-page' && !pasteErrorBlock) {
                  const valid = isValidInput(inputValue, validation)

                  if (/\s/.test(inputValue)) {
                    setLandingPageHasSpacesError(true)
                    inputValue = inputValue.replaceAll(/\s/g, '')
                  }

                  if (!valid && !error) {
                    logAction({
                      variables: {
                        action: 'track-error-landing-page-special-chars',
                        extra: '',
                        websiteSection: 'track-create',
                        functionName: 'beforeChange',
                        pagePath: '/track/create-links',
                      },
                    })
                  }

                  setError(!valid)
                }

                if (encodeSpaces) {
                  setError(/\s/.test(inputValue))
                }

                setPasteErrorBlock(false)

                // Prepare input is handled after the tags are split on paste
                if (pastedText === '') {
                  const preparedVal = prepareInput(inputValue, validation)

                  if (
                    id !== 'landing-page' &&
                    preparedVal !== inputValue &&
                    !errorTracked
                  ) {
                    // Error tracked ensures this does nto fire on every character
                    // Reset on blur
                    logAction({
                      variables: {
                        action: 'track-error-parameter-special-chars',
                        extra: '',
                        websiteSection: 'track-create',
                        functionName: 'beforeChange',
                        pagePath: '/track/create-links',
                      },
                    })

                    setErrorTracked(true)
                  }

                  return preparedVal
                }
              }

              setPasteErrorBlock(false)

              if (pastedText !== '') {
                setPastedText('')
              }

              return pastedText !== '' ? pastedText : inputValue
            }}
            onBeforeSet={(val: string[]): string[] => {
              if (id === 'landing-page') {
                return val.map((link) => {
                  const secureLink = makeLinkSecure(link.replaceAll(/\s/g, ''))

                  if (secureLink !== link) {
                    // Link did not have protocol
                    logAction({
                      variables: {
                        action: 'track-error-landing-page-missing-protocol',
                        extra: '',
                        websiteSection: 'track-create',
                        functionName: 'onBeforeSet',
                        pagePath: '/track/create-links',
                      },
                    })
                  }

                  return secureLink
                })
              }
              return val
            }}
            onChange={(val: string[]) => {
              if (duplicateValuePresent) {
                setDuplicateValuePresent(false)
                return
              }

              let urlValidLength = true

              const result = val.filter((item) => item !== '')

              if (validation && onValidation && isMetaParameter !== true) {
                urlValidLength = !val.some((u) => {
                  return !isValidInput(u, validation)
                })

                onValidation(urlValidLength)

                if (
                  id !== 'landing-page' &&
                  !pasteErrorBlock &&
                  !encodeSpaces
                ) {
                  setError(!urlValidLength)
                }
              }

              if (urlValidLength) {
                updateForm(result)
              }

              setPastedText('')
              setErrorTracked(false)
            }}
            onSetTagStatus={(tag) => {
              if (onSetTagStatus) {
                return onSetTagStatus(tag)
              }
              return null
            }}
          />
        )}
        {landingPageHasSpacesError && (
          <p className={styles.footNoteError}>
            <span className={styles.footNoteError}>
              Spaces are not allowed in the landing page URL.
            </span>
          </p>
        )}
        {errorMessage !== '' && error && (
          <p className={styles.footNoteError}>
            {currentValue.join('') !== '' || id === 'landing-page' ? (
              <>
                {typeof errorMessage === 'string' ? (
                  <ReactMarkdown className={styles.footNoteError}>
                    {errorMessage}
                  </ReactMarkdown>
                ) : (
                  errorMessage
                )}
              </>
            ) : (
              <span className={styles.footNoteError}>
                You must enter a value for {name.toLowerCase()}.
              </span>
            )}
          </p>
        )}
        {duplicateValuePresent && (
          <p className={styles.footNoteError}>
            <span className={styles.footNoteError}>Value already exists.</span>
          </p>
        )}
        {children}
      </FieldSlot>
    </FormRow>
  )
}

interface DateRowProps {
  optional?: boolean
  isMetaParameter?: boolean
  updateForm: (value: string[]) => void
  value: null | string
  name: string
  tooltip: string
  id?: string
  className?: string
  dateFormat: string
  defaultError?: boolean
  onValidation?: (valid: boolean) => void
}

export const DateRow = ({
  optional,
  isMetaParameter,
  updateForm,
  name,
  tooltip,
  id,
  className,
  value,
  dateFormat,
  defaultError = false,
  onValidation,
}: DateRowProps): React.ReactElement => {
  // const optionalLabel = optional ? '(optional)' : ''
  // const metaLabel = isMetaParameter ? '(meta)' : ''

  const showLabel: string[] = []

  if (optional) showLabel.push('optional')

  if (isMetaParameter) showLabel.push('meta')

  const useLabel = showLabel.join(' ')

  let dateValue: null | Date = null

  if (value !== null && value !== '' && moment(value, dateFormat).isValid()) {
    const dateFormatted = moment(value, dateFormat).toDate() // .format(dateFormat)
    dateValue = dateFormatted
  }

  const useDateFormat = dateFormat
    .replace(/Y/gi, 'y')
    .replace(/D/gi, 'd')
    .replace(/(\[Q\])/gi, 'QQ')

  const validateValue = (val: string[]) => {
    if (onValidation && !optional && defaultError) {
      const valueNotSet = value === null || value === ''
      onValidation(!valueNotSet)
    }
  }

  return (
    <FormRow className={className}>
      <LabelSlot className={styles.labelSlot}>
        <Label
          id={`select-${hashCode(name || id)}`}
          optional={`${useLabel !== '' ? `(${useLabel})` : ''}`}
        >
          <Tooltip
            id={`${id}-help-text-tooltip`}
            useIcon
            maxWidth={250}
            tooltipPosition="right"
            tooltipMessage={tooltip}
          >
            {name}
            {!optional && <span className={styles.asterix}>*</span>}
          </Tooltip>
        </Label>
      </LabelSlot>
      <FieldSlot>
        <StyledDatePicker
          isError={(value === null || value === '') && defaultError}
          isClearable
          placeholderText={dateFormat}
          dateFormat={useDateFormat}
          showYearPicker={dateFormat.toLowerCase() === 'yyyy'}
          showMonthYearPicker={dateFormat.toLowerCase() === 'yyyymm'}
          showQuarterYearPicker={dateFormat.toLowerCase() === 'yyyy[q]q'}
          selected={dateValue}
          onChange={(date): void => {
            // The value can never be a null
            // only empty string is permitted in this case
            let val = ['']

            if (date !== null) {
              const dateF = moment(date.toString()).format(dateFormat)
              val = [dateF]
            }

            updateForm(val)
            validateValue(val)
          }}
        />
        {defaultError && (value === null || value === '') && (
          <p className={styles.footNoteError}>
            You must select a value for {name.toLowerCase()}.
          </p>
        )}
      </FieldSlot>
    </FormRow>
  )
}
