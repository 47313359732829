import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import { nanoid } from 'nanoid'

import Button from './button'
import Input from './input'
import { LoadingLabel } from './loader'
import SelectBox from './select-box'
import Tooltip from './tooltip'
import { RequestBrandedDomainModal } from './upgrade-modals'
import {
  currentUserDetails,
  CustomDomainType,
  CustomLinkAliasesByDomain,
} from '../api/apollo/variables'
import { validateShortLinkCandidate } from '../api/graphql/track-create-client'
import { maxCustomLinkAliasLength } from '../api/constants'
import RefreshIcon from '../assets/icon-refresh.svg'
import { getItemByKeyValue, replaceCustomLinkCharacters } from '../helpers'
import { getUserData, saveUserData } from '../helpers/local-client'
import { removeCustomLinkFromStorage } from '../helpers/custom-links'
import {
  defaultShortLinkDomain,
  getCustomDomainID,
  minBatchShortLinks,
} from '../helpers/track-module'
import useCustomLinks from '../hooks/useCustomLinks'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import styles from '../styles/custom-link-fields.module.scss'

interface CustomLinkDomainSelectorProps {
  customLinkType?: CustomDomainType
  onDomainChange?: (domain: string) => void
  className?: string
}

export const CustomLinkDomainSelector = ({
  customLinkType = 'shortLink',
  onDomainChange,
  className,
}: CustomLinkDomainSelectorProps) => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const {
    selectedDomain,
    availableDomains,
    updateSelectedDomain,
  } = useCustomLinks(customLinkType)

  const [showBrandedDomainModal, setShowBrandedDomainModal] = useState(false)

  useEffect(() => {
    if (onDomainChange) onDomainChange(selectedDomain)
  }, [selectedDomain])

  return (
    <>
      <SelectBox
        id="domain-selector"
        className={classNames(className, styles.domainSelectorContainer)}
        labelKey="optionName"
        valueKey="optionValue"
        value={availableDomains.find(
          (option) => option.optionValue === selectedDomain,
        )}
        options={availableDomains.filter((dom) => dom.selected)}
        onChange={(newValue) => {
          if (newValue?.optionValue) {
            updateSelectedDomain(newValue.optionValue)
          }
        }}
      >
        {workspaceID && (
          <Button
            variant="text"
            className={styles.addButton}
            onPressStart={() => setShowBrandedDomainModal(true)}
          >
            Get a branded {customLinkType === 'shortLink' ? 'short' : 'deep'}{' '}
            link +
          </Button>
        )}
      </SelectBox>
      {showBrandedDomainModal && (
        <RequestBrandedDomainModal
          onHideModal={setShowBrandedDomainModal}
          linkType={customLinkType}
        />
      )}
    </>
  )
}

interface CustomLinkAliasMainProps {
  customLinkType?: CustomDomainType
  domainID: string
  fetchingAliases: boolean
  alias: string
  onAliasChange?: (alias: string) => void
  error?: boolean
  className?: string
  setCustomLinkStatus?: Dispatch<SetStateAction<UrlStatus>>
}

interface CustomLinkSingleAliasProps extends CustomLinkAliasMainProps {
  index: number
  canUseCustom?: boolean
  replaceAlias: (indexOrNLinks: number) => Promise<CustomLinkAliasesByDomain>
  setCustomLinkMessage?: Dispatch<SetStateAction<string>>
}

interface CustomLinkBatchAliasProps extends CustomLinkAliasMainProps {
  nLinks: number
  replaceBatchAlias?: (nLinks: number) => Promise<CustomLinkAliasesByDomain>
}

const isSingleAlias = (
  props: CustomLinkSingleAliasProps | CustomLinkBatchAliasProps,
): props is CustomLinkSingleAliasProps =>
  Object.prototype.hasOwnProperty.call(props, 'index')

export const CustomLinkAlias = (
  props: CustomLinkSingleAliasProps | CustomLinkBatchAliasProps,
) => {
  const { isDemoAccount } = useReactiveVar(currentUserDetails)

  const { isFree } = useSubscriptionLevel()

  // Shared props
  const {
    customLinkType,
    domainID,
    fetchingAliases,
    alias,
    onAliasChange,
    error,
    className,
    setCustomLinkStatus,
  } = props

  // Single props
  const {
    index,
    canUseCustom,
    replaceAlias,
    setCustomLinkMessage,
  } = isSingleAlias(props)
    ? props
    : {
        index: null,
        canUseCustom: null,
        replaceAlias: null,
        setCustomLinkMessage: null,
      }

  // Batch props
  const { nLinks, replaceBatchAlias } = !isSingleAlias(props)
    ? props
    : { nLinks: null, replaceBatchAlias: null }

  const [validateCustomLinkAlias] = useLazyQuery(validateShortLinkCandidate, {
    fetchPolicy: 'network-only',
  })

  const [shortLink, setShortLink] = useState(alias)
  const [shortLinkIsCustom, setShortLinkIsCustom] = useState(false)
  const [refreshingShortLink, setRefreshingShortLink] = useState(false)

  useEffect(() => {
    setShortLinkIsCustom(false)
  }, [domainID])

  // Reassign shortLink to fetched alias when it is reset
  useEffect(() => {
    setShortLink(alias)

    if (setCustomLinkMessage) setCustomLinkMessage('')
    if (setCustomLinkStatus) setCustomLinkStatus('')
    if (onAliasChange) onAliasChange(alias)
  }, [alias])

  if (isFree && !isDemoAccount) return null

  return (
    <Input
      name="short-link"
      value={refreshingShortLink ? '' : shortLink}
      readOnly={!canUseCustom}
      placeholder="Creating short link..."
      delay={600}
      className={classNames(className, styles.shortLinkInput, {
        [styles.shortLinkInputAnimated]: fetchingAliases || refreshingShortLink,
        [styles.editable]: canUseCustom,
        [styles.error]: error,
      })}
      beforeChange={(v) => replaceCustomLinkCharacters(v)}
      onBlur={() => {
        // Reset alias to randomly generated one if left empty
        if (shortLink === '' && shortLinkIsCustom) {
          setShortLinkIsCustom(false)
          setShortLink(alias)

          if (onAliasChange) onAliasChange(alias)
          if (setCustomLinkMessage) setCustomLinkMessage('')
          if (setCustomLinkStatus) setCustomLinkStatus('')
        }
      }}
      onValueChange={async (value) => {
        if (!canUseCustom || !setCustomLinkMessage || !setCustomLinkStatus)
          return

        setShortLinkIsCustom(true)
        setShortLink(value)

        // No need to validate empty string
        if (value === '') {
          setCustomLinkMessage('')
          setCustomLinkStatus('invalid')
          return
        }

        // If matches batch alias pattern, do not allow
        if (/-0x(.*)/.test(value)) {
          setCustomLinkMessage('This link alias is not valid')
          setCustomLinkStatus('invalid')
          return
        }

        const isMaxLength = shortLink.length > maxCustomLinkAliasLength

        if (isMaxLength) {
          setCustomLinkMessage(
            'Link alias cannot be more than **1024 characters**.',
          )
          setCustomLinkStatus('invalid')
          return
        }

        setCustomLinkStatus('validating')

        let found: any = -1
        let isAvailable = false

        // Check if alias has already been validated for domain
        const savedData = getUserData(domainID)

        if (savedData && Array.isArray(savedData)) {
          found = getItemByKeyValue(savedData, 'shortLinkID', value)

          if (found && found.isAvailable) {
            const { availableUntil } = found

            const now = new Date(Date.now())

            const diff = moment(availableUntil).utc().diff(moment(now).utc())
            const isExpired = diff < 0

            if (!isExpired) {
              // Can be used
              isAvailable = true
            } else {
              // Needs to be rechecked
              found = -1
              removeCustomLinkFromStorage(value, domainID)
            }
          }
        }

        if (found === -1) {
          const { data: validateShortLinkData } = await validateCustomLinkAlias(
            {
              variables: {
                testCandidate: value,
                customDomainID:
                  customLinkType === 'shortLink'
                    ? getCustomDomainID(domainID)
                    : undefined,
              },
            },
          )

          if (validateShortLinkData) {
            saveUserData(
              [validateShortLinkData.track.validateShortLinkCandidate],
              domainID,
            )

            if (
              validateShortLinkData.track.validateShortLinkCandidate.isAvailable
            )
              isAvailable = true
          }
        }

        if (isAvailable) {
          setCustomLinkMessage(
            `${
              customLinkType === 'shortLink' ? 'Short' : 'Deep'
            } link alias **available**.`,
          )
          setCustomLinkStatus('valid')
        } else {
          setCustomLinkMessage(
            `${
              customLinkType === 'shortLink' ? 'Short' : 'Deep'
            } link **is already in use**.${
              domainID === '' || domainID === defaultShortLinkDomain
                ? ' Upgrade to branded domain or enter a different alias.'
                : ''
            }`,
          )
          setCustomLinkStatus('invalid')
        }

        if (onAliasChange) onAliasChange(value)
      }}
      suffix={
        <>
          <Tooltip
            id="refresh-alias-tooltip"
            tooltipMessage={
              fetchingAliases || refreshingShortLink
                ? ''
                : 'Generate random alias'
            }
            tooltipPosition="left"
          >
            <Button
              variant="iconOnly"
              className={styles.shortLinkInputButton}
              isDisabled={fetchingAliases || refreshingShortLink}
              icon={{
                src: RefreshIcon,
                alt: 'Refresh alias',
                imgHeight: 16,
              }}
              onPress={async () => {
                setRefreshingShortLink(true)
                if (setCustomLinkStatus) setCustomLinkStatus('refetching')

                if (nLinks !== null && replaceBatchAlias) {
                  await replaceBatchAlias(nLinks)
                } else if (index !== null && replaceAlias) {
                  await replaceAlias(index)
                }

                setRefreshingShortLink(false)
                setShortLinkIsCustom(false)
                if (setCustomLinkStatus) setCustomLinkStatus('')
              }}
            />
          </Tooltip>
        </>
      }
    />
  )
}

interface CustomLinkFullProps {
  customLinkType?: CustomDomainType
  fetchBatchAlias?: boolean
  customLinkKey?: string
  onAliasChange?: (alias: string) => void
  onDomainChange?: (domain: string) => void
  onStatusChange?: (status: UrlStatus) => void
  domainSelectorOnly?: boolean
  /** Provide a value to show a fixed-test value for the custom link domain instead of a dropdown */
  staticDomainToUse?: {
    id: string
    label: string
  } | null
  className?: string
  domainSelectorClassName?: string
  aliasClassName?: string
  children?: React.ReactElement
}

export const CustomLinkFull = ({
  customLinkType = 'shortLink',
  fetchBatchAlias = false,
  customLinkKey = '',
  onAliasChange,
  onDomainChange,
  onStatusChange,
  domainSelectorOnly = false,
  staticDomainToUse,
  className,
  domainSelectorClassName,
  aliasClassName,
  children,
}: CustomLinkFullProps) => {
  const {
    canUseCustomLinks: canUseShortLinks,
    selectedDomain,
    fetchNewAliases,
    fetchingAliases,
    replaceAlias,
    replaceBatchAlias,
    availableAliases,
    updateSelectedDomain,
  } = useCustomLinks(customLinkType)

  const [customLinkMessage, setCustomLinkMessage] = useState<string>('')
  const [customLinkStatus, setCustomLinkStatus] = useState<UrlStatus>('')

  // Ensure aliases are fetched for the staticDomain rather than the currently selected one
  // Else, fetch alias for selected domain
  useEffect(() => {
    if (
      !availableAliases ||
      domainSelectorOnly ||
      !selectedDomain ||
      (!fetchBatchAlias &&
        (!availableAliases.individual ||
          availableAliases.individual.length > 0)) ||
      (fetchBatchAlias && availableAliases.batch)
    ) {
      return
    }

    if (staticDomainToUse && selectedDomain !== staticDomainToUse.id) {
      updateSelectedDomain(staticDomainToUse.id)
    }

    fetchNewAliases(
      fetchBatchAlias ? minBatchShortLinks : 1,
      staticDomainToUse?.id,
    )
  }, [
    staticDomainToUse,
    selectedDomain,
    fetchBatchAlias,
    availableAliases,
    domainSelectorOnly,
  ])

  useEffect(() => {
    if (onStatusChange) onStatusChange(customLinkStatus)
  }, [customLinkStatus])

  if (!canUseShortLinks) return null

  return (
    <>
      <div className={classNames(className, styles.fullShortLinkWrapper)}>
        {!staticDomainToUse ? (
          <CustomLinkDomainSelector
            customLinkType={customLinkType}
            onDomainChange={onDomainChange}
            className={domainSelectorClassName}
          />
        ) : (
          <p className={styles.staticDomainValue} style={{ margin: 0 }}>
            {staticDomainToUse.label}
          </p>
        )}
        {!domainSelectorOnly && (
          <>
            <p className={styles.shortLinkInputPrefixDiv}>/</p>
            {fetchBatchAlias ? (
              <CustomLinkAlias
                key={customLinkKey}
                domainID={
                  staticDomainToUse ? staticDomainToUse.id : selectedDomain
                }
                onAliasChange={onAliasChange}
                fetchingAliases={fetchingAliases}
                alias={
                  availableAliases && availableAliases.batch
                    ? availableAliases.batch
                    : ''
                }
                nLinks={minBatchShortLinks}
                replaceBatchAlias={replaceBatchAlias}
                setCustomLinkStatus={setCustomLinkStatus}
                error={customLinkStatus !== '' && customLinkStatus !== 'valid'}
              />
            ) : (
              <CustomLinkAlias
                key={customLinkKey}
                className={classNames(aliasClassName)}
                domainID={selectedDomain}
                onAliasChange={onAliasChange}
                fetchingAliases={fetchingAliases}
                replaceAlias={replaceAlias}
                alias={
                  availableAliases &&
                  availableAliases.individual &&
                  availableAliases.individual.length > 0
                    ? availableAliases.individual[0]
                    : ''
                }
                index={0}
                canUseCustom={!fetchBatchAlias}
                setCustomLinkMessage={setCustomLinkMessage}
                setCustomLinkStatus={setCustomLinkStatus}
                error={customLinkStatus !== '' && customLinkStatus !== 'valid'}
              />
            )}
          </>
        )}
      </div>
      {customLinkStatus === 'validating' && (
        <div
          key={nanoid()}
          className={classNames(
            styles.validationCheckShortLink,
            styles.validationCheck,
          )}
        >
          <LoadingLabel label="Checking availability" />
        </div>
      )}
      {customLinkStatus !== 'validating' && customLinkMessage !== '' ? (
        <div
          key={nanoid()}
          className={classNames(
            styles.validationCheckShortLink,
            styles.validationCheck,
            {
              [styles.urlIsBad]: customLinkStatus === 'invalid',
              [styles.urlIsGood]: customLinkStatus === 'valid',
            },
          )}
        >
          <div
            className={classNames(styles.validationCheckItem, {
              [styles.urlIsBad]: customLinkStatus === 'invalid',
              [styles.urlIsGood]: customLinkStatus === 'valid',
            })}
          >
            <ReactMarkdown linkTarget="_blank">
              {customLinkMessage}
            </ReactMarkdown>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  )
}
