import React, { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'

import { currentUserDetails } from '../api/apollo/variables'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import BulkImport from '../components/bulk-import'
import ButtonTabs from '../components/button-tabs'
import CampaignCodeGeneratorFormMulti from '../components/campaign-code-generator-form'
import CampaignCodeGeneratorCloneTab from '../components/campaign-code-generator-clone-tab'
import CampaignIntroContent from '../components/campaign-intro-content'
import EmailCodeGeneratorForm from '../components/email-code-generator-form'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Link from '../components/link'
import { NewEmailHTMLPreview } from '../components/new-email-html-preview'
import RequestField, { RequestFieldModal } from '../components/request-field'
import SiteWrapper from '../components/site-wrapper'
import TrackCreateFormSingle from '../components/track-create-form-single'
import RecentlyCreatedLinks from '../components/track-recently-created-links'
import { ShowLatestCode } from '../components/track-show-latest-link'
import TwoColumns, { Column } from '../components/two-columns'
import {
  getTrackCreateFormData,
  isSavedFormType,
  saveTrackCreateTab,
  trackCreateTabs,
} from '../helpers/track-create'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import useTrackCreateSavedValues from '../hooks/useTrackCreateSavedValues'
import styles from '../styles/track-create.module.scss'

interface TrackCreateIntroProps {
  hasCreatedCode?: boolean
}

export function TrackCreateIntro({
  hasCreatedCode = false,
}: TrackCreateIntroProps) {
  return (
    <Intro title="Create links">
      <CampaignIntroContent firstTime={!hasCreatedCode}>
        <p>
          Create campaign links here to help you track and understand campaign
          traffic. &nbsp;
          <Link type="arrowForward" href="/track/learn">
            Learn more
          </Link>
        </p>
      </CampaignIntroContent>
    </Intro>
  )
}

export default function TrackCreate() {
  const { workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const {
    fullOnboardingSections: { user: userOnboardingSections },
  } = useOnboarding()

  const [
    getGenerator,
    { data: generatorData, loading: loadingGenerator, error: generatorError },
  ] = useLazyQuery(getCampaignCodeGenerator)

  // Wait for account ID so generator can be cached
  useEffect(() => {
    if (!workspaceID) return

    getGenerator()
  }, [workspaceID])

  const generatedStructure = useMemo(() => {
    if (!generatorData) return null

    return generatorData.campaignCodeGenerator
  }, [generatorData])

  const {
    formValues,
    updateFormValues,
    formSubmissionState,
    setFormSubmissionState,
  } = useTrackCreateSavedValues(generatedStructure)

  const [formIsInitialised, setFormIsInitialised] = useState(false)
  const [currentTab, setCurrentTab] = useState<keyof typeof trackCreateTabs>(
    'single',
  )
  const [previousEmailHtml, setPreviousEmailHtml] = useState('')

  // Set initial tab based on localStorage
  // And populate email HTML
  useEffect(() => {
    if (!userID || !workspaceID || !generatedStructure) return

    const savedFormData = getTrackCreateFormData(workspaceID)

    const { active: activeTab } = savedFormData.options

    setCurrentTab(activeTab)

    // Update form with saved data if present
    if (isSavedFormType(activeTab)) {
      updateFormValues(activeTab, savedFormData[activeTab], {
        resetSubmissionState: true,
      })
    }

    if (activeTab === 'email') {
      const { generatedEmailHtml: prevEmail } = savedFormData.email

      setPreviousEmailHtml(prevEmail || '')
    }

    setFormIsInitialised(true)
  }, [userID, workspaceID, generatedStructure])

  // Check if onboarding step is completed
  const hasCreatedCode = useMemo(() => {
    const createCampaignLinkSection = userOnboardingSections.find(
      (section) => section.onboardingSectionID === 'createCampaignLink',
    )

    return !!(
      createCampaignLinkSection && createCampaignLinkSection.sectionCompleted
    )
  }, [userOnboardingSections])

  /* ******************************************************************* */
  /* Still needed? */

  const [newLinks, setNewLinks] = useState<string | string[]>('')
  const [newEmailHtml, setNewEmailHtml] = useState<GeneratedEmailHTML | null>(
    null,
  )
  const [isIntegrationEmail, setIsIntegrationEmail] = useState(false)
  const [requestFieldName, setRequestFieldName] = useState('')
  const [requestFieldModalActive, setRequestFieldModalActive] = useState(false)

  /* ******************************************************************* */

  return (
    <>
      <SiteWrapper>
        <Layout width={1200}>
          <TrackCreateIntro hasCreatedCode={hasCreatedCode} />
          <TwoColumns>
            <Column main>
              <div>
                <ButtonTabs
                  className={styles.buttonTabs}
                  selected={Object.keys(trackCreateTabs).indexOf(currentTab)}
                  isTopOfBox
                  tabsLabels={Object.values(trackCreateTabs)}
                  type="tabs"
                  onChange={(index) => {
                    const formTab = Object.keys(trackCreateTabs)[index]

                    setCurrentTab(formTab as keyof typeof trackCreateTabs)

                    if (isSavedFormType(formTab)) {
                      saveTrackCreateTab(workspaceID, formTab)

                      const savedFormData = getTrackCreateFormData(workspaceID)

                      updateFormValues(formTab, savedFormData[formTab], {
                        switchFormType: true,
                        resetSubmissionState: true,
                      })
                    }

                    logAction({
                      variables: {
                        action: 'track-create-change-tab',
                        pagePath: '/track/create-links',
                        functionName: 'changeTab',
                        websiteSection: 'track',
                        extra: formTab,
                      },
                    })
                  }}
                >
                  <TrackCreateFormSingle
                    formIsInitialised={formIsInitialised}
                    generatedStructure={generatedStructure}
                    loadingGenerator={loadingGenerator}
                    generatorError={!!generatorError}
                    formValues={formValues.single}
                    updateFormValues={(newValues, options) =>
                      updateFormValues('single', newValues, options)
                    }
                    formSubmissionState={formSubmissionState}
                    setFormSubmissionState={setFormSubmissionState}
                    setNewLinks={setNewLinks}
                  />
                  <CampaignCodeGeneratorFormMulti
                    key="generator-multi"
                    setNewLinks={setNewLinks}
                    hasCreatedCode={hasCreatedCode}
                  />
                  <CampaignCodeGeneratorCloneTab key="generator-clone" />
                  <BulkImport
                    key="generator-bulk"
                    hasCreatedCode={hasCreatedCode}
                  />
                  <EmailCodeGeneratorForm
                    key="generator-email"
                    newEmailHtml={newEmailHtml}
                    setNewEmailHtml={setNewEmailHtml}
                    setIsIntegrationEmail={setIsIntegrationEmail}
                    onRequestNewField={(requestedValue) => {
                      setRequestFieldName(requestedValue)
                      setRequestFieldModalActive(true)
                    }}
                    setNewLinks={setNewLinks}
                    hasCreatedCode={hasCreatedCode}
                  />
                </ButtonTabs>
              </div>

              {/* QR code preview for single link */}
              {currentTab === 'single' &&
                typeof newLinks === 'string' &&
                newLinks !== '' && <ShowLatestCode newLink={newLinks} />}

              {/* Most recently generated email HTML */}
              {/* If created in current session, highlights where URLs were changed */}
              {currentTab === 'email' &&
                (newEmailHtml || previousEmailHtml) && (
                  <NewEmailHTMLPreview
                    newEmailHtml={newEmailHtml}
                    previousEmailHtml={previousEmailHtml}
                    isIntegrationEmail={isIntegrationEmail}
                  />
                )}
            </Column>
            <Column side fixed>
              <RecentlyCreatedLinks newLinks={newLinks} />
              <RequestField />
            </Column>
          </TwoColumns>
        </Layout>
      </SiteWrapper>
      {requestFieldModalActive && (
        <RequestFieldModal
          active={requestFieldModalActive}
          toggleActive={setRequestFieldModalActive}
          requestFieldName={requestFieldName}
        />
      )}
    </>
  )
}
