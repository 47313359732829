import React, { useMemo } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'

import Accordion, { AccordionItemElement } from './accordion'
import AccountSupportSettings from './account-support-settings'
import Preferences from './preferences'
import ProfileSettings from './profile-settings'
import { currentUserDetails } from '../api/apollo/variables'
import { getUserInfo } from '../api/graphql/user-client'
import missingAvatar from '../assets/missing-avatar.png'
import { getUrlQuery } from '../helpers'
import styles from '../styles/regular-user-settings.module.scss'

interface RegularUserSettingsProps {
  topBorder?: boolean
}

export default function RegularUserSettings({
  topBorder = false,
}: RegularUserSettingsProps) {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const { data: currentUserData } = useQuery(getUserInfo)

  const profileImgLink = useMemo(() => {
    if (!currentUserData) return ''

    return currentUserData.currentUser.profileImgLink
  }, [currentUserData])

  const { accordionItems, initialOpenState } = useMemo(() => {
    const allItems: AccordionItemElement[] = [
      {
        key: 'profile',
        header: {
          icon: (
            <div className={styles.profileImg}>
              <img
                src={profileImgLink || missingAvatar}
                alt="Profile"
                style={{ borderRadius: '50%' }}
              />
            </div>
          ),
          title: 'Profile',
          subtitle: 'Update your contact information.',
        },
        expandedContent: <ProfileSettings />,
      },
      {
        key: 'preferences',
        header: {
          icon: '❤️',
          title: 'Preferences',
          subtitle: 'Set your homepage and notifications.',
        },
        expandedContent: <Preferences />,
      },
      {
        key: 'support',
        header: {
          icon: '🏢',
          title: 'Support',
          subtitle: 'View account IDs for debugging.',
        },
        expandedContent: <AccountSupportSettings />,
      },
    ]

    const query = getUrlQuery()

    const openItem = query?.get('show')

    const openState = allItems.map(({ key }) => {
      return openItem === key
    })

    return { accordionItems: allItems, initialOpenState: openState }
  }, [userPermission, profileImgLink])

  if (!userPermission) return null

  return (
    <Accordion
      id="regularUserSettings"
      className={topBorder ? undefined : styles.accordionContainer}
      initialOpenState={initialOpenState}
      accordionItems={accordionItems}
    />
  )
}
