import React, { useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'

import Accordion, { AccordionItemElement } from './accordion'
import ManageWorkspaces from './manage-workspaces'
import WorkspaceDetails from './workspace-details'
import WorkspacePreferences from './workspace-preferences'
import { currentUserDetails } from '../api/apollo/variables'
import { getUrlQuery, isAdminUser } from '../helpers'
import styles from '../styles/workspace-settings.module.scss'

export default function WorkspaceSettings() {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const { accordionItems, initialOpenState } = useMemo(() => {
    const allItems: AccordionItemElement[] = [
      {
        key: 'workspaceDetails',
        header: {
          icon: '🖥️',
          title: 'Workspace details',
          subtitle:
            'Update workspace name, homepage, report and QR code settings.',
        },
        expandedContent: <WorkspaceDetails />,
      },
      {
        key: 'workspacePreferences',
        header: {
          icon: '✅',
          title: 'Workspace preferences',
          subtitle: 'Manage access to features and notifications.',
        },
        expandedContent: <WorkspacePreferences />,
      },
      {
        key: 'workspaceConnections',
        header: {
          icon: '📡',
          title: 'Analytics connections',
          subtitle: 'Create new workspaces and connect website analytics.',
        },
        expandedContent: <ManageWorkspaces />,
      },
    ]

    const query = getUrlQuery()

    const openItem = query?.get('show')

    const openState = allItems.map(({ key }) => {
      return openItem === key
    })

    return { accordionItems: allItems, initialOpenState: openState }
  }, [userPermission])

  if (!userPermission || !isAdminUser(userPermission)) return null

  return (
    <Accordion
      id="workspaceSettings"
      className={styles.accordionContainer}
      initialOpenState={initialOpenState}
      accordionItems={accordionItems}
    />
  )
}
