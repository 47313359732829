import React, { useState, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

import Accordion from './accordion'
import Button, { NavigateButton } from './button'
import Link from './link'
import Tag from './tag'
import { BoxedText, Heading } from './typography'
import { UploadExistingUtmSpreadsheetModal } from './upload-existing-utm-spreadsheet-modal'
import { currentUserDetails } from '../api/apollo/variables'
import { updateCachedOnboardingSectionsProgress } from '../api/graphql/custom-queries'
import Stars from '../assets/star-cluster-yellow.svg'
import useLogAction from '../hooks/useLogAction'
import useOnboarding, {
  OnboardingProgressListItem,
} from '../hooks/useOnboarding'
import { brandName, onboardingSectionData } from '../core/constants'
import styles from '../styles/welcome-checklist.module.scss'

interface WelcomeChecklistProps {
  fwdRef?: React.RefObject<HTMLDivElement> | null
}

const WelcomeChecklist = ({ fwdRef = null }: WelcomeChecklistProps) => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const history = useHistory()

  const logAction = useLogAction()

  const {
    mergedOnboardingSections,
    setFullOnboardingSections,
    updateOnboardingSection,
  } = useOnboarding()

  const boxCompletedStates = useMemo(() => {
    return mergedOnboardingSections.map((section) => {
      if (
        section.sectionCompleted &&
        section.sectionSkippedBefore &&
        !section.sectionSkipped
      ) {
        return false
      }
      if (section.sectionCompleted || section.sectionSkipped) {
        return true
      }
      return false
    })
  }, [mergedOnboardingSections])

  const analyticsSection = mergedOnboardingSections.find(
    (section) => section.onboardingSectionID === 'connectAnalytics',
  )

  const [boxOpenStates, setBoxOpenStates] = useState<boolean[]>(
    new Array(mergedOnboardingSections.length).fill(false),
  )
  const [showUtmSheetUploadModal, setShowUtmSheetUploadModal] = useState(false)

  const skipStep = async (
    newState: boolean,
    onboardingStep: string,
    index: number,
  ) => {
    const copy: OnboardingProgressListItem[] = JSON.parse(
      JSON.stringify(mergedOnboardingSections),
    )
    copy[index].sectionSkipped = newState
    copy[index].sectionSkippedBefore = true

    if (copy[index].sectionSkipped) {
      setBoxOpenStates((curr) => {
        const newOpenState = [...curr]

        newOpenState[index] = false

        if (index < curr.length - 1) {
          newOpenState[index + 1] = true
        }

        return newOpenState
      })

      await logAction({
        variables: {
          ...onboardingSectionData[onboardingStep].skipAction,
          pagePath: '/welcome',
        },
      })
    } else {
      await logAction({
        variables: {
          ...onboardingSectionData[onboardingStep].unskipAction,
          pagePath: '/welcome',
        },
      })
    }

    let type: 'user' | 'account' = 'account'

    if (
      copy[index].onboardingSectionID === 'learnUplifter' ||
      copy[index].onboardingSectionID === 'createCampaignLink' ||
      copy[index].onboardingSectionID === 'learnCampaignLinks' ||
      copy[index].onboardingSectionID === 'completeProfile'
    ) {
      type = 'user'
    }

    setFullOnboardingSections((curr) => {
      const newFull: typeof curr = JSON.parse(JSON.stringify(curr))

      const itemIndex = newFull[type].findIndex(
        (section) =>
          section.onboardingSectionID === copy[index].onboardingSectionID,
      )

      newFull[type].splice(itemIndex, 1, copy[index])

      // Update local state
      updateCachedOnboardingSectionsProgress(workspaceID, type, newFull[type])

      return newFull
    })
  }

  const jsxContent = {
    learnUplifter: (
      <>
        <div style={{ margin: '0 0 2em', width: '100%' }}>
          <iframe
            title="Uplifter intro video"
            src="https://player.vimeo.com/video/561312301?h=3bfec10ac8&autoplay=1&color=e61969&title=0&byline=0&portrait=0"
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>
        <p>
          {brandName} helps you create and manage your campaign links. No more
          messy spreadsheets!
        </p>
        <ul>
          <li>
            Get everyone using the same structure for links and eliminate
            mistakes altogether
          </li>
          <li>Keep all your codes in one place and review their performance</li>
          <li>
            Get alerts of problems in your campaigns, and Increase your
            marketing ROI
          </li>
        </ul>
        <p>
          With {brandName}, every ad, every email, every clickthrough is tracked
          right, every single time.
        </p>
      </>
    ),
    createCampaignLink: (
      <>
        <p>
          You can create campaign links on the{' '}
          <BoxedText>
            <Link href="/track/create-links">Track &gt; Create links</Link>
          </BoxedText>{' '}
          page.
        </p>
        {/* <video width="100%" playsInline controls poster={campaignLinksThumb}>
            <source
              src="https://uplifterblobstorage.blob.core.windows.net/uplifter-videos/how-to-create-campaign-links.mp4"
              type="video/mp4"
            />
            <track
              src="captions_en.vtt"
              kind="captions"
              label="english_captions"
            />
          </video> */}
        <ol>
          <li>Type or copy your Landing page URL</li>
          <li>Type or select the parameters you want to track in your link</li>
          <li>
            Click{' '}
            <Button className={styles.mockButton} demoOnly>
              Create campaign link
            </Button>
          </li>
        </ol>
        <p>
          Your new link will appear on the right side of the page. You can
          download it or share it with whoever needs it.
        </p>
        <p>
          You can view all your links on the{' '}
          <BoxedText>
            <Link href="/track/view-links">Track &gt; View links</Link>
          </BoxedText>{' '}
          page.
        </p>
      </>
    ),
    editTaxonomy: (
      <>
        <Tag inline icon={Stars} className={styles.utmUploadMessage}>
          <p>
            <strong>Have an existing UTM spreadsheet?</strong>{' '}
            <NavigateButton onPress={() => setShowUtmSheetUploadModal(true)}>
              Upload it and we will set up your parameters
            </NavigateButton>
          </p>
        </Tag>
        <p>
          If you need custom rules and parameters, admin users can set them up
          on the{' '}
          <BoxedText>
            <Link href="/track/edit-parameters-and-rules">
              Track &gt; Edit parameters
            </Link>
          </BoxedText>{' '}
          page.
        </p>
        <p>From here you can:</p>
        <ul style={{ margin: '16px 0' }}>
          <li>Add, edit and delete parameters</li>
          <li>
            Change a parameter to either a dropdown, free text or date picker
            field
          </li>
          <li>Define how parameters should be separated in the URL</li>
        </ul>
      </>
    ),
    connectAnalytics: (
      <>
        <p>
          We can import data from your web analytics platform to help you
          measure each links performance.
        </p>
        <p>You can view your analytics metrics in:</p>
        <ul>
          <li>
            <BoxedText>
              <Link href="/track/view-links">Track &gt; View links</Link>
            </BoxedText>{' '}
            after selecting the metrics you want from the dropdown above the
            table
          </li>
          <li>
            <BoxedText>
              <Link href="/report/performance">Report &gt; Performance</Link>
            </BoxedText>{' '}
            after selecting the granularity and time period
          </li>
        </ul>
        {analyticsSection?.sectionCompleted ? (
          <p style={{ fontWeight: 'bold' }}>
            Your website is already connected to your analytics tool.
          </p>
        ) : (
          <p>
            Click the 'Connect analytics' button and follow the instructions.
          </p>
        )}
      </>
    ),
    inviteUsers: (
      <>
        <p>
          {brandName} is designed to let marketeers and agencies self-serve
          campaign links and reports.
        </p>
        <p>
          You can invite other users to use your account in{' '}
          <BoxedText>
            <Link href="/settings?show=team">Settings &gt; Users</Link>
          </BoxedText>
          .
        </p>
        <ul>
          <li>
            Regular users can create campaign links, review performance, and
            request new dropdown values
          </li>
          <li>
            Admin users can also edit your campaign link parameters/rules,
            approve/deny requests for new dropdown values and add/remove users
          </li>
        </ul>
        <p>
          After you've added them, new users will receive an email invitation.
          They need to click on the link in the email and set a password within
          14 days to gain access.
        </p>
      </>
    ),
  }

  const filteredOnboardingSections = mergedOnboardingSections.filter(
    ({ onboardingSectionID }) =>
      onboardingSectionID && !!onboardingSectionData[onboardingSectionID],
  )

  if (boxCompletedStates.length === 0) return null

  return (
    <>
      <Accordion
        id="onboardingSections"
        className={styles.accordionContainer}
        allowMultipleOpen
        initialOpenState={boxOpenStates}
        onToggle={async (index, state) => {
          // Update this section as soon as opened
          if (
            filteredOnboardingSections[index].onboardingSectionID ===
              'learnUplifter' &&
            state === 'close'
          ) {
            updateOnboardingSection('learnUplifter', 'user')

            if (onboardingSectionData.learnUplifter.actionData) {
              await logAction({
                variables: {
                  ...onboardingSectionData.learnUplifter.actionData,
                  pagePath: '/welcome',
                },
              })
            }
          }
        }}
        accordionItems={filteredOnboardingSections.map(
          (
            {
              onboardingSectionID,
              sectionCompleted,
              sectionSkipped,
              sectionSkippedBefore,
            },
            index,
          ) => {
            const sectionFull = onboardingSectionData[onboardingSectionID]

            return {
              key: onboardingSectionID,
              header: {
                checked: boxCompletedStates[index],
                title: (
                  <Heading
                    type={2}
                    align="left"
                    className={
                      boxCompletedStates[index]
                        ? styles.completedSection
                        : undefined
                    }
                  >
                    {sectionFull.title}
                  </Heading>
                ),
              },
              expandedContent: (
                <>
                  {!!sectionFull.content &&
                    typeof sectionFull.content === 'string' && (
                      <ReactMarkdown>{sectionFull.content}</ReactMarkdown>
                    )}
                  {!sectionFull.content && jsxContent[onboardingSectionID]}
                  <div className={styles.buttonsContainer}>
                    {(!!sectionFull.link || !!sectionFull.linkCopy) && (
                      <Button
                        onPress={() => {
                          if (
                            sectionFull.title === `Learn about ${brandName}`
                          ) {
                            setBoxOpenStates((curr) => {
                              const newState = [...curr]

                              newState[index] = false
                              newState[index + 1] = true

                              return newState
                            })

                            updateOnboardingSection('learnUplifter', 'user')
                          } else if (
                            onboardingSectionID === 'connectAnalytics' &&
                            sectionCompleted
                          ) {
                            history.push('/settings?show=connect')
                          } else if (typeof sectionFull.link === 'string') {
                            history.push(sectionFull.link)
                          }
                        }}
                      >
                        {onboardingSectionID === 'connectAnalytics' &&
                          sectionCompleted && <>View connected websites</>}
                        {!(
                          onboardingSectionID === 'connectAnalytics' &&
                          sectionCompleted
                        ) && sectionFull.linkCopy}
                      </Button>
                    )}
                    {!!sectionFull.skipAction && (
                      <Button
                        variant="secondary"
                        className={
                          onboardingSectionID === 'learnUplifter'
                            ? styles.markCompleteRight
                            : ''
                        }
                        onPress={() => {
                          const status =
                            !sectionSkippedBefore && sectionCompleted
                              ? false
                              : !sectionSkipped
                          skipStep(status, onboardingSectionID, index)
                        }}
                      >
                        {boxCompletedStates[index]
                          ? 'Mark as incomplete'
                          : 'Skip'}
                      </Button>
                    )}
                  </div>
                </>
              ),
            }
          },
        )}
      />
      {showUtmSheetUploadModal && (
        <UploadExistingUtmSpreadsheetModal
          onHideModal={setShowUtmSheetUploadModal}
        />
      )}
    </>
  )
}

export default WelcomeChecklist
