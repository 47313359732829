import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { nanoid } from 'nanoid'
import FileSaver from 'file-saver'
import classNames from 'classnames'

import { NavigateButton } from './button'
import FileDragAndDrop from './file-drag-and-drop'
import Input, { Label } from './input'
import Link from './link'
import { Preloader } from './loader'
import { FieldSlot, FormRow, LabelSlot } from './row'
import { CustomLinkFull } from './custom-link-fields'
import Tooltip from './tooltip'
import { InnerBox, OuterBox } from './two-columns'
import { BoxedText, NoteText } from './typography'
import { RequestShortLinksModal } from './upgrade-modals'
import { currentUserDetails } from '../api/apollo/variables'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import {
  getDownloadTemplateLinkGQL,
  uploadBulkTemplate,
} from '../api/REST/track-client'
import { brandName, messages, supportEmail } from '../core/constants'
import { getItemByKeyValue } from '../helpers'
import { getUserData, saveUserData } from '../helpers/local-client'
import {
  FullValidationCheck,
  defaultShortLinkDomain,
  getValidationChecksObject,
} from '../helpers/track-module'
import useCustomLinks from '../hooks/useCustomLinks'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import styles from '../styles/bulk-import.module.scss'

export interface BulkImportProps {
  hasCreatedCode?: boolean
}

export default function BulkImport({ hasCreatedCode = true }: BulkImportProps) {
  const { workspaceID, companySubscriptionLevel } = useReactiveVar(
    currentUserDetails,
  )

  const logAction = useLogAction()
  const { canUseCustomLinks: canUseShortLinks, useAliases } = useCustomLinks()

  const history = useHistory()

  const { updateOnboardingSection } = useOnboarding()

  const [
    getGenerator,
    { data: generatorData, loading: loadingGenerator },
  ] = useLazyQuery(getCampaignCodeGenerator)

  const savedState = getUserData()
  const initialState = savedState?.linkType || 'full'
  const [linkType, setLinkType] = useState<string>(initialState)
  const [shortLinkDomain, setShortLinkDomain] = useState('')
  const [shortLink, setShortLink] = useState('')
  const [shortLinkKey, setShortLinkKey] = useState(nanoid())
  const [shortLinkStatus, setShortLinkStatus] = useState<UrlStatus>('')
  const [showShortLinkModal, setShowShortLinkModal] = useState(false)

  const [error, setError] = useState<string | React.ReactElement>('')
  const [importInProgress, setImportInProgress] = useState(false)

  // Wait for account ID so generator can be cached
  useEffect(() => {
    if (!workspaceID) return

    getGenerator()
  }, [workspaceID])

  const generatedStructure = useMemo(() => {
    if (!generatorData) return null

    return generatorData.campaignCodeGenerator
  }, [generatorData])

  const validationChecks: FullValidationCheck[] | null = useMemo(() => {
    return getValidationChecksObject(generatedStructure)
  }, [generatedStructure])

  // Don't allow short links from local storage for unpaid accounts
  useEffect(() => {
    if (companySubscriptionLevel && linkType === 'short' && !canUseShortLinks) {
      setLinkType('full')
      saveUserData({ linkType: 'full' })
    }
  }, [companySubscriptionLevel])

  // Initial value setter for link type based on validationChecks
  useEffect(() => {
    if (!canUseShortLinks) return

    const foundShortLink = getItemByKeyValue(
      validationChecks,
      'name',
      'FORCE_SHORT_LINK',
    )

    if (
      foundShortLink !== -1 &&
      foundShortLink.enabled &&
      foundShortLink.value
    ) {
      const shortLinkValue = JSON.parse(foundShortLink.value)

      const selectedRule = shortLinkValue.find((option: any) => option.selected)
        .optionValue

      if (selectedRule === 'force-short-links' && linkType === 'full') {
        setLinkType('short')
        return
      }

      if (selectedRule === 'force-long-links' && linkType === 'short') {
        setLinkType('full')
        return
      }

      if (!savedState?.linkType) {
        setLinkType(selectedRule === 'recommend-short-links' ? 'short' : 'full')
        return
      }
    }

    // If rule not found, set based on subscription level
    if (!savedState?.linkType) {
      setLinkType(canUseShortLinks ? 'short' : 'full')
    }
  }, [savedState, generatedStructure, canUseShortLinks])

  // Order/presence of short link options
  const {
    hideLongLinkOption,
    hideShortLinkOption,
    showShortLinkOptionFirst,
  } = useMemo(() => {
    if (!canUseShortLinks) {
      return {
        hideLongLinkOption: false,
        hideShortLinkOption: false,
        showShortLinkOptionFirst: false,
      }
    }

    const foundShortLink = getItemByKeyValue(
      validationChecks,
      'name',
      'FORCE_SHORT_LINK',
    )

    if (foundShortLink && foundShortLink.enabled && foundShortLink.value) {
      const shortLinkValue = JSON.parse(foundShortLink.value)

      const selectedRule = shortLinkValue.find((option: any) => option.selected)
        .optionValue

      switch (selectedRule) {
        case 'force-short-links':
          return {
            hideLongLinkOption: true,
            hideShortLinkOption: false,
            showShortLinkOptionFirst: false,
          }
        case 'force-long-links':
          return {
            hideLongLinkOption: false,
            hideShortLinkOption: true,
            showShortLinkOptionFirst: false,
          }
        case 'recommend-short-links':
          return {
            hideLongLinkOption: false,
            hideShortLinkOption: false,
            showShortLinkOptionFirst: true,
          }
        case 'recommend-long-links':
          return {
            hideLongLinkOption: false,
            hideShortLinkOption: false,
            showShortLinkOptionFirst: false,
          }
        default:
          return {
            hideLongLinkOption: false,
            hideShortLinkOption: false,
            showShortLinkOptionFirst: canUseShortLinks,
          }
      }
    }

    return {
      hideLongLinkOption: false,
      hideShortLinkOption: false,
      showShortLinkOptionFirst: canUseShortLinks,
    }
  }, [generatedStructure, canUseShortLinks])

  // Show short link editor
  const showShortLinkEdit = useMemo(() => {
    return linkType === 'short' || linkType === 'combined'
  }, [linkType])

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        setImportInProgress(true)

        const res = await uploadBulkTemplate({
          file: acceptedFiles.pop() as File,
          createShortLinks: linkType === 'short',
          customDomainID:
            linkType === 'short' && shortLinkDomain !== defaultShortLinkDomain
              ? shortLinkDomain
              : null,
          bulkStart: linkType === 'short' ? shortLink : null,
        })

        if (res === true) {
          setError('')
          setImportInProgress(false)

          if (linkType === 'short') {
            useAliases([shortLink])
            // Re-render shortLink component so custom alias is replaced with existing generated one
            setShortLinkKey(nanoid())
          }

          // @ts-ignore
          if (window.dataLayer && window.dataLayer.push) {
            // @ts-ignore
            window.dataLayer.push({
              event: 'create_campaign_link',
              link_creation_type: 'add-code-bulk',
              link_count: 1,
            })
          }

          logAction({
            variables: {
              action: 'add-code-bulk',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
              extra: '1',
            },
          })

          if (!hasCreatedCode) {
            // Updates the backend cache for onboarding state
            updateOnboardingSection('createCampaignLink', 'user')
          }

          window.setTimeout(() => {
            history.push({
              pathname: '/track/view-links',
              state: { fromBulk: true },
            })
          }, 2000)

          return
        }

        if (
          res?.status === 400 &&
          res?.error?.response?.data &&
          res?.error?.response?.headers['content-type'] ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          // Save the file as a blob and add a button to download it
          const fileBlob = new Blob([new Uint8Array(res.error.response.data)], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })

          setError(
            <>
              <span>
                There were validation issues with your form.{' '}
                <NavigateButton
                  variant="text"
                  onPress={async () => {
                    const now = new Date(Date.now())

                    await FileSaver.saveAs(
                      fileBlob,
                      `${moment(now).format(
                        'YYYY-MM-DD',
                      )} - ${brandName} Bulk Upload Errors.xlsx`,
                    )
                  }}
                >
                  Download error sheet
                </NavigateButton>
              </span>
            </>,
          )

          setImportInProgress(false)

          return
        }

        const resJSON =
          res?.status === 500
            ? res?.error?.response?.data
            : JSON.parse(
                String.fromCharCode.apply(
                  null,
                  // @ts-ignore
                  new Uint8Array(res?.error?.response?.data),
                ),
              )

        const type = resJSON?.detail || ''

        if (
          type === 'BAD_TEMPLATE' ||
          type === 'Template file does not match expected format' ||
          type ===
            'The header of the uploaded file does not match the current template.'
        ) {
          setError(
            'Incorrect import template. Please download and use the sample template.',
          )
        } else if (type === 'BAD_FILE_FORMAT') {
          setError('Incorrect file type.')
        } else if (type === 'FAILED_UPLOAD') {
          setError('File upload has failed, please try again.')
        } else {
          setError(
            <>
              File upload has failed. Please email{' '}
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> for
              help.
            </>,
          )
        }

        setImportInProgress(false)
      }
    },
    [shortLink, shortLinkDomain, linkType],
  )

  if (loadingGenerator)
    return (
      <OuterBox className={styles.outerBox}>
        <InnerBox className={classNames(styles.innerBox, styles.loading)}>
          <Preloader />
        </InnerBox>
      </OuterBox>
    )

  return (
    <>
      <OuterBox className={styles.outerBox}>
        <InnerBox className={styles.innerBox}>
          <p className={styles.intro}>
            <span>
              Bulk create links by uploading a CSV with landing pages and
              parameters.
            </span>
          </p>
          <div className={styles.bulkUploadContent}>
            <ol className={styles.bulkList}>
              <li>
                <NavigateButton
                  className={styles.downloadButton}
                  onPress={async () => {
                    await getDownloadTemplateLinkGQL()
                  }}
                >
                  Download template
                </NavigateButton>
              </li>
              <li>
                <Tooltip
                  id="bulk-upload-download-template-tooltip"
                  useIcon
                  tooltipMessage={
                    <ul>
                      <li>
                        Dropdown parameters match dropdown names (not codes)
                      </li>
                      <li>No prohibited special characters</li>
                      <li>No prohibited parent-child combinations</li>
                      <li>Under max character limit</li>
                      <li>Correct date formats</li>
                    </ul>
                  }
                >
                  Add landing pages and parameters that match your validation
                  rules
                </Tooltip>
              </li>
              <li>
                Save the template as a CSV file (or .xlsx if containing
                non-Latin characters)
              </li>
              <li>
                Choose whether to create short links for all landing pages in
                your CSV
              </li>
              <li>Upload your CSV below</li>
            </ol>
            <NoteText>
              Uploads which don't match your validation rules will return an
              excel file with invalid cells highlighted. Please correct any
              mistakes and reupload.
            </NoteText>
            <p>
              Created links will appear in 'Your recently created links' and{' '}
              <BoxedText>
                <Link href="/track/view-links">Track&gt; View links</Link>
              </BoxedText>
              .
            </p>
            {!hideLongLinkOption && !hideShortLinkOption && (
              <FormRow
                className={classNames(styles.shortLinkRow, {
                  [styles.customiseShortLink]: showShortLinkEdit,
                })}
              >
                <LabelSlot className={styles.labelSlot}>
                  <Label id="link-type">
                    <Tooltip
                      id="link-type-tooltip"
                      useIcon
                      maxWidth={350}
                      tooltipPosition="right"
                      tooltipMessage={messages.linkTypes}
                    >
                      Link type
                    </Tooltip>
                  </Label>
                </LabelSlot>
                <FieldSlot>
                  <div className={styles.inlineWrap}>
                    <div className={styles.radioPanel}>
                      {!showShortLinkOptionFirst && (
                        <Input
                          type="radio"
                          id="link-type-2"
                          name="link-type"
                          label={
                            <>
                              Basic link{' '}
                              {canUseShortLinks && (
                                <span className={styles.recommended}>
                                  (recommended)
                                </span>
                              )}
                            </>
                          }
                          checked={linkType === 'full'}
                          onClick={() => {
                            const val = 'full'
                            setLinkType(val)
                            saveUserData({
                              linkType: val,
                            })
                          }}
                        />
                      )}
                      <Input
                        type="radio"
                        id="link-type-1"
                        name="link-type"
                        disabled={!canUseShortLinks}
                        label={
                          <>
                            Short link{' '}
                            {(showShortLinkOptionFirst ||
                              !canUseShortLinks) && (
                              <span className={styles.recommended}>
                                (recommended)
                              </span>
                            )}
                          </>
                        }
                        checked={
                          linkType === 'short' || linkType === 'combined'
                        }
                        onClick={() => {
                          if (!canUseShortLinks) {
                            // Disabled for unpaid accounts - show modal
                            // @ts-ignore
                            if (window.dataLayer && window.dataLayer.push) {
                              // @ts-ignore
                              window.dataLayer.push({
                                event: 'click-shortlink-upgrade-blocker',
                              })
                            }

                            logAction({
                              variables: {
                                action: 'click-shortlink-upgrade-blocker',
                                websiteSection: 'track',
                                pagePath: window.location.pathname,
                                functionName: 'clickUpgrade',
                                extra: 'bulkUpload',
                              },
                            })

                            setShowShortLinkModal(true)

                            return
                          }

                          const val = 'short'
                          setLinkType(val)
                          saveUserData({
                            linkType: val,
                          })
                        }}
                      />
                      {showShortLinkOptionFirst && (
                        <Input
                          type="radio"
                          id="link-type-2"
                          name="link-type"
                          label="Basic link"
                          checked={linkType === 'full'}
                          onClick={() => {
                            const val = 'full'
                            setLinkType(val)
                            saveUserData({
                              linkType: val,
                            })
                          }}
                        />
                      )}
                    </div>
                  </div>
                </FieldSlot>
              </FormRow>
            )}
            {showShortLinkEdit && (
              <FormRow className={styles.shortLinkRow}>
                <LabelSlot className={styles.labelSlot}>
                  <>
                    <Label id="link-type">
                      <Tooltip
                        id="short-link-type-tooltip"
                        useIcon
                        maxWidth={350}
                        tooltipPosition="right"
                        tooltipMessage={messages.batchShortLinkAlias}
                      >
                        Bulk short link template
                      </Tooltip>
                    </Label>
                  </>
                </LabelSlot>

                <FieldSlot>
                  <div className={styles.linkInputWrapper}>
                    <CustomLinkFull
                      fetchBatchAlias
                      customLinkKey={shortLinkKey}
                      onDomainChange={(domain) => setShortLinkDomain(domain)}
                      onAliasChange={(alias) => setShortLink(alias)}
                      onStatusChange={(status) => setShortLinkStatus(status)}
                    />
                  </div>
                </FieldSlot>
              </FormRow>
            )}
            <FileDragAndDrop
              disabled={shortLinkStatus === 'refetching'}
              error={error}
              onDrop={onDrop}
              inProgress={importInProgress || shortLinkStatus === 'refetching'}
              uploadButtonText="Upload CSV"
            />
          </div>
        </InnerBox>
      </OuterBox>
      {showShortLinkModal && (
        <RequestShortLinksModal onHideModal={setShowShortLinkModal} />
      )}
    </>
  )
}
