import React, { useMemo } from 'react'
import numeral from 'numeraljs'

import Link from './link'
import Modal from './modal'
import { BoxedText } from './typography'
import { supportEmail } from '../core/constants'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/campaign-code-generator.module.scss'

interface WarningModalProps {
  onYes: () => void
  onNo: React.Dispatch<React.SetStateAction<boolean>>
  type: WarningTypes
  maxLength?: number
  url: string[]
}

export default function WarningModal({
  onYes,
  onNo,
  type,
  maxLength = 0,
  url,
}: WarningModalProps) {
  const logAction = useLogAction()

  const action = useMemo(() => {
    switch (type) {
      case 'invalid-length':
        return 'max-url-length'
      case 'invalid-query-length':
        return 'max-query-length'
      case 'no-url':
        return 'missing-landing'
      case 'no-url-shortlink':
        return 'shortlink-without-url'
      case 'has-anchor':
        return 'has-anchor'
      default:
        return ''
    }
  }, [type])

  const { noLabel, modalHeading, yesLabel } = useMemo(() => {
    switch (type) {
      case 'invalid-length':
        return {
          yesLabel: 'Yes, create',
          noLabel: 'Back and edit landing page URL',
          modalHeading: 'These links are too long',
        }
      case 'invalid-query-length':
        return {
          yesLabel: 'Yes, create',
          noLabel: 'Back and edit parameters',
          modalHeading: 'These links are too long',
        }
      case 'no-url':
        return {
          yesLabel: 'Yes, create',
          noLabel: 'Back and add landing page URL',
          modalHeading: `Invalid landing page URL`,
        }
      case 'no-url-shortlink':
        return {
          yesLabel: 'Yes, create',
          noLabel: 'Back and use full link',
          modalHeading: `Short link with no landing page URL`,
        }
      case 'has-anchor':
        return {
          yesLabel: 'Continue creating link',
          noLabel: 'Cancel',
          modalHeading: 'Page anchor detected',
        }
      default:
        return {
          yesLabel: 'Yes, create',
          noLabel: 'Cancel',
          modalHeading: 'Error',
        }
    }
  }, [type])

  const showCreateButton = useMemo(() => {
    return !(type === 'invalid-length' || type === 'invalid-query-length')
  }, [type])

  return (
    <Modal
      setIsOpen={onNo}
      isWarning={type !== 'has-anchor'}
      modalHeader={modalHeading}
      noText={noLabel}
      afterClose={() => {
        logAction({
          variables: {
            action: `${action}-update`,
            extra: JSON.stringify({
              url,
            }),
            websiteSection: 'track-create',
            functionName: 'closeModal',
            pagePath: '/track/create',
          },
        })
      }}
      yesText={yesLabel}
      yesButtonDisabled={!showCreateButton}
      onYes={
        !showCreateButton
          ? undefined
          : () => {
              onYes()

              logAction({
                variables: {
                  action: `${action}-ignore`,
                  extra: JSON.stringify({
                    url,
                  }),
                  websiteSection: 'track-create',
                  functionName: 'ignoreWarning',
                  pagePath: '/track/create',
                },
              })
            }
      }
    >
      <div className={styles.modalBody}>
        {type === 'has-anchor' && (
          <>
            <p>
              Your link contains a page anchor '#' which will be moved to the
              end of your link. This is required to make sure campaign
              parameters are captured and your link can auto scroll.{' '}
              <Link href="https://support.uplifter.ai/hc/en-us/articles/4469479289873-Can-I-use-anchor-tags-or-in-my-landing-page-URL-with-query-string-parameters-utms-">
                Find out more
              </Link>
              .
            </p>
          </>
        )}
        {type === 'no-url' && (
          <>
            <p>
              Your landing page URL is either empty or invalid, users could be
              directed to an error page.
            </p>
            <p>
              You should only create this link if you plan on editing your
              landing page URL in the{' '}
              <BoxedText>Track &gt; View links</BoxedText> table before the
              campaign goes live.
            </p>
          </>
        )}
        {type === 'no-url-shortlink' && (
          <>
            <p>
              You are using a short link but do not have a landing page URL.
            </p>
            <p>
              You should only create this link if you plan on adding your
              landing page URL in the{' '}
              <BoxedText>Track &gt; View links</BoxedText> table before the
              campaign goes live.
            </p>
          </>
        )}
        {type === 'invalid-length' && (
          <>
            <p>
              The resulting links will be too long to work in most web browsers
              or marketing platforms.
            </p>
            <p>
              At least one of your links is longer than the recommended{' '}
              {numeral(maxLength).format('0,0')} characters.
            </p>
            <p>
              Try <strong>changing the landing page</strong> to a shorter url.
            </p>
          </>
        )}
        {type === 'invalid-query-length' && (
          <>
            <p>
              The query string length will be too long to work in most web
              browsers or marketing platforms.
            </p>
            <p>
              At least one of your query links is longer than the recommended{' '}
              {numeral(maxLength).format('0,0')} characters.
            </p>
            <p>
              Type <strong>less characters into the parameter values</strong>{' '}
              for a shorter string.
            </p>
            <p>
              For help email{' '}
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
            </p>
          </>
        )}
      </div>
    </Modal>
  )
}
