import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import classNames from 'classnames'

import Accordion from './accordion'
import Button, { NavigateButton } from './button'
import { BetaLabel } from './counter'
import { Heading } from './typography'
import Input, { ClickEditInput, Label } from './input'
import Row from './row'
import SelectBox, { SelectBoxChecklist } from './select-box'
import Tooltip from './tooltip'
import {
  RequestBrandedDomainModal,
  RequestShortLinksModal,
} from './upgrade-modals'
import { dataSourceReactive } from '../api/apollo/variables'
import {
  getIntegrationsStatus,
  getSavedPardotAcctList,
} from '../api/graphql/integrations-client'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import {
  updateDefaultPardotBusinessUnit,
  updateExistingParamsAddedToStart,
  updateGeneratorParameterByID,
  updateMasterPrefixAndSeparator,
  updateParamsMasterRules,
  updateUplifterIDEnabledState,
} from '../api/graphql/track-edit-client'
import { ValidationChecks } from '../api/types'
import { getItemByKeyValue, prepareInput } from '../helpers'
import {
  FullValidationCheck,
  ValidationChecksCategory,
  defaultEnabled,
  defaultValidationChecksFull,
  getValidationCheck,
} from '../helpers/track-module'
import useCustomLinks, { AvailableDomain } from '../hooks/useCustomLinks'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import styles from '../styles/track-edit-advanced-rules.module.scss'

interface PrefixSeparatorRulesProps {
  useValidation: ValidationChecks[]
  setHoverItem: React.Dispatch<React.SetStateAction<string | null>>
  isLocked: boolean
  setShowUnlockModal: () => void
  trackAction: (action: string, newVal: string, oldVal: string) => void
}

const PrefixSeparatorRules = ({
  useValidation,
  setHoverItem,
  isLocked,
  setShowUnlockModal,
  trackAction,
}: PrefixSeparatorRulesProps) => {
  const { data: campaignCodeGeneratorData } = useQuery(getCampaignCodeGenerator)

  const [updatePrefixSeparator] = useMutation(updateMasterPrefixAndSeparator)

  const { masterPrefix, paramSeparator } = useMemo(() => {
    if (!campaignCodeGeneratorData) {
      return { masterPrefix: '', paramSeparator: '' }
    }

    return campaignCodeGeneratorData.campaignCodeGenerator
  }, [campaignCodeGeneratorData])

  const [prefix, setPrefix] = useState(masterPrefix)
  const [separator, setSeparator] = useState(paramSeparator)

  return (
    <>
      <div
        onMouseEnter={() => setHoverItem('masterPrefix')}
        onMouseLeave={() => setHoverItem(null)}
        style={{ marginBottom: 8 }}
      >
        <Row align="flex-start" vAlign="center" className={styles.ruleRow}>
          <Label id="masterPrefix">
            <Tooltip
              id="master-prefix-tooltip"
              useIcon
              maxWidth={300}
              tooltipPosition="right"
              tooltipMessage="The character(s) that immediately follow the landing page URL. This separates the landing page URL from the query string parameters and normally starts with a question mark."
            >
              Master prefix
            </Tooltip>
          </Label>
          <ClickEditInput
            id="masterPrefix"
            name="masterPrefix"
            className={classNames(styles.advancedRulesClickEdit, {
              [styles.emptyInput]: prefix === '',
            })}
            value={prefix}
            placeholder="(Not set)"
            disableEditFn={isLocked ? () => setShowUnlockModal() : undefined}
            beforeChange={(inputValue: string) => {
              const preparedInput = prepareInput(inputValue, useValidation)

              return preparedInput
            }}
            onChange={(e) => {
              const origVal = prefix

              setPrefix(e)

              updatePrefixSeparator({
                variables: {
                  masterPrefix: e,
                },
              })

              trackAction('update-generator-master-prefix', e, origVal)
            }}
          />
        </Row>
      </div>
      <div
        onMouseEnter={() => {
          setHoverItem('paramSeparator')
        }}
        onMouseLeave={() => {
          setHoverItem(null)
        }}
      >
        <Row align="flex-start" vAlign="center" className={styles.ruleRow}>
          <Label id="separator">
            <Tooltip
              id="separator-tooltip"
              useIcon
              maxWidth={300}
              tooltipPosition="right"
              tooltipMessage="Used to split up each query string parameter. This is usually an ampersand, but may be blank if combining multiple inputs into one query string parameter."
            >
              Separator
            </Tooltip>
          </Label>
          <ClickEditInput
            id="separator"
            name="separator"
            className={classNames(styles.advancedRulesClickEdit, {
              [styles.emptyInput]: separator === '',
            })}
            value={separator}
            placeholder="(Not set)"
            disableEditFn={isLocked ? () => setShowUnlockModal() : undefined}
            beforeChange={(inputValue: string) => {
              const preparedInput = prepareInput(inputValue, useValidation)

              return preparedInput
            }}
            onChange={(e) => {
              const origVal = separator

              setSeparator(e)

              updatePrefixSeparator({
                variables: {
                  paramSeparator: e,
                },
              })

              trackAction('update-generator-separator', e, origVal)
            }}
          />
        </Row>
      </div>
    </>
  )
}

interface AdvancedRuleContainerProps {
  categoryIndex: number
  useValidation: ValidationChecks[]
  setCurrRules: React.Dispatch<React.SetStateAction<ValidationChecksCategory[]>>
  category: ValidationChecksCategory
  showUplifterIDParam: boolean
  isLocked: boolean
  setShowUnlockModal: () => void
  trackAction: (action: string, newVal: string, oldVal: string) => void
}

const AdvancedRuleContainer = ({
  categoryIndex,
  useValidation,
  setCurrRules,
  category,
  showUplifterIDParam,
  isLocked,
  setShowUnlockModal,
  trackAction,
}: AdvancedRuleContainerProps) => {
  const dataSource = useReactiveVar(dataSourceReactive)

  const logAction = useLogAction()
  const { availableDomains: availableShortLinkCustomDomains } = useCustomLinks()
  const { loading: loadingSubscriptionLevel, isFree } = useSubscriptionLevel()

  const { data: generatorData } = useQuery(getCampaignCodeGenerator)
  const { data: integrationData } = useQuery(getIntegrationsStatus)

  const [getSFBusinessUnits, { data: sfBusinessUnitsData }] = useLazyQuery(
    getSavedPardotAcctList,
  )

  const [updateMasterRules] = useMutation(updateParamsMasterRules)
  const [updateExistingParams] = useMutation(updateExistingParamsAddedToStart)
  const [updatePardotBusinessUnit] = useMutation(
    updateDefaultPardotBusinessUnit,
  )
  const [updateUplifterIDRule] = useMutation(updateUplifterIDEnabledState)
  const [updateGeneratorParameter] = useMutation(updateGeneratorParameterByID)

  const [showShortLinkModal, setShowShortLinkModal] = useState(false)
  const [showBrandedLinkModal, setShowBrandedLinkModal] = useState(false)
  const [sfBusinessUnitID, setSfBusinessUnitID] = useState<string | null>(null)
  const [
    showLandingPageRuleDisabled,
    setShowLandingPageRuleDisabled,
  ] = useState(true)

  const salesforceConnected = useMemo(() => {
    if (!integrationData) return false

    return integrationData.currentCompany.pardotIntegrationStatus === 'active'
  }, [integrationData])

  useEffect(() => {
    if (!salesforceConnected) return

    getSFBusinessUnits()
  }, [salesforceConnected])

  // Business units are only required for classic template
  const sfBusinessUnits = useMemo(() => {
    if (!sfBusinessUnitsData) return []

    return sfBusinessUnitsData.track.getSavedPardotAcctList.pardotList
  }, [sfBusinessUnitsData])

  const {
    validationChecks,
    defaultPardotBusinessUnit,
    moveExistingParameters,
    parameters,
    selectedCampaignParam,
  } = useMemo(() => {
    if (!generatorData)
      return {
        validationChecks: [],
        isLocked: false,
        defaultPardotBusinessUnit: null,
        moveExistingParameters: false,
        parameters: [],
        selectedCampaignParam: null,
      }

    const {
      existingParametersAddedToStart,
      paramDefs,
    } = generatorData.campaignCodeGenerator

    const _parameters = paramDefs
      .filter(
        ({ fieldAvailable, metaParameter }) => !metaParameter && fieldAvailable,
      )
      .map(
        ({
          fieldID,
          fieldName,
          prefix: fieldPrefix,
          helpText,
          isCampaignField,
        }) => ({
          fieldID,
          fieldName,
          fieldPrefix,
          isCampaignField,
          tooltip: helpText,
        }),
      )

    const _selectedCampaignParam =
      _parameters.find(({ isCampaignField }) => isCampaignField) ||
      _parameters.find(
        ({ fieldPrefix }) => fieldPrefix.indexOf('utm_campaign=') > -1,
      ) ||
      null

    return {
      ...generatorData.campaignCodeGenerator,
      moveExistingParameters: !existingParametersAddedToStart,
      parameters: _parameters,
      selectedCampaignParam: _selectedCampaignParam,
    }
  }, [generatorData])

  // Set initial state of showLandingPageRuleDisabled
  useEffect(() => {
    const requireLandingPageRule = validationChecks.find(
      ({ name }) => name === 'REQUIRE_LANDING_PAGE',
    )

    setShowLandingPageRuleDisabled(
      typeof requireLandingPageRule?.enabled === 'boolean'
        ? requireLandingPageRule.enabled
        : true,
    )
  }, [validationChecks])

  // Set initial state of SF Business Unit ID
  useEffect(() => {
    setSfBusinessUnitID(defaultPardotBusinessUnit || null)
  }, [defaultPardotBusinessUnit])

  const updateRules = async (
    catIndex: number,
    ruleIndex: number,
    enabled: boolean,
    name: string,
    value: string | null = null,
  ) => {
    const newConfig = [
      {
        enabled,
        name,
        value,
      },
    ]

    // Show landing page is dependent on require landing page
    if (name === 'REQUIRE_LANDING_PAGE') {
      setShowLandingPageRuleDisabled(enabled)

      if (enabled) {
        newConfig.push({
          enabled: true,
          name: 'SHOW_LANDING_PAGE',
          value: null,
        })
      }
    }

    setCurrRules((current) => {
      const newRules: ValidationChecksCategory[] = JSON.parse(
        JSON.stringify(current),
      )

      const currRuleConfig = newRules[catIndex].validationChecks[ruleIndex]

      newRules[catIndex].validationChecks[ruleIndex] = {
        ...currRuleConfig,
        ...newConfig[0],
      }

      return newRules
    })

    await updateMasterRules({
      variables: {
        validationChecks: newConfig,
      },
    })
  }

  const dropdownField = useCallback(
    (index: number, ruleIndex: number, rule: FullValidationCheck) => {
      if (loadingSubscriptionLevel) return <></>

      let parsedValue: AvailableDomain[] = JSON.parse(rule.value as string)

      if (rule.name === 'FORCE_SHORT_LINK' && isFree) {
        parsedValue = parsedValue.filter((item) => {
          return (
            item.optionValue !== 'force-short-links' &&
            item.optionValue !== 'recommend-short-links'
          )
        })
      }

      const selectedOptions = parsedValue.filter((item: any) => item.selected)

      return rule.fieldType === 'select' ? (
        <SelectBox
          id={rule.name}
          className={styles.ruleDropdown}
          isDisabled={!rule.enabled}
          labelKey="optionName"
          valueKey="optionValue"
          value={
            selectedOptions.length > 0 ? selectedOptions[0] : parsedValue[0]
          }
          options={parsedValue}
          onChange={(newValue) => {
            if (isLocked) {
              setShowUnlockModal()

              return
            }

            if (!newValue) return

            const { optionValue: val } = newValue

            const newRuleValue = _.cloneDeep(parsedValue)

            const initialSelectedOption = parsedValue.findIndex(
              (item: any) => item.selected,
            )

            const newSelectedOption = parsedValue.findIndex(
              (item: any) => item.optionValue === val,
            )

            if (newSelectedOption !== -1) {
              newRuleValue[initialSelectedOption] = {
                ...newRuleValue[
                  initialSelectedOption === -1 ? 0 : initialSelectedOption
                ],
                selected: false,
              }

              newRuleValue[newSelectedOption] = {
                ...newRuleValue[newSelectedOption],
                selected: true,
              }

              updateRules(
                index,
                ruleIndex,
                true,
                rule.name,
                JSON.stringify(newRuleValue),
              )

              if (rule.logAction) {
                trackAction(
                  rule.logAction,
                  JSON.stringify(newRuleValue),
                  rule.value as string,
                )
              }
            }
          }}
        >
          {rule.name === 'FORCE_SHORT_LINK' && isFree && (
            <Button
              variant="text"
              className={styles.dropdownUpgradeButton}
              onPressStart={() => {
                // @ts-ignore
                if (window.dataLayer && window.dataLayer.push) {
                  // @ts-ignore
                  window.dataLayer.push({
                    event: 'click-enable-shortlinks-upgrade-blocker',
                  })
                }

                logAction({
                  variables: {
                    action: 'click-enable-shortlinks-upgrade-blocker',
                    websiteSection: 'track',
                    pagePath: 'track/edit-parameters-and-rules',
                    functionName: 'clickUpgrade',
                  },
                })

                setShowShortLinkModal(true)
              }}
            >
              <span>Enable short links</span>
              <BetaLabel className={styles.comingSoon} title="Upgrade" />
            </Button>
          )}
        </SelectBox>
      ) : (
        <SelectBoxChecklist
          id={rule.name}
          className={styles.ruleDropdown}
          excludeAny
          excludeNone
          allLabel="All"
          isClearable={false}
          isDisabled={
            !rule.enabled ||
            (rule.name === 'FORCE_CUSTOM_DOMAIN' &&
              (isFree || availableShortLinkCustomDomains.length === 0))
          }
          labelKey="optionName"
          valueKey="optionValue"
          value={selectedOptions}
          options={parsedValue}
          onChange={(newValue) => {
            if (isLocked) {
              setShowUnlockModal()

              return
            }

            // At least one domain must be selected
            if (rule.name === 'FORCE_CUSTOM_DOMAIN' && newValue.length === 0)
              return

            const newRuleValue: AvailableDomain[] = []

            parsedValue.forEach(({ optionName, optionValue }) => {
              newRuleValue.push({
                optionName,
                optionValue,
                selected: !!newValue.find(
                  (option) => option.optionValue === optionValue,
                ),
              })
            })

            updateRules(
              index,
              ruleIndex,
              true,
              rule.name,
              JSON.stringify(newRuleValue),
            )

            if (rule.logAction) {
              trackAction(
                rule.logAction,
                JSON.stringify(newRuleValue),
                rule.value as string,
              )
            }
          }}
        />
      )
    },
    [loadingSubscriptionLevel, availableShortLinkCustomDomains, isLocked],
  )

  const fieldInner = (
    index: number,
    ruleIndex: number,
    rule: FullValidationCheck,
  ) => {
    return (
      <>
        {rule.value !== null && (
          <>
            {(rule.fieldType === 'select' ||
              rule.fieldType === 'multiselect') &&
            !!rule.value ? (
              <>{dropdownField(index, ruleIndex, rule)}</>
            ) : (
              <ClickEditInput
                id={`${rule.name}-input`}
                name={`${rule.name}-input`}
                className={classNames(styles.advancedRulesClickEdit, {
                  [styles.emptyInput]: rule.value === '',
                  [styles.multiLineInput]: rule.fieldType === 'textarea',
                })}
                type={rule.fieldType === 'textarea' ? 'textArea' : 'text'}
                multilineInput={rule.fieldType === 'textarea'}
                value={rule.value}
                placeholder={
                  rule.name === 'EMAIL_DOMAIN_LIST'
                    ? 'www.example.com'
                    : '(empty)'
                }
                disabled={!rule.enabled}
                disableEditFn={
                  isLocked ? () => setShowUnlockModal() : undefined
                }
                beforeChange={(inputValue: string) => {
                  if (rule.name === 'EMAIL_DOMAIN_LIST') return inputValue

                  const preparedInput = prepareInput(inputValue, useValidation)

                  return preparedInput
                }}
                onChange={(e) => {
                  const origVal = rule.value || ''

                  updateRules(index, ruleIndex, true, rule.name, e)

                  if (rule.logAction) {
                    trackAction(rule.logAction, e, origVal)
                  }
                }}
              />
            )}
          </>
        )}
        {rule.requireUpgrade && (
          <Button
            variant="secondary"
            className={styles.upgradeButton}
            onPress={() => setShowBrandedLinkModal(true)}
          >
            {rule.name === 'FORCE_CUSTOM_DOMAIN' ? 'Add domain' : 'Upgrade'}
          </Button>
        )}
      </>
    )
  }

  return (
    <>
      {/* Special case for Uplifter ID param being enabled: it is not a validation check */}
      {category.category === 'Advanced options' && (
        <>
          {(!dataSource || dataSource.connectionSource !== 'adobe') && (
            <Row
              key="enableUplifterID"
              align="flex-start"
              vAlign="center"
              className={styles.ruleRow}
            >
              <Input
                type="checkbox"
                id="enableUplifterID"
                name="enableUplifterID"
                label="Enable Smart Links"
                className={styles.checkboxContainer}
                checked={showUplifterIDParam}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (isLocked) {
                    setShowUnlockModal()

                    return
                  }

                  const origVal = showUplifterIDParam

                  const { checked } = e.target as HTMLInputElement

                  updateUplifterIDRule({
                    variables: {
                      isEnabled: checked,
                    },
                  })

                  trackAction(
                    'update-generator-rules-uplifter-id-param',
                    `${checked}`,
                    `${origVal}`,
                  )
                }}
              >
                <Tooltip
                  id="enableUplifterID-tooltip"
                  useIcon
                  maxWidth={300}
                  tooltipPosition="right"
                  tooltipMessage="If checked, a unique ID will be added to the end of every link. It allows us to more accurately integrate with Google Analytics and other platforms. (Recommended)"
                />
              </Input>
            </Row>
          )}
          <Row
            key="selectReportCampaignField"
            align="flex-start"
            vAlign="center"
            className={styles.ruleRow}
          >
            <Label id="selectReportCampaignField">
              <Tooltip
                id="default-campaign-field-tooltip"
                useIcon
                maxWidth={300}
                tooltipPosition="right"
                tooltipMessage="This parameter will be used in the 'Marketing journeys' reports for filtering by marketing campaigns."
              >
                Campaign parameter used in reports
              </Tooltip>
            </Label>
            <SelectBox
              id="selectReportCampaignField"
              className={styles.ruleDropdown}
              labelKey="fieldName"
              valueKey="fieldID"
              value={selectedCampaignParam}
              options={parameters}
              onChange={async (newValue) => {
                if (isLocked) {
                  setShowUnlockModal()
                  return
                }

                if (!newValue) return

                // Set the old 'isCampaignField' value back to false
                if (selectedCampaignParam) {
                  await updateGeneratorParameter({
                    variables: {
                      updateFieldID: selectedCampaignParam.fieldID,
                      isCampaignField: false,
                    },
                  })
                }

                updateGeneratorParameter({
                  variables: {
                    updateFieldID: newValue.fieldID,
                    isCampaignField: true,
                  },
                  refetchQueries: [getCampaignCodeGenerator],
                })

                trackAction(
                  'update-generator-rules-report-campaign-field',
                  newValue.fieldID,
                  selectedCampaignParam?.fieldID || '',
                )
              }}
            />
          </Row>
        </>
      )}
      {category.validationChecks.map((_rule, ruleIndex) => {
        const rule = getValidationCheck(
          category.validationChecks,
          _rule.name,
          defaultEnabled[_rule.name] || false,
        )

        if (!rule.showCheckbox && rule.value === null) return null

        return (
          <React.Fragment key={rule.name}>
            <Row align="flex-start" vAlign="center" className={styles.ruleRow}>
              {rule.showCheckbox ? (
                <Input
                  type="checkbox"
                  id={rule.name}
                  name={rule.name}
                  label={rule.ruleTitle}
                  checked={rule.enabled}
                  className={styles.checkboxContainer}
                  disabled={
                    (rule.name === 'SHOW_LANDING_PAGE' &&
                      showLandingPageRuleDisabled) ||
                    (rule.name === 'FORCE_CUSTOM_DOMAIN' &&
                      (isFree || availableShortLinkCustomDomains.length === 0))
                  }
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (isLocked) {
                      setShowUnlockModal()

                      return
                    }

                    const origVal = rule.enabled

                    const { checked } = e.target as HTMLInputElement

                    await updateRules(
                      categoryIndex,
                      ruleIndex,
                      checked,
                      rule.name,
                      rule.value,
                    )

                    if (rule.logAction) {
                      const val = rule.value || ''

                      trackAction(
                        rule.logAction,
                        checked ? `true${val && ` (${val})`}` : 'false',
                        origVal ? `true${val && ` (${val})`}` : 'false',
                      )
                    }

                    // Update existing params rule
                    if (
                      rule.name === 'NO_SPECIAL_CHARS_LANDING_PAGE' &&
                      checked
                    ) {
                      await updateExistingParams({
                        variables: {
                          existingParametersAddedToStart: true,
                        },
                      })
                    }
                  }}
                >
                  <Tooltip
                    id={`${rule.name}-tooltip`}
                    useIcon
                    maxWidth={300}
                    tooltipPosition="right"
                    tooltipMessage={rule.helpText}
                  />
                  {fieldInner(categoryIndex, ruleIndex, rule)}
                </Input>
              ) : (
                <>
                  <Label>
                    <Tooltip
                      id={`${rule.name}-tooltip`}
                      useIcon
                      maxWidth={300}
                      tooltipPosition="right"
                      tooltipMessage={rule.helpText}
                    >
                      {rule.ruleTitle}
                    </Tooltip>
                  </Label>
                  {fieldInner(categoryIndex, ruleIndex, rule)}
                </>
              )}
            </Row>
            {/* Special rule: existingParametersAddedToStart */}
            {/* Only available if NO_SPECIAL_CHARS_LANDING_PAGE is disabled */}
            {rule.name === 'NO_SPECIAL_CHARS_LANDING_PAGE' && (
              <Row
                align="flex-start"
                vAlign="center"
                className={styles.ruleRow}
              >
                <Input
                  type="checkbox"
                  id="existingParametersAddedToStart"
                  name="existingParametersAddedToStart"
                  label="Don't move existing query parameters to the end"
                  checked={!moveExistingParameters}
                  className={styles.checkboxContainer}
                  // Only editable if NO_SPECIAL_CHARS_LANDING_PAGE is disabled
                  disabled={rule.enabled}
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (isLocked) {
                      setShowUnlockModal()

                      return
                    }

                    const { checked } = e.target as HTMLInputElement

                    await updateExistingParams({
                      variables: {
                        existingParametersAddedToStart: checked,
                      },
                    })

                    if (rule.logAction) {
                      trackAction(
                        'update-generator-rules-existing-params-added-to-start',
                        `${checked}`,
                        `${!checked}`,
                      )
                    }
                  }}
                >
                  <Tooltip
                    id="move-existing-parameters-tooltip"
                    useIcon
                    maxWidth={300}
                    tooltipPosition="right"
                    tooltipMessage="If unchecked, existing query string parameters will be moved to the end of new links you create."
                  />
                </Input>
              </Row>
            )}
          </React.Fragment>
        )
      })}
      {/* Special case for email: defaultPardotBusiness unit is not a validation check */}
      {/* It is in the main campaignCodeGenerator object */}
      {category.category === 'Email preferences' && salesforceConnected && (
        <Row
          key="defaultPardotBusinessUnit"
          align="flex-start"
          vAlign="center"
          className={styles.ruleRow}
        >
          <Label id="defaultPardotBusinessUnit">
            <Tooltip
              id="default-pardot-business-unit-tooltip"
              useIcon
              maxWidth={300}
              tooltipPosition="right"
              tooltipMessage="The business unit that is set in the email form on the Track &gt; Create page. Only applies to the Classic builder."
            >
              Default Salesforce business unit
            </Tooltip>
          </Label>
          <SelectBox
            id="defaultPardotBusinessUnit"
            className={styles.ruleDropdown}
            labelKey="pardotDisplayName"
            valueKey="pardotID"
            value={sfBusinessUnits.find(
              (option) => option.pardotID === sfBusinessUnitID,
            )}
            options={sfBusinessUnits}
            onChange={(newValue) => {
              if (isLocked) {
                setShowUnlockModal()
                return
              }

              if (!newValue) return

              const { pardotID: val } = newValue

              updatePardotBusinessUnit({
                variables: {
                  defaultPardotBusinessUnit: val,
                },
              })

              trackAction(
                'update-generator-rules-email-default-pardot-business-unit',
                val,
                sfBusinessUnitID || '',
              )

              setSfBusinessUnitID(val)
            }}
          />
        </Row>
      )}
      {showShortLinkModal && (
        <RequestShortLinksModal onHideModal={setShowShortLinkModal} />
      )}
      {showBrandedLinkModal && (
        <RequestBrandedDomainModal onHideModal={setShowBrandedLinkModal} />
      )}
    </>
  )
}

interface ParametersMasterRulesProps {
  isLocked: boolean
  setShowUnlockModal: () => void
  prefixSeparatorOpen: boolean
  setPrefixSeparatorOpen: Dispatch<SetStateAction<boolean>>
  rules: ValidationChecks[]
  setHoverItem: Dispatch<SetStateAction<string | null>>
  showUplifterIDParam: boolean
}

const ParametersMasterRules = ({
  isLocked = false,
  setShowUnlockModal,
  prefixSeparatorOpen = false,
  setPrefixSeparatorOpen,
  rules,
  setHoverItem,
  showUplifterIDParam,
}: ParametersMasterRulesProps) => {
  const logAction = useLogAction()
  const { isFree } = useSubscriptionLevel()

  const { availableDomains: availableShortLinkCustomDomains } = useCustomLinks()

  const history = useHistory()

  const [accordionOpenState, setAccordionOpenState] = useState([
    prefixSeparatorOpen,
    ...Object.keys(defaultValidationChecksFull).map((item) => false),
  ])
  const [currRules, setCurrRules] = useState(defaultValidationChecksFull)

  const trackAction = useCallback(
    (action: string, newVal: string, oldVal: string) => {
      logAction({
        variables: {
          action,
          extra: JSON.stringify({
            newVal,
            oldVal,
          }),
          websiteSection: 'track',
          pagePath: '/track/edit',
          functionName: 'updateAdvancedRules',
        },
      })
    },
    [],
  )

  const useValidation = useMemo(() => {
    // Special characters must be allowed in master prefix and separator. Only check for casing and spaces
    const replaceSpaces = rules.find(
      (rule) => rule.name === 'REPLACE_SPACES_WITH',
    ) || {
      enabled: true,
      name: 'REPLACE_SPACES_WITH',
      value: '_',
    }

    const lowerCase = rules.find((rule) => rule.name === 'ALL_LOWER_CASE') || {
      enabled: true,
      name: 'ALL_LOWER_CASE',
      value: null,
    }

    return [lowerCase, replaceSpaces]
  }, [rules])

  useEffect(() => {
    if (rules.length > 0) {
      setCurrRules((current) => {
        // Updates the DB rules on load if any do not have values when they should
        const newRules: ValidationChecksCategory[] = _.cloneDeep(current)

        // For paid companies: recommend short links, not basic
        if (!isFree) {
          const shortLinkRulesIndex = newRules.findIndex((ruleGroup) => {
            return ruleGroup.category === 'Short link preferences'
          })

          if (shortLinkRulesIndex !== -1) {
            const ruleIndex = newRules[
              shortLinkRulesIndex
            ].validationChecks.findIndex(
              (rule) => rule.name === 'FORCE_SHORT_LINK',
            )

            newRules[shortLinkRulesIndex].validationChecks[ruleIndex].value = `[
              {
                "optionName": "Recommend basic links",
                "optionValue": "recommend-long-links",
                "selected": false
              },
              {
                "optionName": "Recommend short links",
                "optionValue": "recommend-short-links",
                "selected": true
              },
              {
                "optionName": "Force basic links",
                "optionValue": "force-long-links",
                "selected": false
              },
              {
                "optionName": "Force short links",
                "optionValue": "force-short-links",
                "selected": false
              }
            ]`
          }
        }

        rules.forEach((rule) => {
          let ruleIndex = -1

          const categoryIndex = current.findIndex((category) => {
            const _ruleIndex = category.validationChecks.findIndex(
              (validationRule) => validationRule.name === rule.name,
            )

            if (_ruleIndex > -1) {
              ruleIndex = _ruleIndex
            }

            return _ruleIndex > -1
          })

          if (categoryIndex > -1) {
            newRules[categoryIndex].validationChecks[ruleIndex] = {
              ...newRules[categoryIndex].validationChecks[ruleIndex],
              name: rule.name,
              enabled: rule.enabled,
            }

            // Build rule value JSON from list of available domains
            if (rule.name === 'FORCE_CUSTOM_DOMAIN') {
              newRules[categoryIndex].validationChecks[ruleIndex].enabled = true

              // If rule is null, set based on available domains
              if (!rule.value) {
                newRules[categoryIndex].validationChecks[
                  ruleIndex
                ].value = JSON.stringify(availableShortLinkCustomDomains)
              } else {
                // Else, check if DB value contains all available domains
                const parsedSLDomains: AvailableDomain[] = JSON.parse(
                  rule.value,
                )

                // Add other available domains, it any
                availableShortLinkCustomDomains.forEach(
                  ({ optionValue, optionName }) => {
                    if (
                      getItemByKeyValue(
                        parsedSLDomains,
                        'optionValue',
                        optionValue,
                      ) === -1
                    ) {
                      parsedSLDomains.push({
                        optionName: optionName.replace('https://', ''),
                        optionValue,
                        selected: false,
                      })
                    }
                  },
                )

                // Ensure at least one domain is selected
                if (!parsedSLDomains.find((dom) => dom.selected)) {
                  parsedSLDomains[0].selected = true
                }

                newRules[categoryIndex].validationChecks[
                  ruleIndex
                ].value = JSON.stringify(parsedSLDomains)
              }
            }
            // Uses default value for forcing short links if DB value is null
            else if (rule.name === 'FORCE_SHORT_LINK') {
              newRules[categoryIndex].validationChecks[ruleIndex].enabled = true

              if (rule.value) {
                newRules[categoryIndex].validationChecks[ruleIndex].value =
                  rule.value
              }
            } else {
              newRules[categoryIndex].validationChecks[ruleIndex].value =
                rule.value
            }
          }
        })

        return newRules
      })
    }
  }, [rules, availableShortLinkCustomDomains])

  const filteredRules = useMemo(() => {
    return currRules.filter(({ validationChecks }) =>
      Array.isArray(validationChecks),
    )
  }, [currRules])

  // Update accordion state when prefixSeparator is activated by clicking link preview
  useEffect(() => {
    if (prefixSeparatorOpen) {
      setAccordionOpenState((curr) => curr.map((item, index) => index === 0))
    }
  }, [prefixSeparatorOpen])

  return (
    <>
      <div className={styles.headerRow}>
        <Heading className={styles.rulesHeading} type={2} align="left">
          <Tooltip
            id="advanced-rules-tooltip"
            useIcon
            maxWidth={300}
            tooltipMessage="Set rules to be followed whenever a user creates a new campaign link. You can control aspects such as forcing lowercase, checking that the URL is valid, replacing spaces with other characters, and the maximum URL length."
          >
            Advanced rules
          </Tooltip>
        </Heading>
      </div>
      <Accordion
        id="advancedRules"
        initialOpenState={accordionOpenState}
        onToggle={(index, state) => {
          // Update open state of prefixSeparator
          if (index !== 0 || state === 'close') {
            setPrefixSeparatorOpen(false)
          }
        }}
        accordionItems={[
          {
            // Prefix and separator are not part of validation checks, so must be built separately
            key: 'prefixAndSeparator',
            header: {
              title: 'Prefix and separator',
            },
            expandedContent: (
              <PrefixSeparatorRules
                useValidation={useValidation}
                setHoverItem={setHoverItem}
                isLocked={isLocked}
                setShowUnlockModal={setShowUnlockModal}
                trackAction={trackAction}
              />
            ),
          },
          ...filteredRules.map((category, categoryIndex) => {
            return {
              key: category.category,
              header: {
                title: category.category,
              },
              expandedContent: (
                <AdvancedRuleContainer
                  categoryIndex={categoryIndex}
                  useValidation={useValidation}
                  setCurrRules={setCurrRules}
                  category={category}
                  showUplifterIDParam={showUplifterIDParam}
                  isLocked={isLocked}
                  setShowUnlockModal={setShowUnlockModal}
                  trackAction={trackAction}
                />
              ),
            }
          }),
        ]}
      />
      <NavigateButton
        onPress={() => {
          history.push('/track/create-links')

          trackAction('view-create-link-form', '', '')
        }}
      >
        Create campaign links
      </NavigateButton>
    </>
  )
}

export default ParametersMasterRules
