import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import _ from 'lodash'

import useSubscriptionLevel from './useSubscriptionLevel'
import {
  availableCustomDomains,
  currentUserDetails,
  CustomDomainType,
  customLinkAliasesByDomain,
  selectedDeepLinkDomainReactive,
  selectedShortLinkDomainReactive,
} from '../api/apollo/variables'
import {
  getCampaignCodeGenerator,
  getShortLinkCandidates,
  getShortLinkCustomDomains,
} from '../api/graphql/track-create-client'
import { listDeepLinkServices } from '../api/graphql/track-edit-client'
import { getLocalItem, setLocalItem } from '../helpers/local-client'
import {
  addGeneratedCustomLinkToStorage,
  removeCustomLinkFromStorage,
} from '../helpers/custom-links'
import {
  defaultAppDeepLinkDomain,
  defaultShortLinkDomain,
  getCustomDomainID,
} from '../helpers/track-module'

export interface AvailableDomain {
  optionName: string
  optionValue: string
  /** Whether the domain should show on Track>Create */
  selected: boolean
}

const useCustomLinks = (customLinkType: CustomDomainType = 'shortLink') => {
  const { workspaceID, isDemoAccount } = useReactiveVar(currentUserDetails)

  const fullAvailableDomains = useReactiveVar(availableCustomDomains)

  const selectedShortLinkDomain = useReactiveVar(
    selectedShortLinkDomainReactive,
  )
  const selectedDeepLinkDomain = useReactiveVar(selectedDeepLinkDomainReactive)

  const aliasesByDomain = useReactiveVar(customLinkAliasesByDomain)

  const { isFree, isEnterprise } = useSubscriptionLevel()

  // @ts-ignore
  const [getShortLinkDomains, { data: shortLinkDomainData }] = useLazyQuery(
    getShortLinkCustomDomains,
  )
  // @ts-ignore
  const [getDeepLinkDomains, { data: deepLinkDomainData }] = useLazyQuery(
    listDeepLinkServices,
  )
  const [getValidationChecks, { data: validationChecksData }] = useLazyQuery(
    getCampaignCodeGenerator,
  )

  const [fetchCustomLinkCandidates] = useLazyQuery(getShortLinkCandidates, {
    fetchPolicy: 'network-only',
  })

  const [fetchingAliases, setFetchingAliases] = useState(false)

  // Initial domain fetcher
  useEffect(() => {
    if (!workspaceID) return

    if (customLinkType === 'shortLink') {
      getShortLinkDomains()
      // Gets rule to limit short link domains shown on Track>Create
      getValidationChecks()
    } else {
      getDeepLinkDomains()
    }
  }, [customLinkType, workspaceID])

  // Initialise/update availableCustomDomains reactive variable
  useEffect(() => {
    const currentShortLinkDomains = fullAvailableDomains.filter(
      ({ type }) => type === 'shortLink',
    )
    const currentDeepLinkDomains = fullAvailableDomains.filter(
      ({ type }) => type === 'appLink',
    )

    if (customLinkType === 'shortLink') {
      const fetchedShortLinkDomains =
        shortLinkDomainData?.currentCompany.availableShortLinkCustomDomains?.map(
          ({ customDomainID, customDomainName }) => ({
            type: 'shortLink' as CustomDomainType,
            optionName: customDomainName.replace('https://', ''),
            optionValue: customDomainID,
          }),
        ) || []

      // Add the default shortLink domain
      fetchedShortLinkDomains.unshift({
        type: 'shortLink',
        optionName: defaultShortLinkDomain,
        optionValue: defaultShortLinkDomain,
      })

      if (fetchedShortLinkDomains.length !== currentShortLinkDomains.length) {
        // Retain existing appLink domains
        availableCustomDomains([
          ...fetchedShortLinkDomains,
          ...currentDeepLinkDomains,
        ])
      }
    } else {
      const fetchedDeepLinkDomains =
        deepLinkDomainData?.track.deepLinkQueries.listDeepLinkServices.map(
          ({ deepLinkServiceSubdomain, deepLinkServiceID }) => ({
            type: 'appLink' as CustomDomainType,
            optionName: deepLinkServiceSubdomain,
            optionValue: deepLinkServiceID,
          }),
        ) || []

      if (fetchedDeepLinkDomains.length !== currentDeepLinkDomains.length) {
        // Retain existing shortLink domains
        availableCustomDomains([
          ...currentShortLinkDomains,
          ...fetchedDeepLinkDomains,
        ])
      }
    }
  }, [
    customLinkType,
    fullAvailableDomains,
    shortLinkDomainData,
    deepLinkDomainData,
  ])

  const forceShortLinkDomainsRule = useMemo(() => {
    return (
      validationChecksData?.campaignCodeGenerator.validationChecks.find(
        (validationCheck) => validationCheck.name === 'FORCE_CUSTOM_DOMAIN',
      ) || null
    )
  }, [validationChecksData])

  /** Filters full list of domains to only those of the correct type (shortLink/appLink) */
  const availableDomains: AvailableDomain[] = useMemo(() => {
    if (customLinkType === 'shortLink' && forceShortLinkDomainsRule?.value) {
      return JSON.parse(forceShortLinkDomainsRule.value) as AvailableDomain[]
    }

    const availableDomainsOfType = fullAvailableDomains
      .filter(({ type }) => type === customLinkType)
      .map((customDomain) => {
        if (customLinkType === 'appLink') {
          return { ...customDomain, selected: true }
        }

        return {
          ...customDomain,
          selected: true,
        }
      })

    return availableDomainsOfType
  }, [customLinkType, fullAvailableDomains, forceShortLinkDomainsRule])

  const selectedDomain = useMemo(() => {
    if (customLinkType === 'shortLink') return selectedShortLinkDomain

    return selectedDeepLinkDomain
  }, [customLinkType, selectedShortLinkDomain, selectedDeepLinkDomain])

  // Initialise selectedDomain based on localStorage
  useEffect(() => {
    if (!workspaceID || availableDomains.length === 0) {
      return
    }

    const localData = getLocalItem('track-create') || {}

    if (localData[workspaceID]) {
      const { options } = localData[workspaceID]

      const localSelectedDomain = options[`${customLinkType}Domain`]

      if (
        options &&
        localSelectedDomain &&
        availableDomains.find((dom) => dom.optionValue === localSelectedDomain)
          ?.selected
      ) {
        // Value in localStorage is valid - use that
        if (customLinkType === 'shortLink') {
          selectedShortLinkDomainReactive(localSelectedDomain)
        } else {
          selectedDeepLinkDomainReactive(localSelectedDomain)
        }

        return
      }
    }

    // Can't use localStorage - set based on available domains
    const validAvailableDomains = availableDomains.filter(
      ({ selected }) => selected,
    )

    const defaultDomain =
      customLinkType === 'shortLink'
        ? defaultShortLinkDomain
        : defaultAppDeepLinkDomain

    const validSelectedDomain = validAvailableDomains.find(
      ({ optionValue }) => optionValue !== defaultDomain,
    )?.optionValue

    if (customLinkType === 'shortLink') {
      selectedShortLinkDomainReactive(validSelectedDomain || defaultDomain)
    } else {
      selectedDeepLinkDomainReactive(validSelectedDomain || defaultDomain)
    }
  }, [customLinkType, workspaceID, availableDomains, selectedDomain])

  /** Updates localStorage as well as selectedDomain */
  const updateSelectedDomain = useCallback(
    (newDomain: string) => {
      if (customLinkType === 'shortLink') {
        selectedShortLinkDomainReactive(newDomain)
      } else {
        selectedDeepLinkDomainReactive(newDomain)
      }

      const initialData = getLocalItem('track-create')

      const newData = _.cloneDeep(initialData)
      newData[workspaceID].options = {
        ...initialData[workspaceID].options,
        [`${customLinkType}Domain`]: newDomain,
      }

      setLocalItem('track-create', newData)
    },
    [
      workspaceID,
      customLinkType,
      selectedDeepLinkDomain,
      selectedShortLinkDomain,
    ],
  )

  // Initialise aliases for every availableDomain
  useEffect(() => {
    // Get list of domains not currently in aliasesByDomain
    const domainsNotPresent = availableDomains.filter((domain) => {
      return !aliasesByDomain[domain.optionValue]
    })

    if (domainsNotPresent.length === 0) return

    const newAliasesByDomain = _.cloneDeep(aliasesByDomain)

    domainsNotPresent.forEach((domain) => {
      newAliasesByDomain[domain.optionValue] = { individual: [] }
    })

    customLinkAliasesByDomain(newAliasesByDomain)
  }, [availableDomains, aliasesByDomain])

  /** Correct list of aliases based on selected domain */
  const availableAliases = useMemo(() => {
    return aliasesByDomain[selectedDomain]
  }, [aliasesByDomain, selectedDomain])

  const fetchNewAliases = useCallback(
    async (numAliases = 1, domainToUse?: string) => {
      setFetchingAliases(true)

      const newAliasesByDomain = _.cloneDeep(aliasesByDomain)

      const domain = domainToUse || selectedDomain

      /**
       * Variables used in this mutation depend on custom link type
       * https://github.com/uplifter-limited/uplifter-graphql-backend/issues/421#issuecomment-2206500778
       */
      const { data } = await fetchCustomLinkCandidates({
        variables: {
          nLinks: numAliases,
          customDomainID:
            customLinkType === 'shortLink'
              ? getCustomDomainID(domain)
              : undefined,
          deepLinkServiceID: customLinkType === 'appLink' ? domain : undefined,
        },
      })

      if (data) {
        const { shortLinkCandidates } = data

        switch (shortLinkCandidates.__typename) {
          case 'ShortLinkBatchCandidates':
            newAliasesByDomain[domain].batch = shortLinkCandidates.bulkStart
            addGeneratedCustomLinkToStorage(
              shortLinkCandidates.bulkStart,
              domain,
            )
            break
          default:
            shortLinkCandidates.availableLinkIDs.forEach((linkID) => {
              newAliasesByDomain[domain].individual.push(linkID)
              addGeneratedCustomLinkToStorage(linkID, domain)
            })
            break
        }

        customLinkAliasesByDomain(newAliasesByDomain)
      }

      setFetchingAliases(false)

      return newAliasesByDomain
    },
    [customLinkType, selectedDomain, aliasesByDomain],
  )

  /**
   * Remove existing alias and fetch a new one
   * Without 'using' the existing alias (generating a link)
   */
  const replaceAlias = useCallback(
    async (aliasIndex: number) => {
      const newAliasesByDomain = _.cloneDeep(aliasesByDomain)

      /**
       * Variables used in this mutation depend on custom link type
       * https://github.com/uplifter-limited/uplifter-graphql-backend/issues/421#issuecomment-2206500778
       */
      const { data } = await fetchCustomLinkCandidates({
        variables: {
          nLinks: 1,
          customDomainID:
            customLinkType === 'shortLink'
              ? getCustomDomainID(selectedDomain)
              : undefined,
          deepLinkServiceID:
            customLinkType === 'appLink' ? selectedDomain : undefined,
        },
      })

      if (data) {
        const { shortLinkCandidates } = data

        switch (shortLinkCandidates.__typename) {
          case 'ShortLinkCandidates':
            // Should only ever be one availableLinkID
            shortLinkCandidates.availableLinkIDs.forEach((linkID) => {
              addGeneratedCustomLinkToStorage(linkID, selectedDomain)
            })

            newAliasesByDomain[selectedDomain].individual.splice(
              aliasIndex,
              1,
              ...shortLinkCandidates.availableLinkIDs,
            )
            break
          default:
            // Do nothing
            break
        }

        customLinkAliasesByDomain(newAliasesByDomain)
      }

      return newAliasesByDomain
    },
    [customLinkType, selectedDomain, aliasesByDomain],
  )

  /**
   * Remove existing batch alias and fetch a new one
   * Without 'using' the existing batch alias (generating a link)
   */
  const replaceBatchAlias = useCallback(
    async (nLinks: number) => {
      const newAliasesByDomain = _.cloneDeep(aliasesByDomain)

      const { data } = await fetchCustomLinkCandidates({
        variables: {
          nLinks,
          customDomainID:
            customLinkType === 'shortLink'
              ? getCustomDomainID(selectedDomain)
              : undefined,
          deepLinkServiceID:
            customLinkType === 'appLink' ? selectedDomain : undefined,
        },
      })

      if (data) {
        const { shortLinkCandidates } = data

        switch (shortLinkCandidates.__typename) {
          case 'ShortLinkBatchCandidates':
            newAliasesByDomain[selectedDomain].batch =
              shortLinkCandidates.bulkStart
            addGeneratedCustomLinkToStorage(
              shortLinkCandidates.bulkStart,
              selectedDomain,
            )
            break
          default:
            // Do nothing for non-batch
            break
        }

        customLinkAliasesByDomain(newAliasesByDomain)
      }

      return newAliasesByDomain
    },
    [customLinkType, selectedDomain, aliasesByDomain],
  )

  /**
   * Existing aliases have been used to generate a link
   * Remove them from localStorage and fetch new ones
   */
  const useAliases = useCallback(
    (aliasesToUse: string[]) => {
      if (aliasesToUse.length === 0 || !selectedDomain) return

      aliasesToUse.forEach((aliasToUse) => {
        removeCustomLinkFromStorage(aliasToUse, selectedDomain)
      })

      if (!availableAliases) return

      const newAliasesByDomain = _.cloneDeep(aliasesByDomain)

      // Use batch link only
      if (
        aliasesToUse.length === 1 &&
        aliasesToUse[0] === availableAliases.batch
      ) {
        delete newAliasesByDomain[selectedDomain].batch

        customLinkAliasesByDomain(newAliasesByDomain)

        return
      }

      if (availableAliases.individual.length === 0) return

      const newAliases = availableAliases.individual.filter(
        (shortLink) => aliasesToUse.indexOf(shortLink) === -1,
      )

      newAliasesByDomain[selectedDomain].individual = newAliases

      customLinkAliasesByDomain(newAliasesByDomain)
    },
    [customLinkType, availableAliases, selectedDomain],
  )

  return {
    /** Checks account permission level is suitable for shortLinks/appLinks (however hook is being used) */
    canUseCustomLinks:
      isDemoAccount ||
      (customLinkType === 'shortLink' ? !isFree : isEnterprise),
    availableDomains,
    selectedDomain,
    updateSelectedDomain,
    availableAliases,
    fetchNewAliases,
    fetchingAliases,
    replaceAlias,
    replaceBatchAlias,
    useAliases,
  }
}

export default useCustomLinks
