import React, { useState, useMemo, useEffect } from 'react'
import { nanoid } from 'nanoid'
import classNames from 'classnames'
import { distance } from 'fastest-levenshtein'
import _ from 'lodash'

import Button from './button'
import { Label } from './input'
import { ValidationChecks } from '../api/types'
import { prepareInput } from '../helpers'
import styles from '../styles/add-multi-values-tags.module.scss'

interface TagsWrapperProps {
  children: React.ReactChild
  noBorder?: boolean
  className?: string
}

export function TagsWrapper({
  children,
  noBorder,
  className,
}: TagsWrapperProps): React.ReactElement {
  return (
    <div
      className={classNames(styles.tagWrapper, className, {
        [styles.noBorder]: noBorder,
      })}
    >
      {children}
    </div>
  )
}

interface TagProps {
  tagExists?: boolean
  tagExistsCopy?: string
  onClick?: () => void
  children: string | React.ReactNode
  type?: '' | 'blue' | 'grey'
  className?: string
  compact?: boolean
  status?: 'valid' | 'invalid' | 'validating' | null | undefined
}

export function Tag({
  tagExists,
  tagExistsCopy = 'This value already exists!',
  onClick,
  children,
  type = '',
  className,
  compact,
  status = null,
}: TagProps): React.ReactElement {
  return (
    <div
      className={classNames(className, styles.tag, {
        [styles.tagExists]: tagExists,
        [styles[type]]: true,
        [styles.compact]: compact,
        [styles.valid]: status === 'valid',
        [styles.invalid]: status === 'invalid',
        [styles.validating]: status === 'validating',
      })}
    >
      <Button
        variant="plainBox"
        type="submit"
        className={styles.button}
        onPress={() => {
          if (onClick) onClick()
        }}
      >
        <span className={styles.buttonInner}>{children}</span>
      </Button>
      {tagExists && (
        <span className={styles.tagExistsMessage}>{tagExistsCopy}</span>
      )}
    </div>
  )
}

interface Props {
  initialValue?: string[]
  onChange: (data: string[]) => void
  className?: string
  onCheckIfExists?: (tag: string) => boolean
  onKeyUp?: (tag: string) => void
  label?: string
  placeholder?: string
  placeholderTwo?: string
  type?: 'form' | 'modal'
  possibleValues?: string[]
  enableCommaMultiItems?: boolean
  enableSpaceMultiItems?: boolean
  onSetTagStatus?: (tag: string) => 'invalid' | 'valid' | 'validating' | null
  beforeChange?: (value: string) => string
  onBeforeSet?: (value: string[]) => string[]
  defaultError?: boolean
  onPaste?: (e: any) => void
  transformTags?: boolean
  validation?: ValidationChecks[] | null
  blockEnter?: boolean
}

export default function AddMultiValuesTags({
  type = 'form',
  initialValue = [],
  onChange,
  onCheckIfExists,
  onSetTagStatus,
  className,
  label = '',
  possibleValues = [],
  enableCommaMultiItems = false,
  enableSpaceMultiItems = false,
  onKeyUp,
  placeholder = "Options you'd like to add...",
  beforeChange,
  onBeforeSet,
  defaultError = false,
  transformTags = true,
  validation = null,
  onPaste,
  blockEnter = false,
}: Props): React.ReactElement {
  const [currentInitialValue, setCurrentInitialValue] = useState<string[]>([])
  const [fields, setFields] = useState<string[]>([])
  const [fieldsId, setFieldsId] = useState<string[]>([])
  const [typedValue, setTypedValue] = useState('')

  useEffect(() => {
    if (_.isEqual(initialValue, currentInitialValue)) return

    setCurrentInitialValue(initialValue)
    setFields(initialValue)
    setFieldsId(initialValue.map(() => nanoid()))
  }, [initialValue])

  const onKeyEnter = (val = typedValue) => {
    if (blockEnter) return

    let vals = [val]

    if (enableCommaMultiItems) {
      // Supporting Excel string copy
      vals = val.split(/[;,\t\n]/gi)
    }
    vals = vals.filter((item) => item !== '')
    if (onBeforeSet) {
      vals = onBeforeSet(vals)
    }
    if (validation) {
      vals = vals.map((item) => prepareInput(item, validation))
    } else if (transformTags) {
      vals = vals.map((item) => item.replace(/\s/gi, ''))
    }
    const newFields = fields.concat(vals)
    setFields(newFields)
    onChange(newFields)
    const generatedIds = vals.map(() => {
      return nanoid()
    })
    setFieldsId(fieldsId.concat(generatedIds))
    setTypedValue('')
  }

  const renderItem = (item) => {
    return (
      <Button
        key={nanoid()}
        variant="plainBox"
        className={styles.possibleValue}
        onPress={() => {
          setTypedValue(item)
          onKeyEnter(item)
        }}
      >
        <span>{item}</span>
      </Button>
    )
  }

  const filtered = useMemo(() => {
    if (possibleValues.length === 0 || typedValue === '') {
      return []
    }

    const pattern = typedValue
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .split('')
      .join('.*')

    const result = possibleValues.filter((item: string): boolean => {
      return item.toLowerCase().match(new RegExp(pattern, 'i')) !== null
    })

    result.sort((a, b) => {
      const distA = distance(typedValue, a)
      const distB = distance(typedValue, b)
      return distA - distB
    })

    return result
  }, [possibleValues, typedValue])

  return (
    <div
      className={classNames(className, styles.wrapper, {
        [styles.formType]: type === 'form',
        [styles.error]: defaultError,
      })}
    >
      {label !== '' && <Label modalHeading={type === 'modal'}>{label}</Label>}
      <TagsWrapper>
        <>
          {fields.map((tag: string, index: number) => {
            const tagId = fieldsId[index]
            if (tag === '') {
              return null
            }
            const tagStatus = onSetTagStatus ? onSetTagStatus(tag) : null

            const alreadyExists = onCheckIfExists ? onCheckIfExists(tag) : false

            return (
              <Tag
                key={`${tagId}`}
                tagExists={alreadyExists}
                status={tagStatus || undefined}
                onClick={() => {
                  const f = fields.concat()
                  const fi = fieldsId.concat()
                  f.splice(index, 1)
                  fi.splice(index, 1)
                  if (f.length === 0) {
                    setFields([])
                    onChange([])
                    setFieldsId([nanoid()])
                  } else {
                    setFields(f)
                    onChange(f)
                    setFieldsId(fi)
                  }
                }}
              >
                {tag}
              </Tag>
            )
          })}
          <input
            className={styles.addRow}
            name={nanoid()}
            value={typedValue}
            placeholder={placeholder}
            onBlur={() => {
              // Disable blur on typeahead
              if (possibleValues.length === 0) {
                onKeyEnter()
              }
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.keyCode === 9) {
                onKeyEnter()
              }
              if (event.keyCode === 13) {
                event.preventDefault()
                event.stopPropagation()
                onKeyEnter()
              }
              // space ,
              if (enableCommaMultiItems && event.keyCode === 188) {
                event.preventDefault()
                event.stopPropagation()
                onKeyEnter()
              }
              if (enableSpaceMultiItems && event.keyCode === 32) {
                event.preventDefault()
                event.stopPropagation()
                onKeyEnter()
              }
            }}
            onKeyUp={() => {
              if (onKeyUp) {
                onKeyUp(typedValue)
              }
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const { value: val } = event.target

              const targetInputValue = beforeChange ? beforeChange(val) : val
              setTypedValue(targetInputValue)
            }}
            onPaste={(e) => {
              if (onPaste) {
                onPaste(e)
              }
            }}
          />
        </>
      </TagsWrapper>
      {filtered.length > 0 && (
        <div className={styles.expanded}>
          {filtered.map((item) => renderItem(item))}
        </div>
      )}
    </div>
  )
}
