import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { TrackCreateIntro } from './track-create'
import ButtonTabs from '../components/button-tabs'
import CampaignCodeGeneratorForm from '../components/campaign-code-generator-form'
import GetStarted from '../components/get-started'
import Layout from '../components/layout'
import Modal from '../components/modal'
import SiteWrapper from '../components/site-wrapper'
import RecentlyCreatedLinks from '../components/track-recently-created-links'
import TwoColumns, { Column } from '../components/two-columns'
import { defaultGeneratorObject } from '../helpers/track-module'
import useMobile from '../hooks/useMobile'
import styles from '../styles/get-started.module.scss'

const props = {
  single: true,
  defaultGenerator: defaultGeneratorObject,
}

interface GetStartedPageProps {
  microsoftMarketplace?: boolean
}

export default function GetStartedPage({
  microsoftMarketplace = false,
}: GetStartedPageProps): React.ReactElement {
  const history = useHistory()

  const isMobileMenu = useMobile(1200)

  // Modal component requires a state setter
  // This page shouldn't really be using a modal component, but hey it is what it is
  const [_, setIsOpen] = useState(false)

  return (
    <>
      <SiteWrapper>
        <Layout width={1200}>
          <TrackCreateIntro />
          <TwoColumns>
            <Column main>
              <div>
                <ButtonTabs
                  selected={0}
                  isTopOfBox
                  tabsLabels={['One at a time']}
                  type="tabs"
                >
                  <CampaignCodeGeneratorForm {...props} />
                  <p />
                </ButtonTabs>
              </div>
            </Column>
            <Column
              side
              fixed
              className={styles.rightColumn}
              style={{
                top: `${isMobileMenu ? 70 : 10}px`,
              }}
            >
              <RecentlyCreatedLinks isStatic />
            </Column>
          </TwoColumns>
        </Layout>
      </SiteWrapper>
      <Modal
        className={styles.getStartedModal}
        afterClose={() => history.push('/login')}
        setIsOpen={setIsOpen}
        width="superNarrow"
        blurredBackground
        hideCloseButton
        hideFooter
      >
        <div className={styles.modalBodyContent}>
          <GetStarted microsoftMarketplace={microsoftMarketplace} />
        </div>
      </Modal>
    </>
  )
}
