import React, { useRef, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import {
  loggedInState,
  loginForm,
  LoginState,
  onboardingState,
} from '../api/apollo/variables'
import Button from '../components/button'
import DisplayError from '../components/display-error'
import Footer from '../components/footer'
import Input from '../components/input'
import Message from '../components/login-message'
import Link from '../components/link'
import SiteLogo, { LogoTagline } from '../components/site-logo'
import { GoogleSSO, MicrosoftSSO, OktaSSO } from '../components/sso-buttons'
import { supportEmail } from '../core/constants'
import { emailRegex, loginFormValidation } from '../helpers/forms'
import useAuthenticate from '../hooks/useAuthenticate'
import styles from '../styles/login.module.scss'

interface LoginDefaultProps {
  login: LoginState
  notFound: boolean
  domainExists: boolean
  expiredEmail?: boolean
  inviteOpen?: boolean
}

export const LoginDefault = ({
  login,
  notFound,
  domainExists,
  expiredEmail,
  inviteOpen,
}: LoginDefaultProps) => {
  const { getPolicy } = useAuthenticate()

  const history = useHistory()

  const emailFieldRef = useRef<HTMLInputElement>(null)

  const { email } = login

  const [emailUpdated, setEmailUpdated] = useState('')

  const domain = email.split('@').pop()

  // Ansys licence expired - show special message
  const isAnsys = domain ? domain.indexOf('ansys') > -1 : false

  if (inviteOpen) {
    return (
      <>
        <Input
          type="email"
          name="email"
          label="Email"
          autoComplete="username"
          onChange={(event: React.ChangeEvent<HTMLInputElement>): any =>
            loginForm({
              ...login,
              email: event.target.value,
            })
          }
          value={login.email}
          required
        />
        <Message
          className={styles.emailSubjectMessage}
          message={`This email address has already been invited to join. Please click the link in the invite to complete this process.\n\n**Email subject:** Register for Uplifter`}
        />
        <>
          <div className={styles.divider} />
          <h2>Can't find the email?</h2>
          <Button
            color="grey"
            className={styles.loginFormButton}
            href={`mailto:${supportEmail}`}
          >
            Contact us
          </Button>
          <Link
            className={styles.link}
            href="https://support.uplifter.ai/hc/en-us/articles/360018765078-I-can-t-login-to-Uplifter"
          >
            Need help logging in?
          </Link>
        </>
      </>
    )
  }

  return (
    <>
      <Input
        ref={emailFieldRef}
        type="email"
        name="email"
        label="Email"
        autoComplete="username"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.target.value === email) return

          if (!domainExists && !notFound) {
            loginForm({
              ...login,
              email: event.target.value,
            })
          }

          setEmailUpdated(event.target.value)
        }}
        value={login.email}
        required
      />
      {emailUpdated === email && notFound && (
        <Message message="We don't recognise your email address. If you would like to create a new organisation, sign up below." />
      )}
      {emailUpdated === email && domainExists && (
        <>
          {isAnsys ? (
            <Message
              message={`This account licence has expired, please contact ${supportEmail}`}
            />
          ) : (
            <Message message="We don't recognise your email address for this enterprise account, please try again or contact your admin." />
          )}
        </>
      )}
      {(domainExists || notFound) && (
        <Button
          type="submit"
          className={styles.loginFormButton}
          onPress={async () => {
            const currLoggedInState = loggedInState()

            loggedInState({
              ...currLoggedInState,
              policy: {
                lastMethod: '',
                ...currLoggedInState.policy,
                notFound: false,
                domainExists: false,
              },
            })

            // If email has changed, attempt new login
            if (emailUpdated !== email) {
              let message = ''

              if (!emailUpdated) {
                message = 'Email field cannot be empty'
              }

              if (!emailRegex.test(emailUpdated)) {
                message = 'Email must be valid'
              }

              if (!message) {
                await getPolicy(emailUpdated)
              }

              loginForm({
                ...login,
                email: emailUpdated,
                onFail: {
                  message,
                  attempts: !message ? 0 : login.onFail.attempts + 1,
                },
              })
            } else {
              emailFieldRef.current?.focus()
            }
          }}
        >
          {emailUpdated === email ? 'Try again with another email' : 'Continue'}
        </Button>
      )}
      {expiredEmail && (
        <>
          <h4>
            <strong>Subject</strong> Register for Uplifter
          </h4>
        </>
      )}
      {!notFound && !domainExists && (
        <Button
          type="submit"
          className={styles.loginFormButton}
          onPress={async () => {
            let message = ''

            if (!email) {
              message = 'Email field cannot be empty'
            }

            if (!emailRegex.test(email)) {
              message = 'Email must be valid'
            }

            loginForm({
              ...login,
              onFail: {
                message,
                attempts: !message ? 0 : login.onFail.attempts + 1,
              },
            })

            if (!message) {
              await getPolicy(email)
            }
          }}
        >
          Continue
        </Button>
      )}
      <p>
        Does your company already have an account?{' '}
        <Link
          className={styles.link}
          href="https://support.uplifter.ai/hc/en-us/articles/360018765078-I-can-t-login-to-Uplifter"
        >
          Get help logging in.
        </Link>
      </p>
      {!domainExists && (
        <Button
          variant="secondary"
          className={styles.loginFormButton}
          onPress={() => {
            // Reset failure messages
            loginForm({
              ...login,
              onFail: {
                message: '',
                attempts: 0,
              },
            })

            onboardingState({
              onError: '',
              createdBillingUser: false,
              paddleData: {},
            })

            history.push('/create-account')
          }}
        >
          Sign up a new company
        </Button>
      )}
    </>
  )
}

export const LoginPassword = ({ login }: Pick<LoginDefaultProps, 'login'>) => {
  const { email, password } = login

  const [isPassword, setIsPassword] = useState(true)
  const [passwordFocus, setPasswordFocus] = useState(false)
  const [loading, setLoading] = useState(false)

  const { authPassword } = useAuthenticate()

  return (
    <div className={styles.div}>
      <Input
        type="email"
        name="email"
        label="Email"
        autoComplete="username"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          loginForm({
            ...login,
            email: event.target.value,
          })
        }
        value={email}
        required
      />
      <div
        className={classNames(
          styles.passwordWrapper,
          {
            [styles.focused]: passwordFocus,
          },
          {
            [styles.blurred]: !passwordFocus,
          },
        )}
      >
        <Input
          type={isPassword ? 'password' : 'text'}
          className={styles.passwordInput}
          name="password"
          label="Password"
          autoComplete="current-password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            loginForm({
              ...login,
              password: event.target.value,
            })
          }
          value={password}
          required
          onFocus={() => setPasswordFocus(true)}
          onBlur={() => setPasswordFocus(false)}
        />

        <div>
          <Button
            variant="text"
            color="grey"
            className={styles.showPassword}
            onPress={() => setIsPassword(!isPassword)}
          >
            {isPassword ? 'Show' : 'Hide'}
          </Button>
        </div>
      </div>

      <Button
        type="submit"
        loading={loading}
        isDisabled={loading}
        className={styles.loginFormButton}
        onPress={async () => {
          setLoading(true)
          setIsPassword(true)

          const validationError = (message: string) =>
            loginForm({
              ...login,
              onFail: {
                message,
                attempts: login.onFail.attempts + 1,
              },
            })

          if (loginFormValidation(login, validationError)) {
            await authPassword({
              username: login.email,
              password: login.password,
            })
          }

          setLoading(false)
        }}
      >
        Continue
      </Button>
      <Link className={styles.link} href="/forgot-password" newTab={false}>
        Forgot password?
      </Link>

      <div className={styles.divider} />
      <h3>Or login with authentication provider</h3>
      <OktaSSO login={login} />
      <GoogleSSO login={login} />
      <MicrosoftSSO login={login} />
    </div>
  )
}

interface LoginSSOProps {
  login: LoginState
  lastMethod: 'microsoft' | 'google' | 'okta'
}

export const LoginSSO = ({ login, lastMethod }: LoginSSOProps) => {
  const { authPassword } = useAuthenticate()

  const [loading, setLoading] = useState(false)

  return (
    <>
      {lastMethod === 'okta' && <OktaSSO login={login} fetchDomains />}
      {lastMethod === 'google' && <GoogleSSO login={login} />}
      {lastMethod === 'microsoft' && <MicrosoftSSO login={login} />}
      <div className={styles.divider} />
      <h3>Login with password</h3>
      <Input
        type="email"
        name="email"
        label="Email"
        autoComplete="username"
        onChange={(event: React.ChangeEvent<HTMLInputElement>): any =>
          loginForm({
            ...login,
            email: event.target.value,
          })
        }
        value={login.email}
        required
      />
      <Input
        type="password"
        name="password"
        label="Password"
        autoComplete="current-password"
        onChange={(event: React.ChangeEvent<HTMLInputElement>): any =>
          loginForm({
            ...login,
            password: event.target.value,
          })
        }
        value={login.password}
        required
      />
      <Button
        type="submit"
        loading={loading}
        isDisabled={loading}
        className={styles.loginFormButton}
        onPress={async () => {
          setLoading(true)

          const validationError = (message: string) =>
            loginForm({
              ...login,
              onFail: {
                message,
                attempts: login.onFail.attempts + 1,
              },
            })

          if (loginFormValidation(login, validationError)) {
            await authPassword({
              username: login.email,
              password: login.password,
            })
          }

          setLoading(false)
        }}
      >
        Continue
      </Button>
      <Link className={styles.link} href="/forgot-password" newTab={false}>
        Forgot password?
      </Link>
    </>
  )
}

export default function Login() {
  const login = useReactiveVar(loginForm)
  const user = useReactiveVar(loggedInState)

  const { policy } = user

  const { lastMethod, notFound, domainExists } = policy || {
    lastMethod: '',
    notFound: false,
    domainExists: false,
  }

  return (
    <div className={styles.ssoWrapperOuter}>
      <section className={styles.ssoWrapper}>
        <form onSubmit={(e) => e.preventDefault()}>
          <SiteLogo link="/login" className={styles.svg} />
          <LogoTagline />
          <div className={styles.divider} />
          <DisplayError attempts={login.onFail.attempts}>
            {login.onFail.message}
          </DisplayError>
          {(lastMethod === '' || lastMethod === 'inviteOpen') && (
            <LoginDefault
              login={login}
              notFound={notFound}
              domainExists={domainExists}
              inviteOpen={lastMethod === 'inviteOpen'}
            />
          )}
          {lastMethod && lastMethod === 'password' && (
            <LoginPassword login={login} />
          )}
          {lastMethod &&
            (lastMethod === 'microsoft' ||
              lastMethod === 'google' ||
              lastMethod === 'okta') && (
              <LoginSSO login={login} lastMethod={lastMethod} />
            )}
        </form>
      </section>
      <Footer />
    </div>
  )
}
